import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
import { Location } from './location';

// tslint:disable-next-line:class-name
export interface iAgency {
    name: string;
    address: string;
    phones: string[];
    hours: string;
    days: string;
    deleted: boolean;
    country_path: string;
    logo: string;
    latitude: string;
    longitude: string;
    zipcode: string;
    state_path: string;
    city_path: string;
    email: string;
    timestamp: string;
    display: string;
    locker: boolean;
    pickup: boolean;
    products: boolean;
}

export class Agency extends Entity implements iAgency {

    public name: string;
    public image: string;
    public address: string;
    public phones: string[];
    public hours: string;
    public days: string;
    public deleted: boolean;
    public logo: string;
    public country_path: string;
    public state_path: string;
    public city_path: string;
    public email: string;
    public latitude: string;
    public longitude: string;
    public zipcode: string;
    public timestamp: string;
    public display: string;
    public locker: boolean;
    public pickup: boolean;
    public products: boolean;

    public get country() {
        if (this.country_path) {
            return new Location(this.country_path);
        }
    }

    public set country(value: any) {
        this.country_path = value.__path;
    }

    public get state() {
        if (this.state_path) {
            return new Location(this.state_path);
        }
    }

    public set state(value: any) {
        this.state_path = value.__path;
    }

    public get city() {
        if (this.city_path) {
            return new Location(this.city_path);
        }
    }

    public set city(value: any) {
        this.city_path = value.__path;
    }

    public __toPromise() {
        return this.__promise as Promise<Agency>;
    }

    public __asObservable() {
        return (this.__subject.asObservable() as Observable<Agency>);
    }

    /**
     * @constructor
     * @param {string} path Specifies the navigation location of an agency
     * @param {string} name Name of the agency
     * @param {string} image Agency's logo
     * @param {string} address Agency's address
     * @param {string[]} phones Agency's phone numbers
     * @param {string} hours Working hours
     * @param {string} days Working days
     * @param {boolean} deleted Is the agency deleted or not
     * @param {string} logo Agency's logo
     * @param {string} country_path Agency's country for its location
     * @param {string} state_path Agency's state for its location
     * @param {string} city_path Agency's city for its location
     * @param {string} email Agency's email
     * @param {string} latitude Agency's latitude
     * @param {string} longitude Agency's longitude
     * @param {string} zipcode Agency's zipcode
     * @param {string} timestamp Encoded information identifying when the agency was created
     * @param {string} display
     * @param {boolean} locker Can/can't use locker
     * @param {boolean} pickup Can/can't use pickup
     * @param {boolean} products Can/can't use products
     */
    constructor(path: string,
        name?: string,
        image?: string,
        address?: string,
        phones?: string[],
        hours?: string,
        days?: string,
        // deberiamos poner deleted como false al iniciarse una nueva
        // agencia siempre?
        deleted?: boolean,
        logo?: string,
        country_path?: string,
        state_path?: string,
        city_path?: string,
        email?: string,
        latitude?: string,
        longitude?: string,
        zipcode?: string,
        timestamp?: string,
        display?: string,
        locker?: boolean,
        pickup?: boolean,
        products?: boolean,
    ) {
        super(Agency, path);
        if (name) {
            this.name = name;
        }
        if (image) {
            this.image = image;
        }
        if (logo) {
            this.logo = logo;
        }
        if (address) {
            this.address = address;
        }
        if (phones) {
            this.phones = phones;
        }
        if (hours) {
            this.hours = hours;
        }
        if (days) {
            this.days = days;
        }
        if (deleted) {
            this.deleted = deleted;
        }
        if (country_path) {
            this.country_path = country_path;
        }
        if (state_path) {
            this.state_path = state_path;
        }
        if (city_path) {
            this.city_path = city_path;
        }
        if (email) {
            this.email = email;
        }
        if (latitude) {
            this.latitude = latitude;
        }
        if (longitude) {
            this.longitude = longitude;
        }
        if (zipcode) {
            this.zipcode = zipcode;
        }
        if (timestamp) {
            this.timestamp = timestamp;
        }
        if (display) {
            this.display = display;
        }
        if (locker) {
            this.locker = locker;
        }
        if (pickup) {
            this.pickup = pickup;
        }
        if (products) {
            this.products = products;
        }
    }
}
