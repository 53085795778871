import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product, Inventory, User, Agency } from 'src/app/models';
import { InventoryService } from '../../services/inventory.service';
import { ProductService } from '../../services/product.service';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { InventoryRequestService } from 'src/app/services/inventory-request.service';
import { AgencyService } from 'src/app/services/agency.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {

  requestForm: FormGroup;
  profile: User;
  filter = false;
  modal = false;
  selected: Inventory;
  isLoading = false;
  filterProduct: string;
  filterAgency: string;

  allInventories: Observable<Collection<Inventory>>;
  inventories: Observable<Collection<Inventory>>;
  products: Observable<Collection<Product>>;
  agencies: Observable<Collection<Agency>>;

  /**
   * @constructor
   * @param {FormBuilder} fb Creates an inventory request form
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks
   * @param {UserService} userService Service for users management
   * @param {InventoryService} inventoryService Service for inventories management
   * @param {InventoryRequestService} ivRequestService Service for inventory requests management
   * @param {ProductService} productService Service for products management
   * @param {AlertService} alertService Service for alerts management
   */
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public userService: UserService,
    private inventoryService: InventoryService,
    private ivRequestService: InventoryRequestService,
    private productService: ProductService,
    private alertService: AlertService,
    private agencyService: AgencyService) { }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      this.profile = p;
      this.getData(this.profile);
    });
    this.createRequestForm();
  }

  /**
   * Description: Gets all the products. If the role of the current logged user is admin, gets all the
   * agencies inventories, else it gets only the inventories of the agency of the current logged user.
   * @author Mauro Lanza
   * @param {any} profile Currently logged user
   * @returns void
   */
  getData(profile): void {
    if (profile) {
      this.products = this.productService.getAll().asObservable();
      if (profile.role === 'admin') {
        this.agencies = this.agencyService.getAll().asObservable();
        this.getInventories();
      } else {
        this.filterAgency = profile.agency_path;
        this.allInventories = this.inventoryService.getAll(ref => {
          return ref.where('agency_path', '==', this.filterAgency);
        }).asObservable();
        this.getInventories();
      }
    }
  }

  getInventories() {
    if (this.filterAgency && this.filterProduct) {
      this.inventories = this.inventoryService.getAll(ref => {

        if (this.filter) {
          if (this.profile.role === 'admin') {
            return ref.where('agency_path', '==', this.filterAgency)
              .where('authorized', '==', false)
              .where('product_path', '==', this.filterProduct);
          } else {
            return ref.where('agency_path', '==', this.filterAgency)
              .where('available', '==', false)
              .where('product_path', '==', this.filterProduct);
          }
        } else {
          if (this.profile.role === 'admin') {
            return ref.where('agency_path', '==', this.filterAgency)
              .where('authorized', '==', true)
              .where('product_path', '==', this.filterProduct);
          } else {
            return ref.where('agency_path', '==', this.filterAgency)
              .where('available', '==', true)
              .where('product_path', '==', this.filterProduct);
          }
        }
      }).asObservable();

    } else if (this.filterProduct) {
      this.inventories = this.inventoryService.getAll(ref => {

        if (this.filter) {
          if (this.profile.role === 'admin') {
            return ref.where('authorized', '==', false)
              .where('product_path', '==', this.filterProduct);
          } else {
            return ref.where('available', '==', false)
              .where('product_path', '==', this.filterProduct);
          }
        } else {
          if (this.profile.role === 'admin') {
            return ref.where('authorized', '==', true)
              .where('product_path', '==', this.filterProduct);
          } else {
            return ref.where('available', '==', true)
              .where('product_path', '==', this.filterProduct);
          }
        }
      }).asObservable();

    } else if (this.filterAgency) {
      this.inventories = this.inventoryService.getAll(ref => {

        if (this.filter) {
          if (this.profile.role === 'admin') {
            return ref.where('agency_path', '==', this.filterAgency)
              .where('authorized', '==', false);
          } else {
            return ref.where('agency_path', '==', this.filterAgency)
              .where('available', '==', false);
          }
        } else {
          if (this.profile.role === 'admin') {
            return ref.where('agency_path', '==', this.filterAgency)
              .where('authorized', '==', true);
          } else {
            return ref.where('agency_path', '==', this.filterAgency)
              .where('available', '==', true);
          }
        }
      }).asObservable();

    } else {
      // this.inventories = undefined;

      this.inventories = this.inventoryService.getAll(ref => {

        if (this.filter) {
          if (this.profile.role === 'admin') {
            return ref.where('authorized', '==', false);
          } else {
            return ref.where('available', '==', false);
          }
        } else {
          if (this.profile.role === 'admin') {
            return ref.where('authorized', '==', true);
          } else {
            return ref.where('available', '==', true);
          }
        }
      }).asObservable();
    }

  }

  createRequestForm() {
    this.requestForm = this.fb.group({
      stock: ['', [Validators.required, Validators.pattern('^[1-9][0-9]*$')]],
    });
  }

  /**
   * Description: Asks for confirmation before deleting an inventory. If the deletion is confirmed,
   * makes the deleted inventory unavailable and updates its data in the database.
   * @author Mauro Lanza
   * @param {any} inventory Inventory to delete
   * @returns void
   */
  toggleAvailable(inventory: any): void {
    const check = confirm(`Are you sure you want to ${inventory.available ? 'delete' : 'restore'} "${inventory.product.name}" ?`);
    if (check) {
      inventory.available = !inventory.available;
      this.inventoryService.update(inventory);
    }
  }

  /**
   * Description: Navigates to details route of the inventory that matches the id received as param.
   * @author Mauro Lanza
   * @param inventory
   */
  toggleAuthorized(inventory: any): void {
    const check = confirm(`Are you sure you want to ${inventory.authorized ? 'unauthorize' : 'authorize'} "${inventory.product.name}" ?`);
    if (check) {
      inventory.authorized = !inventory.authorized;
      this.inventoryService.update(inventory);
    }
  }

  /**
   * @author Mauro Lanza
   * @param id
   */
  viewDetails(id: string): void {
    this.router.navigate(['store/inventory/details', id]);
  }

  /**
   * Description: Sets as selected the inventory received as param.
   * @author Mauro Lanza
   * @param {Inventory} inventory Selected inventory
   * @returns void
   */
  request(inventory: Inventory) {
    this.selected = inventory;
    this.modal = true;
  }

  /**
   * Description: Clears form and closes modal.
   * @author Mauro Lanza
   * @returns void
   */
  modalCancel() {
    this.selected = undefined;
    this.requestForm.reset();
    this.modal = false;
  }

  /**
   * Description: Creates a new inventory request. If it is created successfully, shows a success alert.
   * If there is an error, shows a warning alert.
   * @author Mauro Lanza
   * @returns void
   */
  modalRequest() {
    try {
      this.isLoading = true;

      const data = this.requestForm.value;
      data.stock = parseInt(this.requestForm.value.stock, 10);
      data.product_path = this.selected.product_path;
      data.agency_path = this.selected.agency_path;
      data.inventory_path = this.selected.__path;

      this.ivRequestService.create(data).then(() => {
        this.alertService.showAlert('Request created successfully', 'alert-success');
        this.isLoading = false;
        this.modalCancel();
      });
    } catch (error) {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }
}
