import { Component, Input, SimpleChanges, EventEmitter, OnChanges } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Order } from '../../models/order';

@Component({
  selector: 'app-manifest-filter',
  template: ``,
  styleUrls: ['../../app.component.scss']
})
export class ManifestClrDatagridFilterInterfaceComponent implements ClrDatagridFilterInterface<Order>, OnChanges {

  // boolean for filtering
  @Input() checked: boolean;
  changes: EventEmitter<any> = new EventEmitter<any>(false);


  /**
  * Description: Returns true if there are items checked in the manifests list or false if there
  * are not.
  * @author Jorge Del Castillo
  * @returns {any}
  */
  public isChecked(): any {
    console.log('checked', this.checked);

    if (this.checked != null) {
      return this.checked;
    } else {
      return false;
    }
  }


  /**
   * Description: Emits true if there is a change in the manifest filter.
   * @author Jorge Del Castillo
   * @param {SimpleChanges} changes
   * @returns void
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.checked) {
      this.changes.emit(true);
    }
  }

  /**
   * Description: Returns true.
   * @author Jorge Del Castillo
   * @returns {boolean}
   */
  isActive(): boolean {
    return true;
  }

  /**
   * Description: Returns true everytime is called.
   * @param order
   * @returns {boolean}
   */
  accepts(order: Order) {
    return true;
  }

}
