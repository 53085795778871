import { Injectable } from '@angular/core';

import { Manifest } from '../models';
import { Entity, Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { DatePipe } from '@angular/common';
import { Subject, Observable } from 'rxjs';
import { XlsxService } from './xlsx.service';
import { iManifest } from '../models/manifest';
import { iAgency } from '../models/agency';
import { iOrder } from '../models/order';
import { AXInput, AXPages, AXPage } from 'aether-xlsx';
import { iShipping } from '../models/shipping';
import { Shipping } from '../models/shipping';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {

  private pathUrl: string = '/shippings/';
  public shipping: Shipping;

  /**
   * @constructor
   * @param {DatePipe} datePipe Formats a date value according to locale rules 
   */
  constructor(
    private datePipe: DatePipe,
  ) { }

  /**
   * Description: Gets a specific shipping by its id.
   * @author Maximiliano Casale 
   * @param {string} id
   * @returns {Shipping}  
   */
  public get(id: string): Shipping {
    return new Shipping(this.pathUrl + id);
  }

  /**
   * Description: Gets all the collection of shipments.
   * @author Maximiliano Casale
   * @param query 
   * @returns {Collection<Shipping>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Shipping> {
    if (query) {
      return new Collection<Shipping>(Shipping, this.pathUrl, query);
    } else {
      return new Collection<Shipping>(Shipping, this.pathUrl);
    }
  }

  /**
   * Description: Gets only the shipments where the year is the same as the one in the date received as param.
   * @author Maximiliano Casale
   * @param {any} date
   * @returns {Collection<Shipping>} 
   */
  public getNextId(date) {
    return this.getAll((ref) => { return ref.orderBy('number', 'desc').where('year', '==', this.datePipe.transform(date, 'yy')).limit(1) });
  }

  /**
   * Description: Gets the number that corresponds to the next shipping. Allows shipments indexing.
   * @author Sarkis Bazdikian 
   * @returns {Promise<any>}
   */
  public getNextOrderNumber() {
    return new Promise<any>((resolve) => {
      firebase.firestore().collection('/index/').doc('shippings').get().then(query => {
        console.log((query as any).data());
        if (!query.exists) {
          firebase.firestore().collection('/index/').doc('shippings').set({
            'total': 1
          }).then(() => { resolve(1); })
        } else {
          var data = query.data();
          firebase.firestore().collection('/index/').doc('shippings').update({
            'total': data.total + 1
          }).then(() => { resolve(data.total + 1) })
        }
      });
    })
    // ???
    // return 0  
  }

  /**
   * Description: Gets the year of the date received as param.
   * @author Maximiliano Casale
   * @param {string} date
   * @returns {number} Year 
   */
  public getYear(date: string): number {
    return parseInt(date.substring(6));
  }

  /**
   * Description: Gets the month of the date received as param.
   * @author Maximiliano Casale
   * @param {string} date
   * @returns {number} Month 
   */
  public getMonth(date: string): number {
    return parseInt(date.substring(0, 2)) - 1;
  }

  /**
   * Description: Gets the day of the date received as param.
   * @author Maximiliano Casale
   * @param {string} date
   * @returns {number} Day 
   */
  public getDay(date: string): number {
    return parseInt(date.substring(3, 5));
  }

  /**
   * Description: If there is a filter applied, gets all the shipments that match the filter's criteria. Else, it 
   * gets all the shipments that match the requested type and that are not marked as deleted.
   * @author Maximiliano Casale
   * @param {any} filters Filters applied 
   * @param {any} pagination 
   * @param {any} type Requested type of shipping
   * @returns {Collection<Shipping>}
   */
  public searchFiltered(filters, pagination, type): Collection<Shipping> {
    return this.getAll(ref => {
      let query = ref as any;
      if (filters[0]) {

        let f = filters[0]
        if (f && f[0] === 'dateFilter') {
          var from = new Date(this.getYear((f[1] as any).from), this.getMonth((f[1] as any).from), (this.getDay((f[1] as any).from)));
          var to = new Date(this.getYear((f[1] as any).to), this.getMonth((f[1] as any).to), (this.getDay((f[1] as any).to)) + 1);
          query = query.where('createdAt', '>=', firebase.firestore.Timestamp.fromDate(from)).where('createdAt', '<=', firebase.firestore.Timestamp.fromDate(to));
        }
      }
      return query.where('type', '==', type).where('deleted', '==', false);
    })
  }

  /**
   * Description: Creates a new shipping.
   * @author Maximiliano Casale
   * @param {string} id  
   * @param {string[]} manifests_path 
   * @param {any} createdAt 
   * @param {string} createdBy 
   * @param {string} agency_path 
   * @param {number} number 
   * @param {string} year 
   * @param {boolean} deleted 
   * @param {string} type 
   * @returns {Shipping}
   */
  public create(id: string, manifests_path: string[], createdAt: any, createdBy: string, agency_path: string, number: number, year: string, deleted: boolean, type: string): Shipping {
    let doc = firebase.firestore().collection(this.pathUrl).doc();
    let shipping = new Shipping(doc.path, id, manifests_path, firebase.firestore.FieldValue.serverTimestamp(), createdBy, agency_path, number, deleted, year, type);
    shipping.__save();
    return shipping;
  }

  /**
   * Description: Updates a shipping's data.
   * @author Maximiliano Casale
   * @param {Shipping} data 
   * @returns {Promise<void>} 
   */
  public update(data: Shipping): Promise<void> {
    return new Promise<void>(_ => {
      data.__save(true);
    })
  }

  /**
   * Description: Marks a shipping as deleted.
   * @param {string} id Shipping deleted
   * @returns {Promise<void>}  
   */
  public delete(id: string): Promise<void> {
    return new Promise<void>(_ => {
      let shipping = new Shipping(this.pathUrl + id);
      shipping.manifests_path = [];
      shipping.deleted = true;
      this.update(shipping);
    })
  }

}
