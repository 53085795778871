import { Injectable } from '@angular/core';
import { Inventory, Product } from '../models';

import { Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  private pathUrl = '/inventories/';
  public inventory: Inventory;

  /**
   * @constructor
   * @param {AngularFirestore} afs Document-oriented database
   */
  constructor(
    private afs: AngularFirestore,
    private productService: ProductService,
  ) { }

  /**
   * Description: Gets a requested inventory by its id.
   * @author Mauro Lanza
   * @param {string} id Id of the requested inventory
   * @returns {Inventory} Inventory requested
   */
  public get(id: string): Inventory {
    return new Inventory(this.pathUrl + id);
  }

  /**
   * Description: Gets all the inventories of the agency and product received as params.
   * @author Mauro Lanza
   * @param {string} agency_path
   * @param {string} product_path
   * @returns {Collection<Inventory>} Inventories
   */
  public getWhere(agency_path: string, product_path: string) {
    return this.getAll(ref => ref.where('agency_path', '==', agency_path).where('product_path', '==', product_path));
  }

  /**
   * Description: Gets all the inventories that contain specific characteristics.
   * @author Mauro Lanza
   * @param query
   * @returns {Collection<Inventory>} Inventories
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Inventory> {
    if (query) {
      return new Collection<Inventory>(Inventory, this.pathUrl, query);
    } else {
      return new Collection<Inventory>(Inventory, this.pathUrl);
    }
  }

  /**
   * Description: Adds a new inventory to the collection.
   * @author Mauro Lanza
   * @param {any} inventory New inventory
   * @returns {Promise<Inventory>} Addition confirmation
   */
  public create(inventory): Promise<Inventory> {
    const product = new Product(inventory.product_path);
    if (product.agencies == null) {
      product.agencies = [];
    }
    product.agencies.push(inventory.agency_path);
    this.productService.update(product);
    return new Promise<Inventory>((resolve) => {
      firebase.firestore().collection(this.pathUrl).add(inventory).then((docRef) => {
        this.get(docRef.id).__toPromise().then((p) => {
          resolve(p);
        });
      });
    });
  }

  /**
   * Description: Updates an inventory's data in the database.
   * @author Mauro Lanza
   * @param {Inventory} data Data to update
   * @returns {Promise<void>} Update confirmation
   */
  public update(data: Inventory): Promise<void> {
    return new Promise<void>((resolve) => {
      data.__save(true).then(() => resolve());
    });
  }

  /**
   * Description: Subtracts the quantity received as a parameter from the stock of an inventory.
   * @author Mauro Lanza
   * @param {string} path Inventory
   * @param {number} quantity
   */
  public reduceStock(path: string, quantity: number) {
    const inventory = new Inventory(path);
    if (inventory.stock >= quantity) {
      inventory.stock -= quantity;
      this.update(inventory);
    }
  }

}
