import { Injectable } from '@angular/core';

import { Rate, Location } from '../models';
import { Entity, Collection } from 'aether-blaze';
import * as firebase from 'firebase';

import { Subject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RateService {

  private pathUrl = '/rates/';
  private refUrl = '/agencies/';
  public rate: Rate;

  public editingRate: Rate;

  /**
   * @constructor
   */
  constructor() { }

  /**
   * Description: Gets a zone rate by its id.
   * @author Rafael Quintero
   * @param {string} id
   * @returns {Rate}
   */
  public get(id: string): Rate {
    return new Rate(this.pathUrl + id);
  }

  /**
   * Description: Gets all the collection of rates.
   * @author Rafael Quintero
   * @param query
   * @returns {Collection<Rate>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Rate> {
    if (query) {
      return new Collection<Rate>(Rate, this.pathUrl, query);
    } else {
      return new Collection<Rate>(Rate, this.pathUrl);
    }
  }

  /**
   * Description: Creates a new rate.
   * @author Rafael Quintero
   * @param {Rate} newRate
   * @returns {Promise<Rate>}
   */
  public create(newRate: Rate): Promise<Rate> {
    return new Promise<Rate>((resolve) => {
      const doc = firebase.firestore().collection(this.pathUrl).doc();
      const rate = new Rate(
        doc.path,
        newRate.name,
        newRate.type,
        newRate.insurance,
        newRate.active,
        firebase.firestore.FieldValue.serverTimestamp(),
        newRate.aerialRate,
        newRate.maritimeRate,
        newRate.agency_path,
        false,
        newRate.locations,
        newRate.customsManagement,
        newRate.minCustomsManagement
      );
      rate.__save();
      return resolve(rate);
    });
  }

  /**
   * Description: Updates the data of the rate received as param.
   * @author Rafael Quintero
   * @param {Rate} data
   * @returns {Promise<Rate>}
   */
  public update(data: Rate): Promise<void> {
    /// TODO: Set 'Location.display = false' when Location is no longer in use!
    return data.__save(false).then(() => {
      const promises = [];
      for (const c of data.locations) {
        promises.push(
          (new Location(c.country))
            .__toPromise().then((loc) => {
              loc.display = true;
              return loc.__save();
            }).catch((err) => console.error(err))
        );
      }
      return Promise.all(promises).then(() => null);
    });
  }

  // public delete(id: string): void {
  //   let rate = new Rate( this.pathUrl + id );
  //   rate.deleted = true;
  //   this.update(rate);
  // }

}
