import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Order, User } from '../models';
import { Entity, Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { user } from 'firebase-functions/lib/providers/auth';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private pathUrl = '/orders/';
  private refUrl = '/users/';
  public order: Order;
  private selectedOrder: Order;

  /**
   * @constructor
   * @param {DatePipe} datePipe Formats a date value according to locale rules
   */
  constructor(private datePipe: DatePipe) { }

  /**
   * Description: Gets a package order by its id.
   * @author Sarkis Bazdikian
   * @param {string} id Id of the package order
   * @param {boolean} path
   * @returns {Order} Package order
   */
  public get(id: string, path?: boolean): Order {
    if (path) {
      return new Order(id);
    } else {
      return new Order(this.pathUrl + id);
    }
  }

  /**
   * Description: Gets all the package orders.
   * @author Sarkis Bazdikian
   * @param query
   * @returns {Collection<Order>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Order> {
    if (query) {
      return new Collection<Order>(Order, this.pathUrl, query);
    } else {
      return new Collection<Order>(Order, this.pathUrl);
    }
  }

  /**
   * Description: Adds a new package order to the collection with the data received as params.
   * @author Sarkis Bazdikian
   * @param {Order} orderData Order values: Promotion, Shipping Type, Departure date.
   *  Boxes: Packing Services, Insured value, Declared value, Content description, Boxes Dimensions
   * @param {any} senderData Data of the person sending
   * @param {any} receiverData Data of the recipient
   * @param {any} addressData Shipping Address
   * @returns {Promise<Order>} Added order
   */
  public create(orderData: Order, senderData: any, receiverData: any, addressData: any): Promise<Order> {
    return new Promise<Order>((resolve) => {
      // let doc = firebase.firestore().collection(this.pathUrl).doc();
      // console.log('ORDERDATA: ', orderData);
      orderData.recipient = receiverData;
      orderData.sender = senderData;
      orderData.address = addressData;
      orderData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      firebase.firestore().collection(this.pathUrl).add(orderData)
        .then((docRef) => {this.get(docRef.id).__toPromise().then((p) => { resolve(p); }); });
    });
  }

  /**
   * Description: Gets the package orders of the year of the date received as param.
   * @author Sarkis Bazdikian
   * @param {any} date
   * @returns {Collection<Order>}
   */
  public getNextId(date) {
    return this.getAll((ref) => {
      return ref.orderBy('number', 'desc')
        .where('year', '==', this.datePipe.transform(date, 'yy'))
        .limit(1);
    });
  }

  /**
   * Description: Gets the number of the next package order. Allows indexing of package orders.
   * @author Sarkis Bazdikian
   * @returns {Promise<any>}
   */
  public getNextOrderNumber() {
    return new Promise<any>((resolve) => {
      firebase.firestore().collection('/index/').doc('orders').get().then(query => {
        console.log((query as any).data());
        if (!query.exists) {
          firebase.firestore().collection('/index/').doc('orders').set({
            'total': 1
          }).then(() => { resolve(1); });
        } else {
          const data = query.data();
          firebase.firestore().collection('/index/').doc('orders').update({
            'total': data.total + 1
          }).then(() => { resolve(data.total + 1); });
        }
      });
    });
    // ???
    // return 0
  }

  /**
   * Description: Updates a package order's data.
   * @author Sarkis Bazdikian
   * @param {Order} data
   * @returns {Promise<void>} Update confirmation
   */
  public update(data: Order): Promise<void> {
    return new Promise<void>((resolve) => {
      data.__save(true).then(() => resolve());
    });
  }

  /**
   * Description: Gets the selected order from the package orders list.
   * @author Sarkis Bazdikian
   * @returns {Order}
   */
  public getSelected(): Order {
    return this.selectedOrder;
  }

  /**
   * Description: Sets the order received as param as the current selected order in order to show its details.
   * @author Sarkis Bazdikian
   * @param {Order} order
   * @returns void
   */
  public setSelected(order: Order): void {
    this.selectedOrder = order;
  }

  /**
   * Description: Gets the year from the date format received as param.
   * @author Sarkis Bazdikian
   * @param {string} date
   * @returns {number}
   */
  public getYear(date: string): number {
    return parseInt(date.substring(6), 10);
  }

  /**
   * Description: Gets the month from the date format received as param.
   * @author Sarkis Bazdikian
   * @param {string} date
   * @returns {number}
   */
  public getMonth(date: string): number {
    return parseInt(date.substring(0, 2), 10) - 1;
  }

  /**
   * Description: Gets the day from the date format received as param.
   * @author Sarkis Bazdikian
   * @param {string} date
   * @returns {number}
   */
  public getDay(date: string): number {
    return parseInt(date.substring(3, 5), 10);
  }

  /**
   * Description: Searches package orders depending on the filter applied and role of the user doing the search.
   * @author Sarkis Bazdikian
   * @param {any} filters
   * @param {any} pagination
   * @param {string} type
   * @param {User} user
   * @returns {Collection<Order>}
   */
  public searchFiltered(filters, pagination, type: string, user?: User): Collection<Order> {
    return this.getAll(ref => {
      let query = ref as any;
      for (const f of filters) {
        if (f[0] === 'dateFilter') {
          console.log('siempre');

          // var from = new Date(this.getYear((f[1] as any).from), this.getMonth((f[1] as any).from), (this.getDay((f[1] as any).from)));
          // var to = new Date(this.getYear((f[1] as any).to), this.getMonth((f[1] as any).to), (this.getDay((f[1] as any).to)) + 1);
          // query = query.orderBy('createdAt').where('createdAt', '>=', f
          // irebase.firestore.Timestamp.fromDate(from)).where('createdAt', '<=', firebase.firestore.Timestamp.fromDate(to));
        } else if (f[0] === 'manifestFilter') {
          // console.log('que es esto', f, f[0]);

          if (f[1]) {
            console.log(true);
            // .where('search_label', '>=', text)
            // .where('search_label', '<=', text + '\uf8ff')
            query = query.orderBy('manifest', 'asc').where('manifest', '>=', 'manifest').where('manifest', '<=', 'manifest' + '\uf8ff');
            // .where('manifest', '<=', 'manifest' + '\uf8ff')
          } else {
            console.log(false);
            query = query.where('manifest', '==', '???');

          }
          // .where('manifest', '<=', 'manifests/' + '\uf8ff')
        } else {
          query = query.where(f[0], '==', f[1][0]);
        }
      }
      if (user.role === 'admin') {
        return query.where('type', '==', type)
          // .where('deleted', '==', false)
          .orderBy('number', 'desc');
      } else {
        return query.where('agency_path', '==', user.agency_path)
          // .where('deleted', '==', false)
          .where('type', '==', type)
          .orderBy('number', 'desc');
      }
    });
  }

}
