import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  /**
   * @constructor
   * @param {HttpClient} http Offers a simplified client HTTP API that rests on the XMLHttpRequest interface exposed by browsers
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Description: Updates locker's mail with user's name and email.
   * @author Maximiliano Casale
   * @param {any} mailData
   * @param {User} user
   * @returns {Promise<any>} Update confirmation
   */
  public updateLockerMail(mailData, user: User): Promise<any> {
    mailData.user = {
      name: user.name,
      email: user.email
    };
    return new Promise<any>((resolve) => {
      // PROD
       const endpoint = 'https://us-central1-ccarga-xpress-2.cloudfunctions.net/lockerUpdatedMail';
      // DEVELOP
      console.log({ data: mailData });
      // const endpoint = 'https://us-central1-ccarga-xpress-2-dev.cloudfunctions.net/lockerUpdatedMail';
      this.http.post(endpoint, { data: mailData }).toPromise().then(res => {
        console.log(res);
      }).catch(err => {
        console.error(err);
      });
    });
  }
}// end of class
