import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShippingService } from 'src/app/services/shipping.service';
import { Shipping } from 'src/app/models/shipping';

@Component({
  selector: 'app-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss']
})
export class ShipmentDetailsComponent implements OnInit {

  shipmentDetails: Shipping;

  // shipmentDetails = {
  //   id: '-LEggLZ7uwzff_COtNYN',
  //   manifests: ['-LEggFXJLcvqvuU4NbP0', '-LEgfGYONHGm9vqqkX67', '-LEgf-5NvFXNNYxnTs7o', '-LEgeXfeIEUHi_vQE3Lw'],
  //   date: '6/12/2018, 11:02 PM'
  // };

  /**
   * @constructor
   * @param {ActivatedRoute} route Current navigation route
   * @param {ShippingService} shippingService Service for shipping management
   */
  constructor(
    private route: ActivatedRoute,
    private shippingService: ShippingService
  ) { }

  ngOnInit() {
    this.getDetails();
  }

  /**
   * Description: Passes the selected shipment's id obtained from the route to the shipment details 
   * view.
   * @author Mauro Lanza
   * @returns void 
   */
  getDetails() {
    // shipmentDetails = this.manifestService.getById( this.route.snapshot.params['id'] )
    // return this.route.snapshot.params['id'];
    // this.shipmentDetails = this.manifestService.get( this.route.snapshot.params['id'] )
    // return this.route.snapshot.params['id'];
    this.route.params.first().subscribe(params => {
      this.shippingService.get(params['id']).__toPromise().then((p) => {
        this.shipmentDetails = p;
        // console.log(p);
      });
    });
  }

}
