import { Component, OnInit } from '@angular/core';
import { PromotionService } from 'src/app/services/promotion.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Promotion } from 'src/app/models';
import { Location } from '@angular/common';

@Component({
  selector: 'app-promotions-details',
  templateUrl: './promotions-details.component.html',
  styleUrls: ['./promotions-details.component.scss']
})
export class PromotionsDetailsComponent implements OnInit {

  promotion: Promotion;

  /**
   * @constructor
   * @param {Router} router 
   * @param {Location} location Current navigation location
   * @param {ActivatedRoute} route Current navigation route 
   * @param {PromotionService} promotionsService Service for Promotions Management
   */
  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private promotionsService: PromotionService) { }

  ngOnInit() {
    this.promotion = this.promotionsService.get(this.route.snapshot.params['id']);
  }

  /**
   * Description: Navigates to the previous navigation view.
   * @author Mauro Lanza
   * @returns void
   */
  goBack(): void {
    this.location.back();
  }

}
