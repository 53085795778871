import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location as navLocation } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AgencyService } from '../../../services/agency.service';
import { Agency, Location } from '../../../models';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { LocationsService } from '../../../services/locations.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertService } from 'src/app/services/alert.service';


@Component({
  selector: 'app-agency-edit',
  templateUrl: './agency-edit.component.html',
  styleUrls: ['./agency-edit.component.scss']
})
export class AgencyEditComponent implements OnInit {

  public agencyForm: FormGroup;
  country: any;
  state: any;
  city: any;
  countries: Collection<Location>;
  public states: Observable<Collection<Location>>;
  public cities: Observable<Collection<Location>>;
  public agency: Agency;
  imgUrl: string;
  uploadFolder = 'agencies-logos';
  timestamp: string;
  isLoading: boolean;
  safeSrc: SafeResourceUrl;

  /**
   * @constructor
   * @param {FormBuilder} fb Builder of the form for an agency data
   * @param {navLocation} location Current navigation location
   * @param {ActivatedRoute} route Current route
   * @param {AgencyService} agencyService Service for the agencies management
   * @param {LocationsService} locationsService Service for the locations management
   * @param {AlertService} alertService Service for the alerts management
   * @param {DomSanitizer} sanitizer Sanitizes values to be safe to use in the different DOM contexts
   */
  constructor(
    private fb: FormBuilder,
    private location: navLocation,
    private route: ActivatedRoute,
    private agencyService: AgencyService,
    public locationsService: LocationsService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getCountries();
  }

  /**
   * Description: Allows upload of the new agency's logo image. 
   * @author Jorge Del Castillo
   * @param {Observable<string>} url Url of the image to upload
   * @returns void
   */
  onUpload(url: Observable<string>): void {
    url.subscribe((s) => {
      this.imgUrl = s;
      // console.log(s);
    });
  }

  /**
   * Description: Fills the actual form with the info of the agency selected to edit.
   * @author Jorge Del Castillo
   * @returns void
   */
  fillAgencyForm() {
    this.agencyService.get(this.route.snapshot.params['id']).__asObservable()._subscribe((p) => {
      this.agency = p;
      this.imgUrl = this.agency.logo;
      this.timestamp = this.agency.timestamp || new Date().getTime().toString();
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(p.logo) || null;
      // this.imgUrl = this.safeSrc ? this.safeSrc : null;
      // this.agency.__freeze();

      this.agencyForm.setValue({
        name: this.agency.name ? this.agency.name : '',
        address: this.agency.address ? this.agency.address : '',
        zipcode: this.agency.zipcode ? this.agency.zipcode : '',
        phone1: this.agency.phones[0] ? this.agency.phones[0] : '',
        phone2: this.agency.phones[1] ? this.agency.phones[1] : '',
        hours: this.agency.hours ? this.agency.hours : '',
        days: this.agency.days ? this.agency.days : '',
        country_path: this.agency.country_path ? this.agency.country_path : '',
        state_path: this.agency.state_path ? this.agency.state_path : '',
        city_path: this.agency.city_path ? this.agency.city_path : '',
        latitude: this.agency.latitude ? this.agency.latitude : '',
        longitude: this.agency.longitude ? this.agency.longitude : '',
        display: this.agency.display ? this.agency.display : '',
        locker: this.agency.locker ? this.agency.locker : '',
        pickup: this.agency.pickup ? this.agency.pickup : '',
        products: this.agency.products ? this.agency.products : ''
      });
    });
  }

  /**
   * Description: Creates a new form for the info of the agency selected to edit.
   * @author Jorge Del Castillo
   * @returns void
   */
  createForm() {
    this.agencyForm = this.fb.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      phone1: ['', [Validators.required]],
      phone2: [''],
      // phone1: ['', [Validators.required, Validators.pattern('[0-9]{1,11}')]],
      // phone2: ['', [Validators.pattern('[0-9]{1,11}')]],
      hours: [''],
      days: [''],
      country_path: ['', [Validators.required]],
      state_path: ['', [Validators.required]],
      city_path: ['', [Validators.required]],
      latitude: ['', [Validators.required]],
      longitude: ['', [Validators.required]],
      // latitude: ['', [Validators.required,  Validators.pattern('[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?)')]],
      // longitude: ['', [Validators.required, Validators.pattern('[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)')]],
      display: ['', [Validators.required]],
      locker: [''],
      pickup: [''],
      products: ['']
    });
    this.fillAgencyForm();
    this.formControlValueChanges();

  }

  /**
   * Description: Shows different states or cities depending on the country and the state selected.
   * @author Jorge Del Castillo
   * @returns void
   */
  formControlValueChanges() {
    this.agencyForm.controls['country_path'].valueChanges.subscribe(
      (selectedValue) => {
        this.country = selectedValue;
        this.getStates();
      }
    );
    this.agencyForm.controls['state_path'].valueChanges.subscribe(
      (selectedValue) => {
        this.state = selectedValue;
        this.getCities();
      }
    );
  }

  /**
   * Description: Navigates back to the previous view.
   * @author Jorge Del Castillo
   * @returns void
   */
  cancel(): void {
    // this.agencyForm.reset(); ???
    this.location.back();
  }

  /**
   * Description: Gets all the countries available.
   * @author Jorge Del Castillo
   * @returns void
   */
  getCountries(): void {
    this.locationsService.getCountries().asObservable().subscribe((c) => {
      this.countries = c;
      this.createForm();

    });
  }

  /**
   * Description: Gets all the states of the selected country.
   * @author Jorge Del Castillo
   * @returns void
   */
  getStates() {
    this.cities = undefined;
    this.city = '';
    this.state = '';
    this.states = this.country ? this.locationsService.getStates(this.country).asObservable() : null;
  }

  /**
   * Description: Gets all the cities of the selected state.
   * @author Jorge Del Castillo
   * @returns void
   */
  getCities() {
    this.city = '';
    this.cities = this.state ? this.locationsService.getCities(this.state).asObservable() : null;
    if (this.cities) {
      this.cities.subscribe((c) => {
        if (c.iterable.length === 0) {
          this.cities = this.states;
          this.agencyForm.controls['city_path'].setValue(this.state);
        }
      });
    }
  }

  /**
   * Description: Sets to null the current agency's logo image url and the value that indicates if the url 
   * is safe.
   * @author Jorge Del Castillo
   * @returns void 
   */
  resetUpload(): void {
    this.imgUrl = null;
    this.safeSrc = null;
  }

  /**
   * Description: Updates the value of every field of the current agency.
   * @author Jorge Del Castillo
   * @returns void 
   */
  save(): void {
    try {
      this.isLoading = true;
      const value = this.agencyForm.value;
      // this.agencyForm.controls['phones'].setValue([this.agencyForm.value.phone1, this.agencyForm.value.phone2]);
      this.agency.name = value.name;
      this.agency.address = value.address;
      this.agency.zipcode = value.zipcode;
      this.agency.phones = [value.phone1, value.phone2];
      this.agency.hours = value.hours;
      this.agency.days = value.days;
      this.agency.country_path = value.country_path;
      this.agency.state_path = value.state_path;
      this.agency.city_path = value.city_path;
      this.agency.latitude = value.latitude;
      this.agency.longitude = value.longitude;
      this.agency.logo = this.imgUrl;
      this.agency.display = value.display;
      this.agency.locker = value.locker;
      this.agency.pickup = value.pickup;
      this.agency.products = value.products;
      if (!this.agency.timestamp) {
        this.agency.timestamp = new Date().getTime().toString();
      }
      this.agencyService.update(this.agency).then(() => {
        this.alertService.showAlert('Agency updated successfully', 'alert-success');
        this.location.back();
      });
    } catch {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }

  }
}
