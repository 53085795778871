import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  product = {
    id: 'afgtrgbtrb',
    name: 'Cajas',
    description: 'cajas de carton',
    image: '',
    available: true,
    stock: 100,
    stock_warning: [0, 200, 100],
    price: 10,
    sku: 'CAJA-C',
    category: ''
  };

  /**
   * @constructor
   * @param {Router} router Route management
   * @param {Location} location Current navigation location
   */
  constructor(
    private router: Router,
    private location: Location) { }

  ngOnInit() {
  }

  /**
   * Description: Receives the id of the product to edit and uses the router to navigate to the specific product
   * edit view.
   * @author Mauro Lanza
   * @param {string} id Id of the product to edit
   * @returns void
   */
  edit(id: string): void {
    this.router.navigate(['product/edit', id]);
  }

  /**
   * Description: Navigates back to the pevious view.
   * @author Mauro Lanza
   * @returns void
   */
  goBack(): void {
    this.location.back();
  }

}
