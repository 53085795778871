import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Money, User } from '../models';
import { Entity, Collection } from 'aether-blaze';
import * as firebase from 'firebase';

import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MoneyOrderService {

  private pathUrl: string = '/orders/';
  private refUrl: string = '/users/';
  public order: Money;
  private selectedOrder: Money;

  /**
   * @constructor
   * @param {datePipe} datePipe Formats a date value according to locale rules
   */
  constructor(private datePipe: DatePipe) { }

  /**
   * Description: Gets a specific money order by its id.
   * @author Sarkis Bazdikian
   * @param {string} id Id of a money order
   * @returns {Money} Money order
   */
  public get(id: string): Money {
    return new Money(this.pathUrl + id);
  }

  /**
   * Description: Gets all the money orders.
   * @author Sarkis Bazdikian
   * @param query
   * @returns {Collection<Money>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Money> {
    if (query) {
      return new Collection<Money>(Money, this.pathUrl, query);
    } else {
      return new Collection<Money>(Money, this.pathUrl);
    }
  }

  /**
   * Description: Adds a new money order to the money orders collection.
   * @author Sarkis Bazdikian
   * @param {Money} orderData Amount of money and other order's comments
   * @param {any} senderData Data of the person sending
   * @param {any} receiverData Data of the recipient
   * @param {any} addressData Shipping address
   * @returns {Promise<Money>}
   */
  public create(orderData: Money, senderData: any, receiverData: any, addressData: any): Promise<Money> {
    return new Promise<Money>((resolve) => {
      // let doc = firebase.firestore().collection(this.pathUrl).doc();
      orderData.recipient = receiverData;
      orderData.sender = senderData;
      orderData.address = addressData;
      orderData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      firebase.firestore().collection(this.pathUrl).add(orderData).then((docRef) => { this.get(docRef.id).__toPromise().then((p) => { resolve(p) }) });
    })
  }

  /**
   * Description: Gets only the money orders of the year of the date received as param.
   * @author Sarkis Bazdikian
   * @param {any} date
   * @returns {Collection<Money>}
   */
  public getNextId(date) {
    return this.getAll((ref) => { return ref.orderBy('number', 'desc').where('year', '==', this.datePipe.transform(date, 'yy')).limit(1) });
  }

  /**
   * Description: Gets the number of the next money order. Allows indexing of money orders.
   * @author Sarkis Bazdikian
   * @returns {Promise<any>}
   */
  public getNextOrderNumber() {
    return new Promise<any>((resolve) => {
      firebase.firestore().collection('/index/').doc('money_orders').get()
        .then(query => {
          console.log((query as any).data());
          if (!query.exists) {
            firebase.firestore().collection('/index/').doc('money_orders').set({
              'total': 1
            }).then(() => { resolve(1) })
          } else {
            var data = query.data();
            firebase.firestore().collection('/index/').doc('money_orders').update({
              'total': data.total + 1
            }).then(() => { resolve(data.total + 1) })
          }
        });
    });
    // ???
    //  return 0
  }

  /**
   * Description: Updates a money order's data.
   * @author Sarkis Bazdikian
   * @param {Money} data Money order's data
   * @returns void
   */
  public update(data: Money): void {
    data.__save(true);
    // console.log(data);
  }

  // public delete(id: string): void {
  //   let order = new Order( this.pathUrl + id );
  //   order.deleted = true;
  //   this.update(order);
  // }


  /**
   * Description: Gets the money order selected by user to see its details.
   * @author Maximiliano Casale
   * @returns {Money} Money order selected
   */
  public getSelected(): Money {
    return this.selectedOrder
  }

  /**
   * Description: Sets the money order received as param as the selected money order in order to see its details.
   * @author Maximiliano Casale
   * @param {Money} order
   * @returns void
   */
  public setSelected(order: Money): void {
    this.selectedOrder = order;
  }

}