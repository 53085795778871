import { Injectable } from '@angular/core';
import { StoreOrder } from '../models';
import { Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase-admin';
import { resolve } from 'q';

@Injectable({
  providedIn: 'root'
})
export class StoreOrderService {

  private pathUrl = '/store-orders/';
  public storeOrder: StoreOrder;

  /**
   * @constructor
   * @param {AngularFirestore} afs Document-oriented database
   */
  constructor(
    private afs: AngularFirestore
  ) { }

  /**
   * Description: Gets a store order by its id.
   * @author Andrea Scardino
   * @param {string} id
   * @returns {StoreOrder}
   */
  public get(id: string): StoreOrder {
    return new StoreOrder(this.pathUrl + id);
  }

  /**
   * Description: Gets all the collection of store orders.
   * @author Andrea Scardino
   * @param query
   * @returns {Collection<StoreOrder>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<StoreOrder> {
    if (query) {
      return new Collection<StoreOrder>(StoreOrder, this.pathUrl, query);
    } else {
      return new Collection<StoreOrder>(StoreOrder, this.pathUrl);
    }
  }

  /**
   * Description: Updates the data of the store order received as param.
   * @author Andrea Scardino
   * @param {StoreOrder} data
   * @returns {Promise<void>}
   */
  public update(data: StoreOrder): Promise<void> {
    return new Promise<void>((resolve) => {
      data.__save(true).then(() => resolve());
    });
  }

  public changeStatus(id: string, status: string): Promise<void> {
    return new Promise((resolve) => {
      firebase.firestore().collection(this.pathUrl).doc(id).get().then((docRef) => {
        if (docRef.exists) {
          firebase.firestore().collection(this.pathUrl).doc(id).update({'status': status}).then((p) => {
            resolve(p);
          });
        }
      });
    });
  }

}
