import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreProduct } from 'src/app/models/store-product';
import { StoreProductService } from 'src/app/services/store-product.service';
import { Location as navLocation } from '@angular/common';

@Component({
  selector: 'app-store-product-details',
  templateUrl: './store-product-details.component.html',
  styleUrls: ['./store-product-details.component.scss']
})
export class StoreProductDetailsComponent implements OnInit {

  public selectedStoreProduct: StoreProduct;

  /**
   * @constructor
   * @param {ActivatedRoute} activatedRoute Current navigation route
   * @param {StoreProductService} storeProductService Service for store products management
   * @param {navLocation} location Current navigation location
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private storeProductService: StoreProductService,
    private location: navLocation,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.selectedStoreProduct = this.storeProductService.get(params['id']);
      console.log(this.selectedStoreProduct);
    })
  }

  /**
   * Description: Navigates to the previous navigation location.
   * @author Maximiliano Casale
   * @returns void
   */
  cancel(): void {
    this.location.back();
  }
  
}
