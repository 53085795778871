import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[OnlyText]'
})
export class OnlyTextDirective {

  regexStr = '[a-zA-ZñÑ]+';

  /**
   * @constructor
   * @param {ElementRef} el
   */
  constructor(private el: ElementRef) { }

  @Input() OnlyText: boolean;

  /**
   * Description: Executes a TypeScript when a user is pressing a key.
   * @author Jorge Del Castillo
   * @param event KeyboardEvent
   * @returns void
   */
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    if (this.OnlyText) {
      // 8 delete, 9 tab, 27 escape 46 delete 13 enter
      if ([8, 9, 27, 13, 32, 46].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      const regEx = new RegExp(this.regexStr);
      if (regEx.test(e.key)) {
        return;
      } else {
        e.preventDefault();
      }
    }
  }

}

