import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Rx from 'rxjs';

@Injectable()
export class I18nService {
  /**
   * Description: ReplaySubject to manage the language in a variable.
   * @type {ReplaySubject<string>}
   */
  private subjectLanguage = new Rx.ReplaySubject<string>(1);

  /**
   * @constructor
   * @param {TranslateService} translate Internationalization (i18n) library
   */
  constructor(public translate: TranslateService) {
    this.getLanguageLocalStorage();
  }

  /**
   * Description: Gets the current language.
   * @author Sarkis Bazdikian
   * @returns {Rx.Observable<string>} 
   */
  public get language() {
    return this.subjectLanguage.asObservable();
  }

  /**
   * Description: Gets the previously saved language in local storage, and triggers an event that subscribers
   * will hear, which contains the language obtained.
   * @author Sarkis Bazdikian
   * @returns void
   */
  private getLanguageLocalStorage() {
    const lang = localStorage.getItem('language');
    if (lang != null) {
      this.translate.setDefaultLang('en');
      this.changeLanguage('en');
      // this.subjectLanguage.next(lang);
      // this.changeLanguage(lang);
    } else {
      this.translate.setDefaultLang('en');
      this.changeLanguage('en');
    }
  }

  /**
   * Description: Changes the app language and stores it in the local storage.
   * @author Sarkis Bazdikian
   * @param {any} lang to be established
   * @returns void
   */
  public changeLanguage(lang) {
    localStorage.setItem('language', lang);
    this.translate.use(lang);
    this.subjectLanguage.next(lang);
  }
}
