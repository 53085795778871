import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../services/category.service';
import { Category } from '../../models/category';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';

@Component({
  selector: 'app-store-categories',
  templateUrl: './store-categories.component.html',
  styleUrls: ['./store-categories.component.scss']
})
export class StoreCategoriesComponent implements OnInit {
  public categories: Observable<Collection<Category>>;

  public newCat = '';
  public showDeleted = false;

  /**
   * @constructor
   * @param {CategoryService} categoryService
   */
  constructor(
    private categoryService: CategoryService,
  ) { }

  ngOnInit() {
    this.fetchCats();
  }

  /**
   * Description: Fetches all the categories where the deleted atribute matches the param received.
   * @author Maximiliano Casale
   * @returns void
   */
  public fetchCats(): void {
    this.categories = this.categoryService.getAll(ref => {
      return ref.where('deleted', '==', this.showDeleted);
    }).asObservable();
  }

  /**
   * Description: Creates a new category.
   * @author Maximiliano Casale
   * @returns void
   */
  public createCat(): void {
    this.categoryService.create({ name: this.newCat, deleted: false }).then(p => {
      this.newCat = '';
    });
  }

  /**
   * Description: Asks for confirmation to delete a category. If the deletion is confirmed, the category
   * is deleted and updated in the database.
   * @author Maximiliano Casale
   * @param {any} category Category to delete
   * @returns void
   */
  public toggleDeleted(category: any): void {
    const check = confirm(`Are you sure you want to ${category.deleted ? 'restore' : 'delete'} "${category.name}" ?`);
    if (check) {
      category.deleted = !category.deleted;
      this.categoryService.update(category);
    }
  }

}
