import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  content;
  type;
  isClosed = true;

  /**
   * @constructor
   * @param {AlertService} alertService Service for alerts management
   */
  constructor(
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.alertService.alert.subscribe((a) => {
      this.content = a.content;
      this.type = a.type;
      this.isClosed = false;
      setTimeout(() => {
        this.isClosed = true;
      }, 6000);
    });
  }

}
