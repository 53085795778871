import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location as navLocation } from '@angular/common';
import { AgencyService } from '../../../services/agency.service';
import { Location } from '../../../models';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { LocationsService } from '../../../services/locations.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-agency-create',
  templateUrl: './agency-create.component.html',
  styleUrls: ['./agency-create.component.scss']
})
export class AgencyCreateComponent implements OnInit {

  public agencyForm: FormGroup;
  country: any;
  state: any;
  city: any;
  countries: Collection<Location>;
  states: Observable<Collection<Location>>;
  cities: Observable<Collection<Location>>;
  imgUrl: string;
  uploadFolder = 'agencies-logos';
  timestamp: string;
  uploading: boolean;
  isLoading: boolean;

  /**
   * @constructor
   * @param {FormBuilder} fb Builder of the form to create a new agency
   * @param {navLocation} location Current navigation location
   * @param {AgencyService} agencyService Service for the agencies management
   * @param {LocationsService} locationsService Service for the locations management
   * @param {AlertService} alertService Service for the alerts management
   */
  constructor(
    private fb: FormBuilder,
    private location: navLocation,
    private agencyService: AgencyService,
    public locationsService: LocationsService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.timestamp = new Date().getTime().toString();
    this.createAgencyForm();
    this.getCountries();
  }

  /**
   * Description: Writes the content of the new agency form as a message to the console.
   * @author Jorge Del Castillo
   * @returns void
   */
  log() {
    console.log(this.agencyForm);
  }

  /**
   * Description: Allows upload of the new agency's logo image. 
   * @author Jorge Del Castillo
   * @param {Observable<string>} url Url of the image to upload
   * @returns void
   */
  onUpload(url: Observable<string>): void {
    url.subscribe((s) => {
      this.imgUrl = s;
      this.uploading = false;
      // console.log(s);
    });
  }

  /**
   * Description: Creates a form group with every path required to create a new agency.
   * @author Jorge Del Castillo
   * @returns void
   */
  createAgencyForm() {
    this.agencyForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-ZñÑ\\s]*')]],
      address: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      phone1: ['', [Validators.required]],
      phone2: [''],
      // phone1: ['', [Validators.required, Validators.pattern('[0-9]{1,11}')]],
      // phone2: ['', [Validators.pattern('[0-9]{1,11}')]],
      hours: [''],
      days: [''],
      country_path: ['', [Validators.required]],
      state_path: ['', [Validators.required]],
      city_path: ['', [Validators.required]],
      latitude: ['', [Validators.required]],
      longitude: ['', [Validators.required]],
      // latitude: ['', [Validators.required,  Validators.pattern('[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?)')]],
      // longitude: ['', [Validators.required, Validators.pattern('[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)')]],
      deleted: false,
      display: ['', [Validators.required]],
      locker: [''],
      pickup: [''],
      products: ['']
    });
    this.formControlValueChanges();
  }

  /**
   * Description: Shows different states or cities depending on the country and the state selected.
   * @author Jorge Del Castillo
   * @returns void  
   */
  formControlValueChanges() {
    this.agencyForm.controls['country_path'].valueChanges.subscribe(
      (selectedValue) => {
        this.country = selectedValue;
        this.getStates();
      }
    );
    this.agencyForm.controls['state_path'].valueChanges.subscribe(
      (selectedValue) => {
        this.state = selectedValue;
        this.getCities();
      }
    );
  }

  /**
   * Description: Brings all the countries available in the countries collection.
   * @author Jorge Del Castillo
   * @returns void
   */
  getCountries(): void {
    this.locationsService.getCountries().asObservable().subscribe((p) => {
      this.countries = p;
    });
  }

  /**
   * Description: Looks for all the states of the selected country.
   * @author Jorge Del Castillo
   * @returns void
   */
  getStates() {
    this.cities = undefined;
    this.city = '';
    this.state = '';
    this.states = this.country ? this.locationsService.getStates(this.country).asObservable() : null;
  }

  /**
   * Description: Gets all the cities of the selected state.
   * @author Jorge Del Castillo
   * @returns void
   */
  getCities() {
    this.city = '';
    this.cities = this.state ? this.locationsService.getCities(this.state).asObservable() : null;
    if (this.cities) {
      this.cities.subscribe((c) => {
        if (c.iterable.length === 0) {
          this.cities = this.states;
          this.agencyForm.controls['city_path'].setValue(this.state);
        }
      });
    }
  }

  /**
   * Description: Resets the image url to null when the user wants to change the image of the agency.
   * @author Jorge Del Castillo
   * @returns void
   */
  resetUpload(): void {
    this.imgUrl = null;
  }

  /**
   * Description: Once the user clicks on "create", uses the data from de agecyForm to create a new agency
   * in the agencies collection.
   * @author Jorge Del Castillo
   * @returns void
   */
  save(): void {
    try {
      this.isLoading = true;
      const value = this.agencyForm.value;
      // this.agencyForm.controls['phones'].setValue([this.agencyForm.value.phone1, this.agencyForm.value.phone2]);
      const agency = {} as any;
      agency.name = value.name;
      agency.address = value.address;
      agency.zipcode = value.zipcode;
      agency.phones = [value.phone1, value.phone2];
      agency.hours = value.hours;
      agency.days = value.days;
      agency.country_path = value.country_path;
      agency.state_path = value.state_path;
      agency.city_path = value.city_path;
      agency.latitude = value.latitude;
      agency.longitude = value.longitude;
      agency.logo = this.imgUrl;
      agency.timestamp = this.timestamp;
      agency.deleted = false;
      agency.display = value.display;
      agency.locker = value.locker;
      agency.pickup = value.pickup;
      agency.products = value.products;
      this.agencyService.create(agency).then(() => {
        this.location.back();
      });
    } catch {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }

  /**
   * Description: When user clicks on "go back" botton, resets the form and navigates back to the previous
   * view.
   * @author Jorge Del Castillo
   * @returns void
   */
  cancel(): void {
    this.agencyForm.reset();
    this.location.back();
  }

}
