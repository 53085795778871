import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillService } from '../../services/bill.service';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { Bill } from 'src/app/models';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-bill-list',
  templateUrl: './bill-list.component.html',
  styleUrls: ['./bill-list.component.scss']
})

export class BillListComponent implements OnInit {
  public bills: Observable<Collection<Bill>>;
  public profile: User;

  /**
   * @constructor
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks
   * @param {BillService} billService Service for bills management
   * @param {UserService} userService Service for users management
   */
  constructor(
    private router: Router,
    private billService: BillService,
    private userService: UserService) { }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      this.profile = p;
      this.getBillList(this.profile);
    });
  }

  /**
   * Description: Gets all bills and assigns them to "bills".
   * @author Jesus Ponte
   * @param {any} profile
   * @returns void
   */
  public getBillList(profile): void {
    if (profile) {
      if (profile.role === 'admin') {
        this.bills = this.billService.getAll(ref => ref.orderBy('date', 'desc')).asObservable();
      } else {
        this.bills = this.billService.getAll(ref => {
          return ref.where('agency_path', '==', profile.agency_path).orderBy('date', 'desc');
        }).asObservable();
      }
    }
  }

  /**
   * Description: Redirects to the bill details view with the id received as param.
   * @author Jesus Ponte
   * @param {string} id Id of the selected bill
   * @returns void
   */
  viewDetails(id: string): void {
    this.router.navigate([`/store/bill-details/${id}`]);
  }

}
