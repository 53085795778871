import { Component, OnInit } from '@angular/core';
import { Location as navLocation } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../../../services/product.service';
import { Product } from 'src/app/models';
import { Router } from '@angular/router';



@Component({
  selector: 'app-ag-products-details',
  templateUrl: './ag-products-details.component.html',
  styleUrls: ['./ag-products-details.component.scss']
})
export class AgProductsDetailsComponent implements OnInit {

  public product: Product;

  /**
   * @constructor
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks 
   * @param {navLocation} location Current navigation location
   * @param {ActivatedRoute} route Current navigation route 
   * @param {ProductService} productService Service for products management
   */
  constructor(
    private router: Router,
    private location: navLocation,
    private route: ActivatedRoute,
    private productService: ProductService) { }

  ngOnInit() {
    this.getProduct();
  }

  /**
   * Description: Gets the data of the product selected to see its details.
   * @author Mauro Lanza
   * @returns void
   */
  getProduct() {
    this.productService.get(this.route.snapshot.params['id']).__asObservable()._subscribe((p) => {
      this.product = p;
    });
  }

  /**
   * Description: Navigates to the edition view of the selected product to edit.
   * @author Mauro Lanza
   * @param {string} id Id of the selected product to edit
   * @returns void 
   */
  edit(id: string): void {
    this.router.navigate(['store/agency-products/edit', id]);
  }

  /**
   * Description: Navigates to the previous navigation location.
   * @author Mauro Lanza
   * @returns void
   */
  goBack(): void {
    this.location.back();
  }

}
