import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ManifestService } from '../../../services/manifest.service';
import { Manifest } from '../../../models';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/first';


@Component({
  selector: 'app-manifest-details',
  templateUrl: './manifest-details.component.html',
  styleUrls: ['./manifest-details.component.scss']
})
export class ManifestDetailsComponent implements OnInit {

  manifestDetails: Manifest;

  // manifestDetails = {
  //   id: '-LEr5ZrL8qDfAiMgH5PZ',
  //   orders: ['2z3PE7RW', '3B4g0DQQ', '1518719154398', '1707000001', '15VRl1vo', 'xQoXwZwE'],
  //   date: '2016-06-19T07:19:59.152Z'
  // };

  /**
   * @constructor
   * @param {ActivatedRoute} route Current route
   * @param {ManifestService} manifestService Service that allows manifests management
   */
  constructor(
    private route: ActivatedRoute,
    private manifestService: ManifestService
  ) { }

  ngOnInit() {
    this.getDetails();
  }

  /**
   * Description: Gets the details of the selected manifest and sets the manifestDetails model with the 
   * values of the selected manifest.
   * @author darksagas
   * @returns void
   */
  getDetails() {
    // this.shipmentDetails = this.manifestService.get( this.route.snapshot.params['id'] )
    // return this.route.snapshot.params['id'];
    this.route.params.first().subscribe(params => {
      this.manifestService.get(params['id']).__toPromise().then(async (p) => {
        this.manifestDetails = await p;
      });
    });
  }

}
