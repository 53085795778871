import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, PatternValidator, NgForm, FormControl } from '@angular/forms';
import { PersonService } from '../../services/person.service';
import { LocationsService } from '../../services/locations.service';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';
import { Person, Location } from '../../models';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {

  @Input() frqAddressData;
  @Output() addresses = new EventEmitter();
  @Output() addressesRef = new EventEmitter();

  public addressesForm: FormGroup;
  public frqAddresses = new FormControl();
  public countries: Collection<Location>;
  public states: Observable<Collection<Location>>;
  public cities: Observable<Collection<Location>>;
  public city = '';
  public state = '';
  public country = '';
  public availabilityCheck: boolean;

  /**
   * @constructor
   * @param {FormBuilder} fb Creates a new form for an address
   * @param {PersonService} personService Service for persons management
   * @param {LocationsService} locationsService Service for locations management
   */
  constructor(
    private fb: FormBuilder,
    private personService: PersonService,
    private locationsService: LocationsService
  ) { }

  ngOnInit() {
    // console.warn(this.frqAddressData);
    this.createAddressesForm();
    this.getCountries();
    this.createrFrqAddressesForm();
  }

  /**
   * Description: Creates a new form group for an address.
   * @author Sarkis
   * @returns void
   */
  createAddressesForm() {
    this.addressesForm = this.fb.group({
      addressCountry: ['', [Validators.required]],
      addressState: ['', [Validators.required]],
      addressCity: ['', [Validators.required]],
      addressZipCode: [''],
      addressAddress: ['', [Validators.required]],
      addressIndex: [-1],
      addressAvailability: ['', [Validators.required]],
      addressBusinessHours: ['']
    });
    this.formControlValueChanges();
  }

  /**
   * Description: Controls the changes of the Shipping Address' form values.
   * @author Sarkis
   * @returns void
   */
  formControlValueChanges() {
    this.addressesForm.valueChanges.subscribe(
      (selectedValue) => {
        if (selectedValue.addressAvailability === 'Residential') {
          this.addressesForm.get('addressBusinessHours').setValidators([]);
          this.addressesForm.get('addressBusinessHours').updateValueAndValidity({ onlySelf: false, emitEvent: false });

          // selectedValue.addressBusinessHours = '';
        } else if (selectedValue.addressAvailability === 'Office') {
          this.addressesForm.get('addressBusinessHours').setValidators([Validators.required]);
          this.addressesForm.get('addressBusinessHours').updateValueAndValidity({ onlySelf: false, emitEvent: false });
        }
        // console.log(selectedValue);
        this.outAddress();
      }
    );

    this.addressesForm.controls['addressCountry'].valueChanges.subscribe(
      (selectedValue) => {
        this.country = selectedValue;
        this.getStates();
      }
    );

    this.addressesForm.controls['addressState'].valueChanges.subscribe(
      (selectedValue) => {
        this.state = selectedValue;
        this.getCities();
      }
    );

  }

  /**
   * Description: Calls the frequentAddressesChange method once a frequent address is selected.
   * @author Sarkis
   * @returns void
   */
  createrFrqAddressesForm() {
    this.frqAddresses.valueChanges.subscribe(
      (selectedValue) => {
        this.frequentAddressesChange(selectedValue);
      }
    );
  }

  /**
   * Description: Sets the form inputs with the values of the frequent address.
   * @author Sarkis
   * @param {any} selectedValue Value selected as a frequent address
   * @returns void
   */
  frequentAddressesChange(selectedValue) {

    // console.log('frqAddresses', selectedValue);

    if (selectedValue) {
      this.frqAddressData.forEach((address, index) => {
        if (selectedValue === address) {
          this.addressesForm.setValue({
            addressCountry: selectedValue.country,
            addressState: selectedValue.state,
            addressCity: selectedValue.city,
            addressZipCode: selectedValue.zipcode || '',
            addressAddress: selectedValue.address,
            addressIndex: index,
            addressAvailability: selectedValue.availability,
            addressBusinessHours: selectedValue.businessHours || ''
          });
        }
      });
      this.outAddress();
      this.addressesRef.emit(selectedValue);
    } else {
      this.addressesForm.reset();
      this.outAddress();
      this.addressesRef.emit(selectedValue);
    }

  }

  /**
   * Description: Gets the available countries.
   * @author Sarkis
   * @returns void
   */
  getCountries(): void {
    this.locationsService.getCountries((ref) => ref.where('display', '==', true)).asObservable().subscribe((p) => {
      this.countries = p;
    });
  }

  /**
   * Description: Displays the states of the selected country.
   * @author Sarkis
   * @returns void
   */
  getStates() {
    this.cities = undefined;
    this.city = '';
    this.state = '';
    this.states = this.country ? this.locationsService.getStates(this.country).asObservable() : null;
  }

  /**
   * Description: Displays the cities of the selected state.
   * @author Sarkis
   * @returns void
   */
  getCities() {
    this.city = '';
    this.cities = this.state ? this.locationsService.getCities(this.state).asObservable() : null;
    if (this.cities) {
      this.cities.subscribe((c) => {
        if (c.iterable.length === 0) {
          this.cities = this.states;
          this.addressesForm.controls['addressCity'].setValue(this.state);
        }
      });
    }
  }

  /**
   * Description: Emits the addresses.
   * @author Sarkis
   * @returns void
   */
  outAddress() {
    // console.log('[ADDRESS]', this.addressesForm.value);
    this.addresses.emit(this.addressesForm);
  }

  /**
   * Description: Shows/hides "business availability hours" path if the availability is
   * office or residential.
   * @author Sarkis
   * @param {string} target residential/office
   * @returns void
   */
  availability(target: string) {
    if (target === 'residential') {
      this.availabilityCheck = false;
    } else if (target === 'office') {
      this.availabilityCheck = true;
    }
  }

}
