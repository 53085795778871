import { imageLoad } from 'src/app/utils/image-loader';
import { convertImgToBase64URLOneThree } from 'src/app/utils/image-to-base64';
import { PromotionType } from 'src/app/models/promotion';

function modelToBoxArray(model: OrderDetailPDFModel) {
    return model.boxes.map((box, index) => {
        return [
            { text: (index + 1).toString(), style: 'small' },
            { text: box.content, style: 'smaller', noWrap: false },
            { text: box.dimensions, style: 'small', noWrap: true },
            { text: box.weight, style: 'small' },
            { text: box.insured, style: 'small' },
            { text: box.declared, style: 'small' },
            { text: box.packing, style: 'small' },
            {
                text: box.promotionType === PromotionType.COUPON
                    ? box.promotionCode : (box.promotionType === PromotionType.VALUE
                        ? 'SPECIAL' : ''), style: 'small'
            },
            { text: box.cost, style: 'small' }
        ];
    });
}

export interface OrderDetailPDFModel {

    agencyLogo: string;

    id: string;
    type: string;
    createdAt: string;

    sender: {
        name: string;
        email: string;
        number: string;
        stateCountry: string;
        address: string;
    };

    recipient: {
        name: string;
        email: string;
        number: string;
        stateCountry: string;
        address: string;
    };

    agency: {
        name: string;
        email: string;
        number: string;
        stateCountry: string;
        address: string;
    };

    boxes: {
        content: string,
        dimensions: string,
        weight: string,
        insured: string,
        declared: string,
        packing: string,
        promotionCode: string,
        promotionType: PromotionType,
        cost: string
    }[];

    shippingCost: string;
    insurance: string;
    countryTax: string;
    packingTotal: string;
    customsManagement: string;
    total: string;
}

export async function parseOrderDetail(model: OrderDetailPDFModel) {

    const img = await imageLoad(model.agencyLogo);
    const base64 = convertImgToBase64URLOneThree(img);
    const boxesLayout = {
        hLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
                return 0;
            } else if (i === 1) {
                return 2;
            } else {
                return 1;
            }
        },
        vLineWidth: function (i, node) {
            return 0;
        },
        hLineColor: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
                return 'white';
            } else if (i === 1) {
                return 'black';
            } else {
                return 'gray';
            }
        },
        vLineColor: function (i, node) {
            return 'white';
        },
    };

    const tableHeader: any[] = [
        [
            { text: 'Box #', style: 'header', noWrap: true },
            { text: 'Content', style: 'header' },
            { text: 'Dimensions', style: 'header' },
            { text: 'Weight', style: 'header' },
            { text: 'Insured', style: 'header' },
            { text: 'Declared', style: 'header' },
            { text: 'Packing', style: 'header' },
            { text: 'Promotion', style: 'header' },
            { text: 'Shipping Cost', style: 'header', noWrap: true }
        ]
    ];

    const tableFooter: any[] = [
        [
            {
                colSpan: 8,
                text: [
                    {
                        text: 'Shipping Cost\nInsurance\nCountry tax\nPacking Service\nCustoms Management\n',
                        style: 'small'
                    },
                    {
                        text: 'Total',
                        style: 'bold'
                    }
                ],
                // noWrap: true,
                style: 'small'
            },
            '', '', '', '', '', '', '',
            {
                text: [
                    {
                        text: model.shippingCost + '\n' + model.insurance + '\n'
                            + model.countryTax + '\n' + model.packingTotal + '\n' + model.customsManagement + '\n',
                        style: 'small'
                    },
                    {
                        text: model.total,
                        style: 'bold'
                    }
                ]
            }
        ]
    ];

    const table = tableHeader.concat(modelToBoxArray(model), tableFooter);
    const table2 = JSON.parse(JSON.stringify(table));

    const dd = {
        pageMargins: [20, 20, 20, 80],
        pageSize: 'LETTER',
        footer: function (currentPage, pageCount, pageSize) {
            // you can apply any logic and return any valid pdfmake element
            if (currentPage < pageCount) {
                for (const content of dd.content) {
                    const c = content as any;
                    if (c.pageBreak && c.pageBreak === 'before') {
                        if (c.positions[0] && c.positions[0].pageNumber <= currentPage) {
                            return {
                                table: {
                                    dontBreakRows: true,
                                    widths: ['*'],
                                    body: [
                                        [
                                            {
                                                text: 'HE REVISADO LA INFORMACION PRESENTADA EN ESTA PAGINA:',
                                                style: 'terms',
                                                alignment: 'center'
                                            }
                                        ],
                                        [
                                            { text: '\n____________________________', style: 'center' }
                                        ],
                                        [
                                            { text: 'FIRMA DEL CLIENTE', style: 'terms', alignment: 'center' }
                                        ]
                                    ]
                                },
                                layout: 'noBorders'
                            };
                        } else {
                            return {
                                text: 'Client\'s Copy'
                                    + (c.positions[0].pageNumber > 2 ?
                                        (' (' + currentPage + ' of ' + (c.positions[0].pageNumber - 1) + ')')
                                        : ''),
                                style: 'terms',
                                alignment: 'center'
                            };
                        }
                    }
                }
            }
        },
        content: [
            {
                columns: [
                    {
                        style: 'tableHeader',
                        table: {
                            widths: ['auto', '*', 'auto'],
                            body: [
                                [
                                    { colSpan: 2, text: model.id, style: 'title' },
                                    null,
                                    {
                                        rowSpan: 2,
                                        width: 'auto',
                                        image: base64,
                                        fit: [180, 60]
                                    }
                                ],
                                [
                                    { text: [{ text: 'Type: ', style: 'header' }, model.type] },
                                    model.createdAt ? { text: [{ text: 'Date: ', style: 'header' }, model.createdAt] } : null,
                                    null
                                ]
                            ]
                        },
                        layout: 'noBorders'
                    }
                ]
            },
            {
                canvas: [
                    { type: 'line', x1: 0, y1: 0, x2: 570, y2: 0, lineWidth: 2 }
                ],
                margin: [0, 2, 0, 5]
            },
            {
                columns: [
                    {
                        text: [
                            { text: 'Sender:\n', style: 'header' },
                            { text: model.sender.name + '\n', style: 'default' },
                            { text: model.sender.email ? model.sender.email + '\n' : '', style: 'default' },
                            { text: model.sender.number + '\n', style: 'default' },
                            { text: model.sender.stateCountry + '\n', style: 'default' },
                            { text: 'Address:\n', style: 'header' },
                            { text: model.sender.address, style: 'default' },
                        ]
                    },
                    {
                        text: [
                            { text: 'Recipient:\n', style: 'header' },
                            { text: model.recipient.name + '\n', style: 'default' },
                            { text: model.recipient.email ? model.recipient.email + '\n' : '', style: 'default' },
                            { text: model.recipient.number + '\n', style: 'default' },
                            { text: model.recipient.stateCountry + '\n', style: 'default' },
                            { text: 'Address:\n', style: 'header' },
                            { text: model.recipient.address, style: 'default' },
                        ]
                    },
                    {
                        text: [
                            { text: 'Shipping agency:\n', style: 'header' },
                            { text: model.agency.name + '\n', style: 'default' },
                            { text: model.agency.email ? model.agency.email + '\n' : '', style: 'default' },
                            { text: model.agency.number + '\n', style: 'default' },
                            { text: model.agency.stateCountry + '\n', style: 'default' },
                            { text: model.agency.address, style: 'default' },
                        ]
                    }
                ]
            },
            '\n',
            {
                style: 'tableExample',
                table: {
                    widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    headerRows: 1,
                    body: table
                },
                layout: boxesLayout
            },
            model.boxes.length > 1 ? null : {
                canvas: [
                    { type: 'line', x1: -200, y1: 0, x2: 600, y2: 0, lineWidth: 1, dash: { length: 3 } }
                ]
            },
            {
                pageBreak: model.boxes.length > 1 ? 'before' : null,
                columns: [
                    {
                        style: 'tableHeader',
                        table: {
                            widths: ['auto', '*'],
                            body: [
                                [
                                    { colSpan: 2, text: model.id, style: 'title' }, ''
                                ],
                                [
                                    { text: [{ text: 'Type: ', style: 'header' }, model.type] },
                                    model.createdAt ? { text: [{ text: 'Date: ', style: 'header' }, model.createdAt] } : null,
                                ]
                            ]
                        },
                        layout: 'noBorders'
                    }
                ]
            },
            {
                canvas: [
                    { type: 'line', x1: 0, y1: 5, x2: 570, y2: 5, lineWidth: 2 }
                ],
                margin: [0, 0, 0, 5]
            },
            {
                columns: [
                    {
                        text: [
                            { text: 'Sender:\n', style: 'header' },
                            { text: model.sender.name + '\n', style: 'default' },
                            { text: model.sender.email ? model.sender.email + '\n' : '', style: 'default' },
                            { text: model.sender.number + '\n', style: 'default' },
                            { text: model.sender.stateCountry + '\n', style: 'default' },
                            { text: 'Address:\n', style: 'header' },
                            { text: model.sender.address, style: 'default' }
                        ]
                    },
                    {
                        text: [
                            { text: 'Recipient:\n', style: 'header' },
                            { text: model.recipient.name + '\n', style: 'default' },
                            { text: model.recipient.email ? model.recipient.email + '\n' : '', style: 'default' },
                            { text: model.recipient.number + '\n', style: 'default' },
                            { text: model.recipient.stateCountry + '\n', style: 'default' },
                            { text: 'Address:\n', style: 'header' },
                            { text: model.recipient.address, style: 'default' }
                        ]
                    },
                    {
                        text: [
                            { text: 'Shipping agency:\n', style: 'header' },
                            { text: model.agency.name + '\n', style: 'default' },
                            { text: model.agency.email ? model.agency.email + '\n' : '', style: 'default' },
                            { text: model.agency.number + '\n', style: 'default' },
                            { text: model.agency.stateCountry + '\n', style: 'default' },
                            { text: model.agency.address, style: 'default' }
                        ]
                    }
                ],
                margin: [0, 0, 0, 5]
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    headerRows: 1,
                    body: table2
                },
                layout: boxesLayout
            },
            {
                table: {
                    dontBreakRows: true,
                    body: [
                        [
                            {
                                text: 'El cliente declara que dentro de la caja no envia dinero documentos'
                                    + ' (cedula, pasaporte, tarjeta de credito, explosivos, armas o articulos prohibidos) en caso que'
                                    + ' su carga sea decomisada por autoridades aduanales, la empresa Ccargaxpress no'
                                    + ' se hace responsable por dicho decomiso, el empaque de la carga es responsibilidad'
                                    + ' unica del cliente, en caso de perdida total de su carga (Robo o extravio) se aplicara'
                                    + ' el reintegro del monto asegurado por el Sender (toda carga debe ir asegurada por un monto '
                                    + ' minimo de 100 Dolares) de no asegurarla la empresa no respondra por '
                                    + ' el valor del contenido de la misma, el seguro no paga'
                                    + ' perdidas parciales solo perdidas totales.\n',
                                style: 'terms'
                            }
                        ],
                        [
                            {
                                text: 'ACEPTO LOS TERMINOS Y CONDICIONES DE CCARGAXPRESS:',
                                style: 'terms',
                                alignment: 'center'
                            }
                        ],
                        [
                            { text: '____________________________', style: 'center' }
                        ],
                        [
                            { text: 'FIRMA DEL CLIENTE', style: 'terms', alignment: 'center' }
                        ]
                    ]
                },
                layout: 'noBorders'
            },
        ],

        styles: {
            default: {
                fontSize: 10,
            },
            tableHeader: {

            },
            title: {
                bold: true,
                fontSize: 12,
                color: 'black',
                margin: [0, 25, 0, 0]
            },
            titleEnterprise: {
                bold: true,
                fontSize: 12,
                color: 'black',
            },
            header: {
                bold: true,
                fontSize: 11,
                color: 'black'
            },
            bold: {
                bold: true,
                fontSize: 10,
            },
            terms: {
                fontSize: 7,
                alignment: 'justify'
            },
            center: {
                alignment: 'center'
            },
            small: {
                fontSize: 8
            },
            smaller: {
                fontSize: 6
            },
            tableExample: {
                margin: [0, 5, 0, 15],
                alignment: 'right'
            },
        }
    };

    return dd;
}
