import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, PatternValidator, NgForm } from '@angular/forms';
import { PersonService } from '../../services/person.service';
import { MoneyOrderService } from '../../services/money-order.service';
import { UserService } from '../../services/user.service';
import { Person, Money, User, Agency, Rate } from '../../models';
import { RateService } from '../../services/rate.service';
import { LocationsService } from '../../services/locations.service';
import { Router, RouteReuseStrategy } from '@angular/router';

@Component({
  selector: 'app-new-money-orders',
  templateUrl: './new-money-orders.component.html',
  styleUrls: ['./new-money-orders.component.scss']
})
export class NewMoneyOrdersComponent implements OnInit {

  public orderForm: FormGroup;
  public commentForm: FormGroup;
  public orderData: Money;
  public profile: User;
  public promocionvalue1: string;
  public promocionvalue2: string;
  frqSender: any;
  senderRef: { doc: Person; ref: firebase.firestore.DocumentReference; };
  recipientRef: { doc: Person; ref: firebase.firestore.DocumentReference; };

  frqRecipient: any;
  frqAddress: any;
  addressesRef: any;

  // amount: number = 0;

  isLoading: boolean;
  agencyPath: string;

  /**
   * @constructor
   * @param {FormBuilder} fb Form builder for the form groups for the data of the person sending, the data of the recipient, and the shipping address
   * @param {PersonService} personService Service for persons management
   * @param {MoneyOrderService} orderService Service for orders management
   * @param {UserService} userService Service for users management
   * @param {DatePipe} datePipe Formats a date value according to locale rules
   * @param {LocationsService} locationsService Service for locations management
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks
   */
  constructor(
    private fb: FormBuilder,
    private personService: PersonService,
    private orderService: MoneyOrderService,
    private userService: UserService,
    private datePipe: DatePipe,
    private locationsService: LocationsService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      if (p) {
        this.profile = p;
        this.agencyPath = this.profile.agency_path;
      }
    });
    this.createOrderForm();
    this.createCommentForm();
  }

  /**
   * Description: Creates the form to add a new money order.
   * @author Rafael Quintero
   * @returns void
   */
  createOrderForm() {
    this.orderForm = this.fb.group({
      shippingDate: [''],
      shippingType: [''],
      comments: [''],
      amount: ['', [Validators.required, Validators.pattern('[0-9].*')]],
    });
  }

  /**
   * Description: Creates form for order comments.
   * @author Rafael Quintero
   * @returns void
   */
  createCommentForm() {
    this.commentForm = this.fb.group({
      comment: ['']
    });
  }

  /**
   * Description:
   * @author Rafael Quintero
   * @param {string} target
   * @returns void
   */
  onClick2(target: string) {
    if (target === 'promotions1') {
      this.promocionvalue1 = 'si';
    } else if (target === 'promotions2') {
      this.promocionvalue1 = 'no';
    }
  }

  /**
   * Description: Shows as frequent sender the user selected.
   * @author Jorge Del Castillo
   * @param {any} event A frequent user is selected as sender
   * @returns void
   */
  showSender(event) {
    this.frqSender = event;
  }

  /**
   * Description: Shows sender's reference.
   * @author Jorge Del Castillo
   * @param {any} event Sender's reference
   * @returns void
   */
  showSenderRef(event) {
    this.senderRef = event;
  }

  /**
   * Description: Shows as frequent recipient the user selected.
   * @author Jorge Del Castillo
   * @param {any} event A frequent user is selected as recipient
   * @returns void
   */
  showRecipient(event) {
    this.frqRecipient = event;
  }

  /**
   * Description: Shows recipient's reference.
   * @author Jorge Del Castillo
   * @param {any} event Recipient's reference
   * @returns void
   */
  showRecipientRef(event) {
    this.recipientRef = event;
    // try {
    //   if (this.recipientRef.value === null) {
    //     this.recipientRef = null;
    //   }
    // } catch (error) { }
  }

  /**
   * Description: Shows as frequent address the address selected.
   * @author Jorge Del Castillo
   * @param {any} event An frequent address is selected as the current shipping address
   * @returns void
   */
  showAddresses(event) {
    this.frqAddress = event;
  }

  /**
   * Description: Shows address reference in the select frequent address field.
   * @author Jorge Del Castillo
   * @param {any} event A frequent address is selected as the current shipping address
   * @returns void
   */
  showAddressesRef(event) {
    this.addressesRef = event;
    try {
      if (this.addressesRef.value === null) {
        this.addressesRef = null;
      }
    } catch (error) { }
  }

  /**
   * Description: Adds zeros to the beginning of a string. Used for the new money order's ID.
   * @author Sarkis
   * @param {any} bareNum Number to add zeros if it has less than 6 digits
   * @returns {string} The barenum with the zeros as a string
   */
  padNum(bareNum) {
    return '000000'.substring(bareNum.length, 6) + bareNum.toString();
  }

  /**
   * Description: Creates/updates sender's data, creates/updates recipient's data, creates new money order
   * with ID, and creation date and saves it.
   * @author Sarkis
   * @returns {Promise<void>}
   */
  async saveOrder() {
    this.isLoading = true;
    const orderDate = new Date();
    const orderData: any = {};
    const senderData: any = {};
    const recipientData: any = {};
    const addressesData: any = {};

    orderData['year'] = this.datePipe.transform(orderDate, 'yy');
    orderData['agency_path'] = this.profile.agency_path;
    senderData['name'] = this.frqSender.value.sendName;
    senderData['lastname'] = this.frqSender.value.sendLastName;
    senderData['email'] = this.frqSender.value.sendEmail;
    senderData.phone = [];
    senderData.phone[0] = this.frqSender.value.sendPhone1;
    senderData.phone[1] = this.frqSender.value.sendPhone2;
    senderData.phone[2] = this.frqSender.value.sendPhone3;
    senderData.addresses = [{}];
    senderData.addresses[0].address = this.frqSender.value.sendAddress;
    senderData.addresses[0].city = this.frqSender.value.sendCity;
    senderData.addresses[0].country = this.frqSender.value.sendCountry;
    senderData.addresses[0].state = this.frqSender.value.sendState;
    senderData.addresses[0].zipcode = this.frqSender.value.sendZipCode;

    recipientData['name'] = this.frqRecipient.value.receivName;
    recipientData['lastname'] = this.frqRecipient.value.receivLastName;
    recipientData['email'] = this.frqRecipient.value.receivEmail;
    recipientData.phone = [];
    recipientData.phone[0] = this.frqRecipient.value.receivPhone1;
    recipientData.phone[1] = this.frqRecipient.value.receivPhone2;
    recipientData.phone[2] = this.frqRecipient.value.receivPhone3;

    addressesData.address = this.frqAddress.value.addressAddress;
    addressesData.city_name = await this.locationsService.get(this.frqAddress.value.addressCity, true).name;
    addressesData.country_name = await this.locationsService.get(this.frqAddress.value.addressCountry, true).name;
    addressesData.state_name = await this.locationsService.get(this.frqAddress.value.addressState, true).name;
    addressesData.city = this.frqAddress.value.addressCity;
    addressesData.country = this.frqAddress.value.addressCountry;
    addressesData.state = this.frqAddress.value.addressState;
    addressesData.zipcode = this.frqAddress.value.addressZipCode;
    addressesData.availability = this.frqAddress.value.addressAvailability;
    addressesData.businessHours = this.frqAddress.value.addressBusinessHours;

    orderData['shippingDate'] = this.datePipe.transform(this.orderForm.value.shippingDate, 'dd/MM/yy');
    orderData['shippingType'] = this.orderForm.value.shippingType;
    orderData['manifest'] = '???';
    orderData['type'] = 'money';
    orderData['deleted'] = false;
    orderData['createdBy'] = this.profile.email;
    orderData['amount'] = this.orderForm.value.amount;

    // Sender create or update
    // UPDATING SENDER
    if (this.senderRef) {
      if (this.frqSender.value.sendEmail) {
        this.frqSender.value.label = this.frqSender.value.sendName
          + ' ' + this.frqSender.value.sendLastName
          + ' (' + this.frqSender.value.sendEmail + ')';
      } else {
        this.frqSender.value.label = this.frqSender.value.sendName + ' ' + this.frqSender.value.sendLastName;
      }
      this.frqSender.value.search_label = this.frqSender.value.label.toLowerCase();
      this.personService.updateSender(this.senderRef, this.frqSender.value);
    } else {
      // CREATING SENDER
      senderData.agency_path = this.agencyPath;
      if (senderData.email) {
        senderData.label = senderData.name + ' ' + senderData.lastname + ' (' + senderData.email + ')';
      } else {
        senderData.label = senderData.name + ' ' + senderData.lastname;
      }
      senderData.search_label = senderData.label.toLowerCase();
      this.personService.createSender(senderData);
    }

    // Sender create or update
    // UPDATING SENDER
    if (this.senderRef) {
      if (this.frqSender.value.sendEmail) {
        this.frqSender.value.label = this.frqSender.value.sendName + ' ' + this.frqSender.value.sendLastName + ' (' + this.frqSender.value.sendEmail + ')';
      } else {
        this.frqSender.value.label = this.frqSender.value.sendName + ' ' + this.frqSender.value.sendLastName;
      }
      this.frqSender.value.search_label = this.frqSender.value.label.toLowerCase();
      this.personService.updateSender(this.senderRef, this.frqSender.value);
    } else {
      // CREATING SENDER
      senderData.agency_path = this.agencyPath;
      if (senderData.email) {
        senderData.label = senderData.name + ' ' + senderData.lastname + ' (' + senderData.email + ')';
      } else {
        senderData.label = senderData.name + ' ' + senderData.lastname;
      }
      senderData.search_label = senderData.label.toLowerCase();
      this.personService.createSender(senderData);
    }

    // Recipient create or update
    // UPDATING RECIPIENT
    if (this.recipientRef) {
      if (this.frqRecipient.value.receivEmail) {
        this.frqRecipient.value.label = this.frqRecipient.value.receivName + ' ' + this.frqRecipient.value.receivLastName + ' (' + this.frqRecipient.value.receivEmail + ')';
      } else {
        this.frqRecipient.value.label = this.frqRecipient.value.receivName + ' ' + this.frqRecipient.value.receivLastName;
      }
      this.frqRecipient.value.search_label = this.frqRecipient.value.label.toLowerCase();
      this.personService.updateRecipient(this.recipientRef, this.frqRecipient.value);
    } else {
      // CREATING RECIPIENT
      recipientData.agency_path = this.agencyPath;
      if (recipientData.email) {
        recipientData.label = recipientData.name + ' ' + recipientData.lastname + ' (' + recipientData.email + ')';
      } else {
        recipientData.label = recipientData.name + ' ' + recipientData.lastname;
      }
      recipientData.search_label = recipientData.label.toLowerCase();
      this.personService.createRecipient(recipientData).then((p) => {
        // CREATING RECIPIENT ADDRESS
        this.personService.createAddress(p, addressesData);
      });
    }
    if (this.addressesRef) {
      this.personService.updateAddress(this.recipientRef, addressesData, this.frqAddress.value.addressIndex);
    }
    // else if (!this.addressesRef) {
    //   this.personService.createNewAddress(this.recipientRef, addressesData);
    // }

    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const date = new Date();

    this.orderService.getNextOrderNumber().then((number) => {
      orderData['number'] = number;
      orderData['id'] = 'MO' + '-' + monthNames[date.getMonth()]
        + '-' + date.getFullYear().toString().substr(-2) + '-' + this.padNum(number);
      this.orderService.create(orderData, senderData, recipientData, addressesData).then((p) => {
        this.router.navigate([`/order-details/${p.__id}`]);
      });
    });
  }
}
