
/**
 * Convert an image to a base64 url encoded as png with 5:2 aspect ratio
 * @param  {img}   Image
 */
export function convertImgToBase64URLOneThree(img: HTMLImageElement) {
    const canvas = document.createElement('CANVAS') as HTMLCanvasElement,
    ctx = canvas.getContext('2d');

    if (img.width / img.height >= 3) {
        canvas.width = img.width;
        canvas.height = img.width / 3;
    } else if (img.height / img.width >= 3) {
        canvas.height = img.height;
        canvas.width = img.height * 3;
    } else {
        canvas.height = img.height;
        canvas.width = img.width;
    }

    if (img.width > canvas.width || img.height > canvas.height) {

        const imageAspectRatio = img.width / img.height;
        const canvasAspectRatio = canvas.width / canvas.height;
        let renderableHeight = canvas.height,
          renderableWidth = canvas.width,
          xStart = 0,
          yStart = 0,
          steps = 0;

        // If image's aspect ratio is less than canvas's we fit on height
        // and place the image centrally along width
        if (imageAspectRatio < canvasAspectRatio) {
            renderableWidth = img.width * (renderableHeight / img.height);
            xStart = (canvas.width - renderableWidth) / 2;
            yStart = 0;

            // tslint:disable-next-line:no-bitwise
            steps = (img.width / canvas.width) >> 1;
        }

        // If image's aspect ratio is greater than canvas's we fit on width
        // and place the image centrally along height
        if (imageAspectRatio > canvasAspectRatio) {
            renderableHeight = img.height * (renderableWidth / img.width);
            xStart = 0;
            yStart = (canvas.height - renderableHeight) / 2;

            // tslint:disable-next-line:no-bitwise
            steps = (img.height / canvas.height) >> 1;
        }

        // pre-filter image using steps as radius
        ctx.filter = `blur(${steps / 2}px)`;

        ctx.drawImage(img, xStart, yStart, renderableWidth, renderableHeight);
    } else {
        ctx.drawImage(img, canvas.width / 2 - img.width / 2, canvas.height / 2 - img.height / 2);
    }

    return canvas.toDataURL('image/png');
}
