import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product, Inventory, User, Agency, InventoryRequest } from 'src/app/models';
import { InventoryService } from '../../services/inventory.service';
import { ProductService } from '../../services/product.service';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { AgencyService } from 'src/app/services/agency.service';
import { InventoryRequestService } from 'src/app/services/inventory-request.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-inventory-requests',
  templateUrl: './inventory-requests.component.html',
  styleUrls: ['./inventory-requests.component.scss']
})

export class InventoryRequestsComponent implements OnInit {

  profile: User;
  filterAgency: String;
  filterProduct: String;
  isLoading = false;

  inventories: Observable<Collection<Inventory>>;
  products: Observable<Collection<Product>>;
  agencies: Observable<Collection<Agency>>;

  pending: Observable<Collection<InventoryRequest>>;
  delivering: Observable<Collection<InventoryRequest>>;
  archived: Observable<Collection<InventoryRequest>>;

  /**
   * @constructor
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks 
   * @param {UserService} userService Service for users management
   * @param {InventoryService} inventoryService Service for inventories management
   * @param {ProductService} productService Service for products management
   * @param {AgencyService} agencyService Service for agencies management
   * @param {InventoryRequestService} ivRequestService Service for inventory requests management
   * @param {AlertService} alertService Service for alerts management
   */
  constructor(
    private router: Router,
    public userService: UserService,
    private inventoryService: InventoryService,
    private productService: ProductService,
    private agencyService: AgencyService,
    private ivRequestService: InventoryRequestService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      this.profile = p;
      this.getData(this.profile);
    });
  }

  /**
   * Description: Gets all the products. If the role of the current user is admin, gets all the agencies
   * and all the inventories. Else, gets only the inventories of the agency of the current user.
   * @author Mauro Lanza
   * @param {User} profile Current user
   * @returns void
   */
  getData(profile: User): void {
    if (profile) {
      this.products = this.productService.getAll().asObservable();
      if (profile.role === 'admin') {
        this.agencies = this.agencyService.getAll().asObservable();
        this.inventories = this.inventoryService.getAll().asObservable();
        this.getRequests();
      } else {
        this.inventories = this.inventoryService.getAll(ref => ref.where('agency_path', '==', profile.agency_path)).asObservable();
        this.filterAgency = profile.agency_path;
        this.getRequests();
      }
    }
  }

  /**
   * Description: If there is a filter and the role of the current user is admin, gets all the inventory
   * requests that are not pending or deleted and orders them by response date. If there is a filter but
   * the role of the current user is not admin, gets only the inventory requests of the user's agency
   * that are not deleted or pending and orders them by response date. If there is no filter, gets the
   * pending requests. 
   * @author Mauro Lanza
   * @returns void
   */
  getRequests(): void {
    if (this.filterAgency && this.filterProduct) {

      this.pending = this.ivRequestService.getAll(ref => {
        return ref.where('deleted', '==', false)
          .where('pending', '==', true)
          .where('agency_path', '==', this.filterAgency)
          .where('product_path', '==', this.filterProduct)
          .orderBy('request_date', 'desc');
      }).asObservable();

      this.delivering = this.ivRequestService.getAll(ref => {
        return ref.where('deleted', '==', false)
          .where('pending', '==', false)
          .where('archived', '==', false)
          .where('agency_path', '==', this.filterAgency)
          .where('product_path', '==', this.filterProduct)
          .orderBy('response_date', 'desc');
      }).asObservable();

      this.archived = this.ivRequestService.getAll(ref => {
        return ref.where('deleted', '==', false)
          .where('pending', '==', false)
          .where('archived', '==', true)
          .where('agency_path', '==', this.filterAgency)
          .where('product_path', '==', this.filterProduct)
          .orderBy('response_date', 'desc');
      }).asObservable();

    } else if (this.filterProduct) {

      this.pending = this.ivRequestService.getAll(ref => {
        return ref.where('deleted', '==', false)
          .where('pending', '==', true)
          .where('product_path', '==', this.filterProduct)
          .orderBy('request_date', 'desc');
      }).asObservable();

      this.delivering = this.ivRequestService.getAll(ref => {
        return ref.where('deleted', '==', false)
          .where('pending', '==', false)
          .where('archived', '==', false)
          .where('product_path', '==', this.filterProduct)
          .orderBy('response_date', 'desc');
      }).asObservable();

      this.archived = this.ivRequestService.getAll(ref => {
        return ref.where('deleted', '==', false)
          .where('pending', '==', false)
          .where('archived', '==', true)
          .where('product_path', '==', this.filterProduct)
          .orderBy('response_date', 'desc');
      }).asObservable();

    } else if (this.filterAgency) {

      this.pending = this.ivRequestService.getAll(ref => {
        return ref.where('deleted', '==', false)
          .where('pending', '==', true)
          .where('agency_path', '==', this.filterAgency)
          .orderBy('request_date', 'desc');
      }).asObservable();

      this.delivering = this.ivRequestService.getAll(ref => {
        return ref.where('deleted', '==', false)
          .where('pending', '==', false)
          .where('archived', '==', false)
          .where('agency_path', '==', this.filterAgency)
          .orderBy('response_date', 'desc');
      }).asObservable();

      this.archived = this.ivRequestService.getAll(ref => {
        return ref.where('deleted', '==', false)
          .where('pending', '==', false)
          .where('archived', '==', true)
          .where('agency_path', '==', this.filterAgency)
          .orderBy('response_date', 'desc');
      }).asObservable();

    } else {

      this.pending = undefined;
      this.delivering = undefined;
      this.archived = undefined;

    }

  }

  /**
   * Description: Navigates to the details' route of the selected inventory request.
   * @author Mauro Lanza
   * @param {string} id Id of the selected inventory request
   * @returns void
   */
  viewDetails(id: string): void {
    this.router.navigate(['store/inventory/requests/details', id]);
  }

  /**
   * Description: Accepts the inventory request received as param. If the request is accepted, shows
   * a success alert. If there is an error, shows a warning.
   * @author Mauro Lanza
   * @param {InventoryRequest} request 
   * @returns void 
   */
  acceptSelected(request: InventoryRequest): void {
    try {
      this.isLoading = true;

      const data = request;

      this.ivRequestService.accept(data).then(() => {
        this.alertService.showAlert('Request accepted successfully', 'alert-success');
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }

  /**
   * Description: Rejects the inventory request received as param. If the request is jected successfully,
   * shows a success alert. If there is an error, handles it by showing a warning alert.
   * @author Mauro Lanza
   * @param {InventoryRequest} request
   * @returns void 
   */
  rejectSelected(request: InventoryRequest): void {
    try {
      this.isLoading = true;

      const data = request;

      this.ivRequestService.reject(data).then(() => {
        this.alertService.showAlert('Request rejected successfully', 'alert-success');
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }

  /**
   * Description: Deletes the inventory request received as param. If the request is deleted successfully,
   * shows a success alert. If there is an error, shows a warning alert.
   * @author Mauro Lanza
   * @param {InventoryRequest} request 
   * @returns void 
   */
  deleteSelected(request: InventoryRequest) {
    try {
      this.isLoading = true;

      const data = request;

      this.ivRequestService.delete(data).then(() => {
        this.alertService.showAlert('Request deleted successfully', 'alert-success');
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }

  arrivedSelected(request: InventoryRequest) {
    try {
      this.isLoading = true;

      const data = request;

      this.ivRequestService.arrived(data).then(() => {
        this.alertService.showAlert('Request arrived successfully', 'alert-success');
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }

  cancelDelivery(request: InventoryRequest) {
    try {
      this.isLoading = true;

      const data = request;

      this.ivRequestService.cancelDelivery(data).then(() => {
        this.alertService.showAlert('Response canceled successfully', 'alert-success');
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }

}
