import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Rate } from '../../../models/rate';
import { RateService } from '../../../services/rate.service';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { LocationsService } from 'src/app/services/locations.service';

@Component({
  selector: 'app-rates-list',
  templateUrl: './rates-list.component.html',
  styleUrls: ['./rates-list.component.scss']
})
export class RatesListComponent implements OnInit {

  public loading = false;

  public toggle: boolean = false; // Filters view toggler
  //public filtersForm: FormGroup; // Filters
  public zones: Observable<Collection<Rate>>;
  public selectedItem: Rate;
  public modalOn: boolean = false;

  /**
   * @constructor
   * @param {FormBuilder} fb  
   * @param {Router} router Navigation router
   * @param {RateService} rates Service for rates management
   * @param {LocationsService} locationService Service for locations management
   */
  constructor(private fb: FormBuilder,
    private router: Router,
    private rates: RateService,
    private locationService: LocationsService) { }

  ngOnInit() {
    // this.zones = this.rates.getAllRates();
    //this.createFormFilter();
    this.zones = this.rates.getAll().asObservable();
    // this.zones.subscribe((p) => console.log(p) );
    // this.rates.getAll().asObservable().subscribe((ratesCollection)=>{
    //   this.zones = ratesCollection.iterable;
    //   setTimeout(()=>{
    //     this.loading = false;
    //   }, 10);
    // });

  }

  /** 
   * Description: Initializes the form for the filters
   * @author Maximiliano Casale
   */
  // public createFormFilter(): void {
  //   this.filtersForm = this.fb.group(
  //     {
  //       dateFrom: [''],
  //       dateTo: [''],
  //       rateType: [''],
  //     });
  // }

  test() { }

  /**
   * Description: Shows selected zone details.
   * @author Maximiliano Casale
   * @param {any} zone: Selected zone to display details
   * @returns void
   */
  public viewDetails(zone): void {
    this.selectedItem = zone;
    // console.log(this.selectedItem);
    this.modalOn = this.modalOn === true ? false : true;
  }

  /**
   * Description: Navigates to the edit view of the selected zone using its id. 
   * @author Maximiliano Casale
   * @param {string} zoneId Id of the selected zone to edit
   * @returns void
   */
  public editZone(zoneId: string): void {
    this.modalOn = true;
    this.router.navigate([`rates-management/edit/${zoneId}`]);
  }

}
