import { Observable } from 'rxjs';
import { Product } from './product';
import { Entity } from 'aether-blaze';
import { Agency } from './agency';

export class Inventory extends Entity {
  public product_path: string;
  public agency_path: string;
  public available: boolean;
  public stock: number;
  public authorized: boolean;
  public warning_hi: number;
  public warning_low: number;

  public get product() {
    if (this.product_path) {
      return new Product(this.product_path);
    }
  }

  public set product(value: Product) {
    this.product_path = value.__path;
  }

  public get agency() {
    if (this.agency_path) {
      return new Agency(this.agency_path);
    }
  }

  public set agency(value: Agency) {
    this.agency_path = value.__path;
  }

  /**
   * Description: Returns the inventory as a promise.
   * @author Mauro Lanza
   * @returns {Promise<Inventory>}
   */
  public __toPromise() {
    return this.__promise as Promise<Inventory>;
  }

  /**
   * Description: Returns the inventory as an observable.
   * @author Mauro Lanza
   * @returns {Observable<Inventory>}
   */
  public __asObservable() {
    return this.__subject.asObservable() as Observable<Inventory>;
  }

  public set productStock(newStock: number) {
    this.stock = newStock;
  }

  /**
   * @constructor
   * @param {string} path Specifies the navigation path of an inventory
   * @param {string} product_path Path of the product in the inventory
   * @param {string} agency_path Path of the agency making the inventory
   * @param {boolean} available Is the inventory available or not
   * @param {number} stock Stock of a product in the inventory
   * @param {number} warning_hi Warning for yellow alert of remaining stock
   * @param {number} warning_low Warning for red alert of remaining stock
   */
  constructor(
    path: string,
    product_path?: string,
    agency_path?: string,
    available?: boolean,
    stock?: number,
    authorized?: boolean,
    warning_hi?: number,
    warning_low?: number
  ) {
    super(Inventory, path);
    if (product_path) {
      this.product_path = product_path;
    }
    if (agency_path) {
      this.agency_path = agency_path;
    }
    if (available) {
      this.available = available;
    }
    if (stock) {
      this.stock = stock;
    }
    if (authorized) {
      this.authorized = authorized;
    }
    if (warning_hi) {
      this.warning_hi = warning_hi;
    }
    if (warning_low) {
      this.warning_low = warning_low;
    }
  }
}
