import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
import { DocumentReference } from 'firebase/firestore';
import { Agency } from './agency';
import { iPerson } from './person';
import { Location } from './location';

export interface iOrder {
    id: string;
    agency_path: string;
    recipient: iPerson;
    sender: iPerson;
    address: {
        address: string;
        city: string;
        country: string;
        state: string;
        city_name: string;
        country_name: string;
        state_name: string;
        zipcode: string;
        availability: string;
        businessHours: string;
    };
    shippingDate: string;
    shippingType: string;
    packing: boolean;
    promotion: boolean;
    createdAt: any;
    manifest: string;
    deleted: boolean;
    boxes: any[];
    number: number;
    year: string;
    shippingCost: number;
    insurance: number;
    countryTax: number;
    packingService: number;
    customsManagement: number;
    total: number;
}

export class Order extends Entity implements iOrder {
    public id: string;
    public agency_path: string;
    public recipient: iPerson;
    public sender: iPerson;
    public address: {
        address: string;
        city: string;
        country: string;
        state: string;
        city_name: string;
        country_name: string;
        state_name: string;
        zipcode: string;
        availability: string;
        businessHours: string;
    };
    public shippingDate: string;
    public shippingType: string;
    public packing: boolean;
    public promotion: boolean;
    public createdAt: any;
    public comments: string;
    public manifest: string;
    public deleted: boolean;
    public boxes: any[];
    public number: number;
    public year: string;
    public shippingCost: number;
    public insurance: number;
    public countryTax: number;
    public packingService: number;
    public customsManagement: number;
    public total: number;

    /**
     * Description: Returns the order as a promise.
     * @author Alejandro Font
     * @returns {Promise<Order>}
     */
    public __toPromise() {
        return this.__promise as Promise<Order>;
    }

    /**
     * Description: Returns the order as an observable.
     * @author Alejandro Font
     * @returns {Observable<Order>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<Order>);
    }

    public get agency(): Agency {
        return new Agency(this.agency_path);
    }

    public get country() {
        if (this.address) {
            return new Location(this.address.country);
        }
    }

    public set country(value: any) {
        this.address = value.__path;
    }

    public get state() {
        if (this.address) {
            return new Location(this.address.state);
        }
    }

    public set state(value: any) {
        this.address.state = value.__path;
    }

    public get city() {
        if (this.address) {
            return new Location(this.address.city);
        }
    }

    public set city(value: any) {
        this.address.city = value.__path;
    }

    /**
     * @constructor
     * @param {string} path
     * @param {string} id Order's identification
     * @param {string} agency_path Agency to which the order corresponds
     * @param {iPerson} recipient Info of the person that receives the money/package
     * @param {iPerson} sender Info of the person that sends the money/package
     * @param address Address of the recipient
     * @param {string} shippingDate
     * @param {string} shippingType
     * @param {boolean} packing
     * @param {boolean} promotion
     * @param {any} createdAt Date when the order was made
     * @param {string} manifest Manifest to which the order belongs
     * @param {boolean} deleted
     * @param {any[]} boxes
     * @param {number} number
     * @param {string} comments
     * @param {string} year
     * @param {number} shippingCost
     * @param {number} insurance
     * @param {number} countryTax
     * @param {number} packingService
     * @param {number} total
     */
    constructor(path: string,
        id?: string,
        agency_path?: string,
        recipient?: iPerson,
        sender?: iPerson,
        address?: {
            address: string;
            city: string;
            country: string;
            state: string;
            city_name: string;
            country_name: string;
            state_name: string;
            zipcode: string;
            availability: string;
            businessHours: string;
        },
        shippingDate?: string,
        shippingType?: string,
        packing?: boolean,
        promotion?: boolean,
        createdAt?: any,
        manifest?: string,
        deleted?: boolean,
        boxes?: any[],
        number?: number,
        comments?: string,
        year?: string,
        shippingCost?: number,
        insurance?: number,
        countryTax?: number,
        packingService?: number,
        customsManagement?: number,
        total?: number
    ) {
        super(Order, path);
        if (id) {
            this.id = id;
        }
        if (agency_path) {
            this.agency_path = agency_path;
        }
        if (recipient) {
            this.recipient = recipient;
        }
        if (sender) {
            this.sender = sender;
        }
        if (comments) {
            this.comments = comments;
        }
        if (address) {
            this.address = address;
        }
        if (shippingDate) {
            this.shippingDate = shippingDate;
        }
        if (shippingType) {
            this.shippingType = shippingType;
        }
        if (packing) {
            this.packing = packing;
        }
        if (promotion) {
            this.promotion = promotion;
        }
        if (createdAt) {
            this.createdAt = createdAt;
        }
        if (manifest) {
            this.manifest = manifest;
        }
        if (deleted) {
            this.deleted = deleted;
        }
        if (boxes) {
            this.boxes = boxes;
        }
        if (number) {
            this.number = number;
        }
        if (year) {
            this.year = year;
        }
        if (shippingCost) {
            this.shippingCost = shippingCost;
        }
        if (insurance) {
            this.insurance = insurance;
        }
        if (countryTax) {
            this.countryTax = countryTax;
        }
        if (packingService) {
            this.packingService = packingService;
        }
        if (customsManagement) {
            this.customsManagement = customsManagement;
        }
        if (total) {
            this.total = total;
        }
    }
}
