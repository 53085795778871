import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { User, Agency } from '../../models';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AgencyService } from '../../services/agency.service';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  inviteDetails: FormGroup;
  profile: User;
  userAgency: Agency;
  agenciesList: Observable<Collection<Agency>>;
  agency: string;

  isLoading: boolean;

  /**
   * @constructor
   * @param {UserService} userService Service that allows users management
   * @param {FormBuilder} fb Form builder that allows to create a form to invite a new user
   * @param {Location} location Current navigation location
   * @param {AgencyService} agencyService Service for the agencies management
   * @param {AlertService} alertService Service for the alerts management
   */
  constructor(
    public userService: UserService,
    private fb: FormBuilder,
    private location: Location,
    private agencyService: AgencyService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      this.profile = p;
      // console.log(p);
      this.getAgencies(this.profile);
    });
    this.createInviteForm();
  }

  /**
   * Description: Gets different agencies depending on the role of the user.
   * @author Jorge Del Castillo
   * @param {User} profile 
   * @returns void 
   */
  getAgencies(profile: User): void {
    switch (profile.role) {
      case 'manager-ccarga':
      case 'manager':
        // this.agenciesList = this.agencyService.getAll('id', '==', profile.agency.id).asObservable();
        // this.agenciesList[] = [profile.agency];
        // this.userAgency = this.agencyService.get(profile.agency._key.path.segments.slice(-1)[0]);
        this.userAgency = this.agencyService.get(profile.agency_path.substring(9));
        break;

      case 'admin':
        this.agenciesList = this.agencyService.getAll().asObservable();
        break;

      default:
        break;
    }
  }

  /**
   * Description: Creates a new form to create a new user.
   * @author Jorge Del Castillo
   * @returns void
   */
  createInviteForm() {
    this.inviteDetails = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['', [Validators.required]],
      agency_path: ['', [Validators.required]],
    });
  }

  // save(): void {
  //   this.isLoading = true;
  //   this.userService.invite(this.inviteDetails.value).then(() => {
  //     this.isLoading = false;
  //     alert('User invited successfully');
  //     this.location.back();
  //   });
  // }

  /**
   * Description: Handles the submit of the new user.
   * @author Jorge Del Castillo
   * @returns {Promise<void>} New user invitation as a void promise
   */
  async submitHandler() {
    try {
      this.isLoading = true;
      await this.userService.invite(this.inviteDetails.value).then(() => {
        this.isLoading = false;
      });
    } catch (err) {
      this.alertService.showAlert('An error occurred. Please contact your administrator', 'alert-warning');
      console.log(err)
      this.isLoading = false;
    }
  }

  /**
   * Description: Resets the fields of the invite user form and navigates back to the previous view.
   * @author Jorge Del Castillo
   * @returns void
   */
  cancel(): void {
    this.inviteDetails.reset();
    this.location.back();
  }

}
