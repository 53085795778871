import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
import { Category } from './category';

export class iStoreProduct {
    name?: string;
    sku?: string;
    description?: string;
    image?: string[];
    price?: number;
    tax?: number;
    timestamp?: string[];
    deleted?: boolean;
    weight?: number;
    height?: number;
    width?: number;
    length?: number;
    stock?: number;
    category_path?: string;
}

export class StoreProduct extends Entity implements iStoreProduct {

    public sku?: string;
    public name?: string;
    public description?: string;
    public image?: string[];
    public price?: number;
    public category_path?: string;
    public deleted?: boolean;
    public timestamp?: string[];
    public tax?: number;
    public weight?: number;
    public height?: number;
    public width?: number;
    public length?: number;
    public stock?: number;
    public stockWarning?: number;


    public get category(): Category {
        return new Category(this.category_path);
    }

    /**
    * Description: Returns the store product as a promise.
    * @author Maximiliano Casale
    * @returns {Promise<StoreProduct>}
    */
    public __toPromise() {
        return this.__promise as Promise<StoreProduct>;
    }

    /**
     * Description: Returns the store product as an observable.
     * @author Maximiliano Casale
     * @returns {Observable<StoreProduct>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<StoreProduct>);
    }

    /**
     * @constructor
     * @param {string} path
     * @param {string} sku Id
     * @param {string} name
     * @param {string} description
     * @param {string[]} image
     * @param {number} price
     * @param {string[]} category
     * @param {boolean} deleted
     * @param {string[]} timestamp
     * @param {number} tax
     * @param {number} weight
     * @param {number} height
     * @param {number} width
     * @param {number} length
     * @param {number} stock
     * @param {number} stockWarning
     */
    constructor(path: string,
        sku?: string,
        name?: string,
        description?: string,
        image?: string[],
        price?: number,
        category_path?: string,
        deleted?: boolean,
        timestamp?: string[],
        tax?: number,
        weight?: number,
        height?: number,
        width?: number,
        length?: number,
        stock?: number,
        stockWarning?: number,
    ) {
        super(StoreProduct, path);
        if (sku) {
            this.sku = sku;
        }
        if (name) {
            this.name = name;
        }
        if (description) {
            this.description = description;
        }
        if (image) {
            this.image = image;
        }
        if (price) {
            this.price = price;
        }
        if (category_path) {
            this.category_path = category_path;
        }
        if (deleted) {
            this.deleted = deleted;
        }
        if (timestamp) {
            this.timestamp = timestamp;
        }
        if (tax) {
            this.tax = tax;
        }
        if (weight) {
            this.weight = weight;
        }
        if (height) {
            this.height = height;
        }
        if (width) {
            this.width = width;
        }
        if (length) {
            this.length = length;
        }
        if (stock) {
            this.stock = stock;
        }
        if (stockWarning) {
            this.stockWarning = stockWarning;
        }
    }
}
