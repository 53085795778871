/// <reference types="@types/googlemaps" />
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PickUpService } from 'src/app/services/pick-up.service';
import { PickUp } from 'src/app/models/pickup';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import {
  MapsAPILoader,
  AgmMap
} from '@agm/core';
import {
  GoogleMapsAPIWrapper
} from '@agm/core/services';
import { UserService } from 'src/app/services/user.service';
import { LocationsService } from 'src/app/services/locations.service';

declare var google: any;


@Component({
  selector: 'app-pick-up-details',
  templateUrl: './pick-up-details.component.html',
  styleUrls: ['./pick-up-details.component.scss']
})
export class PickUpDetailsComponent implements OnInit {

  public pickUp: PickUp;

  // map params
  public lat: number;
  public lng: number;
  public origin: any = '';
  public destination: any = '';
  public zoom = 12;
  public userRole: string;

  public userAddress: string;
  isLoading: boolean;

  /**
   * @constructor
   * @param {ActivatedRoute} activatedRoute Contains the information about the route associated with a component loaded in the outlet
   * @param {PickUpService} pickUpService Service for pick ups management
   * @param {MapsAPILoader} mapsAPILoader Provides a convenient wrapper for the Google Maps API, allowing it to be called in the promise syntax
   * @param {UserService} userService Service for users management
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private pickUpService: PickUpService,
    private mapsAPILoader: MapsAPILoader,
    private userService: UserService,
    private router: Router,
    private locationService: LocationsService,


  ) { }

  ngOnInit() {
    this.isLoading = false;
    this.userService.userProfile.subscribe(u => {
      this.userRole = u.role;
    });
    this.activatedRoute.params.subscribe(params => {
      this.pickUpService.get(params['id']).__asObservable().subscribe(pu => {
        this.pickUp = pu;
        this.lng = Number(pu.agency.longitude);
        this.lat = Number(pu.agency.latitude);
        this.mapsAPILoader.load().then(() => {
          const geocoder = new google.maps.Geocoder;
          this.origin = new google.maps.LatLng(this.lat, this.lng);
          geocoder.geocode({ address: this.pickUp.userAddress }, res => {
            this.userAddress = res[0].formatted_address;
            const lng = res[0].geometry.location.lng();
            const lat = res[0].geometry.location.lat();
            this.destination = new google.maps.LatLng(lat, lng);
          });
        });
      });
    });
  }

  completePickup() {
    this.isLoading = true;
    if (this.pickUp) {
      this.pickUp.completed = true;
      this.pickUpService.update(this.pickUp).then(() => {
        // this.router.naviate.goback();
        this.router.navigate([`/pick-up-list`]);
      });
    }
  }

  /**
   * Description: Creates pdf for the selected pick up.
   * @author Maximiliano Casale
   * @returns void
   */
  public downloadPDF(): void {
    const pdf = new jspdf('p', 'mm', 'a4');
    const element = document.getElementById('pdf');
    html2canvas(element).then(canvas => {
      const imgWidth = 198;
      const imgHeight = canvas.height * 1.30 * imgWidth / canvas.width;
      const margin = 7;
      const position = 10;
      const contentDataURL = canvas.toDataURL('img/png');
      pdf.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);
      pdf.save(`${this.pickUp.user.name}'s PickUp`);
    });
  }
}
