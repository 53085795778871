import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { OrderService } from '../../services/order.service';
import { AgencyService } from '../../services/agency.service';
import { Order, Agency, Location } from '../../models';
import { LocationsService } from 'src/app/services/locations.service';
import 'rxjs/add/operator/first';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { switchMap, tap, delay } from 'rxjs/operators';
import { parseDate } from 'tough-cookie';
import { PdferService } from 'src/app/services/pdfer.service';
import { OrderDetailPDFModel } from 'src/app/templates/pdf/order-details';
import { timer, of } from 'rxjs';

@Component({
  selector: 'app-orders-details',
  templateUrl: './orders-details.component.html',
  styleUrls: ['./orders-details.component.scss']
})
export class OrdersDetailsComponent implements OnInit {

  public selectedOrder: Order; // type: order
  public orderAgency: Agency;
  public noLogo = '../../assets/no-logo.png';
  public modalOn = false;
  deleteModal = false;
  isLoading = false;

  srcData: SafeResourceUrl;
  img: any;
  imageToShow: string | ArrayBuffer;

  public pagination: Array<Array<any>> = [];

  /**
   * @constructor
   * @param {OrderService} orderService Service for orders management
   * @param {ActivatedRoute} route Contains the information about the route associated with a component loaded in the outlet
   * @param {AgencyService} agencyService Service for agencies management
   * @param {DomSanitizer} sanitizer Helps to prevent attackers from injecting malicious client-side scripts
   * @param {LocationsService} locationService Service for locations management
   */
  constructor(private orderService: OrderService,
    private route: ActivatedRoute,
    private agencyService: AgencyService,
    private sanitizer: DomSanitizer,
    // si se usa
    private locationService: LocationsService,
    private router: Router,

    private pdfer: PdferService
  ) { }

  ngOnInit() {

    this.route.params.first().pipe(
      switchMap(params => {
        this.selectedOrder = this.orderService.get(params['id']);
        return this.selectedOrder.__asObservable();
      }),
      switchMap((order: Order) => {
        if (order.boxes) {
          this.pagination = this.paginateOrder(order);
        }
        console.log(this.pagination);
        this.orderAgency = new Agency(order.agency_path);
        return this.orderAgency.__asObservable();
      }),
      switchMap((agency: Agency) => {
        // this.srcData = this.sanitizer.bypassSecurityTrustResourceUrl(this.orderAgency.logo);
        return this.agencyService.getBase64Img(agency.timestamp);
      }),
      tap((i) => {
        this.img = i.base64;
      })
    ).subscribe(() => {
      // console.log('I\'m Done');
    });
  }

  /**
   * Description: Paginates amount of boxes in the order for the PDF file.
   * @author Maximiliano Casale
   * @param order: the order to be paginated.
   * @returns void
   */
  public paginateOrder(order: Order): Array<Array<any>> {
    const rowsPerPage = 15;
    const pages = order.boxes.length / rowsPerPage;
    const pageArrays = [];
    for (let pag = 0; pag <= pages; pag++) {
      const pagedArr = order.boxes.slice(pag * rowsPerPage, (pag + 1) * rowsPerPage);
      if (pagedArr.length > 0) { pageArrays.push(pagedArr); }
    }
    return pageArrays;
  }


  /**
   * Description: Generates the selected order's pdf.
   * @author Maximiliano Casale
   * @returns void
   */
  public captureMoneyPDF() {

    const senderState = new Location(this.selectedOrder.sender.addresses[0].state);
    const senderCountry = new Location(this.selectedOrder.sender.addresses[0].country);
    const senderCity = new Location(this.selectedOrder.sender.addresses[0].city);

    return Promise.all([
      senderState.__toPromise(),
      senderCountry.__toPromise(),
      senderCity.__toPromise()
    ]).then(() => {

      const recipientAddress =
      this.selectedOrder.address.address + ', '
      + this.selectedOrder.address.city_name + ' - '
      + this.selectedOrder.address.availability.toUpperCase()
      + (this.selectedOrder.address.availability === 'Office' ?
        ' ' + this.selectedOrder.address.businessHours.toUpperCase() :
        '');

      this.pdfer.buildMoneyOrderDetailPDF({

        agencyLogo: this.img,

        id: this.selectedOrder.id,
        createdAt: this.selectedOrder.createdAt ?
          this.selectedOrder.createdAt.toDate().toLocaleString()
          : null,

        sender: {
          name: this.selectedOrder.sender.name + ' ' + this.selectedOrder.sender.lastname,
          email: this.selectedOrder.sender.email,
          number: this.selectedOrder.sender.phone.filter((n) => n.length > 1).join(' - '),
          stateCountry: senderState.name + ', ' + senderCountry.name,
          address: this.selectedOrder.sender.addresses[0].address
            + ', ' + senderCity.name,
          city: senderCity.name
        },

        recipient: {
          name: this.selectedOrder.recipient.name + ' ' + this.selectedOrder.recipient.lastname,
          email: this.selectedOrder.recipient.email,
          number: this.selectedOrder.recipient.phone.filter((n) => n.length > 1).join(' - '),
          stateCountry: this.selectedOrder.address.state_name + ', ' + this.selectedOrder.address.country_name,
          address: recipientAddress,
          city: this.selectedOrder.address.city_name
        },

        agency: {
          name: this.orderAgency.name,
          email: this.orderAgency.email,
          number: this.orderAgency.phones.filter((n) => n.length > 1).join(' - '),
          stateCountry: this.orderAgency.state.name + ', ' + this.orderAgency.country.name,
          address: this.orderAgency.address
        },

        amount: (this.selectedOrder as any).amount + ' $'
      });
    });
  }

  /**
   * Description: Creates boxes table depending on the selected order's boxes.
   * @author Maximiliano Casale
   * @returns void
   */
  public captureTags(): Promise<void> {
    const senderState = new Location(this.selectedOrder.sender.addresses[0].state);
    const senderCountry = new Location(this.selectedOrder.sender.addresses[0].country);
    const senderCity = new Location(this.selectedOrder.sender.addresses[0].city);

    return Promise.all([
      senderState.__toPromise(),
      senderCountry.__toPromise(),
      senderCity.__toPromise()
    ]).then(() => {

      const recipientAddress =
      this.selectedOrder.address.address + ', '
      + this.selectedOrder.address.city_name + ' - '
      + this.selectedOrder.address.availability.toUpperCase()
      + (this.selectedOrder.address.availability === 'Office' ?
        ' ' + this.selectedOrder.address.businessHours.toUpperCase() :
        '');

      this.pdfer.buildOrderTagsPDF({

        agencyLogo: this.img,

        id: this.selectedOrder.id,
        type: this.selectedOrder.shippingType,

        sender: {
          name: this.selectedOrder.sender.name + ' ' + this.selectedOrder.sender.lastname,
          stateCountry: senderState.name + ', ' + senderCountry.name,
          city: senderCity.name
        },

        recipient: {
          name: this.selectedOrder.recipient.name + ' ' + this.selectedOrder.recipient.lastname,
          stateCountry: this.selectedOrder.address.state_name + ', ' + this.selectedOrder.address.country_name,
          city: this.selectedOrder.address.city_name
        },

        agency: {
          name: this.orderAgency.name
        },

        boxes: this.selectedOrder.boxes.map((b) => {
          return {
            dimensions: b.dimHeight + ' x ' + b.dimWidth + ' x ' + b.dimLength,
            weight: b.weight
          };
        })
      });
    });
  }

  /**
   * Description: Creates receipt for the selected order.
   * @author Maximiliano Casale
   * @param {string} id Id of the selected order
   * @returns void
   */
  public captureReceipt(): Promise<void> {

    const senderState = new Location(this.selectedOrder.sender.addresses[0].state);
    const senderCountry = new Location(this.selectedOrder.sender.addresses[0].country);
    const senderCity = new Location(this.selectedOrder.sender.addresses[0].city);

    return Promise.all([
      senderState.__toPromise(),
      senderCountry.__toPromise(),
      senderCity.__toPromise()
    ]).then(() => {

      const recipientAddress =
      this.selectedOrder.address.address + ', '
      + this.selectedOrder.address.city_name + ' - '
      + this.selectedOrder.address.availability.toUpperCase()
      + (this.selectedOrder.address.availability === 'Office' ?
        ' ' + this.selectedOrder.address.businessHours.toUpperCase() :
        '');

      this.pdfer.buildOrderDetailPDF({

        agencyLogo: this.img,
        id: this.selectedOrder.id,
        type: this.selectedOrder.shippingType,
        createdAt: this.selectedOrder.createdAt ?
          this.selectedOrder.createdAt.toDate().toLocaleString()
          : null,

        sender: {
          name: this.selectedOrder.sender.name + ' ' + this.selectedOrder.sender.lastname,
          email: this.selectedOrder.sender.email,
          number: this.selectedOrder.sender.phone.filter((n) => n.length > 1).join(' - '),
          stateCountry: senderState.name + ', ' + senderCountry.name,
          address: this.selectedOrder.sender.addresses[0].address
            + ', ' + senderCity.name
        },

        recipient: {
          name: this.selectedOrder.recipient.name + ' ' + this.selectedOrder.recipient.lastname,
          email: this.selectedOrder.recipient.email,
          number: this.selectedOrder.recipient.phone.filter((n) => n.length > 1).join(' - '),
          stateCountry: this.selectedOrder.address.state_name + ', ' + this.selectedOrder.address.country_name,
          address: recipientAddress
        },

        agency: {
          name: this.orderAgency.name,
          email: this.orderAgency.email,
          number: this.orderAgency.phones.filter((n) => n.length > 1).join(' - '),
          stateCountry: this.orderAgency.state.name + ', ' + this.orderAgency.country.name,
          address: this.orderAgency.address
        },

        boxes: this.selectedOrder.boxes.map((b) => {
          return {
            content: b.describeContent.split(' ').reduce((a, c) => {
              while (c.length > 20) {
                a += ' ' + c.substr(0, 20) + '-';
                c = c.substr(20);
              }
              return a += ' ' + c;
            }, ''),
            dimensions: b.dimHeight + ' x ' + b.dimWidth + ' x ' + b.dimLength,
            weight: b.weight,
            insured: b.insuredValue,
            declared: b.declaredValue,
            packing: b.packingValue,
            promotionCode: b.promotionCode,
            promotionType: b.promotionType,
            cost: b.priceBox
          };
        }),

        shippingCost: this.selectedOrder.shippingCost.toString(),
        insurance: this.selectedOrder.insurance.toString(),
        countryTax: this.selectedOrder.countryTax.toString(),
        packingTotal: this.selectedOrder.packingService.toString(),
        customsManagement: this.selectedOrder.customsManagement ? this.selectedOrder.customsManagement.toString() : '0',
        total: this.selectedOrder.total.toString(),
      });
    });
  }

  deleteOrder() {
    try {
      this.isLoading = true;

      this.selectedOrder.deleted = true;

      this.orderService.update(this.selectedOrder).then(() => {
        // this.alertService.showAlert('Request created successfully', 'alert-success');
        this.isLoading = false;
        this.deleteModal = false;
        this.router.navigate(['order-list']);

      });
    } catch (error) {
      this.isLoading = false;
      // this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }


}
