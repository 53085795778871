import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AlertItem {

  content: string;
  type: string;

}

export class AlertService {

  alert = new Subject<AlertItem>();

  /**
   * @constructor
   */
  constructor() { }

  /**
   * Description: Shows the content received as param as an alert.
   * @author Jorge Del Castillo
   * @param {string} content Content to alert 
   * @param {string} type Type of alert
   * @returns void
   */
  showAlert(content: string, type: string): void {
    this.alert.next({ content: content, type: type } as AlertItem)
  }

  /**
   * Description: Shows that an error in the system ocurred as an alert.
   * @author Jorge Del Castillo
   * @returns void
   */
  errorHandler() {
    this.alert.next({ content: 'An error ocurred, please contact your administrator.', type: 'alert-warning' })
  }
}
