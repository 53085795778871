import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
// import { Agency, Order, User } from './index';
import { Agency } from './agency';
import { User } from './user';

export interface iShipping {
    id: string;
    manifests_path: string[];
    createdAt: any;
    createdBy: string;
    agency_path: string;
    number: number;
    deleted: boolean;
    year: string;
    type: string;
}

export class Shipping extends Entity implements iShipping {
    public id: string;
    public number: number;
    public manifests_path: string[];
    public createdAt: any;
    public createdBy: string;
    public agency_path: string;
    public deleted: boolean;
    public year: string;
    public type: string;

    public get agency() {
        if (this.agency_path) {
            return new Agency(this.agency_path);
        }
    }
    public set agency(value: Agency) {
        this.agency_path = value.__path;
    }

    public get user() {
        if (this.createdBy) {
            return new User(this.createdBy);
        }
    }

    /**
     * Description: Evaluates if the shipping is deleted or not.
     * @author
     * @returns {boolean}
     */
    public isDeleted() {
        return this.deleted;
    }


    public set user(value: User) {
        this.createdBy = value.__path;
    }

    /**
     * Description: Returns the shipping as a promise.
     * @author
     * @returns {Promise<Shipping>}
     */
    public __toPromise() {
        return this.__promise as Promise<Shipping>;
    }

    /**
     * Description: Returns the shipping as an observable.
     * @author
     * @returns {Observable<Shipping>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<Shipping>);
    }

    /**
     * @constructor
     * @param {string} path
     * @param {string} id Includes "PA" if it is a package shipping or "MO" if it is a money shipping, its creation date and a serial number
     * @param {string[]} manifests_path
     * @param {any} createdAt Date with month, day and year
     * @param {string} createdBy
     * @param {string} agency_path
     * @param {number} number
     * @param {boolean} deleted
     * @param {string} year
     * @param {string} type Package or money
     */
    constructor(path: string,
        id?: string,
        manifests_path?: string[],
        createdAt?: any,
        createdBy?: string,
        agency_path?: string,
        number?: number,
        deleted?: boolean,
        year?: string,
        type?: string,
    ) {
        super(Shipping, path);
        if (id) {
            this.id = id;
        }
        if (manifests_path) {
            this.manifests_path = manifests_path;
        }
        if (createdAt) {
            this.createdAt = createdAt;
        }
        if (createdBy) {
            this.createdBy = createdBy;
        }
        if (agency_path) {
            this.agency_path = agency_path;
        }
        if (number) {
            this.number = number;
        }
        if (deleted !== undefined) {
            this.deleted = deleted;
        }
        if (year) {
            this.year = year;
        }
        if (type) {
            this.type = type;
        }
    }
}
