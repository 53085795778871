import { Injectable } from '@angular/core';

import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { OrderDetailPDFModel, parseOrderDetail } from '../templates/pdf/order-details';
import { MoneyOrderDetailPDFModel, parseMoneyOrderDetail } from '../templates/pdf/money-order-details';
import { parseOrderTag, OrderTagPDFModel } from '../templates/pdf/order-tags';
import { parseManifestPDF } from '../templates/pdf/manifest';
import { parseMoneyManifestPDF } from '../templates/pdf/money-manifest';

@Injectable({
  providedIn: 'root'
})
export class PdferService {

  constructor() { }

  public async buildMoneyOrderDetailPDF(data: MoneyOrderDetailPDFModel) {

    const parsed = await parseMoneyOrderDetail(data);

    const pdf = pdfMake.createPdf(parsed);

    pdf.download(data.id + '.pdf');
  }

  public async buildOrderDetailPDF(data: OrderDetailPDFModel) {

    const parsed = await parseOrderDetail(data);

    const pdf = pdfMake.createPdf(parsed);

    pdf.download(data.id + '.pdf');
  }

  public async buildOrderTagsPDF(data: OrderTagPDFModel) {

    const parsed = await parseOrderTag(data);

    const pdf = pdfMake.createPdf(parsed);

    pdf.download(data.id + '.pdf');
  }

  public async buildManifestPDF(data: any) {
    const parsed = parseManifestPDF(data);

    const pdf = pdfMake.createPdf(parsed);

    pdf.download(data.id + '.pdf');
  }

  public async buildMoneyManifestPDF(data: any) {
    const parsed = parseMoneyManifestPDF(data);

    const pdf = pdfMake.createPdf(parsed);

    pdf.download(data.id + '.pdf');
  }
}
