import { Observable } from 'rxjs';
import { Product } from './product';
import { Entity } from 'aether-blaze';
import { Agency } from './agency';
import { Timestamp } from '@google-cloud/firestore';

export class InventoryRequest extends Entity {

    public product_path: string;
    public agency_path: string;
    public inventory_path: string;
    public stock: number;

    public pending: boolean;
    public status: string;
    public archived: boolean;

    public request_date: Timestamp;
    public response_date: Timestamp;
    public arrived_date: Timestamp;

    public deleted: boolean;

    public get product() {
        if (this.product_path) {
            return new Product(this.product_path);
        }
    }

    public set product(value: Product) {
        this.product_path = value.__path;
    }

    public get inventory() {
        if (this.inventory_path) {
            return new Product(this.inventory_path);
        }
    }

    public set inventory(value: Product) {
        this.inventory_path = value.__path;
    }

    public get agency() {
        if (this.agency_path) {
            return new Agency(this.agency_path);
        }
    }

    public set agency(value: Agency) {
        this.agency_path = value.__path;
    }

    /**
     * Description: Returns an inventory request as a promise.
     * @author Mauro Lanza
     * @returns {Promise<InventoryRequest>}
     */
    public __toPromise() {
        return this.__promise as Promise<InventoryRequest>;
    }

    /**
     * Description: Returns the requested inventory as an observable.
     * @author Mauro Lanza
     * @returns {Observable<InventoryRequest>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<InventoryRequest>);
    }

    /**
     * @constructor
     * @param {string} path Specifies the navigation path of an inventory request
     * @param {string} product_path Path of the product in the inventory request
     * @param {string} agency_path Path of the agency making the inventory request
     * @param {string} inventory_path Path of the inventory of the agency making the request
     * @param {boolean} pending Is/not the request pending
     * @param {string} status Status of the request
     * @param {boolean} archived Is/not the request archived
     * @param {number} stock Quantity of the product requested
     * @param {Timestamp} date Date of the request
     * @param {boolean} deleted Is/not the request deleted
     */
    constructor(path: string,
        product_path?: string,
        agency_path?: string,
        inventory_path?: string,
        pending?: boolean,
        status?: string,
        archived?: boolean,
        stock?: number,
        request_date?: Timestamp,
        response_date?: Timestamp,
        arrived_date?: Timestamp,
        deleted?: boolean
    ) {
        super(InventoryRequest, path);
        if (product_path) {
            this.product_path = product_path;
        }
        if (agency_path) {
            this.agency_path = agency_path;
        }
        if (inventory_path) {
            this.inventory_path = inventory_path;
        }
        if (pending) {
            this.pending = pending;
        }
        if (status) {
            this.status = status;
        }
        if (archived) {
            this.archived = archived;
        }
        if (stock) {
            this.stock = stock;
        }
        if (request_date) {
            this.request_date = request_date;
        }
        if (response_date) {
            this.response_date = response_date;
        }
        if (arrived_date) {
            this.arrived_date = arrived_date;
        }
        if (deleted) {
            this.deleted = deleted;
        }
    }
}
