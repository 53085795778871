import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';
import { Promotion } from 'src/app/models';
import { PromotionService } from 'src/app/services/promotion.service';


@Component({
  selector: 'app-promotions-list',
  templateUrl: './promotions-list.component.html',
  styleUrls: ['./promotions-list.component.scss']
})
export class PromotionsListComponent implements OnInit {

  filter = false;
  promotions: Observable<Collection<Promotion>>;

  /**
   * @constructor
   * @param {Router} router Navigation router
   * @param {PromotionService} promotionService Service for promotions management
   */
  constructor(
    private router: Router,
    private promotionService: PromotionService) { }

  ngOnInit() {
    this.getPromotions();
  }

  /**
   * Description: Gets the available promotions as an observable.
   * @author Mauro Lanza
   * @returns void
   */
  getPromotions(): void {
    this.promotions = this.promotionService.getAll().asObservable();
  }

  /**
   * Description: Alerts the user of a promotion deletion.
   * @author Mauro Lanza
   * @param {any} promotion Promotion to be deleted
   * @returns void
   */
  toggleDeleted(promotion: any): void {
    const check = confirm(`Are you sure you want to ${promotion.enabled ? 'delete' : 'restore'} "${promotion.name}" ?`);
    if (check) {
      promotion.enabled = !promotion.enabled;
      this.promotionService.update(promotion);
    }
  }

  /**
   * Description: Navigates to the selected promotion details view.
   * @author Mauro Lanza
   * @param id Id of the selected promotion
   * @returns void
   */
  viewDetails(id: string): void {
    this.router.navigate(['promotions/details', id]);
  }

}
