import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { User } from '../../models';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent implements OnInit {

  profile: User;

  /**
   * @constructor
   * @param {UserService} userService Service for users management
   */
  constructor(
    private userService: UserService) {
  }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      this.profile = p;
    });
  }

}
