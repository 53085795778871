import { Injectable } from '@angular/core';
import { Location } from '../models';
import { Collection } from 'aether-blaze';
// para mass create
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  private pathUrl = '/locations/';
  private pathStates = '/states/';
  private pathCities = '/cities/';

  /**
   * @constructor
   */
  constructor() { }

  /**
   * Description: Creates a new location in the location collection.
   * @author Mauro Lanza
   * @param {string} path
   * @param {string} name
   * @returns {Location} New location
   */
  public create(path: string, name: string): Location {
    const doc = firebase.firestore().collection(this.pathUrl).doc();
    const usr = new Location(doc.path, name);
    usr.__save(true);
    return usr;
  }

  /**
   * Description: Gets a location by its id.
   * @author Mauro Lanza
   * @param {string} id Id of the location requested
   * @param {boolean} raw
   * @returns {Location} Location requested
   */
  public get(id: string, raw?: boolean): Location {
    if (raw) {
      return new Location(id);
    }
    return new Location(this.pathUrl + id);
  }

  /**
   * Description: Gets the countries available.
   * @author Mauro Lanza
   * @param query
   * @returns {Collection<Location>} Countries available
   */
  public getCountries(query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Location> {
    // return new Collection<Location>(Location, this.pathUrl);
    if (query) {
      return new Collection<Location>(Location, this.pathUrl, query);
    } else {
      return new Collection<Location>(Location, this.pathUrl);
    }
  }

  /**
   * Description: Gets the states of the country received as param.
   * @author Mauro Lanza
   * @param {string} path Country
   * @returns {Collection<Location>} States
   */
  public getStates(path: string): Collection<Location> {
    if (path) {
      return new Collection<Location>(Location, path + this.pathStates);
    } else {
      return null;
    }
  }

  /**
   * Description: Gets the cities of the state received as param.
   * @author Mauro Lanza
   * @param {string} path State
   * @returns {Collection<Location>} Cities
   */
  public getCities(path: string): Collection<Location> {
    if (path) {
      return new Collection<Location>(Location, path + this.pathCities);
    } else {
      return null;
    }
  }

}
