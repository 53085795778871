import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/models';
import { ProductService } from '../../services/product.service';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';
import { StoreProductService } from 'src/app/services/store-product.service';
import { StoreProduct } from 'src/app/models/store-product';

@Component({
  selector: 'app-store-products',
  templateUrl: './store-products.component.html',
  styleUrls: ['./store-products.component.scss']
})
export class StoreProductsComponent implements OnInit {  // @Output() sender = new EventEmitter();

  filter = false;
  filterSKU = '';

  storeProducts: Observable<Collection<StoreProduct>>;

  /**
   * @constructor
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks
   * @param {StoreProductService} storeProductService Service for store products management
   */
  constructor(
    private router: Router,
    private storeProductService: StoreProductService) { }

  ngOnInit() {
    this.getProducts();
  }

  /**
   * Description: Gets all the store products.
   * @author Mauro Lanza
   * @returns void
   */
  getProducts(): void {
    this.storeProducts = this.storeProductService.getAll((ref) => {
      return ref.where('deleted', '==', false)
    }).asObservable();
    // console.log(this.storeProducts);
  }

  filterDeleted(){
    // console.log(this.filter);
    
    if(this.filter == false){
      this.storeProducts = this.storeProductService.getAll((ref) => {
        return ref.where('deleted', '==', true)
      }).asObservable();
    }else {
      this.storeProducts = this.storeProductService.getAll((ref) => {
        return ref.where('deleted', '==', false)
      }).asObservable();
    }
  }

  /**
   * Description: Asks for confirmation to delete a product. If the deletion is confirmed, the product
   * is marked as deleted and updates it in the database.
   * @author Mauro Lanza
   * @param {any} product Product to delete
   * @returns void
   */
  toggleDeleted(product: any): void {
    const check = confirm(`Are you sure you want to ${product.deleted ? 'restore' : 'delete'} "${product.name}" ?`);
    if (check) {
      product.deleted = !product.deleted;
      this.storeProductService.update(product);
    }
  }

  /**
   * Description: Navigates to the edition view of the selected product.
   * @author Mauro Lanza
   * @param {string} id Id of the selected product to edit
   * @returns void
   */
  editSelected(id: string): void {
    this.router.navigate(['store/products/edit', id]);
  }

  /**
   * Description: Navigates to the details view of the selected product.
   * @author Mauro Lanza
   * @param {string} id 
   * @returns void
   */
  viewDetails(id: string): void {
    this.router.navigate(['store/products/details', id]);
  }

}