import { Category } from '../models/category';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  public category: Category;
  public pathUrl = 'categories/';

  /**
   * @constructor
   */
  constructor(
  ) { }

  /**
   * Description: Adds a new category to the collection.
   * @author Maximiliano Casale
   * @param {any} data New category's data
   * @returns {Promise<Category>} Returns the added category
   */
  public create(data: any): Promise<Category> {
    return firebase.firestore().collection(this.pathUrl).add(data).then(res => {
      return this.get(res.id).__toPromise();
    });
  }

  /**
   * Description: Gets a requested category by its id.
   * @author Maximiliano Casale
   * @param {string} id Id of the requested category
   * @returns {Category} Category requested
   */
  public get(id: string): Category {
    return new Category(this.pathUrl + id);
  }

  /**
   * Description: Gets all the available categories.
   * @author Maximiliano Casale
   * @param query
   * @returns {Collection<Category>} Categories collection
   */
  public getAll(query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Category> {
    if (query) {
      return new Collection<Category>(Category, this.pathUrl, query);
    } else {
      return new Collection<Category>(Category, this.pathUrl);
    }
  }

  /**
   * Description: Updates a category with the data received as param.
   * @author Maximiliano Casale
   * @param {Category} data Data to update
   * @returns {Promise<void>} Update confirmation
   */
  public update(data: Category): Promise<void> {
    return data.__save(true);
  }

  /**
   * Description: Marks as deleted the category received as param.
   * @author Maximiliano Casale
   * @param {Category} data Category to mark as deleted
   * @returns {Promise<void>} Confirmation of the operation
   */
  public delete(data: Category): Promise<void> {
    data.deleted = true;
    return data.__save();
  }

}
