import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';

export class Location extends Entity {

    public name: string;

    /**
     * Description: Returns the location as a promise.
     * @author
     * @returns {Promise<Location>}
     */
    public __toPromise() {
        return this.__promise as Promise<Location>;
    }

    /**
     * Description: Returns the locations as an observable.
     * @author
     * @returns {Observable<Location>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<Location>);
    }

    /**
     * @constructor
     * @param {string} path Location's path
     * @param {string} name Location's name
     */
    constructor(path: string,
        name?: string,
    ) {
        super(Location, path);
        if (name) {
            this.name = name;
        }
    }
}
