import { Observable } from 'rxjs';
import { Agency } from './agency';
import { Entity } from 'aether-blaze';
import { Timestamp } from '@google-cloud/firestore';

export class Bill extends Entity {

    // public products: Array<{ inventory_path: string, quantity: number }>;
    public products: Array<any>;
    public agency_path: string;
    public paymentMethod: string;
    public total: number;
    public date: Timestamp;

    /**
     * Description: Returns the bill as a promise.
     * @author Mauro Lanza
     * @returns {Promise<Bill>}
     */
    public __toPromise() {
        return this.__promise as Promise<Bill>;
    }

    /**
     * Description: Returns the bill as an observable.
     * @author Mauro Lanza
     * @returns {Observable<Bill>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<Bill>);
    }

    public get agency() {
        if (this.agency_path) {
            return new Agency(this.agency_path);
        }
    }

    public set agency(value: Agency) {
        this.agency_path = value.__path;
    }

    /**
     * @constructor
     * @param {string} path Specifies the navigation path of a bill
     * @param {any[]} products Products added to the bill
     * @param {string} agency_path Agency to which the bill corresponds
     */
    constructor(path: string,
        products?: any[],
        agency_path?: string,
        paymentMethod?: string,
        total?: number,
        date?: Timestamp,
    ) {
        super(Bill, path);
        if (products) {
            this.products = products;
        }
        if (agency_path) {
            this.agency_path = agency_path;
        }
        if (paymentMethod) {
            this.paymentMethod = paymentMethod;
        }
        if (total) {
            this.total = total;
        }
        if (date) {
            this.date = date;
        }
    }
}
