import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';


@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {

  public productDetails: FormGroup;

  product = {
    id: 'afgtrgbtrb',
    name: 'Cajas',
    description: 'cajas de carton',
    image: 'src',
    available: true,
    stock: 100,
    stock_warning: [0, 200, 100],
    price: 10,
    sku: 'CAJA-C',
    category: 'some'
  };

  upload = false;

  /**
   * @constructor
   * @param {FormBuilder} fb Formbuilder for the product details form 
   * @param {Location} location Current navigation location
   */
  constructor(
    private fb: FormBuilder,
    private location: Location) { }

  ngOnInit() {
    this.createProductForm();
  }

  /**
   * Description: Creates a form group for the details of the product to edit.
   * @author Mauro Lanza
   * @returns void
   */
  createProductForm() {
    this.productDetails = this.fb.group({
      sendName: [this.product.name, [Validators.required, Validators.pattern('[a-zA-Z]*')]],
      sendCategory: [this.product.category, [Validators.required]],
      sendPrice: [this.product.price, [Validators.required, Validators.pattern('[0-9]*')]],
      sendAvailable: [this.product.available, [Validators.required]],
      sendDescription: [this.product.description, [Validators.required]],
      sendImage: [this.product.image, [Validators.required]],
      sendStock: [this.product.stock, [Validators.required, Validators.pattern('[0-9]*')]],
      sendStockWarning: [this.product.stock_warning, []],
    });
  }

  /**
   * Description: Method called when the "change image" botton is clicked.
   * @author Mauro Lanza
   * @returns void
   */
  toggleUpload(): void {
    this.upload = !this.upload;
  }

  /**
   * Description: Navigates back to the previous navigation view.
   * @author Mauro Lanza
   * @returns void
   */
  save(): void {
    // product service edit
    this.location.back();
  }

  /**
   * Description: Resets the product details form group and navigates back to the previous navigation view.
   * @author Mauro Lanza
   * @returns void 
   */
  cancel(): void {
    this.productDetails.reset();
    this.location.back();
  }

}
