import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyNumber]'
})
export class OnlyNumbersDirective {

  regexStr = '[0-9.]';

  /**
   * @constructor
   * @param {ElementRef} el  
   */
  constructor(private el: ElementRef) { }

  @Input() OnlyNumber: boolean;

  /**
   * Description: Executes a TypeScript when a user is pressing a key.
   * @author Jorge Del Castillo
   * @param event KeyboardEvent
   * @returns void
   */
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    if (this.OnlyNumber) {
      // 8 delete 27 escape 46 delete 13 enter 9 tab
      if ([8, 9, 27, 13, 46].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      const regEx = new RegExp(this.regexStr);
      if (regEx.test(e.key)) {
        return;
      } else {
        e.preventDefault();
      }
    }
  }

}
