import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InventoryService } from '../../../services/inventory.service';
import { Inventory, User } from 'src/app/models';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-inventory-details',
  templateUrl: './inventory-details.component.html',
  styleUrls: ['./inventory-details.component.scss']
})
export class InventoryDetailsComponent implements OnInit {

  public profile: User;
  public warningForm: FormGroup;
  public inventory: Inventory;
  isLoading = false;

  /**
   * @constructor
   * @param {FormBuilder} fb Creates an inventory form
   * @param {Location} location Current navigation location
   * @param {ActivatedRoute} route Current navigation route
   * @param {InventoryService} inventoryService Service for the inventories management
   * @param {AlertService} alertService Service for alerts management
   * @param {UserService} userService Service for users management
   */
  constructor(
    private fb: FormBuilder,
    private location: Location,
    private route: ActivatedRoute,
    private inventoryService: InventoryService,
    private alertService: AlertService,
    public userService: UserService) {}

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      this.profile = p;
    });
    this.getProduct();
  }

  /**
   * Description: Creates form for edition of warning values.
   * @author Mauro Lanza
   * @param {Inventory} data
   * @returns void
   */
  createWarningForm(data: Inventory): void {
    this.warningForm = this.fb.group({
      warning_low: [data.warning_low, [Validators.required, Validators.pattern('^[0-9]*$')]],
      warning_hi: [data.warning_hi, [Validators.required, Validators.pattern('^[0-9]*$')]]
    });
  }

  /**
   * Description: Gets an inventory by its id.
   * @author Mauro Lanza
   * @returns void
   */
  getProduct() {
    this.inventoryService
      .get(this.route.snapshot.params['id'])
      .__asObservable()
      ._subscribe(p => {
        this.inventory = p;
        this.createWarningForm(p);
      });
  }

    /**
   * Description: Updates the inventory with the values in the warning form. If it is updated
   * successfully, shows a success alert. If there is an error, handles it and alerts the user.
   * @author Mauro Lanza
   * @returns void
   */
  save() {
    try {
      this.isLoading = true;

      const data = this.warningForm.value;
      this.inventory.warning_hi = parseInt(data.warning_hi, 10);
      this.inventory.warning_low = parseInt(data.warning_low, 10);

      this.inventoryService.update(this.inventory).then(() => {
        this.alertService.showAlert('Inventory updated successfully', 'alert-success');
        this.isLoading = false;
      });

    } catch (error) {
      console.error(error);
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
    this.isLoading = false;
  }

  /**
   * Description: Navigates to the previous navigation location.
   * @author Mauro Lanza
   * @returns void
   */
  goBack(): void {
    this.warningForm.reset();
    this.location.back();
  }
}
