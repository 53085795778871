import { Component, OnInit } from '@angular/core';
import { ShippingService } from 'src/app/services/shipping.service';

@Component({
  selector: 'app-shipment-money-list',
  templateUrl: './shipment-money-list.component.html',
  styleUrls: ['./shipment-money-list.component.scss']
})
export class ShipmentMoneyListComponent implements OnInit {

  public shipments;

  /**
   * @constructor
   * @param {ShippingService} shippingService Service for shipments management 
   */
  constructor(private shippingService: ShippingService) { }

  ngOnInit() {
    this.shipments = this.shippingService.getAll((ref) => { return ref.where('deleted', '==', false).where('type', '==', 'money') }).asObservable()
  }

}
