import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, PatternValidator, NgForm, FormControl } from '@angular/forms';
import { PersonService } from '../../services/person.service';
import { LocationsService } from '../../services/locations.service';
import { Collection } from 'aether-blaze';
import { Observable, Subject } from 'rxjs';
import { Person, Location } from '../../models';
import { typeAhead } from 'src/app/utils/type-ahead';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss']
})
export class RecipientsComponent implements OnInit {

  @Output() recipient = new EventEmitter();
  @Output() recipientRef = new EventEmitter();
  @Input() agencyPath = [];

  public recipientInput$ = new Subject<string>();

  public recipientForm: FormGroup;
  public frqRecipientData: {
    doc: Person,
    ref: firebase.firestore.DocumentReference
  }[] = [];
  public frqRecipient = new FormControl();

  public frqRecipientsLoading: boolean;
  public frqRecipients$: Observable<{ doc: Person; ref: firebase.firestore.DocumentReference; }[]>;

  /**
   * @constructor
   * @param {FormBuilder} fb Form builder for the recipients data form
   * @param {PersonService} personService Service for persons management
   * @param {LocationsService} locationsService Service for locations management
   */
  constructor(
    private fb: FormBuilder,
    private personService: PersonService,
    private locationsService: LocationsService
  ) { }

  ngOnInit() {

    this.createRecipientForm();
    this.selectedFrqRecipientValueChanges();

    this.frqRecipients$ = typeAhead(
      this.recipientInput$.asObservable().pipe(
        map(text => {
          if (text) {
            return text.toLowerCase();
          } else {
            return text;
          }
        })
      ),
      (text) => {
        return this.personService.getFrqPersonData((ref) => {
          return ref
            .where('agency_path', '==', this.agencyPath)
            .where('recipient', '==', true)
            .where('search_label', '>=', text)
            .where('search_label', '<=', text + '\uf8ff')
            .limit(100);
        });
      },
      (isLoading) => {
        this.frqRecipientsLoading = isLoading;
      }
    );
  }

  /**
   * Description: Creates the form for the recipient's data.
   * @author Sarkis
   * @returns void
   */
  createRecipientForm() {
    this.recipientForm = this.fb.group({
      receivName: ['', [Validators.required, Validators.pattern('[a-zA-ZñÑ\\s]*')]],
      receivLastName: ['', [Validators.required, Validators.pattern('[a-zA-ZñÑ\\s]*')]],
      receivEmail: ['', [Validators.email]],
      receivPhone1: ['', [Validators.required, Validators.minLength(6), Validators.pattern('[0-9]*')]],
      receivPhone2: ['', [Validators.required, Validators.minLength(6), Validators.pattern('[0-9]*')]],
      receivPhone3: ['', [Validators.minLength(6), Validators.pattern('[0-9]*')]]
    });
    this.formControlValueChanges();
  }

  /**
   * Description: Controls the recipient's data if any of the values changes.
   * @author Sarkis
   * @returns void
   */
  formControlValueChanges() {
    this.recipientForm.valueChanges.subscribe(
      (selectedValue) => {
        // console.log(selectedValue);
        this.outRecipient();
      }
    );
    // this.recipientForm.controls['receivCountry'].valueChanges.subscribe(
    //   (selectedValue) => {
    //     this.country = selectedValue;
    //     this.getStates();
    //   }
    // );
    // this.recipientForm.controls['receivState'].valueChanges.subscribe(
    //   (selectedValue) => {
    //     this.state = selectedValue;
    //     this.getCities();
    //   }
    // )
  }

  /**
   * Description: Calls this.frequentRecipientChange() method everytime the selected frequent recipient
   * changes.
   * @author Sarkis
   * @returns void
   */
  selectedFrqRecipientValueChanges() {
    this.frqRecipient.valueChanges.subscribe(
      (selectedValue) => {
        this.frequentRecipientChange(selectedValue);
      }
    );
  }

  /**
   * Description: Changes the current recipient's data form values with the values of the new selected
   * frequent recipient received as param.
   * @author Sarkis
   * @param {any} selectedValue New selected value of a frequent recipient
   * @returns void
   */
  frequentRecipientChange(selectedValue) {

    if (selectedValue && selectedValue.doc) {
      this.recipientForm.setValue({
        receivName: selectedValue.doc.name,
        receivLastName: selectedValue.doc.lastname,
        receivEmail: selectedValue.doc.email || '',
        receivPhone1: selectedValue.doc.phone[0],
        receivPhone2: selectedValue.doc.phone[1],
        receivPhone3: selectedValue.doc.phone.length > 2 ? selectedValue.doc.phone[2] : ''
      });
      this.recipientRef.emit(selectedValue);
      this.outRecipient();
    } else {
      this.recipientForm.reset();
      this.outRecipient();
      this.recipientRef.emit(selectedValue);
    }
  }

  // getCountries(): void {
  //   this.locationsService.getCountries().asObservable().subscribe((p) => {
  //     this.countries = p
  //   } )
  // }

  // getStates() {
  //   this.cities = undefined;
  //   this.city = '';
  //   this.state = '';
  //   this.states = this.country ? this.locationsService.getStates(this.country).asObservable() : null;
  // }

  // getCities() {
  //   this.city = '';
  //   this.cities =  this.state ? this.locationsService.getCities(this.state).asObservable() : null;
  // this.cities.subscribe((c) => {
  //   if (c.iterable.length === 0) {
  //     this.cities = this.states;
  //     this.senderForm.controls['sendCity'].setValue(this.state);
  //   }
  // })
  // }

  /**
   * Description: Emits the recipient's form.
   * @author Sarkis
   * @returns void
   */
  outRecipient() {
    this.recipient.emit(this.recipientForm);
  }

}
