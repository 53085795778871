import { Component, OnInit, Input, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Locker } from 'src/app/models/locker';
import { ClrDatagridFilterInterface } from '@clr/angular';

@Component({
  selector: 'app-complete-filter',
  templateUrl: './complete-filter.component.html',
  styleUrls: ['./complete-filter.component.scss']
})
export class CompleteFilterComponent implements ClrDatagridFilterInterface<Locker>, OnChanges {

  changes: EventEmitter<any> = new EventEmitter<any>(false);

  // boolean for filtering
  @Input() checked: boolean;

  /**
   * Description: Checks if a list item is checked or not.
   * @author ???
   * @returns {any[]}
   */
  public isChecked(): any[] {
    if (this.checked !== undefined) {
      return [this.checked];
    } else {
      return [true];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checked) {
      this.changes.emit(true);
    }
  }


  /**
   * Description: Checks if a list item is active and not checked.
   * @author ???
   * @returns {boolean} true if it is active and false if it is not
   */
  isActive(): boolean {
    return !this.checked;
  }

  /**
   * Description: Returns true/false if the locker received as parameter is or not complete.
   * @author ???
   * @param {Locker} locker An user locker
   * @returns {boolean}
   */
  accepts(locker: Locker) {
    return locker.complete === false;
  }

}
