import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
import { DocumentReference } from 'firebase/firestore';
import { Agency } from './agency';
import { iPerson } from './person';
import { Location } from './location';

export interface iMoney {
    id: string;
    agency_path: string;
    recipient: iPerson;
    sender: iPerson;
    address: {
        address: string;
        city: string;
        country: string;
        state: string;
        city_name: string;
        country_name: string;
        state_name: string;
        zipcode: string;
        availability: string;
        businessHours: string;
    };
    shippingDate: string;
    shippingType: string;
    createdAt: any;
    manifest: string;
    deleted: boolean;
    number: number;
    year: string;
    amount: number;
}

export class Money extends Entity implements iMoney {
    public id: string;
    public agency_path: string;
    public recipient: iPerson;
    public sender: iPerson;
    public address: {
        address: string;
        city: string;
        country: string;
        state: string;
        city_name: string;
        country_name: string;
        state_name: string;
        zipcode: string;
        availability: string;
        businessHours: string;
    };
    public shippingDate: string;
    public shippingType: string;
    public createdAt: any;
    public manifest: string;
    public deleted: boolean;
    public number: number;
    public year: string;
    public amount: number;

    /**
     * Description: Returns the money order as a promise.
     * @author Sarkis
     * @returns {Promise<Money>}
     */
    public __toPromise() {
        return this.__promise as Promise<Money>;
    }

    /**
     * Description: Returns the money order as an observable.
     * @author Sarkis
     * @returns {Observable<Money>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<Money>);
    }

    public get agency(): Agency {
        return new Agency(this.agency_path);
    }

    public get country() {
        if (this.address) {
            return new Location(this.address.country);
        }
    }

    public set country(value: any) {
        this.address = value.__path;
    }

    public get state() {
        if (this.address) {
            return new Location(this.address.state);
        }
    }

    public set state(value: any) {
        this.address.state = value.__path;
    }

    public get city() {
        if (this.address) {
            return new Location(this.address.city);
        }
    }

    public set city(value: any) {
        this.address.city = value.__path;
    }

    /**
     * @constructor
     * @param {string} path Path of the money order
     * @param {string} id Id of the money order
     * @param {string} agency_path Agency to which the money order corresponds
     * @param {iPerson} recipient Data of the person that receives the money
     * @param {iPerson} sender Data of the person that sends the money
     * @param address Address info of the recipient
     * @param {string} shippingDate
     * @param {string} shippingType Money
     * @param {any} createdAt Date
     * @param {string} manifest Manifest to which the order corresponds
     * @param {boolean} deleted Is it or not marked as deleted
     * @param {number} number
     * @param {string} year
     * @param {number} amount
     */
    constructor(path: string,
        id?: string,
        agency_path?: string,
        recipient?: iPerson,
        sender?: iPerson,
        address?: {
            address: string;
            city: string;
            country: string;
            state: string;
            city_name: string;
            country_name: string;
            state_name: string;
            zipcode: string;
            availability: string;
            businessHours: string;
        },
        shippingDate?: string,
        shippingType?: string,
        createdAt?: any,
        manifest?: string,
        deleted?: boolean,
        number?: number,
        year?: string,
        amount?: number
    ) {
        super(Money, path);
        if (id) {
            this.id = id;
        }
        if (agency_path) {
            this.agency_path = agency_path;
        }
        if (recipient) {
            this.recipient = recipient;
        }
        if (sender) {
            this.sender = sender;
        }
        if (address) {
            this.address = address;
        }
        if (shippingDate) {
            this.shippingDate = shippingDate;
        }
        if (shippingType) {
            this.shippingType = shippingType;
        }
        // if (packing) {
        //     this.packing = packing;
        // }
        // if (promotion) {
        //     this.promotion = promotion;
        // }
        if (createdAt) {
            this.createdAt = createdAt;
        }
        if (manifest) {
            this.manifest = manifest;
        }
        if (deleted) {
            this.deleted = deleted;
        }
        // if (boxes) {
        //     this.boxes = boxes;
        // }
        if (number) {
            this.number = number;
        }
        if (year) {
            this.year = year;
        }
        // if (shippingCost) {
        //     this.shippingCost = this.shippingCost;
        // }
        // if (insurance) {
        //     this.insurance = this.insurance;
        // }
        // if (countryTax) {
        //     this.countryTax = this.countryTax;
        // }
        // if (packingService) {
        //     this.packingService = this.packingService;
        // }
        if (amount) {
            this.amount = this.amount;
        }
    }
}
