import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
import { Timestamp } from '@google-cloud/firestore';

export class iProduct {
    sku?: string;
    name?: string;
    description?: string;
    image?: string;
    price?: number;
    deleted?: boolean;
    timestamp?: string;
    tax?: number;
    agencies?: string[];
}

export class Product extends Entity implements iProduct {

    public sku?: string;
    public name?: string;
    public description?: string;
    public image?: string;
    public price?: number;
    public deleted?: boolean;
    public timestamp?: string;
    public tax?: number;
    public agencies?: string[];


    /**
     * Description: Returns the product as a promise.
     * @author Mauro Lanza
     * @returns {Promise<Product>}
     */
    public __toPromise() {
        return this.__promise as Promise<Product>;
    }

    /**
     * Description: Returns the product as an observable.
     * @author Mauro Lanza
     * @returns {Observable<Product>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<Product>);
    }

    /**
     * @constructor
     * @param {string} path
     * @param {string} sku
     * @param {string} name
     * @param {string} description
     * @param {string} image Referential image
     * @param {number} price
     * @param {boolean} deleted
     * @param {string} timestamp
     * @param {number} tax
     */
    constructor(path: string,
        sku?: string,
        name?: string,
        description?: string,
        image?: string,
        price?: number,
        deleted?: boolean,
        timestamp?: string,
        tax?: number,
        agencies?: string[],

    ) {
        super(Product, path);
        if (sku) {
            this.sku = sku;
        }
        if (name) {
            this.name = name;
        }
        if (description) {
            this.description = description;
        }
        if (image) {
            this.image = image;
        }
        if (price) {
            this.price = price;
        }
        if (deleted) {
            this.deleted = deleted;
        }
        if (timestamp) {
            this.timestamp = timestamp;
        }
        if (tax) {
            this.tax = tax;
        }
        if (agencies) {
            this.agencies = agencies;
        }
    }
}
