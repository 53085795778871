import { Injectable } from '@angular/core';

import { Agency } from '../models';
import { Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  private pathUrl = '/agencies/';
  public agency: Agency;

  /**
   * @constructor
   * @param {AngularFirestore} afs Document-oriented database
   */
  constructor(
    private afs: AngularFirestore
  ) { }

  /**
   * Description: Looks for an specific agency by its id.
   * @author Sarkis
   * @param {string} id Requested agency's id
   * @returns {Agency} Agency requested 
   */
  public get(id: string): Agency {
    return new Agency(this.pathUrl + id);
  }

  /**
   * Description: Gets all the agencies.
   * @author Sarkis
   * @param query 
   * @returns {Collection<Agency>} 
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Agency> {
    if (query) {
      return new Collection<Agency>(Agency, this.pathUrl, query);
    } else {
      return new Collection<Agency>(Agency, this.pathUrl);
    }
  }

  /**
   * Description: Adds a new agency to the Agency collection.
   * @author Sarkis
   * @param {any} agency New agency
   * @returns {Promise<Agency>}  
   */
  public create(agency): Promise<Agency> {
    return new Promise<Agency>((resolve) => {
      // const doc = firebase.firestore().collection(this.pathUrl).doc();
      firebase.firestore().collection(this.pathUrl).add(agency).then((docRef) => {
        this.get(docRef.id).__toPromise().then((p) => {
          resolve(p);
        });
      });
    });
  }

  /**
   * Description: Updates an agency's data in the database.
   * @author Sarkis
   * @param {Agency} data Agency's data
   * @returns {Promise<void>} To know if it was properly updated
   */
  public update(data: Agency): Promise<void> {
    return new Promise<void>((resolve) => {
      data.__save(true).then(() => resolve());
    });
  }

  /**
   * Description: Gets a specific image from the images collection.
   * @author Jorge Del Castillo
   * @param {any} id Id of the requested image
   * @returns {Observable<any>} Gets the requested image as an observable because the image can change 
   */
  getBase64Img(id): Observable<any> {
    return this.afs.doc('/images/' + id).valueChanges();
  }

  // public delete(id: string): void {
  //   let agency = new Agency( this.pathUrl + id );
  //   agency.deleted = true;
  //   this.update(agency);
  // }
}
