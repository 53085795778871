import { Injectable } from '@angular/core';

import { Person, Agency } from '../models';
import { Entity, Collection } from 'aether-blaze';
import * as firebase from 'firebase';

import { Subject, Observable, combineLatest } from 'rxjs';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { AXInput } from 'aether-xlsx';
import { iPerson } from '../models/person';
import { XlsxService } from './xlsx.service';

@Injectable({
  providedIn: 'root'
})
export class PersonService {


  private pathUrl = '/persons/';
  public person: Person;

  /**
   * @constructor
   * @param {AngularFirestore} afs Document-oriented database
   */
  constructor(private afs: AngularFirestore,
    private xlsx: XlsxService) { }

  /**
   * Description: Gets a person's data by its id.
   * @author Sarkis Bazdikian
   * @param {string} id
   * @returns {Person}
   */
  public get(id: string): Person {
    return new Person(this.pathUrl + id);
  }

  /**
   * Description: Gets all the persons data.
   * @author Sarkis Bazdikian
   * @param query
   * @returns {Collection<Person>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query
  ): Collection<Person> {
    if (query) {
      return new Collection<Person>(Person, this.pathUrl, query);
    } else {
      return new Collection<Person>(Person, this.pathUrl);
    }
  }

  /**
   * Description: Gets a frequent user's data.
   * @author Sarkis Bazdikian
   * @param query
   * @returns {Observable<{doc: Person; ref: firebase.firestore.CollectionReference;}[]>} Person's document and doc's reference
   */
  public getFrqPersonData(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query
  ): Observable<{ doc: Person, ref: firebase.firestore.DocumentReference }[]> {
    return this.afs.collection(this.pathUrl, query).get().map((snapshot) => {
      return snapshot.docs.map((d) => ({
        doc: d.data() as Person,
        ref: d.ref
      }));
    });
  }

  /**
   * Description: Gets only those persons that are not recipients. If they are not recipients, they are senders.
   * @author Sarkis Bazdikian
   * @returns {Collection<Person>}
   */
  public getSenders() {
    return this.getAll((ref) => ref.where('recipient', '==', false));
    // return this.getAll();
  }

  /**
   * Description: Gets only those persons that are recipients. In order to do that, checks the person's atribute
   * "recipient" which is a boolean that is true only if the person is a recipient.
   * @author Sarkis Bazdikian
   * @returns {Collection<Person>}
   */
  public getRecipients() {
    return this.getAll((ref) => ref.where('recipient', '==', true));
  }

  /**
   * Description: Adds a new person as sender to the person's collection.
   * @author Sarkis Bazdikian
   * @param {any} data Data of the new sender
   * @returns {Promise<Person>}
   */
  public createSender(data: any): Promise<Person> {
    data.recipient = false;
    return new Promise<Person>((resolve) => {
      firebase.firestore().collection(this.pathUrl)
        .add(data).then((docRef) => { this.get(docRef.id).__toPromise().then((p) => { resolve(p); }); });
    });
  }

  /**
   * Description: Adds a new person as recipient to the person's collection.
   * @author Sarkis Bazdikian
   * @param {any} data Data of the new recipient
   * @returns {Promise<Person>}
   */
  public createRecipient(data: any): Promise<Person> {
    data.recipient = true;
    return new Promise<Person>((resolve) => {
      firebase.firestore().collection(this.pathUrl)
        .add(data).then((docRef) => { this.get(docRef.id).__toPromise().then((p) => { resolve(p); }); });
    });
  }

  /**
   * Description: Adds a new address to the array of addresses of the person received as param.
   * @author Sarkis Bazdikian
   * @param {Person} ref
   * @param {any} address
   * @returns void
   */
  public createAddress(ref: Person, address: any): void {
    if (ref.addresses && ref.recipient) {
      ref.addresses.push(address);
      ref.__save(true);
    } else if (!ref.addresses) {
      ref.addresses = [];
      ref.addresses.push(address);
      ref.__save(true);
    }

    // if (ref.addresses && ref.recipient) {
    //   ref.addresses.push(address);
    //   // const obj = {
    //   //   name: ref.name,
    //   //   lastName: ref.lastname,
    //   //   address: ref.addresses,
    //   //   as: ref.agency_path,
    //   //   as: ref.email,
    //   //   as: ref.
    //   // }
    //   this.afs.doc(ref.__path).update(ref)
    //   // ref.__save(true);
    // } else if (!ref.addresses) {
    //   ref.addresses = [];
    //   ref.addresses.push(address);
    //   this.afs.doc(ref.__path).update(ref)
    //   // ref.__save(true);
    // }
  }

  /**
   * Description: Adds a new address to the array of addresses of the person received as param.
   * @author Sarkis Bazdikian
   * @param ref Person
   * @param {any} address New address
   * @returns void
   */
  public createNewAddress(ref: { doc: Person; ref: firebase.firestore.DocumentReference; }, address: any): void {
    if (ref.doc.addresses && ref.doc.recipient) {
      ref.doc.addresses.push(address);
      this.afs.doc(ref.ref.path).update(ref.doc);
      // ref.__save(true);
    } else if (!ref.doc.addresses) {
      ref.doc.addresses = [];
      ref.doc.addresses.push(address);
      this.afs.doc(ref.ref.path).update(ref.doc);
      // ref.__save(true);
    }
  }

  /**
   * Description: Updates sender's data.
   * @author Sarkis Bazdikian
   * @param ref
   * @param {any} data
   * @returns void
   */
  public updateSender(ref: { doc: Person; ref: firebase.firestore.DocumentReference; }, data: any): void {
    ref.doc.name = data.sendName;
    ref.doc.lastname = data.sendLastName;
    ref.doc.email = data.sendEmail;
    ref.doc.phone[0] = data.sendPhone1;
    ref.doc.phone[1] = data.sendPhone2;
    ref.doc.phone[2] = data.sendPhone3;
    ref.doc.addresses[0].address = data.sendAddress;
    ref.doc.addresses[0].city = data.sendCity;
    ref.doc.addresses[0].country = data.sendCountry;
    ref.doc.addresses[0].state = data.sendState;
    ref.doc.addresses[0].zipcode = data.sendZipCode;
    ref.doc.label = data.label;
    ref.doc.search_label = data.search_label;
    this.afs.doc(ref.ref.path).update(ref.doc);
    // ref.__save(true);
  }

  /**
   * Description: Updates recipient's data.
   * @author Sarkis Bazdikian
   * @param ref Recipient's document and its reference
   * @param {any} data Reciient's dataa modified
   * @returns void
   */
  public updateRecipient(ref: { doc: Person; ref: firebase.firestore.DocumentReference; }, data: any): void {
    ref.doc.name = data.receivName;
    ref.doc.lastname = data.receivLastName;
    ref.doc.email = data.receivEmail;
    ref.doc.phone[0] = data.receivPhone1;
    ref.doc.phone[1] = data.receivPhone2;
    ref.doc.phone[2] = data.receivPhone3;
    ref.doc.label = data.label;
    ref.doc.search_label = data.search_label;
    this.afs.doc(ref.ref.path).update(ref.doc);

    // ref.__save(true);
  }

  /**
   * Description: Updates one of the addresses associated to the person's document received as param. In order
   * to know which one, uses the index received as param. The index matches the position in the person's
   * addresses array.
   * @author Sarkis Bazdikian
   * @param ref
   * @param {any} data Modified address
   * @param {number} index Position in the person's addresses array of the modified address
   * @returns void
   */
  public updateAddress(ref: { doc: Person; ref: firebase.firestore.DocumentReference; }, data: any, index: number): void {
    ref.doc.addresses[index] = data;
    this.afs.doc(ref.ref.path).update(ref.doc);
    // ref.__save(true);
  }

  /**
   * Description: Deletes the person received as param.
   * @author Sarkis Bazdikian
   * @param {Person} data
   * @returns void
   */
  public delete(data: Person): void {
    data.__delete();
  }

  /**
   * Description: Exports all the data of the persons of the agency of the current signed in user to an excel
   * document.
   * @author Sarkis Bazdikian
   * @param {string} agency_path Name of the agency of the current signed in user
   * @returns void
   */
  public exportAll(filter: string, agency_path?: string): Promise<void> {

    let query: Query = this.afs.firestore.collection(this.pathUrl);
    const agencies: Query = this.afs.firestore.collection('/agencies/');

    if (agency_path) {
      query = query.where('agency_path', '==', agency_path);
    }
    if (filter === 'All') {
      // query = query.where('agency_path', '==', agency_path);
    } else if (filter === 'Senders') {
      query = query.where('recipient', '==', false);
    } else if (filter === 'Receivers') {
      query = query.where('recipient', '==', true);
    }

    return Promise.all([
      query.get(),
      agencies.get()
    ]).then(values => {
      const snap = values[0];
      const aSnap = values[1];
      const persons = snap.docs.map(d => d.data() as iPerson);

      const addresses = persons.map(p => p.addresses).reduce((a, c) => a.concat(c), []);
      // "DISTINCT countries"
      const country_paths = addresses.reduce((a, c) => {
        if (c && !a.find(country => country === c.country)) {
          a.push(c.country);
        }
        return a;
      }, []);
      // "DISTINCT states"
      const state_paths = addresses.reduce((a, c) => {
        if (c && !a.find(state => state === c.state)) {
          a.push(c.state);
        }
        return a;
      }, []);
      // "DISTINCT cities"
      const city_paths = addresses.reduce((a, c) => {
        if (c && !a.find(city => city === c.city)) {
          a.push(c.city);
        }
        return a;
      }, []);

      const countriesQs = country_paths.map((p) => p ? this.afs.firestore.doc(p).get() : null);
      const statesQs = state_paths.map((p) => p ? this.afs.firestore.doc(p).get() : null);
      const citiesQs = city_paths.map((p) => p ? this.afs.firestore.doc(p).get() : null);

      return Promise.all([
        Promise.all(countriesQs),
        Promise.all(statesQs),
        Promise.all(citiesQs),
      ]).then((results) => {
        const countries = results[0];
        const states = results[1];
        const cities = results[2];

        const input: AXInput = {
          pages: {
            'Clientes': {
              headers: ['Email', 'Nombre', 'Apellido', 'Sender/Recipient', 'Telefono', 'Agencia', 'Direccion'],
              rows: snap.docs.map(d => d.data() as iPerson)
                .sort((a, b) => ('' + a.lastname).localeCompare('' + b.lastname))
                .map(u => {
                  const agencyDoc = aSnap.docs.find((a) => a.ref.path === u.agency_path);
                  const agency = agencyDoc ? agencyDoc.data() : null;
                  const agency_name = agency ? agency.name : '';
                  return [
                    u.email,
                    u.name,
                    u.lastname,
                    u.recipient ? 'Recipient' : 'Sender',
                    u.phone.join(' '),
                    agency_name,
                  ].concat(
                    u.addresses ? u.addresses.map((a) => {
                      const city = cities.find((c) => c ? c.ref.path === a.city : '' === a.city);
                      const state = states.find((s) => s ? s.ref.path === a.state : '' === a.state);
                      const country = countries.find((c) => c ? c.ref.path === a.country : '' === a.country);
                      const addr = [a.address];
                      if (city && city.data() && city.data().name && city.data().name.length > 0) {
                        addr.push(city.data().name);
                      }
                      if (state && state.data() && state.data().name && state.data().name.length > 0) {
                        addr.push(state.data().name);
                      }
                      if (country && country.data() && country.data().name && country.data().name.length > 0) {
                        addr.push(country.data().name);
                      }
                      return addr.join(', ');
                    }) : null
                  );
                })
            }
          }
        };
        const date = new Date();
        this.xlsx.export(input, 'CCarga-Clients-' + date.toLocaleDateString());
      });
    });
  }

}
