import { ProductService } from './../../../services/product.service';
import { StoreOrderService } from 'src/app/services/store-order.service';
import { Component, OnInit } from '@angular/core';
import { StoreOrder } from 'src/app/models';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location as navLocation } from '@angular/common';

@Component({
  selector: 'app-store-order-details',
  templateUrl: './store-order-details.component.html',
  styleUrls: ['./store-order-details.component.scss']
})
export class StoreOrderDetailsComponent implements OnInit {


  /* public storeOrder = [
    {
      _id: 1,
      date: '12/11/2017',
      status: 'Delivered',
      subtotal: 558.46,
      shipping: 10,
      total: 568.46,
      quantity: 2,
      userEmail: 'andreascardino@hotmail.com',
      shippingAddress: [
        {
          address: 'Green Avenue, 22',
          city: 'Dallas',
          state: 'Texas',
          zipcode: 75201,
          country: 'United States',
        }
      ],
      products: [
        {
          sku: 'abc123',
          name: 'Nintendo Switch',
          description: 'Nintendo Switch console, Nintendo Switch dock, Joy-Con (L) and Joy-Con (R), and Two Joy-Con strap accessories',
          image: "https://images-na.ssl-images-amazon.com/images/I/71ivrWiYkLL._AC_.jpg",
          price: 299.96,
        },
        {
          sku: 'cba321',
          name: 'AKG Noise Cancelling Headphones',
          description: 'AKG Noise Cancelling Headphones N60NC Wireless Bluetooth - Black',
          image: 'https://images-na.ssl-images-amazon.com/images/I/71kfR-tn1LL._SX425_.jpg',
          price: 258.50,
        }
      ]
    }
  ]
 */
  public storeOrders: StoreOrder;
  orderProducts: Array<any> = [];

  /**
   * @constructor
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks
   * @param {ActivatedRoute} route Current navigation route
   * @param {StoreOrderService} storeOrderService Service for store orders management
   * @param {navLocation} location Current navigation location
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private storeOrderService: StoreOrderService,
    private productService: ProductService,
    private location: navLocation) { }

  ngOnInit() {
    // this.getOrder();
    // console.log("fff", this.storeOrder);
    this.activatedRoute.params.subscribe(params => {
      this.storeOrders = this.storeOrderService.get(params['id']);
      console.log(this.storeOrders);
      this.storeOrders.products.forEach(product => {
          this.productService.getProductByID(this.storeOrders.__id, true).__toPromise().then(storeProduct => {
            product = storeProduct;
          });
        this.orderProducts.push(product);
      });
    });

    this.route.params.subscribe(params => {
      const id = params['id'];
      this.storeOrderService.get(id).__toPromise().then(order => {
        this.storeOrders = order;
        this.storeOrders.products.forEach(product => {
          if (typeof product.product === 'string') {
            this.productService.getProductByID(product.product, true).__toPromise().then(storeProduct => {
              product.product = storeProduct;
            });
          }
          this.orderProducts.push(product);
        });
      });
    });
  }

  /**
   * Description: Gets a store order by its id.
   * @author Andrea Scardino
   * @returns void
   */
  getOrder() {
    this.storeOrderService.get(this.route.snapshot.params['id']).__asObservable()._subscribe((p) => {
      this.storeOrders = p;
    });
  }

  /**
   * Description: Navigates to the previous navigation route.
   * @author Andrea Scardino
   * @returns void
   */
  goBack(): void {
    this.location.back();
  }


  changeStatus(): void {
    console.log('[EDITED]', this.storeOrders);
    this.storeOrderService.changeStatus(this.storeOrders.__id, this.storeOrders.status);
  }

}
