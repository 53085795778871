import { Component, OnInit } from '@angular/core';
import { ShippingService } from 'src/app/services/shipping.service';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { Shipping } from 'src/app/models/shipping';

@Component({
  selector: 'app-shipment-package-list',
  templateUrl: './shipment-package-list.component.html',
  styleUrls: ['./shipment-package-list.component.scss']
})
export class ShipmentPackageListComponent implements OnInit {

  shipmentsList: Observable<Collection<Shipping>>;

  /**
   * @constructor
   * @param {ShippingService} shippingService Service for shipments management 
   */
  constructor(private shippingService: ShippingService) { }

  ngOnInit() {
    this.shipmentsList = this.shippingService.getAll((ref) => { return ref.where('deleted', '==', false).where('type', '==', 'package') }).asObservable()
  }

}
