import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
import { Agency } from './agency';

// tslint:disable-next-line:class-name
export interface iPerson {
    name: string;
    lastname: string;
    email: string;
    phone: string[];
    recipient: boolean;
    addresses: {
        country: string;
        state: string;
        city: string;
        zipcode: string;
        address: string;
        availability: string;
        businessHours: string;
    }[];
    agency_path: string;
    label: string;
    search_label: string;
}

export class Person extends Entity implements iPerson {

    public name: string;
    public lastname: string;
    public email: string;
    public phone: string[];
    public recipient: boolean;
    public addresses: {
        country: string;
        state: string;
        city: string;
        zipcode: string;
        address: string;
        availability: string;
        businessHours: string;
    }[];
    public agency_path: string;
    public label: string;
    public search_label: string;


    public get agency() {
        if (this.agency_path) {
            return new Agency(this.agency_path);
        }
    }

    public set agency(value: Agency) {
        this.agency_path = value.__path;
    }

    /**
     * Description: Returns the person as a promise.
     * @author Alejandro Font
     * @returns {Promise<Person>}
     */
    public __toPromise() {
        return this.__promise as Promise<Person>;
    }

    /**
     * Description: Returns the person as an observable.
     * @author Alejandro Font
     * @returns {Observable<Person>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<Person>);
    }

    /**
     * @constructor
     * @param {string} path
     * @param {string} name
     * @param {string} lastname
     * @param {string} email
     * @param {string[]} phone
     * @param {boolean} recipient
     * @param addresses
     * @param {string} agency_path Shipping agency
     * @param {string} label
     * @param {string} search_label
     */
    constructor(path: string,
        name?: string,
        lastname?: string,
        email?: string,
        phone?: string[],
        recipient?: boolean,
        addresses?: {
            country: string;
            state: string;
            city: string;
            zipcode: string;
            address: string;
            availability: string;
            businessHours: string;
        }[],
        agency_path?: string,
        label?: string,
        search_label?: string
    ) {
        super(Person, path);
        if (name) {
            this.name = name;
        }
        if (lastname) {
            this.lastname = lastname;
        }
        if (email) {
            this.email = email;
        }
        if (label) {
            this.label = label;
        }
        if (phone) {
            this.phone = phone;
        }
        if (recipient) {
            this.recipient = recipient;
        }
        if (addresses) {
            this.addresses = addresses;
        }
        if (agency_path) {
            this.agency_path = agency_path;
        }
        if (search_label) {
            this.search_label = search_label;
        }
    }
}
