import { Injectable } from '@angular/core';

import { Manifest } from '../models';
import { Entity, Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { DatePipe } from '@angular/common';
import { Subject, Observable } from 'rxjs';
import { XlsxService } from './xlsx.service';
import { iManifest } from '../models/manifest';
import { iAgency, Agency } from '../models/agency';
import { iOrder, Order } from '../models/order';
import { AXInput, AXPages, AXPage } from 'aether-xlsx';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ManifestService {

  private pathUrl = '/manifests/';
  public manifest: Manifest;

  /**
   * @constructor
   * @param {XlsxService} xlsx Parser and writer for various spreadsheet formats
   * @param {DatePipe} datePipe Formats a date value according to locale rules
   * @param {UserService} userService Service for users management
   */
  constructor(
    private xlsx: XlsxService,
    private datePipe: DatePipe,
    private userService: UserService,
  ) { }

  /**
   * Description: Gets a manifest by its id.
   * @author Maximiliano Casale
   * @param {string} id Id of the requested manifest
   * @returns {Manifest} Manifest requested
   */
  public get(id: string): Manifest {
    return new Manifest(this.pathUrl + id);
  }

  /**
   * Description: Gets all the manifest collection.
   * @author Maximiliano Casale
   * @param query
   * @returns {Collection<Manifest>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Manifest> {
    if (query) {
      return new Collection<Manifest>(Manifest, this.pathUrl, query);
    } else {
      return new Collection<Manifest>(Manifest, this.pathUrl);
    }
  }

  /**
   * Description: Filters the manifests by year.
   * @author Maximiliano Casale
   * @param {any} date
   * @returns {Collection<Manifest>}
   */
  public getNextId(date) {
    return this.getAll((ref) => ref.orderBy('number', 'desc').where('year', '==', this.datePipe.transform(date, 'yy')).limit(1));
  }

  /**
   * Description: Gets next order number.
   * @author Maximiliano Casale
   * @returns {Promise<any>}
   */
  public getNextOrderNumber() {
    return new Promise<any>((resolve) => {
      firebase.firestore().collection('/index/').doc('manifests').get().then(query => {
        console.log((query as any).data());
        if (!query.exists) {
          firebase.firestore().collection('/index/').doc('manifests').set({
            'total': 1
          }).then(() => { resolve(1); });
        } else {
          const data = query.data();
          firebase.firestore().collection('/index/').doc('manifests').update({
            'total': data.total + 1
          }).then(() => { resolve(data.total + 1); });
        }
      });
    });
    // ???
    // return 0
  }

  /**
   * Description: Creates a new manifest.
   * @author Maximiliano Casale
   * @param {string} id Manifest's id
   * @param {string[]} orders_path Orders that belong to the manifest
   * @param {any} createdAt
   * @param {string} createdBy
   * @param {string} agency_path Agency of the user creating the manifest
   * @param {number} number
   * @param {string} year
   * @param {boolean} deleted
   * @param {string} shipping
   * @param {string} type Package/money
   * @returns {Manifest} New manifest
   */
  public create(
    id: string,
    orders_path: string[],
    createdAt: any,
    createdBy: string,
    agency_path: string,
    number: number,
    year: string,
    deleted: boolean,
    shipping: string,
    type: string
  ): Manifest {
    const doc = firebase.firestore().collection(this.pathUrl).doc();
    const manifest = new Manifest(
      doc.path,
      id,
      orders_path,
      firebase.firestore.FieldValue.serverTimestamp(),
      createdBy,
      agency_path,
      number,
      year,
      deleted,
      shipping,
      type
    );
    manifest.__save();
    return manifest;
  }

  /**
   * Description: Updates the manifest received as param in the database.
   * @author Maximiliano Casale
   * @param {Manifest} data Manifest to update
   * @returns {Promise<void>}
   */
  public update(data: Manifest): Promise<void> {
    return new Promise<void>((resolve) => {
      data.__save(true);
    });
  }

  /**
   * Description: Marks as deleted the manifest with the id received as param.
   * @author Maximiliano Casale
   * @param {string} id Id of the deleted manifest
   * @returns {Promise<void>}
   */
  public delete(id: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const manifest = new Manifest(this.pathUrl + id);
      manifest.orders_path = [];
      manifest.deleted = true;
      this.update(manifest);
    });
  }

  /**
   * Description: Gets the year of the date received as param.
   * @author Maximiliano Casale
   * @param {string} date
   * @returns {number} Year of the date
   */
  public getYear(date: string): number {
    return parseInt(date.substring(6), 10);
  }

  /**
   * Description: Gets the month of the date received as param.
   * @author Maximiliano Casale
   * @param {string} date
   * @returns {number} Month of the date
   */
  public getMonth(date: string): number {
    return parseInt(date.substring(0, 2), 10) - 1;
  }

  /**
   * Description: Gets the day of the date received as param.
   * @author Maximiliano Casale
   * @param {string} date
   * @returns {number} Day of the date
   */
  public getDay(date: string): number {
    return parseInt(date.substring(3, 5), 10);
  }

  /**
   * Description: Searches for all the manifest that match the filters' criteria.
   * @author Maximiliano Casale
   * @param {any} filters To apply
   * @param {any} pagination
   * @param {any} type Package/money
   * @param {any} user
   */
  public searchFiltered(filters, pagination, type, user?): Collection<Manifest> {
    return this.getAll(ref => {
      console.log(filters);
      let query = ref as any;
      if (filters[0]) {
        const f = filters[0];
        if (f[0] === 'dateFilter') {
          const from = new Date(this.getYear((f[1] as any).from), this.getMonth((f[1] as any).from), (this.getDay((f[1] as any).from)));
          const to = new Date(this.getYear((f[1] as any).to), this.getMonth((f[1] as any).to), (this.getDay((f[1] as any).to)) + 1);
          query = query.where('createdAt', '>=', firebase.firestore.Timestamp.fromDate(from))
            .where('createdAt', '<=', firebase.firestore.Timestamp.fromDate(to));
        }
      }
      this.userService.userProfile.subscribe((p) => {

      });
      if (user.role === 'admin') {
        return query.where('type', '==', type).where('deleted', '==', false).where('shipping', '==', '???');
      } else {
        return query.where('agency_path', '==', user.agency_path).where('deleted', '==', false).where('shipping', '==', '???');
      }
    });
  }

  /**
   * Description: Allows to export the selected manifest as an excel/PDF document.
   * @author Maximiliano Casale
   * @param {string} path Selected manifest id
   * @returns {Promise<void>}
   */
  public async export(path: string) {
    const fs = firebase.firestore();
    const manifestDoc = fs.doc(path);
    // console.log('DOC: ', manifestDoc);
    const snap = await manifestDoc.get();
    // console.log('GET: ', snap);
    if (snap) {
      const manifest = snap.data() as iManifest;
      // console.log('MAN: ', manifest)
      let agency: iAgency;
      let orders: iOrder[];

      if (manifest) {
        if (manifest.agency_path) {
          agency = (await fs.doc(manifest.agency_path).get()).data() as iAgency;
        } else {
          throw new Error('Manifest malforned: missing agency_path');
        }
        if (manifest.orders_path && manifest.orders_path.length > 0) {
          orders = await Promise.all(manifest.orders_path.map(async (p) => {
            return (await fs.doc('/orders/' + p).get()).data() as iOrder;
          }));
        } else {
          throw new Error('Manifest malformed: missing order_paths');
        }

        const input = {
          pages: {
            'Manifest': {
              headers: [
                'Created At',
                'Created By',
                'Agency',
                'Order'
              ],
              rows: orders.map((order) => {
                return [
                  this.datePipe.transform(manifest.createdAt.toDate(), 'medium'),
                  manifest.createdBy,
                  agency.name,
                  order.id
                ];
              })
            } as AXPage
          } as AXPages
        } as AXInput;

        this.xlsx.export(input, 'manifest');

      } else {
        throw new Error('Manifest not found: ' + path);
      }
    } else {
      throw new Error('Manifest not found: ' + path);
    }
  }

  public async exportAll(agencyPath: string, dateFrom, dateTo) {
    const fs = firebase.firestore();
    const agency = (await fs.doc(agencyPath).get()).data() as iAgency;

    const from = new Date(this.getYear(dateFrom),
      this.getMonth(dateFrom),
      (this.getDay(dateFrom)));
    const to = new Date(this.getYear(dateTo),
      this.getMonth(dateTo),
      (this.getDay(dateTo)) + 1);

    const orders = (await fs.collection('/orders/')
      .where('type', '==', 'package')
      .where('agency_path', '==', agencyPath)
      .where('deleted', '==', false)
      .where('createdAt', '>=', firebase.firestore.Timestamp.fromDate(from))
      .where('createdAt', '<=', firebase.firestore.Timestamp.fromDate(to))
      .orderBy('createdAt', 'desc').get()).docs.map((doc) => doc.data());

    const input = {
      pages: {
        'Manifest': {
          headers: [
            'Created At',
            'Created By',
            'Agency',
            'Order ID',
            'Shipping',
            'Boxes',
            'Total price',
            'sender',
            'recipient',
            'sent to'
          ],

          rows:
            orders.map((order) => {

              return [
                this.datePipe.transform(order.createdAt.toDate(), 'medium'),
                order.createdBy,
                agency.name,
                order.id,
                order.shippingType,
                order.boxes.length,
                order.total,
                order.sender.name + ' ' + order.sender.lastname,
                order.recipient.name + ' ' + order.recipient.lastname,
                order.address.country_name + ' ' + order.address.state_name + ' ' + order.address.city_name
              ];
            })
        } as AXPage
      } as AXPages
    } as AXInput;

    this.xlsx.export(input, agency.name + '-orders-' + dateFrom + '-' + dateTo);

  }
}
