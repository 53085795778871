import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
import { Agency } from './agency';
import { User } from './user';
import { Timestamp } from 'rxjs/internal/operators/timestamp';

export interface iPickUp {
    id: string;
    agency_path: string;
    boxesQty: number;
    distance: {
        text: string,
        value: number,
    };
    email: string;
    instructions: string;
    phone: string;
    price: number;
    timeFrom: {
        hour: number,
        minute: number,
    };
    timeTo: {
        hour: number,
        minute: number,
    };
    userAddress: string;
    user_path: string;
    completed: boolean;
    createdAt: firebase.firestore.Timestamp;
}

export class PickUp extends Entity implements iPickUp {
    public id: string;
    public agency_path: string;
    public boxesQty: number;
    public distance: {
        text: string,
        value: number,
    };
    public email: string;
    public instructions: string;
    public phone: string;
    public price: number;
    public timeFrom: {
        hour: number,
        minute: number,
    };
    public timeTo: {
        hour: number,
        minute: number,
    };
    public userAddress: string;
    public user_path: string;
    public completed: boolean;
    public createdAt: firebase.firestore.Timestamp;

    public get agency() {
        if (this.agency_path) {
            return new Agency(this.agency_path);
        }
    }

    public get user() {
        if (this.user_path) {
            return new User(this.user_path);
        }
    }

    /**
     * Description: Returns the pick up as a promise.
     * @author Maximiliano Casale
     * @returns {Promise<PickUp>}
     */
    public __toPromise() {
        return this.__promise as Promise<PickUp>;
    }

    /**
     * Description: Returns the pick up as an observable.
     * @author Maximiliano Casale
     * @returns {Observable<PickUp>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<PickUp>);
    }

    /**
     * @constructor
     * @param {string} path
     * @param {string} id
     * @param {string} agency_path
     * @param {number} boxesQty Number of boxes to pick up
     * @param distance
     * @param {string} email
     * @param {string} instructions
     * @param {string} phone
     * @param {number} price
     * @param timeFrom Available hours
     * @param timeTo Available hours
     * @param {string} user_path
     * @param {string} userAddress
     * @param {boolean} completed
     * @param {Timestamp} createdAt
     */
    constructor(path: string,
        id?: string,
        agency_path?: string,
        boxesQty?: number,
        distance?: { text: string, value: number },
        email?: string,
        instructions?: string,
        phone?: string,
        price?: number,
        timeFrom?: { hour: number, minute: number },
        timeTo?: { hour: number, minute: number },
        user_path?: string,
        userAddress?: string,
        completed?: boolean,
        createdAt?: firebase.firestore.Timestamp
    ) {
        super(PickUp, path);
        if (id) {
            this.id = id;
        }
        if (agency_path) {
            this.agency_path = agency_path;
        }
        if (boxesQty) {
            this.boxesQty = boxesQty;
        }
        if (distance) {
            this.distance = distance;
        }
        if (email) {
            this.email = email;
        }
        if (instructions) {
            this.instructions = instructions;
        }
        if (phone) {
            this.phone = phone;
        }
        if (price) {
            this.price = price;
        }
        if (timeFrom) {
            this.timeFrom = timeFrom;
        }
        if (timeTo) {
            this.timeTo = timeTo;
        }
        if (userAddress) {
            this.userAddress = userAddress;
        }
        if (user_path) {
            this.user_path = user_path;
        }
        if (completed) {
            this.completed = completed;
        }
        if (createdAt) {
            this.createdAt = createdAt;
        }
    }
}
