import { Component, OnInit } from '@angular/core';
import { StoreService } from '../../services/store.service';
import { BillService } from '../../services/bill.service';
import { Bill } from '../../models/bill';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { Agency } from 'src/app/models';
import { getBodyNode } from '@angular/animations/browser/src/render/shared';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-store-manifest',
  templateUrl: './store-manifest.component.html',
  styleUrls: ['./store-manifest.component.scss']
})
export class StoreManifestComponent implements OnInit {

  public cart: any[] = [];
  public cartTotal: number = 0;
  public paymentMethod: string;
  public agency: Agency;
  public bill: Bill;

  /**
   * @constructor
   * @param {StoreService} storeService Service for stores management 
   * @param {BillService} billService Service for bills management
   * @param {ActivatedRoute} route Current navigation route
   */
  constructor(private storeService: StoreService, private billService: BillService, private route: ActivatedRoute) { }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      console.log(params['id']);
      console.log(this.billService.get(params['id']));
      this.bill = this.billService.get(params['id']);
      this.cartTotal = this.bill.total;
      console.log(this.bill.paymentMethod);
      this.paymentMethod = this.bill.paymentMethod;
      //this.agency = this.bill.agency_path;
      console.log(this.bill);

    });
    console.log('entre');
  }

  generateBill() {
    console.log(this.cart);
    console.log(this.agency);
  }

  /**
   * Description: Gets all the store's data: its cart's data, total, payment method and the agency it 
   * belongs to.
   * @author Jesus Ponte
   * @returns void
   */
  getStoreData() {
    this.storeService.getCartData()
      .then(data => {
        this.cart.push(data);
      });
    this.cartTotal = this.storeService.getCartTotal();
    this.paymentMethod = this.storeService.getPaymentMethod();
    this.agency = this.storeService.getAgencyPath();
  }

  /**
   * Description: Creates a pdf of the store manifest.
   * @author Jesus Ponte
   * @returns void
   */
  public captureScreen(): void {
    var element = document.getElementById('pdf');

    html2canvas(element).then(canvas => {
      var imgWidth = 198;
      var pageHeight = 295;
      var imgHeight = canvas.height * 1.30 * imgWidth / canvas.width;
      var heightLeft = pageHeight - imgHeight;
      const margin = 7;
      const contentDataURL = canvas.toDataURL('img/png');
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 10;
      var position2 = imgHeight + 38;
      pdf.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);

      //Disclaimer firmado por el cliente.
      pdf.setFontSize(6);
      var disclaimer = 'EL CLIENTE (SENDER) DECLARA QUE DENTRO DE LA CAJA NO ENVIA DINERO, DOCUMENTOS COMO(CEDULA, PASAPORTE, TARJETAS DE CREDITO), EXPLOSIVOS, ARMAS Y'
      var disclaimer2 = 'ARTICULOS PROHIBIDOS. EN CASO DE QUE LA CARGA SEA EXTRAVIADA EN SU TOTALIDAD, SE APLICARA EL REINTEGRO POR EL MONTO ASEGURADO, EL SEGURO NO CUBRE'
      var disclaimer3 = 'PERDIDAS PARCIALES NI DAÑOS DEL CONTENIDO, EL EMPAQUE DE LA CARGA ES RESPONSABILIDAD UNICA DEL CLIENTE'
      pdf.text(10, imgHeight + position + 5, disclaimer);
      pdf.text(10, imgHeight + position + 8, disclaimer2);
      pdf.text(10, imgHeight + position + 11, disclaimer3);
      pdf.text(10, imgHeight + position + 15, 'ACEPTO LOS TERMINOS Y CONDICIONES DE CCARGAXPRESS');
      pdf.line(imgWidth / 2 - 20, imgHeight + position + 18, imgWidth / 2 + 20, imgHeight + position + 18);
      pdf.text(imgWidth / 2 - 15, imgHeight + position + 20, 'Firma del Cliente (en tienda)');
      //fin disclaimer

      const linePos = imgHeight + position + 24;
      if (heightLeft >= imgHeight + position + 40) {
        pdf.line(0, linePos, canvas.width, linePos);
        pdf.addImage(contentDataURL, 'PNG', margin, position2, imgWidth, imgHeight);
        pdf.text(10, imgHeight + position2 + 5, disclaimer);
        pdf.text(10, imgHeight + position2 + 8, disclaimer2);
        pdf.text(10, imgHeight + position2 + 11, disclaimer3);
      } else {
        pdf.addPage()
        pdf.addImage(contentDataURL, 'PNG', 5, position, imgWidth, imgHeight);
        pdf.text(10, imgHeight + position + 5, disclaimer);
        pdf.text(10, imgHeight + position + 8, disclaimer2);
        pdf.text(10, imgHeight + position + 11, disclaimer3);
      }


      var NumberTal = 'purchase-manifest';
      pdf.save(`Order ${NumberTal}`)
    });
  }

}
