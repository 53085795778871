import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Agency } from '../../../models';
import { AgencyService } from '../../../services/agency.service';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-agencies-list',
  templateUrl: './agencies-list.component.html',
  styleUrls: ['./agencies-list.component.scss']
})
export class AgenciesListComponent implements OnInit {

  filter = false;
  agencies: Observable<Collection<Agency>>;

  /**
   * @constructor
   * @param {Router} router Route management 
   * @param {AgencyService} agencyService Service for the agencies management 
   */
  constructor(
    private router: Router,
    private agencyService: AgencyService) { }

  ngOnInit() {
    this.getAgencies();
  }

  /**
   * Description: Gets the available agencies as an observable.
   * @author Mauro Lanza
   * @returns void
   */
  getAgencies(): void {
    this.agencies = this.agencyService.getAll().asObservable();
  }

  /**
   * Description: Confirmation to delete agency.
   * @author Mauro Lanza
   * @param {any} agency Agency to delete
   * @returns void
   */
  toggleDeleted(agency: any): void {
    const check = confirm(`Are you sure you want to ${agency.deleted ? 'restore' : 'delete'} "${agency.name}" ?`);
    if (check) {
      agency.deleted = !agency.deleted;
      this.agencyService.update(agency);
    }
  }

  /**
   * Description: Allows navigation to selected agency edit view.
   * @author Mauro Lanza
   * @param {string} id Id of the selected agency to edit
   * @returns void
   */
  editSelected(id: string): void {
    this.router.navigate(['agencies/edit', id]);
  }

  /**
   * Description: Allows navigation to selected agency's details.
   * @author Mauro Lanza
   * @param {string} id Id of the agency selected to see details
   * @returns void
   */
  viewDetails(id: string): void {
    this.router.navigate(['agencies/details', id]);
  }

}
