import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
import { Agency } from './agency';

// tslint:disable-next-line:class-name
interface iLocation {
  country: string;
  city: string;
  state: string;
}

// tslint:disable-next-line:class-name
export interface iRateValues {
  public: number;
  private: number;
  tax: number;
  shipmentType: string;
  minPounds: number;
}

export class Rate extends Entity {

  public name: string;
  public type: string;
  public insurance: number;
  public minimum_insurance: number;
  public active: boolean;
  public createdAt: any;
  public aerialRate: iRateValues;
  public maritimeRate: iRateValues;
  public agency_path: string;
  public deleted: boolean;
  public locations: Array<iLocation>;
  public customsManagement: number;
  public minCustomsManagement: number;

  public get agency() {
    if (this.agency_path) {
      return new Agency(this.agency_path);
    }
  }

  /**
   * Description: Returns the rate as a promise.
   * @author Maximiliano Casale
   * @returns {Promise<Rate>}
   */
  public __toPromise() {
    return this.__promise as Promise<Rate>;
  }

  /**
   * Description: Returns the rate as an observable.
   * @author Maximiliano Casale
   * @returns {Observable<Rate>}
   */
  public __asObservable() {
    return (this.__subject.asObservable() as Observable<Rate>);
  }

  /**
   * @constructor
   * @param {string} path
   * @param {string} name
   * @param {string} type Local/Global
   * @param {number} insurance Percentage
   * @param {boolean} active
   * @param {any} createdAt
   * @param aerialRate Public aerial rate, private aerial rate and zone aerial tax
   * @param maritimeRate Public maritime rate, private maritime rate and zone maritime tax
   * @param {string} agency
   * @param {boolean} deleted
   * @param {iLocation[]} locations Locations with the same rates
   * @param {customsManagement} customsManagement Locations with the same rates
   * @param {minCustomsManagement} minCustomsManagement Locations with the same rates
   */
  constructor(path: string,
    name?: string,
    type?: string,
    insurance?: number,
    active?: boolean,
    createdAt?: any,
    aerialRate?: iRateValues,
    maritimeRate?: iRateValues,
    agency?: string,
    deleted?: boolean,
    locations?: Array<iLocation>,
    customsManagement?: number,
    minCustomsManagement?: number,
  ) {
    super(Rate, path);
    if (name) {
      this.name = name;
    }
    if (type) {
      this.type = type;
    }
    if (insurance) {
      this.insurance = insurance;
    }
    if (active) {
      this.active = active;
    }
    if (createdAt) {
      this.createdAt = createdAt;
    }
    if (aerialRate) {
      this.aerialRate = aerialRate;
    }
    if (maritimeRate) {
      this.maritimeRate = maritimeRate;
    }
    if (agency) {
      this.agency_path = agency;
    }
    if (deleted) {
      this.deleted = deleted;
    }
    if (locations) {
      this.locations = locations;
    }
    if (customsManagement) {
      this.customsManagement = customsManagement;
    }
    if (minCustomsManagement) {
      this.minCustomsManagement = minCustomsManagement;
    }
  }
}

