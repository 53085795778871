import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
import { Agency } from './agency';

// tslint:disable-next-line:class-name
export interface iUser {
    role?: string;
    tax?: string;
    email?: string;
    name?: string;
    lastname?: string;
    phone?: string[];
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    zipcode?: string;
    $key?: string;
    orders?: string[];
    status?: boolean;
    agency_path?: string;
}

export class User extends Entity implements iUser {

    public role?: string;
    public tax?: string;
    public email?: string;
    public name?: string;
    public lastname?: string;
    public phone?: string[];
    public address?: string;
    public city?: string;
    public state?: string;
    public country?: string;
    public zipcode?: string;
    public $key?: string;
    public orders?: string[];
    public status?: boolean;
    public agency_path?: string;

    public get agency() {
        if (this.agency_path) {
            return new Agency(this.agency_path);
        }
    }

    public set agency(value: Agency) {
        this.agency_path = value.__path;
    }

    /**
     * Description: Returns the user as a promise.
     * @author Sarkis
     * @returns {Promise<User>}
     */
    public __toPromise() {
        return this.__promise as Promise<User>;
    }

    /**
     * Description: Returns the user as an observable.
     * @author Sarkis
     * @returns {Observable<User>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<User>);
    }

    /**
     * @constructor
     * @param {string} path
     * @param {string} role Admin/agent/user/manager/manager-ccarga
     * @param {string} tax
     * @param {string} email
     * @param {string} name
     * @param {string} lastname
     * @param {string[]} phone
     * @param {string} address
     * @param {string} city
     * @param {string} state
     * @param {string} country
     * @param {string} zipcode
     * @param {string} $key
     * @param {string[]} orders
     * @param {boolean} status Disabled/enabled
     * @param {string} agency_path
     */
    constructor(path: string,
        role?: string,
        tax?: string,
        email?: string,
        name?: string,
        lastname?: string,
        phone?: string[],
        address?: string,
        city?: string,
        state?: string,
        country?: string,
        zipcode?: string,
        $key?: string,
        orders?: string[],
        status?: boolean,
        agency_path?: string
    ) {
        super(User, path);
        if (role) {
            this.role = role;
        }
        if (tax) {
            this.tax = tax;
        }
        if (email) {
            this.email = email;
        }
        if (name) {
            this.name = name;
        }
        if (lastname) {
            this.lastname = lastname;
        }
        if (phone) {
            this.phone = phone;
        }
        if (address) {
            this.address = address;
        }
        if (city) {
            this.city = city;
        }
        if (state) {
            this.state = state;
        }
        if (country) {
            this.country = country;
        }
        if (zipcode) {
            this.zipcode = zipcode;
        }
        if ($key) {
            this.$key = $key;
        }
        if (orders) {
            this.orders = orders;
        }
        if (status) {
            this.status = status;
        }
        if (agency_path) {
            this.agency_path = agency_path;
        }
    }
}
