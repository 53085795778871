import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { User } from '../../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  userForm: FormGroup;
  userName: User;
  email: string;

  user = [
    { email: 'reds@gmail.com', userName: 'reds' },
    { email: 'roers@gmail.com', userName: 'roers' },
    { email: 'raws@gmail.com', userName: 'raws' },
    { email: 'rkjs@gmail.com', userName: 'rkjs' },
  ];

  /**
   * @constructor
   * @param {UserService} userService Service that allows users management
   * @param {FormBuilder} fb Form builder that allows to create a form to invite a new user 
   */
  constructor(public userService: UserService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.createInviteForm();
  }

  /**
   * Description: Creates a form group with the fields required to create a new user.
   * @author Rafael Quintero
   * @returns void
   */
  createInviteForm() {
    this.userForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      userName: ['', [Validators.required]],
    });
  }

  /**
   * Description: Pushes the current value of the user form into this.user.
   * @author Rafael Quintero
   * @returns void 
   */
  save() {
    this.user.push(this.userForm.value);
    this.resetBox();
    // this.user=[];
    // console.log(this.user);
  }

  /**
   * Description: Resets the user form.
   * @author Rafael Quintero
   * @returns void
   */
  resetBox() {
    this.userForm.reset();
  }

  onSubmit() {
  }

}

