import { Injectable } from '@angular/core';
import { PickUp } from '../models/pickup';
import { Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { resolve } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class PickUpService {

  private pathUrl = '/pickups/';
  public pickUp: PickUp;

  /**
   * @constructor
   */
  constructor() { }

  /**
   * Description: Gets a pick up by its id.
   * @author Maximiliano Casale
   * @param {string} id
   * @returns {PickUp}
   */
  public get(id: string): PickUp {
    return new PickUp(this.pathUrl + id);
  }

  /**
   * Description: Gets all the pick ups in the collection.
   * @author Maximiliano Casale
   * @param query
   * @returns {Collection<PickUp>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<PickUp> {
    if (query) {
      return new Collection<PickUp>(PickUp, this.pathUrl, query);
    } else {
      return new Collection<PickUp>(PickUp, this.pathUrl);
    }
  }

  /**
   * Description: Adds recently created pick up to the firestore collection.
   * @author Maximiliano Casale
   * @param {PickUp} newPickUp
   * @returns {Promise<>firebase.firestore.DocumentReference} Reference of the added pick up
   */
  public create(newPickUp: PickUp) {
    newPickUp.createdAt = firebase.firestore.Timestamp.now();
    return firebase.firestore().collection(this.pathUrl).add(newPickUp);
  }

  /**
   * Description: Updates modified pick up's data.
   * @author Maximiliano Casale
   * @param {PickUp} data
   * @returns {Promise<void>} Update confirmation
   */
  public update(data: PickUp): Promise<void> {
    return data.__save(true);
  }

}

