import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LockerService } from 'src/app/services/locker.service';
import { Locker } from 'src/app/models/locker';
import { Location } from '@angular/common';
import { MailService } from 'src/app/services/mail.service';

@Component({
  selector: 'app-locker-details',
  templateUrl: './locker-details.component.html',
  styleUrls: ['./locker-details.component.scss']
})
export class LockerDetailsComponent implements OnInit {

  public oldLocker: Locker;

  public selectedLocker: Locker;
  public selectedImg: string;
  public imgModal: boolean;

  /**
   * @constructor
   * @param {ActivatedRoute} route Current navigation route
   * @param {LockerService} lockerService Service for lockers management
   * @param {Location} location Current navigation location
   * @param {MailService} mailService Service for mails management
   */
  constructor(private route: ActivatedRoute,
    private lockerService: LockerService,
    private location: Location,
    private mailService: MailService
  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.lockerService.get(params['id']).__asObservable().subscribe(locker => {
        this.selectedLocker = locker;
        this.oldLocker = this.saveOldLocker();


      });
    });
  }

  /**
   * Description: Gets all the shipments and trackings of every shipment associated to the locker.
   * @author Maximiliano Casale
   * @returns {Locker} Model that contains all locker's data
   */
  public saveOldLocker(): Locker {
    let oldLocker;
    let oldShipments = [];
    for (const shipment of this.selectedLocker.shipments) {
      let oldTrackings = [];
      let oldShipment;
      for (const tracking of shipment.trackings) {
        const oldTracking = { ...tracking };
        oldTrackings.push(oldTracking);
      }
      oldShipment = { ...shipment };
      oldShipment.trackings = oldTrackings;
      oldShipments.push(oldShipment);
    }
    oldLocker = { ...this.selectedLocker };
    oldLocker.shipments = oldShipments;
    return oldLocker;
  }

  /**
   * Description: Shows the shipment's image if there is an image assignated.
   * @author Maximiliano Casale
   * @param {number} index Position in the array of shipments of the shipment requested
   * @returns void
   */
  public showImgModal(index: number): void {
    this.selectedImg = this.selectedLocker.shipments[index].img;
    this.imgModal = true;
  }

  /**
   * Description: Checks if the trackings of a shipment are or not complete and in that order if the
   * shipment is or not complete.
   * @author Maximiliano Casale
   * @param {any} shipment Shipment to check
   * @returns {boolean}
   */
  public trackingsComplete(shipment): boolean {
    for (const tracking of shipment.trackings) {
      if (tracking.complete === false) {
        return false;
      }
    }
    shipment.complete === true;
    return true;
  }

  /**
   * Description: Checks if the shipments of the locker received as param are or not complete and in that order
   * if the locker is or not complete.
   * @author Maximiliano Casale
   * @param {any} locker Locker selected from the list
   * @returns {boolean}
   */
  public shipmentsComplete(locker): boolean {
    for (const shipment of locker.shipments) {
      if (shipment.complete === false) {
        return false;
      }
    }
    return true;
  }

  /**
   * Description: Checks every shipment of the selected locker, if the shipment has trackings, checks if
   * the trackings are complete or not. Checks if every shipment of the locker is complete and updates the
   * selected locker.
   * @author Maximiliano Casale
   * @returns void
   */
  public update(): void {
    for (const shipment of this.selectedLocker.shipments) {
      if (shipment.trackings) {
        shipment.complete = this.trackingsComplete(shipment);
      }
    }
    this.selectedLocker.complete = this.shipmentsComplete(this.selectedLocker);
    this.lockerService.update(this.selectedLocker).then(_ => {
      this.sendMail();
      this.location.back();
    });
  }

  /**
   * Description: Updates the locker mail everytime the locker details are updated.
   * @author Maximiliano Casale
   * @returns void
   */
  public sendMail(): void {
    this.mailService.updateLockerMail(this.buildMailData(), this.selectedLocker.user);
  }

  /**
   * Description: Compares the previous locker with the new locker and fills the mail data in order to
   * update it.
   * @author Maximiliano Casale
   * @returns {Object}
   */
  public buildMailData(): Object {
    const mailData = {
      trackings: [],
      shipments: [],
      lockerDone: false
    };
    const shipmentsLength = this.selectedLocker.shipments.length;
    for (let i = 0; i < shipmentsLength; i++) {
      const trackingsLength = this.selectedLocker.shipments[i].trackings.length;
      for (let j = 0; j < trackingsLength; j++) {
        if (this.selectedLocker.shipments[i].trackings[j].complete !== this.oldLocker.shipments[i].trackings[j].complete) {
          mailData.trackings.push(this.selectedLocker.shipments[i].trackings[j].tracking);
        }
      } if (this.selectedLocker.shipments[i].complete !== this.oldLocker.shipments[i].complete) {
        mailData.shipments.push(this.selectedLocker.shipments[i]);
      }
    }
    mailData.lockerDone = this.selectedLocker.complete !== this.oldLocker.complete;
    return mailData;
  }

}

