import { imageLoad } from 'src/app/utils/image-loader';
import { convertImgToBase64URLOneThree } from 'src/app/utils/image-to-base64';

function modelToTagsArray(model: OrderTagPDFModel, base64: string) {
    return model.boxes.map((box, index) => {
        const conditional = (index % 4 === 1 || index % 4 === 2 || index % 4 === 3);
        return [
            conditional ? '\n' : null,
            conditional ? {
                canvas: [
                    { type: 'line', x1: -200, y1: 0, x2: 600, y2: 0, lineWidth: 1, dash: { length: 3 } }
                ],
                margin: [0, 10, 0, 10]
            } : null,
            conditional ? '\n' : null,
            {
                pageBreak: index !== 0 && !conditional ? 'before' : null,
                columns: [
                    {
                        style: 'tableHeader',
                        table: {
                            widths: ['*', 'auto', 'auto'],
                            body: [
                                [
                                    {
                                        colSpan: 2,
                                        text: [
                                            { text: 'Shipper: ', style: 'shipper' },
                                            { text: model.agency.name, style: 'shipper' }
                                        ],
                                        noWrap: true
                                    },
                                    '',
                                    {
                                        rowSpan: 3,
                                        image: base64,
                                        fit: [160, 64],
                                    },
                                ],
                                [
                                    {
                                        colSpan: 2,
                                        text: [
                                            { text: 'ID: ', style: 'ident' },
                                            { text: model.id, style: 'ident' }
                                        ],
                                        noWrap: true
                                    },
                                    '',
                                ],
                                [
                                    {
                                        colSpan: 2,
                                        text: [
                                            { text: 'Type: '},
                                            { text: model.type }
                                        ],
                                        style: 'amount'
                                    },
                                    '',
                                ],
                                [
                                    { text: [{ text: 'H x W x L: '}, { text: box.dimensions }], style: 'amount' },
                                    { text: [{ text: 'Weight: '}, { text: box.weight }], style: 'amount' },
                                    { text: (index + 1) + '/' + model.boxes.length, style: 'count'}
                                ],
                            ]
                        },
                        layout: 'noBorders'
                    }
                ]
            },
            {
                columns: [
                    {
                        style: 'labelTable',
                        table: {
                            widths: ['auto', '*', '*'],
                            body: [
                                [
                                    '',
                                    { text: 'Sender', style: 'header' },
                                    { text: 'Recipient', style: 'header' }
                                ],
                                [
                                    { text: 'Name', style: 'header' },
                                    { text: model.sender.name, noWrap: true },
                                    { text: model.recipient.name, noWrap: true}
                                ],
                                [
                                    { text: 'Location', style: 'header' },
                                    { text: model.sender.stateCountry + ' - ' + model.sender.city, noWrap: true},
                                    { text: model.recipient.stateCountry + ' - ' + model.recipient.city, noWrap: true}
                                ]
                            ]
                        },
                        layout: 'lightHorizontalLines'
                    }
                ]
            }
        ];
    });
}

export interface OrderTagPDFModel {

    agencyLogo: string;

    id: string;
    type: string;

    sender: {
        name: string;
        stateCountry: string;
        city: string;
    };

    recipient: {
        name: string;
        stateCountry: string;
        city: string;
    };

    agency: {
        name: string;
    };

    boxes: {
        dimensions: string,
        weight: string,
    }[];
}

export async function parseOrderTag(model: OrderTagPDFModel) {

    const img = await imageLoad(model.agencyLogo);
    const base64 = convertImgToBase64URLOneThree(img);
    const content = modelToTagsArray(model, base64);

    const dd = {
        pageMargins: [ 20, 20, 20, 20 ],
        pageSize: 'LETTER',
        content,

        styles: {
            default: {
                fontSize: 10,
            },
            tableHeader: {

            },
            count: {
                alignment: 'right',
                bold: true,
            },
            title: {
                bold: true,
                fontSize: 12,
                color: 'black',
                margin: [0, 25, 0, 0]
            },
            amount: {
                fontsize: 10
            },
            titleEnterprise: {
                bold: true,
                fontSize: 12,
                color: 'black',
            },
            header: {
                bold: true,
                fontSize: 11,
                color: 'black'
            },
            bold: {
                bold: true
            },
            terms: {
                fontSize: 7,
                alignment: 'justify'
            },
            center: {
                alignment: 'center'
            },
            small: {
                fontSize: 8
            },
            tableExample: {
                margin: [0, 5, 0, 15],
                alignment: 'right'
            },
            shipper: {
                fontSize: 20,
                color: '#7abd25'
            },
            ident: {
                fontSize: 20,
                color: 'purple'
            },
            labelTable: {
                alignment: 'center'
            },
        }
    };

    return dd;
}
