import { Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { Router } from '@angular/router';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {

  sort = ClrDatagridSortOrder.UNSORTED;
  filter = '';
  filterSKU = '';

  products = [];

  /**
   * @constructor
   * @param {Router} router Navigation router
   * @param {ProductService} productService Service that allows products management
   */
  constructor(
    private router: Router,
    private productService: ProductService) { }

  ngOnInit() {
    this.getProducts();
  }

  /**
   * Description: Checks item's stock and gives a danger, warning or success signal depending on the stock.
   * @author Mauro Lanza
   * @param item Item from which stock is wanted
   * @returns {string} "text-danger" | "text-warning" | "text-success"
   */
  stockWarning(item) {
    if (item.stock <= item.stock_warning[2]) {
      return 'text-danger';
    } else if (item.stock <= item.stock_warning[1]) {
      return 'text-warning';
    } else {
      return 'text-success';
    }
  }

  /**
   * Description: Sorts the stock.
   * @author Mauro Lanza
   * @param filter Boolean to know how to sort the stock
   * @returns void
   */
  sortStock(filter: boolean): void {
    if (filter) {
      this.sort = (this.sort === ClrDatagridSortOrder.ASC) ? ClrDatagridSortOrder.DESC : ClrDatagridSortOrder.ASC;
    } else {
      this.sort = ClrDatagridSortOrder.UNSORTED;
    }
  }

  /**
   * Description: Deletes the selected product from the list.
   * @author Mauro Lanza
   * @param product Product to delete from the list
   * @returns void
   */
  deleteSelected(product: any): void {
    const check = confirm(`Are you sure you want to delete "${product.name}" ?`);
    // product service delete product by id
    if (check) {
      // mientras
      // this.products.splice(this.products.findIndex(item => item.name === product.name), 1);
    }
  }

  /**
   * Description: Navigates to the selected product edit view.
   * @author Mauro Lanza
   * @param id Id of the product to edit
   * @returns void
   */
  editSelected(id: string): void {
    this.router.navigate(['product/edit', id]);
  }

  /**
   * Description: Navigates to the selected product's details view.
   * @author Mauro Lanza
   * @param id Id of the selected product
   * @returns void
   */
  viewDetails(id: string): void {
    this.router.navigate(['product/details', id]);
  }

  /**
   * Description: Gets the products in firebase as an observable.
   * @author Mauro Lanza
   * @returns void
   */
  getProducts(): void {
    this.productService.getAll().asObservable().subscribe(async (productCollection) => {
      // once they arrive save them to allAgencies
      this.products = await productCollection.iterable;
    });
  }

}
