import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FilterByDatesPipe'
})
export class FilterByDates implements PipeTransform {

  /**
   * Description: If there is a date range, returns only the data that is in the range.
   * @author Mauro Lanza
   * @param {any} data Data to filter
   * @param {string} start_date
   * @param {string} end_date
   * @returns {any}
   */
  transform(data: any, start_date: string, end_date: string): any {
    if (start_date && end_date) {
      return data.filter(function (item) {
        return Date.parse(item.date) >= Date.parse(start_date) && Date.parse(item.date) <= Date.parse(end_date);
      });
    } else {
      return data;
    }
  }
}

@Pipe({
  name: 'FilterByDeletedPipe'
})
export class FilterByDeleted implements PipeTransform {

  /**
   * Description: Filters the data received as parameter by its deleted atribute.
   * @author Sarkis
   * @param {any} data Data to filter
   * @param {boolean} deleted
   */
  transform(data: any, deleted: boolean): any {
    if (data) {
      return data.filter(function (item) {
        return item.deleted === deleted;
      });
    }
  }
}


@Pipe({
  name: 'FilterByAvailablePipe'
})
export class FilterByAvailable implements PipeTransform {

  /**
   * Description: Filters the data received as parameter by its available atribute.
   * @author
   * @param {any} data Data to filter
   * @param {boolean} available Filter's criteria
   * @returns {any} Data that matches the criteria
   */
  transform(data: any, available: boolean): any {
    if (data) {
      return data.filter(function (item) {
        return item.available === available;
      });
    }
  }
}

@Pipe({
  name: 'FilterByEnabledPipe'
})
export class FilterByEnabled implements PipeTransform {

  /**
   * Description: Filters the data received as parameter by its enabled atribute.
   * @author
   * @param {any} data Data to filter
   * @param {boolean} enabled Filter's criteria
   * @returns {any}
   */
  transform(data: any, enabled: boolean): any {
    if (data) {
      return data.filter(function (item) {
        return item.enabled === enabled;
      });
    }
  }
}

@Pipe({
  name: 'FilterByPendingPipe'
})
export class FilterByPending implements PipeTransform {

  /**
   * Description: Filters the data received as parameter by its status.
   * @author
   * @param {any} data Data to filter
   * @param {boolean} pending Filter's criteria
   * @returns {any}
   */
  transform(data: any, pending: boolean): any {
    if (data) {
      if (pending) {
        return data.filter(function (item) {
          return item.status === 'Pending';
        });
      } else {
        return data.filter(function (item) {
          return item.status !== 'Pending';
        });
      }
    }
  }
}


@Pipe({
  name: 'FilterByString'
})
export class FilterByString implements PipeTransform {

  /**
   * Description: Filters the value received as parameter by the data.
   * @author
   * @param {any[]} value Value to filter
   * @param {string} data Filter's criteria
   * @returns {any}
   */
  transform(value: any[], data: string): any {
    if (value) {
      return value.filter(function (item) {
        return item.product.name.toLowerCase().includes(data);
      });
    }
  }
}

@Pipe({
  name: 'OrderByNamePipe'
})
export class OrderByName implements PipeTransform {

  /**
   * Description: Orders a list by a string.
   * @author
   * @param {any} data Data to order
   * @param {string} sorting Order criteria
   * @returns {any}
   */
  sortBy(data: any, sorting: string) {
    if (data) {
      switch (sorting) {
        case 'nameAZ':
          return data.sort((a, b) => {
            if (a.name) {
              return a.name.localeCompare(b.name);
            } else {
              return null;
            }
          });

        case 'nameZA':
          return data.sort((a, b) => {
            if (b.name) {
              return b.name.localeCompare(a.name);
            } else {
              return null;
            }
          });
      }
    }
  }

  /**
   * Description: Sorts the data received by the sorting criteria.
   * @param data Data to sort
   * @param sorting Sorting criteria
   * @returns {any} Sorted data
   */
  transform(data: any, sorting: string): any {
    return this.sortBy(data, sorting);
  }
}

