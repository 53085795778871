import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { User, Location } from '../../models';
import { LocationsService } from '../../services/locations.service';
import { AlertService } from '../../services/alert.service';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  public profileDetails: FormGroup;
  public ready = false;
  // location service

  profile: any;
  country: any;
  state: any;
  city: any;
  countries: Collection<Location>;
  states: Observable<Collection<Location>>;
  cities: Observable<Collection<Location>>;

  /**
   * @constructor
   * @param {UserService} userService Service for users management
   * @param {FormBuilder} fb Builds the form group for user's profile info
   * @param {Router} router Enables navigation from one view to the next
   * @param {LocationsService} locationsService Service for locations management
   * @param {AlertService} alertService Service for alerts management
   */
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private locationsService: LocationsService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      this.profile = p;
      // console.log(p)
      if (this.profile) {
        this.profile = this.userService.get(this.profile.ref.id);
        // console.log(this.profile);
        this.createProfileForm(p);
        // console.log(this.profileDetails.value);

      }
    });
    this.getCountries();
  }

  /**
   * Description: Creates the user's profile form to insert its personal info.
   * @author Jorge Del Castillo
   * @param {User} profile User's current profile
   * @returns void
   */
  createProfileForm(profile: User) {
    // console.log(profile);
    // console.log(profile.name);
    profile.phone = profile.phone ? profile.phone : ['', '', ''];
    this.profileDetails = this.fb.group({
      sendName: [profile.name ? profile.name : '', [Validators.required, Validators.pattern('[a-zA-ZñÑ\\s]*')]],
      sendLastName: [profile.lastname ? profile.lastname : '', [Validators.required, Validators.pattern('[a-zA-ZñÑ\\s]*')]],
      sendPhone1: [profile.phone[0] ? profile.phone[0] : '',
      [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(6)]],
      sendPhone2: [profile.phone[1] ? profile.phone[1] : '',
      [Validators.pattern('[0-9]*'), Validators.minLength(6)]],
      sendPhone3: [profile.phone[2] ? profile.phone[2] : '', [Validators.pattern('[0-9]*'), Validators.minLength(6)]],
      addressCountry: [profile.country ? profile.country : ''],
      addressState: [profile.state ? profile.state : ''],
      addressCity: [profile.city ? profile.city : ''],
      sendZipCode: [profile.zipcode ? profile.zipcode : '', [Validators.minLength(4), Validators.pattern('[0-9]*')]],
      sendAddress: [profile.address ? profile.address : '']
    });
    this.formControlValueChanges();
    // console.log(this.profileDetails.value);
    this.ready = true;
  }

  /**
   * Description: Controls changes in the selected country to show different states and in the selected state
   * to show different cities.
   * @author Jorge Del Castillo
   * @returns void
   */
  formControlValueChanges() {
    this.profileDetails.controls['addressCountry'].valueChanges.subscribe(
      (selectedValue) => {
        // console.log(selectedValue)
        this.country = selectedValue;
        this.getStates();
      }
    );
    this.profileDetails.controls['addressState'].valueChanges.subscribe(
      (selectedValue) => {
        this.state = selectedValue;
        this.getCities();
      }
    );
  }

  /**
   * Description: Subscribes to the available countries.
   * @author Jorge Del Castillo
   * @returns void
   */
  getCountries(): void {
    this.locationsService.getCountries().asObservable().subscribe((p) => {
      this.countries = p;
    });
  }

  /**
   * Description: Gets all the available states of the selected country.
   * @author Jorge Del Castillo
   * @returns void
   */
  getStates() {
    this.cities = undefined;
    this.city = '';
    this.state = '';
    this.states = this.country ? this.locationsService.getStates(this.country.__path).asObservable() : null;
  }

  /**
   * Description: Gets all the available cities of the selected state.
   * @author Jorge Del Castillo
   * @returns void
   */
  getCities() {
    this.city = '';
    this.cities = this.state ? this.locationsService.getCities(this.state.__path).asObservable() : null;
    if (this.cities) {
      this.cities.subscribe((c) => {
        if (c.iterable.length === 0) {
          this.cities = this.states;
          this.profileDetails.controls['addressCity'].setValue(this.state);
        }
      });
    }
  }

  /**
   * Description: Saves the new inserted values in the user's profile and updates the profile in the database.
   * @author Jorge Del Castillo
   * @returns void
   */
  save(): void {
    this.profile.name = this.profileDetails.value.sendName;
    this.profile.lastname = this.profileDetails.value.sendLastName;
    this.profile.phone = [this.profileDetails.value.sendPhone1, this.profileDetails.value.sendPhone2, this.profileDetails.value.sendPhone3];
    this.profile.country = this.profileDetails.value.addressCountry.name;
    this.profile.state = this.profileDetails.value.addressState.name;
    this.profile.city = this.profileDetails.value.addressCity.name;
    this.profile.zipcode = this.profileDetails.value.sendZipCode;
    this.profile.address = this.profileDetails.value.sendAddress;
    this.userService.update(this.profile).then(() => {
      this.alertService.showAlert('Profile updated.', 'alert-success');
      this.router.navigate(['/profile']);
    });
  }

  /**
   * Description: Resets the profile details form and navigates to the profile view.
   * @author Mauro Lanza
   * @returns void
   */
  cancel(): void {
    this.profileDetails.reset();
    this.router.navigate(['/profile']);
  }

}
