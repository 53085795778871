import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location as navLocation } from '@angular/common';
import { InventoryService } from '../../../services/inventory.service';
import { ProductService } from '../../../services/product.service';
import { AgencyService } from '../../../services/agency.service';
import { AlertService } from 'src/app/services/alert.service';
import { Product, Agency, Inventory } from 'src/app/models';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';


@Component({
  selector: 'app-inventory-create',
  templateUrl: './inventory-create.component.html',
  styleUrls: ['./inventory-create.component.scss']
})
export class InventoryCreateComponent implements OnInit {

  public inventoryForm: FormGroup;
  isLoading = false;
  public products: Observable<Collection<Product>>;
  public productsArray: any;
  public available_products: any[];
  public inventory_products: any;
  public agencies: Observable<Collection<Agency>>;
  public inventories: Observable<Collection<Inventory>>;
  public agency_path: string;
  public disabled = true;

  /**
   * @constructor
   * @param {FormBuilder} fb Creates a form group to create a new inventory
   * @param {navLocation} location Current navigation location
   * @param {InventoryService} inventoryService Service for inventories management
   * @param {ProductService} productService Service for products management
   * @param {AgencyService} agencyService Service for agencies management
   * @param {AlertService} alertService Service for alerts management
   */
  constructor(
    private fb: FormBuilder,
    private location: navLocation,
    private inventoryService: InventoryService,
    private productService: ProductService,
    private agencyService: AgencyService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.getResources();
  }

  /**
   * Description: Gets all the none deleted products as an observable, all the agencies, all the
   * inventories and creates a form to create a new inventory.
   * @author Mauro Lanza
   * @returns void
   */
  getResources() {
    this.isLoading = true;

    this.products = this.productService.getAll(ref => ref.where('deleted', '==', false)).asObservable();
    this.agencies = this.agencyService.getAll().asObservable();
    this.inventories = this.inventoryService.getAll().asObservable();
    this.createInventoryForm();

    this.isLoading = false;
  }

  /**
   * Description: Creates the form for the inventory.
   * @author Mauro Lanza
   * @returns void
   */
  createInventoryForm() {
    this.inventoryForm = this.fb.group({
      agency_path: ['', [Validators.required]],
      product_path: ['', [Validators.required]],
    });
    this.formControlValueChanges();
  }

  /**
   * Description: Subscribes to any change in the agency selected. If the agency selected changes,
   * the current inventory's agency path is set with the value of the new selected agency and gets
   * the products of the inventory of the new selected agency.
   * @author Mauro Lanza
   * @returns void
   */
  formControlValueChanges() {
    this.inventoryForm.controls['agency_path'].valueChanges.subscribe(
      (selectedValue) => {
        if (selectedValue) {
          this.agency_path = selectedValue;
          this.disabled = true;
          this.getProducts();
        }
      }
    );
  }


  /**
   * Description: Compares the products of the current inventory with the products of the new one
   * every time the agency path changes.
   * @author Mauro Lanza
   * @param {any} otherArray Products of the new inventory
   * @returns {Boolean}
   */
  comparer(otherArray) {
    return function (current) {
      return otherArray.filter(function (other) {
        return other.__path === current.__path;
      }).length === 0;
    };
  }

  /**
   * Description: Gets all the products that are not marked as deleted and that correspond to the
   * selected agency.
   * @author Mauro Lanza
   * @returns void
   */
  getProducts() {
    this.productService.getAll(ref => {
      return ref.where('deleted', '==', false)
        .where('agencies', 'array-contains', this.agency_path);
    }).asObservable().subscribe(i => {

      this.inventory_products = i.iterable;

      this.productService.getAll(ref => {
        return ref.where('deleted', '==', false);
      }).asObservable().subscribe(j => {

        this.productsArray = j.iterable;

        this.available_products = this.productsArray.filter(this.comparer(this.inventory_products));

      });
    });

  }

  /**
   * Description: Creates the new inventory with the values in the inventory form. If it is created
   * successfully, shows a success alert. If there is an error, handles it and alerts the user.
   * @author Mauro Lanza
   * @returns void
   */
  save() {
    try {
      this.isLoading = true;

      const data = this.inventoryForm.value;
      data.available = true;
      data.stock = 0;
      data.authorized = true;
      data.warning_low = 50;
      data.warning_hi = 100;

      this.inventoryService.create(data).then(() => {
        this.alertService.showAlert('Inventory created successfully', 'alert-success');
        this.inventoryForm.reset();
        this.isLoading = false;
      });

    } catch (error) {
      console.error(error);
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
    this.isLoading = false;
  }

  /**
   * Description: Resets the inventory form's values and navigates to the previous navigation location.
   * @author Mauro Lanza
   * @returns void
   */
  cancel(): void {
    this.inventoryForm.reset();
    this.location.back();
  }

}
