import { imageLoad } from 'src/app/utils/image-loader';
import { convertImgToBase64URLOneThree } from 'src/app/utils/image-to-base64';

export interface MoneyOrderDetailPDFModel {

    agencyLogo: string;

    id: string;
    createdAt: string;

    sender: {
        name: string;
        email: string;
        number: string;
        stateCountry: string;
        address: string;
        city: string;
    };

    recipient: {
        name: string;
        email: string;
        number: string;
        stateCountry: string;
        address: string;
        city: string;
    };

    agency: {
        name: string;
        email: string;
        number: string;
        stateCountry: string;
        address: string;
    };

    amount: string;
}

export async function parseMoneyOrderDetail(model: MoneyOrderDetailPDFModel) {



    const img = await imageLoad(model.agencyLogo);
    const base64 = convertImgToBase64URLOneThree(img);

    const dd = {
        pageMargins: [20, 20, 20, 80],
        pageSize: 'LETTER',
        content: [
            {
                columns: [
                    {
                        style: 'tableHeader',
                        table: {
                            widths: ['auto', '*', 'auto'],
                            body: [
                                [
                                    { colSpan: 2, text: model.id, style: 'title' },
                                    null,
                                    {
                                        rowSpan: 2,
                                        width: 'auto',
                                        image: base64,
                                        fit: [180, 60]
                                    }
                                ],
                                [
                                    { text: [{ text: 'Amount: ', style: 'header' }, model.amount] },
                                    model.createdAt ? { text: [{ text: 'Date: ', style: 'header' }, model.createdAt] } : null,
                                    null
                                ]
                            ]
                        },
                        layout: 'noBorders'
                    }
                ]
            },
            {
                canvas: [
                    { type: 'line', x1: 0, y1: 5, x2: 570, y2: 5, lineWidth: 2 }
                ],
                margin: [0, 0, 0, 5]
            },
            {
                columns: [
                    {
                        text: [
                            { text: 'Sender:\n', style: 'header' },
                            { text: model.sender.name + '\n', style: 'default' },
                            { text: model.sender.email ? model.sender.email + '\n' : '', style: 'default' },
                            { text: model.sender.number + '\n', style: 'default' },
                            { text: model.sender.stateCountry + '\n', style: 'default' },
                            { text: 'Address:\n', style: 'header' },
                            { text: model.sender.address, style: 'default' }
                        ]
                    },
                    {
                        text: [
                            { text: 'Recipient:\n', style: 'header' },
                            { text: model.recipient.name + '\n', style: 'default' },
                            { text: model.recipient.email ? model.recipient.email + '\n' : '', style: 'default' },
                            { text: model.recipient.number + '\n', style: 'default' },
                            { text: model.recipient.stateCountry + '\n', style: 'default' },
                            { text: 'Address:\n', style: 'header' },
                            { text: model.recipient.address, style: 'default' }
                        ]
                    },
                    {
                        text: [
                            { text: 'Shipping agency:\n', style: 'header' },
                            { text: model.agency.name + '\n', style: 'default' },
                            { text: model.agency.email ? model.agency.email + '\n' : '', style: 'default' },
                            { text: model.agency.number + '\n', style: 'default' },
                            { text: model.agency.stateCountry + '\n', style: 'default' },
                            { text: model.agency.address, style: 'default' }
                        ]
                    }
                ]
            },
            '\n',
            {
                canvas: [
                    { type: 'line', x1: -200, y1: 0, x2: 600, y2: 0, lineWidth: 1, dash: { length: 3 } }
                ]
            },
            '\n',
            {
                columns: [
                    {
                        style: 'tableHeader',
                        table: {
                            widths: ['auto', '*'],
                            body: [
                                [
                                    { colSpan: 2, text: model.id, style: 'titleEnterprise' }, ''
                                ],
                                [
                                    { text: [{ text: 'Amount: ', style: 'header' }, model.amount] },
                                    model.createdAt ? { text: [{ text: 'Date: ', style: 'header' }, model.createdAt] } : null,
                                ]
                            ]
                        },
                        layout: 'noBorders'
                    }
                ]
            },
            {
                canvas: [
                    { type: 'line', x1: 0, y1: 5, x2: 570, y2: 5, lineWidth: 2 }
                ],
                margin: [0, 0, 0, 5]
            },
            {
                columns: [
                    {
                        text: [
                            { text: 'Sender:\n', style: 'header' },
                            { text: model.sender.name + '\n', style: 'default' },
                            { text: model.sender.email ? model.sender.email + '\n' : '', style: 'default' },
                            { text: model.sender.number + '\n', style: 'default' },
                            { text: model.sender.stateCountry + '\n', style: 'default' },
                            { text: 'Address:\n', style: 'header' },
                            { text: model.sender.address, style: 'default' }
                        ]
                    },
                    {
                        text: [
                            { text: 'Recipient:\n', style: 'header' },
                            { text: model.recipient.name + '\n', style: 'default' },
                            { text: model.recipient.email ? model.recipient.email + '\n' : '', style: 'default' },
                            { text: model.recipient.number + '\n', style: 'default' },
                            { text: model.recipient.stateCountry + '\n', style: 'default' },
                            { text: 'Address:\n', style: 'header' },
                            { text: model.recipient.address, style: 'default' }
                        ]
                    },
                    {
                        text: [
                            { text: 'Shipping agency:\n', style: 'header' },
                            { text: model.agency.name + '\n', style: 'default' },
                            { text: model.agency.email ? model.agency.email + '\n' : '', style: 'default' },
                            { text: model.agency.number + '\n', style: 'default' },
                            { text: model.agency.stateCountry + '\n', style: 'default' },
                            { text: model.agency.address, style: 'default' }
                        ]
                    }
                ],
                margin: [0, 0, 0, 5]
            },
            {
                table: {
                    dontBreakRows: true,
                    body: [
                        [
                            {
                                text: 'El cliente declara que dentro de la caja no envia dinero documentos'
                                    + ' (cedula, pasaporte, tarjeta de credito, explosivos, armas o articulos prohibidos) en caso que'
                                    + ' su carga sea decomisada por autoridades aduanales, la empresa Ccargaxpress no'
                                    + ' se hace responsable por dicho decomiso, el empaque de la carga es responsibilidad'
                                    + ' unica del cliente, en caso de perdida total de su carga (Robo o extravio) se aplicara'
                                    + ' el reintegro del monto asegurado por el Sender (toda carga debe ir asegurada por un monto '
                                    + ' minimo de 100 Dolares) de no asegurarla la empresa no respondra por '
                                    + ' el valor del contenido de la misma, el seguro no paga'
                                    + ' perdidas parciales solo perdidas totales.\n\n',
                                style: 'terms'
                            }
                        ],
                        [
                            {
                                text: 'ACEPTO LOS TERMINOS Y CONDICIONES DE CCARGAXPRESS:',
                                style: 'terms',
                                alignment: 'center'
                            }
                        ],
                        [
                            { text: '\n____________________________', style: 'center' }
                        ],
                        [
                            { text: 'FIRMA DEL CLIENTE', style: 'terms', alignment: 'center' }
                        ]
                    ]
                },
                layout: 'noBorders'
            },
            '\n',
            {
                canvas: [
                    { type: 'line', x1: -200, y1: 0, x2: 600, y2: 0, lineWidth: 1, dash: { length: 3 } }
                ]
            },
            '\n',
            {
                columns: [
                    {
                        style: 'tableHeader',
                        table: {
                            widths: ['auto', '*', 'auto'],
                            body: [
                                [
                                    { colSpan: 2, text: model.id, style: 'title' },
                                    null,
                                    {
                                        rowSpan: 2,
                                        width: 'auto',
                                        image: base64,
                                        fit: [180, 60]
                                    }
                                ],
                                [
                                    { text: [{ text: 'Amount: ', style: 'header' }, model.amount] },
                                    model.createdAt ? { text: [{ text: 'Date: ', style: 'header' }, model.createdAt] } : null,
                                    null
                                ]
                            ]
                        },
                        layout: 'noBorders'
                    }
                ]
            },
            {
                columns: [
                    {
                        style: 'labelTable',
                        table: {
                            widths: ['auto', '*', '*'],
                            body: [
                                [
                                    '',
                                    { text: 'Sender', style: 'header' },
                                    { text: 'Recipient', style: 'header' }
                                ],
                                [
                                    { text: 'Name', style: 'header' },
                                    model.sender.name,
                                    model.recipient.name
                                ],
                                [
                                    { text: 'Location', style: 'header' },
                                    model.sender.stateCountry + ' - ' + model.sender.city,
                                    model.recipient.stateCountry + ' - ' + model.recipient.city,
                                ]
                            ]
                        },
                        layout: 'lightHorizontalLines'
                    }
                ]
            },
        ],

        styles: {
            default: {
                fontSize: 10,
            },
            tableHeader: {

            },
            title: {
                bold: true,
                fontSize: 12,
                color: 'black',
                margin: [0, 25, 0, 0]
            },
            titleEnterprise: {
                bold: true,
                fontSize: 12,
                color: 'black',
            },
            header: {
                bold: true,
                fontSize: 11,
                color: 'black'
            },
            bold: {
                bold: true
            },
            terms: {
                fontSize: 7,
                alignment: 'justify'
            },
            center: {
                alignment: 'center'
            },
            small: {
                fontSize: 8
            },
            tableExample: {
                margin: [0, 5, 0, 15],
                alignment: 'right'
            },
            shipper: {
                fontSize: 20,
                color: '#7abd25'
            },
            ident: {
                fontSize: 20,
                color: 'purple'
            },
            labelTable: {
                alignment: 'center'
            },
        }
    };

    return dd;
}
