import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PersonService } from '../../services/person.service';
import { OrderService } from '../../services/order.service';
import { UserService } from '../../services/user.service';
import { Person, Order, User, Rate } from '../../models';
import { RateService } from '../../services/rate.service';
import { LocationsService } from '../../services/locations.service';
import { PromotionService } from '../../services/promotion.service';
import { AlertService } from '../../services/alert.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { debounce, debounceTime } from 'rxjs/operators';
import { timer } from 'rxjs';
import { iRateValues } from 'src/app/models/rate';
import { PromotionType } from 'src/app/models/promotion';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {
  [x: string]: any;

  public promotionType = PromotionType;

  // Forms
  public orderForm: FormGroup;
  public BoxForm: FormGroup;
  public commentForm: FormGroup;
  public dropdown: any;
  public changeButtonText = 0;
  public changeButtonText2 = 0;
  public frqSendersData: Person[];
  // public frqRecipientsData: Person[];
  public orderData: Order;
  public profile: User;
  public agencyRates: Rate[];
  public globalRates: Rate[];
  public addressLocationRate: Rate;
  public promotionDescription = '';
  public promotionFound = 'none';
  public promotionPromo: number;
  public promotionExpired = 'no';

  // public promotionUntil: Timestamp;
  opcionSeleccionado: number;
  verSeleccion: number;
  box = '';
  cajaDisable: boolean;
  priceBox: number;
  priceBoxes: number;
  // senderRef: Person;
  senderRef: { doc: Person; ref: firebase.firestore.DocumentReference; };
  // recipientRef: Person | any;
  recipientRef: { doc: Person; ref: firebase.firestore.DocumentReference; };
  addressesRef: Person | any;
  boxes = [];
  shippingCost = 0;
  privateShippingCost = 0;
  insurance = 0;
  countryTax = 0;
  packingService = 0;
  total = 0;
  frqUserID = '';
  frqSender: any;
  frqRecipient: any;
  frqAddress: any;
  frqUserID2 = '';
  public phone1: string;
  public promocionvalue1: string;
  public Packing_Servicesvalue2: string;
  public Packagevalue: string;
  public promocionvalue2: string;
  public promocionvalue3: string;
  public onClickYes1: string;
  public onClickYes = '1';
  public sumaPrueba = 0;
  isLoading: boolean;
  agencyPath: string;
  addressesForm: FormGroup;
  country: any;
  state: any;
  countries: any;
  cities: any;
  city: string;
  states: any;

  /**
   * @constructor
   * @param {FormBuilder} fb Creates forms quickly and manages them
   */
  constructor(
    private fb: FormBuilder,
    private personService: PersonService,
    private orderService: OrderService,
    private userService: UserService,
    private datePipe: DatePipe,
    private rateService: RateService,
    private locationsService: LocationsService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.phone1 = '',
      this.promocionvalue1 = '',
      this.Packing_Servicesvalue2 = '',
      this.Packagevalue = '',
      this.promocionvalue2 = '';
    this.promocionvalue3 = '';
    this.onClickYes1 = '';
    this.onClickYes = '1';
  }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      if (p) {
        this.profile = p;
        this.agencyPath = this.profile.agency_path;
        this.fetchRates();
      }
    });
    this.createAddressesForm();
    this.getCountries();
    this.fetchGlobalRates();
    this.createOrderForm();
    this.createBoxForm();
    this.createCommentForm();
  }

  /**
   * Description: Creates the form for order comments.
   * @author Maximiliano Casale
   * @returns void
   */
  createCommentForm() {
    this.commentForm = this.fb.group({
      comment: ['']
    });
  }

  /**
   * Description: Creates form for order values.
   * @author Maximiliano Casale
   * @returns void
   */
  createOrderForm() {
    this.orderForm = this.fb.group({
      promotion: [''],
      promotionValue1: ['', [Validators.pattern('[0-9].*')]],
      promotionValue2: [''],
      packageAerial: [0],
      packageMaritime: [0],
      shippingDate: [''],
      shippingType: ['']
    });
    this.orderForm.controls['shippingType'].valueChanges.subscribe((s) => {
      this.shippingType = s;
      this.reCalcBoxesPrice(s);
    });
  }

  /**
   * Description: Creates form for the boxes.
   * @author Maximiliano Casale
   * @returns void
   */
  createBoxForm() {
    this.BoxForm = this.fb.group({
      packing: [''],
      packingValue: [0, [Validators.pattern('[0-9].*')]],
      insuredValue: [0, [Validators.required, Validators.min(0), Validators.pattern('[0-9.]*')]],
      declaredValue: ['', [Validators.required, Validators.pattern('[0-9.]*')]],
      describeContent: ['', [Validators.required]],
      dimHeight: ['', [Validators.required, Validators.pattern('[0-9.]*')]],
      dimWidth: ['', [Validators.required, Validators.pattern('[0-9.]*')]],
      dimLength: ['', [Validators.required, Validators.pattern('[0-9.]*')]],
      weight: ['', [Validators.required, Validators.pattern('[0-9.]*')]],
      privateShippingCost: [''],
      priceBox: [''],
      tax: [''],
      insuranceCost: [''],
      subtotal: [''],
      priceBoxes: [''],
      promotion: [''],
      promotionType: [PromotionType.NONE],
      promotionValue: ['', [Validators.pattern('^(?:(?=[.]*)([0-9]*[.][0-9]+)|([0-9]+))$')]],
      promotionCode: [''],
      promotionObject: [null],
    });
    this.BoxForm.get('promotionType').valueChanges.subscribe((t) => {
      if (t !== PromotionType.COUPON) {
        this.BoxForm.get('promotionObject').setValue(null);
      }
    });

    this.BoxForm.get('promotionCode').valueChanges.pipe(debounceTime(1000)).subscribe((code) => {
      console.log('Changes', code);
      firebase.firestore().collection('/promotions/').where('name', '==', code).limit(1).get().then((p) => {
        if (!p.empty && p.docs[0]) {
          const promo = p.docs[0].data();
          promo.expired = new Date(promo.end_date) <= firebase.firestore.Timestamp.now().toDate();
          this.BoxForm.get('promotionObject').setValue(promo);
        } else {
          this.BoxForm.get('promotionObject').setValue(null);
        }
      });
    });

  }

  // Rates

  /**
   * Description: Fetches the rates of the agency of the current user.
   * @author Maximiliano Casale
   * @returns void
   */
  public fetchRates(): void {
    if (this.profile) {
      const agency_path = this.profile.agency_path;
      this.rateService.getAll((ref) => {
        return ref.where('agency_path', '==', agency_path);
      }).asObservable().subscribe(async (rates) => {
        this.agencyRates = rates.iterable;
        // console.log('AGENCY RATES', this.agencyRates);
      });
    }
  }

  /**
   * Description: Fetches global rates.
   * @author Maximiliano Casale
   * @returns void
   */
  public fetchGlobalRates() {
    this.rateService.getAll((ref) => {
      return ref.where('type', '==', 'Global');
    }).asObservable().subscribe(async (p) => {
      this.globalRates = await p.iterable;
      // console.log('Global rates', p.iterable)
    });
  }

  /**
   * Description: Creates a new form group for an address.
   * @author Sarkis
   * @returns void
   */
  createAddressesForm() {
    this.addressesForm = this.fb.group({
      addressCountry: ['', [Validators.required]],
      addressState: ['', [Validators.required]],
      addressCity: ['', [Validators.required]],
    });
    this.formControlValueChanges();
  }

  /**
     * Description: Controls the changes of the Shipping Address' form values.
     * @author Sarkis
     * @returns void
     */
  formControlValueChanges() {
    this.addressesForm.valueChanges.subscribe(
      (selectedValue) => {
        if (selectedValue.addressAvailability === 'Residential') {
          this.addressesForm.get('addressBusinessHours').setValidators([]);
          this.addressesForm.get('addressBusinessHours').updateValueAndValidity({ onlySelf: false, emitEvent: false });

          // selectedValue.addressBusinessHours = '';
        } else if (selectedValue.addressAvailability === 'Office') {
          this.addressesForm.get('addressBusinessHours').setValidators([Validators.required]);
          this.addressesForm.get('addressBusinessHours').updateValueAndValidity({ onlySelf: false, emitEvent: false });
        }
        // console.log(selectedValue);
      }
    );

    this.addressesForm.controls['addressCountry'].valueChanges.subscribe(
      (selectedValue) => {
        this.country = selectedValue;
        this.getStates();
        this.pickRate();
      }
    );

    this.addressesForm.controls['addressState'].valueChanges.subscribe(
      (selectedValue) => {
        this.state = selectedValue;
        this.getCities();
        this.pickRate();
      }
    );

    this.addressesForm.controls['addressCity'].valueChanges.subscribe(
      (selectedValue) => {
        this.city = selectedValue;
        this.pickRate();
      }
    );
  }

  /**
   * Description: Gets the available countries.
   * @author Sarkis
   * @returns void
   */
  getCountries(): void {
    this.locationsService.getCountries((ref) => ref.where('display', '==', true)).asObservable().subscribe((p) => {
      this.countries = p;
    });
  }

  /**
   * Description: Displays the states of the selected country.
   * @author Sarkis
   * @returns void
   */
  getStates() {
    this.cities = undefined;
    this.city = '';
    this.state = '';
    this.states = this.country ? this.locationsService.getStates(this.country).asObservable() : null;
  }

  /**
   * Description: Displays the cities of the selected state.
   * @author Sarkis
   * @returns void
   */
  getCities() {
    this.city = '';
    this.cities = this.state ? this.locationsService.getCities(this.state).asObservable() : null;
    if (this.cities) {
      this.cities.subscribe((c) => {
        if (c.iterable.length === 0) {
          this.cities = this.states;
          this.addressesForm.controls['addressCity'].setValue(this.state);
        }
      });
    }
  }

  /**
   * Description: Picks the rate needed for the current selection of location..
   * @author Maximiliano Casale
   * @returns void
   */
  public pickRate() {
    let pickedRate: Rate;
    let rateByState: Rate;
    let rateByCountry: Rate;
    let rateByNone: Rate;

    const country = this.country;
    const state = this.state;
    const city = this.city;

    if (this.agencyRates) {
      for (let i = 0; i < this.agencyRates.length; i++) {
        for (let j = 0; j < this.agencyRates[i].locations.length; j++) {
          if (this.agencyRates[i].locations[j].country === country) {
            if (this.agencyRates[i].locations[j].state === state) {
              if (this.agencyRates[i].locations[j].city === city) {
                pickedRate = this.agencyRates[i]; // If the exact location is found save the rate aside and break the loops.
                // j = this.agencyRates[i].locations.length;
                i = this.agencyRates.length;
                break;
              } else if (this.agencyRates[i].locations[j].city === 'All') {
                // If the state is found but the city is All, save it and keep looking
                rateByState = this.agencyRates[i];  // Save the rate in a set to avoid saving duplicate rates.
              }// else just ignore
            } else if (this.agencyRates[i].locations[j].state === 'All') {
              // If the country is found but the state is All, save it and keep looking
              rateByCountry = this.agencyRates[i];
              // console.log('si pickeo!!!!!!!');

            }// else just ignore
          } else if (this.agencyRates[i].locations[j].country === 'All') {
            rateByNone = this.agencyRates[i];
            // console.warn(rateByNone);
          }
        }
      }
    }
    // End of filtering.
    if (!pickedRate) {
      if (!rateByState) {
        if (!rateByCountry) {
          if (!rateByNone) {
            // console.log('no consegui nada local, voy por global')
            this.pickGlobalRate();
          } else {
            this.addressLocationRate = rateByNone;
            // console.log('consegui by local none', this.addressLocationRate)
          }
        } else {
          this.addressLocationRate = rateByCountry; // if theres no rate by state use by country
          // console.log('consegui by local country', this.addressLocationRate)

        }
      } else {
        this.addressLocationRate = rateByState; // if theres no rate by city use by state
        // console.log('consegui by local state', this.addressLocationRate)

      }
    } else {
      this.addressLocationRate = pickedRate;
      // console.log('consegui perfect match', this.addressLocationRate)

    }

    this.BoxForm.get('insuredValue').setValidators([
      Validators.required,
      Validators.pattern('[0-9.]*'),
      Validators.min(this.addressLocationRate.minimum_insurance)
    ]);
    this.BoxForm.get('insuredValue').updateValueAndValidity();

    this.reCalcBoxesPrice(this.shippingType);
  }

  /**
   * Description: If there is no local rate, picks the global rate.
   * @author Maximiliano Casale
   * @returns void
   */
  public pickGlobalRate() {
    let pickedRate: Rate;
    let rateByState: Rate;
    let rateByCountry: Rate;
    let rateByNone: Rate;

    const country = this.country;
    const state = this.state;
    const city = this.city;
    // console.log(this.globalRates)
    if (this.globalRates) {
      for (let i = 0; i < this.globalRates.length; i++) {
        for (let j = 0; j < this.globalRates[i].locations.length; j++) {
          if (this.globalRates[i].locations[j].country === country) {
            if (this.globalRates[i].locations[j].state === state) {
              if (this.globalRates[i].locations[j].city === city) {
                pickedRate = this.globalRates[i]; // If the exact location is found save the rate aside and break the loops.
                // j = this.globalRates[i].locations.length;
                i = this.globalRates.length;
                break;
              } else if (this.globalRates[i].locations[j].city === 'All') {
                // If the state is found but the city is All, save it and keep looking
                rateByState = this.globalRates[i];  // Save the rate in a set to avoid saving duplicate rates.
              }// else just ignore
            } else if (this.globalRates[i].locations[j].state === 'All') {
              // If the country is found but the state is All, save it and keep looking
              rateByCountry = this.globalRates[i];
              // console.log('si picke3o!!!!!!!');
            }// else just ignore
          } else if (this.globalRates[i].locations[j].country === 'All') {
            rateByNone = this.globalRates[i];
            // console.warn(rateByNone);
          }
        }
      }
    }
    // End of filtering.
    // console.log('empiezo a filtrar')
    if (!pickedRate) {
      if (!rateByState) {
        if (!rateByCountry) {
          if (!rateByNone) {
            // console.log('no consegui nada global, QUE LE PASO A DEFAULT');
          } else {
            this.addressLocationRate = rateByNone;
            // console.log('consegui by global none', this.addressLocationRate)
          }
        } else {
          this.addressLocationRate = rateByCountry; // if theres no rate by state use by country
          // console.log('consegui by global country', this.addressLocationRate)
        }
      } else {
        this.addressLocationRate = rateByState; // if theres no rate by city use by state
        // console.log('consegui by global state', this.addressLocationRate)
      }
    } else {
      this.addressLocationRate = pickedRate;
      // console.log('consegui todo')
    }
  }

  /**
   * Description: Resets the box form when the RESET button is clicked.
   * @author Rafael Quintero
   * @returns void
   */
  resetBox() {
    this.orderForm.reset();
    this.BoxForm.reset();
  }

  /**
   * Description: Adds zeros to the beginning of a string.
   * @author Rafael Quintero
   * @param {any} bareNum Number to add zeros if it has less than 6 digits
   * @returns {string} The barenum with the zeros as a string
   */
  padNum(bareNum) {
    return '000000'.substring(bareNum.length, 6) + bareNum.toString();
  }

  /**
   * Description: Deletes the selected box to delete from the order in construction.
   * @author Maximiliano Casale
   * @param {any} i Box to delete
   * @returns void
   */
  delete(i) {
    const box = i;
    this.shippingCost -= box[0].priceBox;
    this.privateShippingCost += box[0].privateShippingCost;
    this.insurance -= box[0].insuranceCost;
    this.countryTax -= box[0].tax;
    this.packingService -= box[0].packingValue;

    this.total = Math.ceil(this.shippingCost + this.insurance + this.countryTax + this.packingService);
  }

  /**
   * Description: Calculates the price of a box, depending on the promotion and the shipping type.
   * @author Alejandro Font
   * @param {any} box Box to which the shipping price wants to be calculated
   * @param {any} shippingType The shipping method can be aerial or maritime
   * @returns {any} Price of the box
   */
  priceCalc(box, shippingType): any {
    // obj to save the results
    const priceObj: any = {};

    // variables used to calculate
    let rate: iRateValues;
    let volume: number;
    if (shippingType === 'Maritime') {
      rate = this.addressLocationRate.maritimeRate;
      // calculate maritime volume
      volume = (Number(box.dimHeight) * Number(box.dimWidth) * Number(box.dimLength)) / 1728;
      volume = (rate.minPounds < volume) ? volume : rate.minPounds;
    } else {
      rate = this.addressLocationRate.aerialRate;
      // calculate aerial volume
      volume = (Number(box.dimHeight) * Number(box.dimWidth) * Number(box.dimLength)) / 166;
      volume = (box.weight < volume) ? volume : box.weight;
      volume = (rate.minPounds < volume) ? volume : rate.minPounds;
    }

    let packingValue;
    if (box.packing === 'YES') {
      packingValue = +box.packingValue;
    } else {
      packingValue = 0;
    }

    // Calculates cost depends on promotion
    let shippingCost, privateShippingCost;
    if (box.promotionType === PromotionType.VALUE) {
      shippingCost = Number(box.promotionValue);
      privateShippingCost = Number(box.promotionValue);
    } else if (box.promotionType === PromotionType.COUPON) {
      if (box.promotionObject.type = '%') {
        shippingCost = Math.floor(volume * rate.public * (1 - box.promotionObject.value / 100) * 10000) / 10000;
        privateShippingCost = Math.floor(volume * rate.private * (1 - box.promotionObject.value / 100) * 10000) / 10000;
      } else {
        shippingCost = (volume * rate.public) - box.promotionObject.value;
        privateShippingCost = (volume * rate.private) - box.promotionObject.value;
      }
    } else {
      shippingCost = (volume * rate.public);
      privateShippingCost = (volume * rate.private);
    }

    // Calculates insurance minimun value of 100$ a box
    let insuranceCost = 0;
    if (+box.insuredValue < this.addressLocationRate.minimum_insurance) {
      box.insuredValue = this.addressLocationRate.minimum_insurance;
    }

    // Fix floating point rounding errors
    insuranceCost = Math.floor((+box.insuredValue) * (this.addressLocationRate.insurance / 100) * 10000) / 10000;


    // Calculates Tax
    const tax = box.declaredValue * (rate.tax / 100);

    // Calculates subtotal
    const subtotal = shippingCost + insuranceCost + tax;

    // Sets values with math ceil to obj to return results
    priceObj.shippingCost = shippingCost > 0 ? Math.ceil(shippingCost) : 0;
    priceObj.privateShippingCost = privateShippingCost > 0 ? Math.ceil(privateShippingCost) : 0;
    priceObj.insuranceCost = Math.ceil(insuranceCost);
    priceObj.tax = Math.ceil(tax);
    priceObj.subtotal = Math.ceil(subtotal);
    priceObj.packingValue = Math.ceil(packingValue);
    return priceObj;
  }

  /**
   * Description: Recalculates the boxes price if the shipping type changes.
   * @author Maximiliano Casale
   * @param {string} shippingType The shipping method can be aerial or maritime
   * @returns void
   */
  reCalcBoxesPrice(shippingType: string) {
    if (this.boxes.length > 0) {

      this.shippingCost = 0;
      this.privateShippingCost = 0;
      this.insurance = 0;
      this.countryTax = 0;
      this.packingService = 0;

      this.boxes.forEach(box => {
        const priceObj = this.priceCalc(box, shippingType);
        box.priceBox = priceObj.shippingCost;
        box.tax = priceObj.tax;
        box.insuranceCost = priceObj.insuranceCost;
        box.subtotal = priceObj.subtotal;
        box.privateShippingCost = priceObj.privateShippingCost;

        this.shippingCost += priceObj.shippingCost;
        this.privateShippingCost += priceObj.privateShippingCost;
        this.insurance += priceObj.insuranceCost;
        this.countryTax += priceObj.tax;
        this.packingService += priceObj.packingValue;
      });

      this.total = Math.ceil(this.shippingCost + this.insurance + this.countryTax + this.packingService);
    }
  }

  /**
   * Description: Adds a box to the order.
   * @author Maximiliano Casale
   * @returns void
   */
  addBox() {
    const priceObj = this.priceCalc(this.BoxForm.value, this.orderForm.value.shippingType);

    this.BoxForm.controls['priceBox'].setValue(priceObj.shippingCost);
    this.BoxForm.controls['tax'].setValue(priceObj.tax);
    this.BoxForm.controls['insuranceCost'].setValue(priceObj.insuranceCost);
    this.BoxForm.controls['subtotal'].setValue(priceObj.subtotal);
    this.BoxForm.controls['privateShippingCost'].setValue(priceObj.privateShippingCost);
    this.BoxForm.controls['packingValue'].setValue(priceObj.packingValue);

    this.boxes.push(this.BoxForm.value);

    this.shippingCost += priceObj.shippingCost;
    this.privateShippingCost += priceObj.privateShippingCost;
    this.insurance += priceObj.insuranceCost;
    this.countryTax += priceObj.tax;
    this.packingService += priceObj.packingValue;

    this.total = Math.ceil(this.shippingCost + this.insurance + this.countryTax + this.packingService);

    this.Packing_Servicesvalue2 = null;
    this.BoxForm.reset();
    this.changeButtonText = 1;
  }

  /**
   * Description: Returns boolean to know if there are any boxes in the order.
   * @author Maximiliano Casale
   * @returns {boolean}
   */
  boxesEmpty(): boolean {
    return (this.boxes.length > 0) ? false : true;
  }
}
