import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OrderService } from '../../services/order.service';
import { UserService } from '../../services/user.service';
import { Order } from '../../models/order';
import { User, Agency } from '../../models';
import { Collection } from 'aether-blaze';
import { Observable, Subscription } from 'rxjs';
import { AgencyService } from '../../services/agency.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { DateClrDatagridFilterInterfaceComponent } from '../../components/datagrid-filters/date-filter.component';
import { ManifestClrDatagridFilterInterfaceComponent } from '../../components/datagrid-filters/manifest-filter.component';
import { CheckboxClrDatagridFilterInterfaceComponent } from '../../components/datagrid-filters/checkbox-filter.component';
import { Router } from '@angular/router';
import { ManifestService } from 'src/app/services/manifest.service';
import { DatePipe } from '@angular/common';
import { LocationsService } from 'src/app/services/locations.service';

@Component({
  selector: 'app-money-order-list',
  templateUrl: './money-order-list.component.html',
  styleUrls: ['./money-order-list.component.scss']
})
export class MoneyOrderListComponent implements OnInit {

  // interactive inputs
  public toggle = true;
  public loading = true;
  public fetching = false;

  public filtersForm: FormGroup; // Filters

  // private user: User;
  private user: User;
  // private orders: Observable<Collection<Order>>;
  public orders: Observable<Collection<Order>>;
  private allOrders: Observable<Collection<Order>>;

  public selected = [];  // items selected array

  selectedCountry: string;
  selectedState: string;
  selectedCity: string;
  cities: any;
  states: any;
  countries: any;

  agencies: Array<Agency>;

  agencyStates: any;
  senderStates: any;
  recipientStates: any;
  agencyCities: any;
  senderCities: any;
  recipientCities: any;

  activeFilters: any;
  pagination = {
    currentPage: 0,
    pageSize: 10,
    lastPage: 0,
  }

  /**
   * @constructor
   * @param {FormBuilder} fb Form builder to create a filter's form
   * @param {OrderService} orderService Service for orders management
   * @param {UserService} userService Service for users management
   * @param {AgencyService} agencyService Service for agencies management
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks
   * @param {ManifestService} manifestService Service for manifests management
   * @param {DatePipe} datePipe Formats a date value according to locale rules
   * @param {LocationsService} locationsService Service for locations management
   */
  constructor(
    private fb: FormBuilder,
    private orderService: OrderService,
    private userService: UserService,
    private agencyService: AgencyService,
    private router: Router,
    private manifestService: ManifestService,
    private datePipe: DatePipe,
    private locationsService: LocationsService,
  ) { }

  ngOnInit() {
    this.getCountries();
    this.createFormFilter();
    this.fetchOrders();
    this.getAgencies();
  }

  /**
   * Description: Gets the agencies as an observable.
   * @author Maximiliano Casale
   * @returns void
   */
  public getAgencies() {
    this.agencyService.getAll().asObservable().subscribe(a => {
      this.agencies = a.iterable;
    })
  }

  /**
   * Description: Fetches all the money orders if the current user's role is admin (global manager).
   * If it is not admin, it fetches only the money orders of the current user's agency.
   * @author Maximiliano Casale
   * @returns void
   */
  public fetchOrders() {
    this.userService.userProfile.subscribe(p => {
      this.user = p;
      if (this.user.role === 'admin') { // if it is a global manager
        this.orders = this.orderService.getAll((ref) => ref.where('type', '==', 'money').where('manifest', '==', '???').orderBy('number', 'desc')).asObservable();
        // this.allOrders.subscribe(async (o) => {
        //   this.orders = await o.iterable;
        // });
        this.loading = false;
      } else { // if it is not,
        // query with where agency_path == this.user.agency_path;
        this.orders = this.orderService.getAll((ref) => ref.where('type', '==', 'money').where('manifest', '==', '???').where('agency_path', '==', p.agency_path).orderBy('number', 'desc')).asObservable();
        // this.allOrders.subscribe(async (o) => {
        //   // console.log('orders:', o);
        //   this.orders = await o.iterable;
        // });
        this.loading = false;
      }
    });
    console.log(this.pagination);
  }

  /**
   * Description: Resets filter's form.
   * @author Maximiliano Casale
   * @returns void
   */
  public reset(): void {
    this.filtersForm.reset();
    this.searchFiltered();
  }

  /**
   * Description: Requests the server the items that match the criteria in the filters.
   * @author Maximiliano Casale
   * @returns void
   */
  public refresh(state: ClrDatagridStateInterface): void {

    // mapping of the filters
    const filters: { [prop: string]: any[] } = {};
    if (state.filters) {  // if there are any filters we map them to the array
      for (const filter of state.filters) {
        if (filter instanceof DateClrDatagridFilterInterfaceComponent) {
          filters.dateFilter = (<DateClrDatagridFilterInterfaceComponent>filter).dateValues();
        } else if (filter instanceof ManifestClrDatagridFilterInterfaceComponent) {
          filters.manifestFilter = (<ManifestClrDatagridFilterInterfaceComponent>filter).isChecked();
        } else if (filter instanceof CheckboxClrDatagridFilterInterfaceComponent) {
          filters.deleted = (<CheckboxClrDatagridFilterInterfaceComponent>filter).isChecked();
        } else {
          const { property, value } = <{ property: string, value: string }>filter;
          filters[property] = [value];
        }
      }
    }
    this.activeFilters = filters;
    console.log(filters, state.page);
  }

  /**
   * Description: Does a filtered search of the money orders and shows only those orders that match the
   * the criteria in the filters.
   * @author Maximiliano Casale
   * @returns void
   */
  public searchFiltered(): void {
    this.fetching = true;
    const filtersMap = Object.entries(this.activeFilters);
    this.orders = this.orderService.searchFiltered(filtersMap, this.pagination, 'money', this.user).asObservable();
    this.fetching = false;
    // this.orders.subscribe(res =>{
    //   this.orders = res.iterable;
    //  })
  }

  /**
   * Description: Initializes the form for the filters.
   * @author Maximiliano Casale
   * @returns void
   */
  public createFormFilter(): void {
    this.filtersForm = this.fb.group(
      {
        orderId: [''],
        dateFrom: [''],
        dateTo: [''],
        shippingType: [''],
        agencyName: [''],
        agency_path: [],
        recipientName: [''],
        recipientCountry: [],
        recipientState: [],
        recipientCity: [],
        manifest: [false],
        deleted: [false]
      });
  }

  /**
   * Description: Adds zeros to the beginning of a string. Used for the order's ID.
   * @author Maximiliano Casale
   * @param {any} bareNum Number to add zeros if it has less than 6 digits
   * @returns {string} The barenum with the zeros as a string
   */
  public padNum(bareNum): string {
    bareNum = bareNum + '';
    const zeroString = '000000';
    return zeroString.substring(bareNum.length, 6) + bareNum;
  }

  /**
   * Description: Adds the selected orders as a manifest.
   * @author Maximiliano Casale
   * @returns void
   */
  public addToManifest(): void {
    const orderPaths: Array<string> = [];
    const createdDate = new Date();
    let numberID: number;
    const year: string = this.datePipe.transform(createdDate, 'yy');
    this.selected.forEach(element => {
      orderPaths.push(element.__id);
    });
    this.manifestService.getNextOrderNumber().then((number) => {
      let id: string;
      numberID = number;
      id = `MAN-MO-${this.datePipe.transform(createdDate, 'MMM')}-${year}-${this.padNum(numberID)}`;
      this.manifestService.create(id, orderPaths, createdDate.toString(), this.user.__path, this.user.agency_path, numberID, year, false, '???', 'money').__toPromise().then(res => {
        this.assignManifests(res.__path, orderPaths);
      }).catch(err => { console.error(err); });
    });
    this.selected = [];
  }

  /**
   * Description: Assigns the manifest path to all orders in the newly created manifest.
   * @author Maxmiliano Casale
   * @param {string} manifestId
   * @param {Array<string>} orders
   * @returns void
   */
  public assignManifests(manifestId: string, orders: Array<string>): void {
    orders.forEach(order_path => {
      this.orderService.get(order_path).__toPromise().then(order => {
        order.manifest = manifestId;
        this.orderService.update(order);
      }).catch(err => {
        console.log('194', err);
      });
    });
  }

  /**
   * Description: Deploys a modal with the details for the selected order.
   * @author Maximiliano Casale
   * @param {Order} order Selected order
   * @returns void
   */
  public viewDetails(order: Order): void {
    this.orderService.setSelected(order);
    this.router.navigate([`/order-details/${order.__id}`]);
  }

  /**
   * Description: Gets the countries as an observable.
   * @author Maximiliano Casale
   * @returns void
   */
  getCountries() {
    this.locationsService.getCountries().asObservable().subscribe(countries => {
      this.countries = countries.iterable;
    });
  }

  /**
   * Description: Gets the states of the selected country to filter.
   * @author Maximiliano Casale
   * @param {string} type If the country selected is the sender's, reciepient's or agency's.
   * @returns void
   */
  getStates(type: string) {
    let selectedCountry;
    if (type === 'sender') {
      selectedCountry = this.filtersForm.get('senderCountry').value;
      selectedCountry && selectedCountry !== 'All' ? this.locationsService.getStates(selectedCountry).asObservable().subscribe(states => { this.senderStates = states.iterable; }) : null;
    } else if (type === 'recipient') {
      selectedCountry = this.filtersForm.get('recipientCountry').value;
      selectedCountry && selectedCountry !== 'All' ? this.locationsService.getStates(selectedCountry).asObservable().subscribe(states => { this.recipientStates = states.iterable; }) : null;
    } else if (type === 'agency') {
      selectedCountry = this.filtersForm.get('agencyCountry').value;
      selectedCountry && selectedCountry !== 'All' ? this.locationsService.getStates(selectedCountry).asObservable().subscribe(states => { this.agencyStates = states.iterable; }) : null;
    }
  }

  /**
   * Description: Gets the cities of the selected state as an observable.
   * @author Maximiliano Casale
   * @param {string} type Sender/recipient/agency
   * @returns void
   */
  getCities(type: string) {
    let selectedState;
    if (type === 'sender') {
      selectedState = this.filtersForm.get('senderState').value;
      selectedState && selectedState !== 'All' ? this.locationsService.getCities(selectedState).asObservable().subscribe(cities => { this.senderCities = cities.iterable; }) : null;
    } else if (type === 'recipient') {
      selectedState = this.filtersForm.get('recipientState').value;
      selectedState && selectedState !== 'All' ? this.locationsService.getCities(selectedState).asObservable().subscribe(cities => { this.recipientCities = cities.iterable; }) : null;
    } else if (type === 'agency') {
      selectedState = this.filtersForm.get('agencyState').value;
      selectedState && selectedState !== 'All' ? this.locationsService.getCities(selectedState).asObservable().subscribe(cities => {
        this.agencyCities = cities.iterable;
      }) : null;

    }

  }

}
