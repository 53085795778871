import { Injectable } from '@angular/core';

import { User } from '../models';
import { Entity, Collection } from 'aether-blaze';
import * as firebase from 'firebase';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, Query } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from './auth.service';

import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject, Observable } from 'rxjs';

import 'rxjs/add/operator/map';
import { Router } from '@angular/router';

import { AngularFireFunctions } from '@angular/fire/functions';
import { iUser } from '../models/user';
import { AXInput, AXPage, AetherXlsx } from 'aether-xlsx';
import { XlsxService } from './xlsx.service';
import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';

// var functions = firebase.functions();

@Injectable({
  providedIn: 'root'
})
export class UserService {


  public static userRole = 'user';
  public static adminRole = 'admin';

  private pathUrl = '/users/';
  private authSubscription: Subscription;
  public profile: User;

  private profileSubscription: Subscription;
  private profileSubject = new ReplaySubject<User>(1);

  /**
   * @constructor
   * @param {AngularFirestore} afs Document-oriented database
   * @param {AngularFireAuth} afAuth Simpler way to setup user authentication
   * @param {AuthService} authService Service for authentications management
   * @param {AngularFireFunctions} afFunctions Allows to call functions directly from the Firebase app
   * @param {XlsxService} xlsx Service that allows to export a file as a Excel spreadsheet
   * @param {AlertService} alertService Service for alerts management
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks
   * @param {HttpClient} http Offers a simplified client HTTP API for Angular applications
   */
  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private afFunctions: AngularFireFunctions,
    private xlsx: XlsxService,
    private alertService: AlertService,
    private router: Router,
    private http: HttpClient) {
    this.authSubscription = afAuth.authState.subscribe(u => {
      if (u != null) {
        this.getProfile(u.uid);
      } else {
        this.clearProfile();
      }
    });
  }

  private mapRef = function (a) {
    let doc = null;
    if (a.payload.doc) {
      doc = a.payload.doc;
    } else {
      doc = a.payload;
    }
    const data = doc.data();
    data.ref = doc.ref;
    return data;
  };

  /**
   * Description: Gets the profile information of the current logged user once the "Profile" option is clicked.
   * @author Sarkis Bazdikian
   * @param {string} uid Id of the current logged user
   * @returns void
   */
  public getProfile(uid: string): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    this.profileSubscription = this.afs.doc(this.pathUrl + uid)
      .snapshotChanges().map(this.mapRef).subscribe(p => {
        this.profileSubject.next(p);
      });
  }

  get userProfile() {
    return this.profileSubject.asObservable();
  }

  /**
   * Description: Gets a user by its id.
   * @author Sarkis Bazdikian
   * @param {string} id User's id
   * @returns {User}
   */
  public get(id: string): User {
    const usr = new User(this.pathUrl + id);
    return usr;
  }

  /**
   * Description: Gets all the users collection.
   * @author Sarkis Bazdikian
   * @param query
   * @returns {Collection<User>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query
  ): Collection<User> {
    if (query) {
      return new Collection<User>(User, this.pathUrl, query);
    } else {
      return new Collection<User>(User, this.pathUrl);
    }
  }

  /**
   * Description: Creates a new user.
   * @author Sarkis Bazdikian
   * @param {string} name New user's name
   * @param {string} email New user's email
   * @returns {User} New user
   */
  public create(name: string, email: string): User {
    const doc = firebase.firestore().collection(this.pathUrl).doc();
    const usr = new User(doc.path, name, email);
    usr.__save();
    return usr;
  }

  /**
   * Description: Deletes a user.
   * @author Sarkis Bazdikian
   * @param {string} id Id of the user to delete
   * @returns void
   */
  public delete(id: string): void {
    const usr = new User(this.pathUrl + id);
    usr.__delete();
  }

  /**
   * Description: Updates user's status.
   * @author Sarkis Bazdikian
   * @param {string} id User's id
   * @param {boolean} status Enabled/disabled
   */
  public status(id: string, status: boolean): void {
    const user = new User(this.pathUrl + id);
    user.status = status;
    this.update(user);
  }

  /**
   * Description: Updates user's data.
   * @author Sarkis Bazdikian
   * @param {User} data
   * @returns {Promise<void>} Update confirmation/denial
   */
  public update(data: User): Promise<void> {
    return new Promise<void>((resolve) => {
      data.__save(true).then(() => {
        resolve();
      });
    });
  }

  // public invite(data) {
  //   return new Promise<void>((resolve) => {
  //     const endpoint = 'https://us-central1-pos-auction-jda.cloudfunctions.net/invite';
  //     this.http.post(endpoint, {data: data}).subscribe(u => {
  //       // console.log('uid??', (u as any).result.uid);
  //       this.inviteSuccessCallback(u).then(() => resolve());
  //     });
  //   });

  /**
   * Description: Manages new user invitation. If the user is invited successfully, shows a success alert.
   * If there is an error, catches the error and shows a warning alert.
   * @author Sarkis Bazdikian
   * @param {any} data New user's data.
   * @returns {Promise<any>} Resolved if the user is successfully invited
   */
  public invite(data: any): Promise<any> {
    return new Promise<any>((resolve) => {
      // PROD
      const endpoint = 'https://us-central1-ccarga-xpress-2.cloudfunctions.net/invite';
      // DEVELOP
      // const endpoint = 'https://us-central1-ccarga-xpress-2-dev.cloudfunctions.net/invite';
      // var invites = this.afFunctions.httpsCallable('invite');
      this.http.post(endpoint, { data: data }).subscribe(response => {
        console.log('[RESPONSE]: ', response, data);
        if ((response as any).result.email) {
          this.signInSuccessCallbackInvite((response as any).result, data.agency_path, data.role)
            .then(() => {
              resolve();
              this.alertService.showAlert('User invited successfully', 'alert-success');
            })
            .catch((error) => { console.log(error); });
        } else if ((response as any).result.errorInfo) {
          this.alertService.showAlert((response as any).result.errorInfo.message, 'alert-warning');
          resolve();
        }
      }, err => {
        // nunca va a entrar aca creo
        console.error(err);
      });
    });
  }

  /**
   * Description: Once the user is successfully signed in, verifies if the user exists in the database and
   * if it does not exist, creates the user with the user's data received.
   * @author Sarkis Bazdikian
   * @param {any} signInSuccessData Successful sign in confirmation data
   * @returns {Promise<void>}
   */
  public signInSuccessCallback(signInSuccessData): Promise<void> {
    return new Promise<void>((resolve) => {

      const user = signInSuccessData.user;

      const profileDoc = this.afs.doc<User>(this.pathUrl + user.uid);

      profileDoc.ref.get().then(doc => {
        if (!doc.exists) {
          profileDoc.set({
            email: user.email,
            name: user.displayName,
            phone: user.phoneNumber,
            role: 'user',
            status: true
          } as User, {
              merge: true
            });
        }
        this.update(new User(this.pathUrl + user.uid));
        resolve();
      });
    });
  }

  /**
   * Description: If the user signs in successfully, but the user does not exist in the database, creates a new
   * user with the data of the user received as param.
   * @author Sarkis Bazdikian
   * @param {any} signInSuccessData Data returned by a successful sign in
   * @param {any} name Name of the signed in user
   * @param {any} lastname Lastname of the signed in user
   * @returns {Promise<void>}
   */
  public signInSuccessCallbackEmail(signInSuccessData, name, lastname): Promise<void> {
    return new Promise<void>((resolve) => {

      const user = signInSuccessData.user;

      const profileDoc = this.afs.doc<User>(this.pathUrl + user.uid);

      profileDoc.ref.get().then(doc => {
        if (!doc.exists) {
          profileDoc.set({
            email: user.email,
            name: name,
            lastname: lastname,
            role: 'user',
            status: true
          } as User, {
              merge: true
            });
        }
        resolve();
      });
    });
  }

  /**
   * Description: If the user is successfully invited, gets the user's id from the signInSuccessData and gets
   * the user's document, if it does not exist, creates it and then updates its data in the database.
   * @author Sarkis Bazdikian
   * @param {any} signInSuccessData Data obtained once the user is successfully signed in
   * @param {any} agency_path Agency that the user belongs to
   * @param {any} role User's role
   * @returns {Promise<void>}
   */
  public signInSuccessCallbackInvite(signInSuccessData, agency_path, role): Promise<void> {
    return new Promise<void>((resolve) => {

      const user = signInSuccessData;

      const profileDoc = this.afs.doc<User>(this.pathUrl + user.uid);

      profileDoc.ref.get().then(doc => {
        if (!doc.exists) {
          profileDoc.set({
            email: user.email,
            role: role,
            status: true,
            agency_path: agency_path
          } as User, {
              merge: true
            });
        }
        this.update(new User(this.pathUrl + user.uid));
        resolve();
      });
    });
  }

  /**
   * Description: Logs out current signed in user.
   * @author Sarkis Bazdikian
   * @returns {Promise<void>}
   */
  public logOut() {
    this.clearProfile();
    return this.authService.logout();
  }

  /**
   * Description: Clears the profile of the current signed in user.
   * @author Sarkis Bazdikian
   * @returns void
   */
  public clearProfile() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
      this.profileSubscription = null;
    }
    this.profileSubject.next(null);
  }

  /**
   * Description: Exports all the data of the users of the agency of the current signed in user to an excel
   * document.
   * @author Sarkis Bazdikian
   * @param {string} agency_path Name of the agency of the current signed in user
   * @returns void
   */
  public exportAll(agency_path?: string): Promise<void> {

    let query: Query = this.afs.firestore.collection(this.pathUrl);

    if (agency_path) {
      query = query.where('agency_path', '==', agency_path);
    }

    return query.get().then(snap => {
      if (snap && snap.docs && snap.docs.length > 0) {
        const input: AXInput = {
          pages: {
            'Usuarios': {
              headers: ['Email', 'Nombre', 'Apellido', 'Telefono', 'Direccion'],
              rows: snap.docs.map(d => d.data() as iUser)
                .sort((a, b) => ('' + a.lastname).localeCompare('' + b.lastname))
                .map(u => [u.email, u.name, u.lastname, u.phone ? u.phone[0] : '', u.address])
            }
          }
        };
        const date = new Date();
        this.xlsx.export(input, 'CCarga-Users-' + date.getMonth() + '-' + date.getDate() + '-' + date.getFullYear());
      }
    });
  }
}
