import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Locker, iLocker } from '../models/locker';
import { Collection } from 'aether-blaze';

@Injectable({
  providedIn: 'root'
})
export class LockerService {

  private pathUrl: string = '/lockers/'
  public locker: Locker;

  /**
   * @constructor
   */
  constructor() { }

  /**
   * Description: Gets a specific locker by its id.
   * @author Maximiliano Casale
   * @param {string} id Id of the requested locker
   * @returns {Locker} Locker requested
   */
  public get(id: string): Locker {
    return new Locker(this.pathUrl + id);
  }

  /**
   * Description: Gets the entire lockers collection.
   * @author Maximiliano Casale
   * @param query
   * @returns {Collection<Locker>} Lockers collection
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Locker> {
    if (query) {
      return new Collection<Locker>(Locker, this.pathUrl, query);
    } else {
      return new Collection<Locker>(Locker, this.pathUrl);
    }
  }

  /**
   * Description: Creates a new locker.
   * @author Maximiliano Casale
   * @param {iLocker} newLocker
   * @returns {Locker} Locker created
   */
  public create(newLocker: iLocker) {
    let doc = firebase.firestore().collection(this.pathUrl).doc();
    let locker = new Locker(doc.path, newLocker.id, newLocker.user_path, newLocker.agency_path, newLocker.shipments, false, firebase.firestore.Timestamp.now(), newLocker.comments, false);
    locker.__save();
    return locker;
  }

  /**
   * Description: Updates a locker's data.
   * @author Maximiliano Casale
   * @param {Locker} data Updated data
   * @returns {Promise<void>} Update confirmation
   */
  public update(data: Locker): Promise<void> {
    return data.__save(true);
  }

  /**
   * Description: Marks as deleted the locker with the id received as param.
   * @author Maximiliano Casale
   * @param {string} id Id of the deleted locker
   * @returns {Promise<void>} Updates the deleted locker
   */
  public delete(id: string): Promise<void> {
    return new Promise<void>(_ => {
      let locker = new Locker(this.pathUrl + id);
      locker.deleted = true;
      return this.update(locker);
    })
  }

  /**
   * Description: Gets the year of the date received as param.
   * @author Maximiliano Casale
   * @param {string} date
   * @returns {number} Year
   */
  public getYear(date: string): number {
    return parseInt(date.substring(6));
  }

  /**
   * Description: Gets the month of the date received as param.
   * @author Maximiliano Casale
   * @param {string} date
   * @returns {number} Month
   */
  public getMonth(date: string): number {
    return parseInt(date.substring(0, 2)) - 1;
  }

  /**
   * Description: Gets the day of the date received as param.
   * @author Maximiliano Casale
   * @param {string} date
   * @returns {number} Day
   */
  public getDay(date: string): number {
    return parseInt(date.substring(3, 5));
  }

  /**
   * Description: Gets only the lockers that match the filters' criteria.
   * @author Maximiliano Casale
   * @param {any} filters Criteria
   * @param {any} pagination
   * @param {any} userAgency_path If there is no filters' criteria gets all the lockers of the user's agency
   * @returns {Collection<Locker>} Lockers
   */
  public searchFiltered(filters, pagination, userAgency_path): Collection<Locker> {
    return this.getAll(ref => {
      let query = ref as any;
      for (let i = 0; i < filters.length; i++) {
        let f = filters[i];
        if (f[0] === 'dateFilter') {
          var from = new Date(this.getYear((f[1] as any).from), this.getMonth((f[1] as any).from), (this.getDay((f[1] as any).from)));
          var to = new Date(this.getYear((f[1] as any).to), this.getMonth((f[1] as any).to), (this.getDay((f[1] as any).to)) + 1);
          query =
           query.where('createdAt', '>=', firebase.firestore.Timestamp.fromDate(from))
           .where('createdAt', '<=', firebase.firestore.Timestamp.fromDate(to));
        }
        if (f[0] === 'complete') {
          query = query.where('complete', '==', false)
        }
      }
      if (userAgency_path != null) {
        return query.where('agency_path', '==', userAgency_path).orderBy('createdAt');
      } else {
        return query.orderBy('createdAt');
      }
    })
  }

}//end of class
