import { Injectable } from '@angular/core';
import { Agency } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  /**
   * @constructor
   */
  constructor() { }

  cartData = [];
  cartTotal: number;
  paymentMethod: any;
  agencyPath: Agency;

  /**
   * Description: Allows a purchase by setting the store's cart data (purchased products), total paid, payment 
   * method and the name of the agency that made the purchase.
   * @author Jesus Ponte 
   * @param {any[]} cart Cart's data 
   * @param {number} total Total paid
   * @param {string} method Payment method
   * @param {Agency} agency Agency that made the purchase
   * @returns void
   */
  setCartData(cart: any[], total: number, method: string, agency: Agency) {
    this.cartData = cart;
    this.cartTotal = total;
    this.paymentMethod = method;
    this.agencyPath = agency;
    // console.log('After loading this inside me, take responsability UwU');
  }

  /**
   * Description: Gets current cart's content.
   * @author Jesus Ponte
   * @returns {Promise<{}>}
   */
  public getCartData() {
    let promise = new Promise((resolve, reject) => {
      if (this.cartData.length > 0) {
        resolve();
      } else {
        reject();
      }
    })
    return promise
  }

  /**
   * Description: Gets the total to pay.
   * @author Jesus Ponte
   * @returns {number} 
   */
  getCartTotal() {
    return this.cartTotal;
  }

  /**
   * Description: Gets payment method used.
   * @author Jesus Ponte
   * @returns {any} Payment method
   */
  getPaymentMethod() {
    return this.paymentMethod;
  }

  /**
   * Description: Gets the agency that made the purchase.
   * @author Jesus Ponte
   * @returns {Agency} 
   */
  getAgencyPath() {
    return this.agencyPath;
  }

}

