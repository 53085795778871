import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { User, Agency } from '../../models';
import { AgencyService } from 'src/app/services/agency.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  profile: User;
  isAdmin = false;
  isAgent = false;
  isManager = false;
  isManagerCcarga = false;
  isDeveloper = false;
  agency: Agency;

  /**
   * @constructor
   * @param {UserService} userService Service for users management
   * @param {AgencyService} agencyService Service for agencies management
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks
   */
  constructor(
    private userService: UserService,
    private agencyService: AgencyService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      if (p) {
        this.profile = p;
        if (this.profile.agency_path) {
          this.agency = new Agency(this.profile.agency_path);
        }
        const role = this.profile.role;
        this.userRole(role);
      }
    });
  }

  /**
   * Description: Navigates to the login view when the user logs out.
   * @author
   * @returns void
   */
  logOut() {
    this.userService.logOut().then(() => this.router.navigate(['login']));
  }

  /**
   * Description: Allows navigation only to the allowed views for each role.
   * @author
   * @param {string} role Current user's role
   * @returns void
   */
  userRole(role: string) {
    this.isAdmin = false;
    this.isAgent = false;
    this.isManager = false;
    this.isManagerCcarga = false;
    this.isDeveloper = false;

    switch (role) {
      case 'admin': this.isAdmin = true; break;
      case 'agent': this.isAgent = true; break;
      case 'manager': this.isManager = true; break;
      case 'manager-ccarga': this.isManagerCcarga = true; break;
      case 'developer': this.isDeveloper = true; break;
      default: { }
    }
  }
}
