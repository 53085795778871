import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AgencyService } from '../../services/agency.service';
import { User, Agency, Person } from '../../models';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { PersonService } from 'src/app/services/person.service';


@Component({
  selector: 'app-persons-list',
  templateUrl: './persons-list.component.html',
  styleUrls: ['./persons-list.component.scss']
})
export class PersonsListComponent implements OnInit {


  public excelModal = false;
  public excelModalSelect: string;

  public basic = false;
  public profile: User;
  public agencies: Observable<Collection<Agency>>;

  public selected: User;
  public newAgency: Agency;

  persons: Observable<Collection<Person>>;
  usersIterable: User[];

  public statusFilterOptions = [
    { key: true, value: 'Recipient' },
    { key: 'Sender', value: 'Sender' }
  ];
  /**
   * @constructor
   * @param {UserService} userService Service for users management
   * @param {AgencyService} agencyService Service for agencies management
   * @param {PersonService} personService Service for person management
   */
  constructor(
    public userService: UserService,
    private agencyService: AgencyService,
    private personService: PersonService
  ) { }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      this.profile = p;
      // this.getUsers(this.profile);
    });
    this.agencies = this.agencyService.getAll().asObservable();
  }

  /**
   * Description: Gets all the users of the app.
   * @author Mauro Lanza
   * @param profile Current logged user
   * @returns void
   */
  getUsers(): void {
    this.userService.userProfile.subscribe(p => {
      this.profile = p;
      if (p) {
        if (p.role === 'admin') {
          this.agencies = this.agencyService.getAll().asObservable();
          this.persons = this.personService.getAll().asObservable();
        } else {
          this.persons = this.personService.getAll(ref => ref.where('agency_path', '==', p.agency_path)).asObservable();
        }

      }
    });

  }


  /**
   * Description: Shows certain users depending of the current logged user role.
   * @author Mauro Lanza
   * @returns void
   */
  public export(filter: string) {
    if (this.profile.role === 'admin') {
      if (this.excelModalSelect) {
        this.personService.exportAll(filter, this.excelModalSelect);
      } else {
        this.personService.exportAll(filter);
      }
    } else {
      this.personService.exportAll(filter, this.profile.agency_path);
    }
  }

  /**
   * Description: Allows to show the edit view of the selected person.
   * @author Mauro Lanza
   * @param {any} person person selected to edit
   * @returns void
   */
  editPerson(person): void {
    this.selected = person;
    this.basic = true;
  }

}
