import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  /**
   * @constructor
   * @param {UserService} userService Service for users management
   * @param {Router} router Navigation router
   */
  constructor(
    private userService: UserService,
    private router: Router,

  ) { }

  /**
   * Description: Activates or not the admin guard depending on the user role.
   * @author Alejandro Font
   * @param next Route that the user wants to access
   * @param state Represents the state of the router at the moment
   * @return {Promise<boolean>}
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.userProfile.pipe(
      map(user => {
        return user && user.role === UserService.adminRole;
      }),
      tap(result => {
        if (!result) {
          console.log('Admin access is required');
          this.router.navigate(['']);
        }
      })
    );

  }

}
