import { Timestamp } from '@google-cloud/firestore';
import { iStoreProduct } from './store-product';
import { iPerson } from './person';
import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';

interface iOrderProduct {
    product: string;
    product_quantity: number;
}

export interface iStoreOrder {
    date: firebase.firestore.Timestamp;
    status: string;
    address_path: string;
    total: number;
    products: Array<iOrderProduct>;
    user_path: string;
    userEmail: string;
}

export class StoreOrder extends Entity implements iStoreOrder {
    public products: Array<iOrderProduct>;
    public status: string;
    public date: firebase.firestore.Timestamp;
    public total: number;
    public address_path: string;
    public user_path: string;
    public userEmail: string;

    public __toPromise() {
        return this.__promise as Promise<StoreOrder>;
    }

    public __asObservable() {
        return (this.__subject.asObservable() as Observable<StoreOrder>);
    }

    constructor(
        path: string,
        products?: Array<iOrderProduct>,
        status?: string,
        date?: firebase.firestore.Timestamp,
        total?: number,
        address_path?: string,
        user_path?: string,
        userEmail?: string,
    ) {
        super(StoreOrder, path);
        if (products) {
            this.products = products;
        }
        if (status) {
            this.status = status;
        }
        if (date) {
            this.date = date;
        }
        if (total) {
            this.total = total;
        }
        if (address_path) {
            this.address_path = address_path;
        }
        if (user_path) {
            this.user_path = user_path;
        }
        if (userEmail) {
            this.userEmail = userEmail;
        }


    }
}
