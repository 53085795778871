import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { tap } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  @Input()
  public folder: string;
  @Input()
  public timestamp: string;

  // Download URL
  downloadURL = new Observable<string>();

  @Output()
  uploadUrl = new EventEmitter<Observable<string>>();

  @Output()
  uploadData = new EventEmitter<{}>();

  @Output()
  uploading = new EventEmitter<boolean>();

  // State for dropzone CSS toggling
  isHovering: boolean;

  /**
   * @constructor
   * @param {AngularFireStorage} storage Firebase storage
   * @param {AngularFirestore} afs
   */
  constructor(private storage: AngularFireStorage, private afs: AngularFirestore) { }

  uploadPercent: Observable<number>;

  /**
   * Description: Allows image upload.
   * @author Jorge Del Castillo
   * @param {any} event
   * @returns void
   */
  uploadFile(event) {
    // Client-side validation example
    if (event.target.files[0].type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      return;
    }
    const file = event.target.files[0];
    // const filePath = `${this.folder}/${new Date().getTime()}_${file.name}`;
    const filePath = `${this.folder}/${this.timestamp}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        this.storage.ref(filePath);
        this.downloadURL.subscribe((s) => {
          this.afs.collection('images').doc(this.timestamp).set({ filePath, size: file.size, file_name: file.name, image: s });
          this.uploadData.emit({ file_name: file.name, image: s });
        });
        // Update firestore on completion
        this.uploadUrl.emit(this.downloadURL);
      })
    )
      .subscribe();

  }

  /**
   * Description: Event listener.
   * @author Jorge Del Castillo
   * @param {boolean} event Mouse hovers over a toggle
   * @returns void
   */
  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  /**
   * Description: Determines if the upload task is active.
   * @author Jorge Del Castillo
   * @param {any} snapshot
   * @returns {boolean} Condition
   */
  isActive(snapshot) {
    const condition = snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
    this.uploading.emit(condition);
    return condition;
  }

}
