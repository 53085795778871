import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { OrderService } from '../../services/order.service';
import { ManifestService } from '../../services/manifest.service';
import { AgencyService } from '../../services/agency.service';
import { Order, Manifest, User, Agency } from '../../models';
import { ShippingService } from 'src/app/services/shipping.service';
import { UserService } from 'src/app/services/user.service';
import { DatePipe } from '@angular/common';
import { PdferService } from 'src/app/services/pdfer.service';

@Component({
  selector: 'app-manifest-detail',
  templateUrl: './manifest-detail.component.html',
  styleUrls: ['./manifest-detail.component.scss']
})
export class ManifestDetailComponent implements OnInit {

  @Input() data: any;
  @Input() dataType: string;

  generate = false;
  delete = false;

  ordersList: any;
  manifestsList: any;
  pages: Array<Object>;

  userAgency: string;


  confirmation = new FormControl();

  /**
   * @constructor
   * @param {Router} router Navigation router
   * @param {OrderService} orderService Service for orders management
   * @param {ManifestService} manifestService Service for manifests management
   * @param {AgencyService} agencyService Service for agencies management
   * @param {ShippingService} shippingService Service for shipments management
   * @param {UserService} userService Service for users management
   * @param {DatePipe} datePipe Pipe to format a date
   */
  constructor(
    // private shipmentService: ShipmentService,
    private router: Router,
    private orderService: OrderService,
    private manifestService: ManifestService,
    private agencyService: AgencyService,
    private shippingService: ShippingService,
    private userService: UserService,
    private datePipe: DatePipe,
    private pdfer: PdferService,
  ) { }

  ngOnInit() {
    if (this.dataType === 'manifest') {
      this.getOrders().then(res => {
        this.ordersList = res;
        this.countOrders();
      });
    } else if (this.dataType === 'shipment') {
      this.getManifests().then(res => {
        this.manifestsList = res;
      });
    }

    // console.log(this.data);
  }

  /**
   * Description: If the details requested are of a shipment, returns the manifests related to the
   * shipment but if the details requested are of a manifest, returns the orders related to the
   * requested manifest.
   * @author Maximiliano Casale
   * @returns {any} Manifests | Orders
   */
  dataList() {
    switch (this.dataType) {
      case 'shipment':
        return this.data.manifests_path;
      case 'manifest':
        return this.data.orders_path;
    }
  }

  /**
   * Description: Deletes an item from manifests/shipments.
   * @author Maximiliano Casale
   * @returns void
   */
  deleteItem() {
    if (this.dataType === 'manifest') {
      this.ordersList.forEach(order => {
        order.manifest = '???';
        this.orderService.update(order);
      });
      this.manifestService.delete(this.data.__id);
      if (this.data.type === 'package') {
        this.router.navigate(['/packages-manifests']);
      } else {
        this.router.navigate(['/money-manifests']);
      }
    } else if (this.dataType === 'shipment') {
      this.manifestsList.forEach(manifest => {
        manifest.shipping = '???';
        this.manifestService.update(manifest);
      });
      this.shippingService.delete(this.data.__id);
      if (this.data.type === 'package') {
        this.router.navigate(['/packages-shipments']);
      } else {
        this.router.navigate(['/money-shipments']);
      }
    }
    this.delete = false;
  }

  /**
   * Description: If the details requested are of a manifest, navigates to the order details view,
   * if the details requested are of a shipment, navigates to the manifest details view.
   * @author Maximiliano Casale
   * @param id Id of the data requested to see details
   * @returns void
   */
  goTo(id) {
    switch (this.dataType) {
      case 'manifest':
        this.router.navigate(['/order-details', id]);
        break;
      case 'shipment':
        this.router.navigate(['/manifest-details', id]);
        break;
      default:
        break;
    }
  }

  /**
   * Description: Counts the orders in order to index the orders list and facilitate the search of
   * an order.
   * @author Maximiliano Casale
   * @returns void
   */
  public countOrders(): void {
    let index = 1;
    for (const order of this.ordersList) {
      order['orderNumber'] = index;
      index++;
    }
  }


  /**
   * Description: Calculates the shipping cost, insurance, packing service, country tax ad total of
   * the order.
   * @author Maximiliano Casale
   * @returns {string} Quantity, shipping cost, insurance, packing service, country tax and total
   */
  calculateResults(): string {
    let shippingCost = 0;
    let insurance = 0;
    let packingService = 0;
    let countryTax = 0;
    let total = 0;
    for (const order of this.ordersList) {
      shippingCost += order.shippingCost;
      insurance += order.insurance;
      packingService += order.packingService;
      countryTax += order.countryTax;
    }
    total = Math.ceil(shippingCost + insurance + packingService + countryTax);
    return `Qty: ${this.ordersList.length} / Shipping Cost: ${shippingCost}`
      + ` / Insurance: ${insurance} / Packing Service: ${packingService}`
      + ` / Country Tax: ${countryTax} / Total: ${total}`;
  }

  /**
   * Description: Generates the excel with the manifest details.
   * @author Maximiliano Casale
   * @returns void
   */
  generateExcel() {
    // funcion
    this.generate = false;
    // console.log(this.data.__path);
    this.manifestService.export(this.data.__path);

  }

  /**
   * Description: Checks if the manifest selected is deletable.
   * @author Maximiliano Casale
   * @returns {boolean}
   */
  public itsDeletable(): boolean {
    if (this.dataType === 'manifest' && this.data.shipping !== '???') {
      return false;
    }
    return true;
  }

  /**
   * Description: Generates pdf of the selected manifest.
   * @author Maximiliano Casale
   * @returns void
   */
  public async generatePDF(): Promise<void> {

    const model = this.data.__copy();
    model.orders = this.ordersList.map((o) => o.__copy());
    Promise.all([
      new Agency(this.data.agency_path).__toPromise()
    ]).then((values) => {
      model.agency = values[0];

      if (model.type === 'money') {
        this.pdfer.buildMoneyManifestPDF(model);
      } else {
        this.pdfer.buildManifestPDF(model);
      }
    });

  }

  /**
   * Description: Gets the orders of the selected manifest.
   * @author Maximiliano Casale
   * @returns {Promise<Order[]>} A promise with the array of orders of the requested manifest
   */
  public async getOrders(): Promise<Order[]> {
    // let ordersList: Order[] = [];
    const promises = [];
    for (const orderId of this.dataList()) {
      promises.push(this.orderService.get(orderId).__toPromise());
    }
    return Promise.all(promises);
  }

  /**
   * Description: Gets the manifests of the selected shipment.
   * @author Maximiliano Casale
   * @returns {Promise<Manifest[]>} A promise with the array of manifests  of the requested shipment
   */
  public async getManifests(): Promise<Manifest[]> {
    const promises = [];
    for (const manifestId of this.dataList()) {
      promises.push(this.manifestService.get(manifestId).__toPromise());
    }
    return Promise.all(promises);
  }

}
