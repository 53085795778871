import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { map, tap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestrictUserGuard implements CanActivate {

  bool = new Subject<boolean>();

  /**
   * @constructor
   * @param {UserService} userService Service for users management
   * @param {Router} router Navigation router
   */
  constructor(
    private userService: UserService,
    private router: Router,
  ) {
  }

  /**
   * Description: Checks if the user is authorized to navigate to the next route.
   * @author Alejandro Font
   * @param {ActivatedRouteSnapshot} next Route that the user wants to access
   * @param {RouterStateSnapshot} state Represents the state of the router at the moment
   * @returns {boolean | Observable<boolean> | Promise<boolean>}
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.userProfile.pipe(
      switchMap(user => {
        return of(user && user.role && user.role !== UserService.userRole);
      }),
      tap(result => {
        if (!result) {
          this.router.navigate(['unauthorized']);
        }
      })
    );
  }

}
