import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, PatternValidator, NgForm, FormControl } from '@angular/forms';
import { PersonService } from '../../services/person.service';
import { LocationsService } from '../../services/locations.service';
import { Collection } from 'aether-blaze';
import { Observable, Subject, of, concat } from 'rxjs';
import { Person, Location } from '../../models';
import { UserService } from 'src/app/services/user.service';
import { tap, switchMap, catchError, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { typeAhead } from 'src/app/utils/type-ahead';

@Component({
  selector: 'app-senders',
  templateUrl: './senders.component.html',
  styleUrls: ['./senders.component.scss']
})
export class SendersComponent implements OnInit {

  @Output() sender = new EventEmitter();
  @Output() senderRef = new EventEmitter();
  @Input() agencyPath: string;

  public senderInput$ = new Subject<string>();

  public senderForm: FormGroup;
  public frqSendersData: {
    doc: Person,
    ref: firebase.firestore.DocumentReference
  }[] = [];
  public frqSender = new FormControl();

  public countries: Collection<Location>;
  public states: Observable<Collection<Location>>;
  public cities: Observable<Collection<Location>>;
  public city = '';
  public state = '';
  public country = '';

  public frqSendersLoading: boolean;
  public frqSenders$: Observable<{ doc: Person; ref: firebase.firestore.DocumentReference; }[]>;

  private typeAheadTooShortError = 'Typehead text not long enough';

  /**
   * @constructor
   * @param {FormBuilder} fb Form builder for the sender's info form group
   * @param {PersonService} personService Service for persons management
   * @param {LocationsService} locationsService Service for locations management
   * @param {UserService} userService Service for users management
   */
  constructor(
    private fb: FormBuilder,
    private personService: PersonService,
    private locationsService: LocationsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.createSenderForm();
    this.getCountries();
    this.selectedFrqSenderValueChanges();

    this.frqSenders$ = typeAhead(
      this.senderInput$.asObservable().pipe(
        map(text => {
          if (text) {
            return text.toLowerCase();
          } else {
            return text;
          }
        })
      ),
      (text) => {
        return this.personService.getFrqPersonData((ref) => {
          return ref
            .where('agency_path', '==', this.agencyPath)
            .where('recipient', '==', false)
            .where('search_label', '>=', text)
            .where('search_label', '<=', text + '\uf8ff')
            .limit(100);
        });
      },
      (isLoading) => {
        this.frqSendersLoading = isLoading;
      }
    );
  }

  /**
   * Description: Creates a form group for a sender's info.
   * @author Sarkis
   * @returns void
   */
  createSenderForm() {
    this.senderForm = this.fb.group({
      sendName: ['', [Validators.required, Validators.pattern('[a-zA-ZñÑ\\s]*')]],
      sendLastName: ['', [Validators.required, Validators.pattern('[a-zA-ZñÑ\\s]*')]],
      sendEmail: ['', [Validators.required, Validators.email]],
      sendPhone1: ['', [Validators.required, Validators.minLength(6), Validators.pattern('[0-9]*')]],
      sendPhone2: ['', [Validators.minLength(6), Validators.pattern('[0-9]*')]],
      sendPhone3: ['', [Validators.minLength(6), Validators.pattern('[0-9]*')]],
      sendCountry: ['', [Validators.required]],
      sendState: ['', [Validators.required]],
      sendCity: ['', [Validators.required]],
      sendZipCode: ['', [Validators.required, Validators.minLength(4)]],
      sendAddress: ['', [Validators.required]]
    });
    this.formControlValueChanges();
  }

  /**
   * Description: Subscribes to any change made in the sender's form.
   * @author Sarkis
   * @returns void
   */
  formControlValueChanges() {
    this.senderForm.valueChanges.subscribe(
      (selectedValue) => {
        // console.log(selectedValue);
        this.outSender();
      }
    );
    this.senderForm.controls['sendCountry'].valueChanges.subscribe(
      (selectedValue) => {
        this.country = selectedValue;
        this.getStates();
      }
    );
    this.senderForm.controls['sendState'].valueChanges.subscribe(
      (selectedValue) => {
        this.state = selectedValue;
        this.getCities();
      }
    );
  }

  /**
   * Description: Subscribes to any change in the sender selected as frequent.
   * @author Sarkis
   * @returns void
   */
  selectedFrqSenderValueChanges() {
    this.frqSender.valueChanges.subscribe(
      (selectedValue) => {
        this.frequentSenderChange(selectedValue);
      }
    );
  }

  /**
   * Description: Changes the sender's info in the form group for the info of the new sender selected.
   * @author Sarkis
   * @param selectedValue New value selected as frequent sender
   * @returns void
   */
  frequentSenderChange(selectedValue) {
    if (selectedValue && selectedValue.doc) {
      this.senderForm.setValue({
        sendName: selectedValue.doc.name,
        sendLastName: selectedValue.doc.lastname,
        sendEmail: selectedValue.doc.email,
        sendPhone1: selectedValue.doc.phone[0],
        sendPhone2: selectedValue.doc.phone[1] || '',
        sendPhone3: selectedValue.doc.phone.length > 2 ? selectedValue.doc.phone[2] : '',
        sendCountry: selectedValue.doc.addresses[0].country,
        sendCity: selectedValue.doc.addresses[0].city,
        sendState: selectedValue.doc.addresses[0].state,
        sendAddress: selectedValue.doc.addresses[0].address,
        sendZipCode: selectedValue.doc.addresses[0].zipcode
      });
      // this.sender.emit(this.senderForm.value);
      this.senderRef.emit(selectedValue);
      this.outSender();
    } else {
      this.senderForm.reset();
      this.outSender();
      this.senderRef.emit(selectedValue);
    }
  }

  /**
   * Description: Gets the countries available as an observable.
   * @author Sarkis
   * @returns void
   */
  getCountries(): void {
    this.locationsService.getCountries().asObservable().subscribe((p) => {
      this.countries = p;
    });
  }

  /**
   * Description: Gets the states of the selected country.
   * @author Sarkis
   * @returns void
   */
  getStates() {
    this.cities = undefined;
    this.city = '';
    this.state = '';
    this.states = this.country ? this.locationsService.getStates(this.country).asObservable() : null;
  }

  /**
   * Description: Gets the cities of the selected state.
   * @author Sarkis
   * @returns void
   */
  getCities() {
    this.city = '';
    this.cities = this.state ? this.locationsService.getCities(this.state).asObservable() : null;
    if (this.cities) {
      this.cities.subscribe((c) => {
        if (c.iterable.length === 0) {
          this.cities = this.states;
          this.senderForm.controls['sendCity'].setValue(this.state);
        }
      });
    }
  }

  /**
   * Description: Emits the sender's info in the sender's form.
   * @author Sarkis
   * @returns void
   */
  outSender() {
    this.sender.emit(this.senderForm);
  }

}
