function generateTable(model) {
    const boxes: any[] = [
        [
            { text: 'Box #', style: 'header' },
            { text: 'Dimensions', style: 'header' },
            { text: 'Weight', style: 'header' },
            { text: 'Shipping Cost', style: 'header' },
            { text: 'Insurance Cost', style: 'header' },
            { text: 'Packing Service', style: 'header' },
            { text: 'Country Tax', style: 'header' },
        ]
    ];

    let bcounter = 1;
    for (const box of model.boxes) {
        boxes.push([
            { text: bcounter++, style: 'center' },
            { text: box.dimHeight + ' x ' + box.dimLength + ' x ' + box.dimWidth, style: 'center' },
            { text: box.weight, style: 'center' },
            { text: box.priceBox, style: 'center' },
            { text: box.insuranceCost, style: 'center' },
            { text: box.packingValue, style: 'center' },
            { text: box.tax, style: 'center' },
        ]);
    }
    // boxes.push([
    //     { colSpan: 6, text: 'Total', style: 'headerRight' },
    //     null, null, null, null, null,
    //     { text: model.total, style: 'header' },
    // ]);

    return {
        table: {
            widths: ['*', '*', '*', '*', '*', '*', '*'],
            headerRows: 1,
            dontBreakRows: true,
            keepWithHeaderRows: 1,
            body: boxes
        },
        layout: {
            hLineWidth: function (i, node) {
                return (i === 0 || i === 1 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
                return (i === 0 || i === 1 || i === node.table.body.length) ? 'black' : 'black';
            },
            vLineColor: function (i, node) {
                return 'black';
            },
        }
    };
}

function generateHeader(model, index, agency) {
    const block = {
        id: 'begin-' + index,
        stack: [
            {
                canvas: [
                    { type: 'line', x1: -5, y1: 0, x2: 792 - 35, y2: 0, lineWidth: 3 }
                ],
                margin: [0, 0, 0, 5]
            },
            {
                columns: [
                    { text: index, style: 'boldRight', width: 15 },
                    { text: model.id, style: 'center', width: 130 },
                    { text: model.shippingType, style: 'center', width: 50 },
                    { text: model.shippingCost, style: 'center', width: 40 },
                    { text: model.insurance, style: 'center', width: 30 },
                    { text: model.packingService, style: 'center', width: 30 },
                    { text: agency.name, style: 'center', width: 100 },
                    { text: model.recipient.name, style: 'center', width: 100 },
                    { text: model.recipient.phone.filter((s) => s.length > 0).join(', '), style: 'center', width: 100 },
                    { text: new Date().toLocaleString(), style: 'center', width: '*' },
                ]
            },
            {
                canvas: [
                    { type: 'line', x1: 0, y1: 0, x2: 792 - 40, y2: 0, lineWidth: 1 }
                ]
            },
            {
                columns: [
                    { text: 'To:', style: 'header', width: 30 },
                    {
                        text: model.address.state_name
                            + ', ' + model.address.country_name
                            + ', ' + model.address.address,
                        style: 'center',
                        width: '*'
                    },
                ]
            },
            generateTable(model),
            {
                columns: [
                    { text: 'Total: ', style: 'boldRight', width: '*' },
                    {
                        text: model.total,
                        style: 'boldRight',
                        width: 'auto'
                    },
                ],
                margin: [0, 5, 0, 0]
            },
            {
                canvas: [
                    { type: 'line', x1: -5, y1: 0, x2: 792 - 35, y2: 0, lineWidth: 3 }
                ],
                margin: [0, 10, 0, 0]
            },
        ]
    };

    return block;
}

function generateContent(models: any[], agency) {
    const content = [];
    let counter = 0;
    for (const model of models) {
        content.push(generateHeader(model, ++counter, agency));
    }
    return content;
}

export function parseManifestPDF(model: any) {
    return {
        pageMargins: [20, 39, 20, 59],
        pageSize: 'LETTER',
        pageOrientation: 'landscape',
        pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
            if (
                currentNode.id // If the current node has an ID
                && currentNode.id.startsWith('begin') // And it starts with 'begin'
                && currentNode.id.split('-')[1] !== 1 // And it doesn't end with '-1'
                && (currentNode.pageNumbers.length !== 1) // And it is spanned in more than one page
            ) {
                return true; // Pagebreak before
            }
            return false;
        },
        header: {
            columns: [
                { text: '#', style: 'header', width: 15 },
                { text: 'Tracking ID', style: 'header', width: 130 },
                { text: 'Type', style: 'header', width: 50 },
                { text: 'S. Cost', style: 'header', width: 40 },
                { text: 'Insur.', style: 'header', width: 30 },
                { text: 'Pack', style: 'header', width: 30 },
                { text: 'Agency', style: 'header', width: 100 },
                { text: 'Recipient', style: 'header', width: 100 },
                { text: 'Phones', style: 'header', width: 100 },
                { text: 'Date', style: 'header', width: '*' }
            ],
            margin: [20, 20, 20, 20]
        },
        footer: function (index, total) {
            return {
                columns: [
                    { text: model.id },
                    { text: model.createdAt ? model.createdAt.toDate().toLocaleString() : null, style: 'center' },
                    { text: index + '/' + total, style: 'right' },
                ],
                margin: [20, 20, 20, 20]
            };
        },
        content: generateContent(model.orders, model.agency).concat({
            columns: [
                { text: 'Orders: ' + model.orders.length },
                { text: 'Boxes: ' + model.orders.reduce((a, c) => a + c.boxes.length, 0) },
                { text: 'Total: ' + model.orders.reduce((a, c) => a + c.total, 0) + '$' },
            ],
            style: 'total',
            margin: [0, 10, 0, 0]
        }),

        styles: {
            header: {
                bold: true,
                fontSize: 11,
                color: 'black',
                alignment: 'center',
            },
            headerRight: {
                bold: true,
                fontSize: 11,
                color: 'black',
                alignment: 'right',
            },
            right: {
                alignment: 'right',
            },
            bold: {
                bold: true
            },
            boldRight: {
                bold: true,
                fontSize: 13,
                alignment: 'right',
            },
            center: {
                alignment: 'center'
            },
            total: {
                alignment: 'right',
                bold: true,
                fontSize: 15,
                color: 'black'
            }
        }
    };
}
