import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { InventoryRequest } from 'src/app/models';
import { InventoryRequestService } from 'src/app/services/inventory-request.service';


@Component({
  selector: 'app-iv-requests-details',
  templateUrl: './iv-requests-details.component.html',
  styleUrls: ['./iv-requests-details.component.scss']
})
export class IvRequestsDetailsComponent implements OnInit {

  public request: InventoryRequest;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private ivRequestService: InventoryRequestService) { }

  ngOnInit() {
    this.getProduct();
  }

  getProduct() {
    this.ivRequestService.get(this.route.snapshot.params['id']).__asObservable()._subscribe((p) => {
      this.request = p;
    });
  }

  goBack(): void {
    this.location.back();
  }

}
