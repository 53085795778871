import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location as navLocation } from '@angular/common';
import { PromotionService } from '../../../services/promotion.service';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-promotions-create',
  templateUrl: './promotions-create.component.html',
  styleUrls: ['./promotions-create.component.scss']
})
export class PromotionsCreateComponent implements OnInit {

  public promotionForm: FormGroup;
  public promocionvalue1: string;
  isLoading: boolean;

  /**
   * @constructor
   * @param {FormBuilder} fb Form builder for a new promotion form group
   * @param {navLocation} location Current navigation location
   * @param {PromotionService} promotionService Service for promotions management
   * @param {AlertService} alertService Service for alerts management
   */
  constructor(
    private fb: FormBuilder,
    private location: navLocation,
    private promotionService: PromotionService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.createPromotionForm();
  }

  /**
   * Description: Creates a new form group for a new promotion.
   * @author Mauro Lanza
   * @returns void
   */
  createPromotionForm() {
    this.promotionForm = this.fb.group({
      label: ['', [Validators.required, Validators.pattern('[a-z0-9A-ZñÑ\\s]*')]],
      description: ['', [Validators.required]],
      expDate: [''],
      promotion: ['', [Validators.required]],
      promotionValue1: ['', [Validators.pattern('[0-9].*')]],
      promotionValue2: ['', [Validators.pattern('[0-9].*'), Validators.min(1), Validators.max(100)]],
      // phones: [],
      // logo: '',
      enabled: true
    });
  }

  /**
   * Description: Creates a new promotion in firebase.
   * @author Mauro Lanza
   * @returns void
   */
  save(): void {
    try {
      this.isLoading = true;
      const value = this.promotionForm.value;
      const promotion = {} as any;
      promotion.name = value.label;
      promotion.description = value.description;
      promotion.end_date = value.expDate;
      promotion.value = this.promotionForm.get('promotion').value === 'COUPON' ? value.promotionValue2 : value.promotionValue1;
      promotion.type = this.promotionForm.get('promotion').value === 'COUPON' ? '%' : 'value';
      promotion.enabled = true;
      this.promotionService.create(promotion).then(() => {
        this.isLoading = false;
        this.location.back();
      });
    } catch {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }

  /**
   * Description: Sets the value of the new promotion.
   * @author Mauro Lanza
   * @param {string} value Value of the new promotion indicated by the user
   * @returns void
   */
  onClick(value: string) {
    this.promocionvalue1 = value;
  }

  /**
   * Description: Resets the new promotion form and navigates back to the previous navigation view.
   * @author Mauro Lanza
   * @returns void
   */
  cancel(): void {
    this.promotionForm.reset();
    this.location.back();
  }

}
