import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { first, tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  /**
   * @constructor
   * @param {AngularFireAuth} afAuth Firebase authentication for angular
   * @param {Router} router Navigation router
   */
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router
  ) { }

  /**
   * Description: Checks if the user is logged in.
   * @author Alejandro Font
   * @returns {Observable<firebase.User>}
   */
  isLoggedIn() {
    return this.afAuth.authState.pipe(first());
  }

  /**
   * Description: Allows navigation to the next route only if the user is logged in.
   * @author Alejandro Font
   * @param {ActivatedRouteSnapshot} next Route that the user wants to access
   * @param {RouterStateSnapshot} state Represents the state of the router at the moment
   * @returns {boolean | Observable<boolean> | Promise<boolean>}
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.afAuth.authState.pipe(
      map(user => !!user),
      tap(isLogged => {
        if (isLogged) {
          // console.log('Esta Logeado', isLogged);
        } else {
          // console.log('No Logeado', isLogged);
          this.router.navigate(['login']);
        }
      })
    );
  }

}
