// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// DEVELOP
// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyCtyerWwNY9uFb6X3LA1pwXVzAisCVQnqo",
//     authDomain: "ccarga-xpress-2-dev.firebaseapp.com",
//     databaseURL: "https://ccarga-xpress-2-dev.firebaseio.com",
//     projectId: "ccarga-xpress-2-dev",
//     storageBucket: "ccarga-xpress-2-dev.appspot.com",
//     messagingSenderId: "355438796185"
//   }
// };

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAI73_bWJDbh7TKQlEH12bakEf0M3MUqmU",
    authDomain: "ccarga-xpress-2.firebaseapp.com",
    databaseURL: "https://ccarga-xpress-2.firebaseio.com",
    projectId: "ccarga-xpress-2",
    storageBucket: "ccarga-xpress-2.appspot.com",
    messagingSenderId: "814684649077"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
