import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
// import { Agency, Order, User } from './index';
import { Agency } from './agency';
import { User } from './user';


export interface iManifest {
    id: string;
    orders_path: string[];
    createdAt: any;
    createdBy: string;
    agency_path: string;
    number: number;
    year: string;
    deleted: boolean;
    shipping: string;
    type: string;
}

export class Manifest extends Entity implements iManifest {
    public id: string;
    public number: number;
    public year: string;
    public orders_path: string[];
    public createdAt: any;
    public createdBy: string;
    public agency_path: string;
    public deleted: boolean;
    public shipping: string;
    public type: string;

    public get user() {
        if (this.createdBy) {
            return new User(this.createdBy);
        }
    }

    public set user(value: User) {
        this.createdBy = value.__path;
    }

    /**
     * Description: Returns true if the manifest has been marked as deleted or false if it has not.
     * @author Sarkis
     * @returns {boolean}
     */
    public isDeleted() {
        return this.deleted;
    }

    public get agency() {
        if (this.agency_path) {
            return new Agency(this.agency_path);
        }
    }

    public set agency(value: Agency) {
        this.agency_path = value.__path;
    }

    /**
     * Description: Returns the manifest as a promise.
     * @author Sarkis
     * @returns {Promise<Manifest>}
     */
    public __toPromise() {
        return this.__promise as Promise<Manifest>;
    }

    /**
     * Description: Returns the manifest as an observable.
     * @author Sarkis
     * @returns {Observable<Manifest>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<Manifest>);
    }

    /**
     * @constructor
     * @param {string} path Manifest's path
     * @param {string} id Manifest's id
     * @param {string[]} orders_path Manifest's orders' path
     * @param {any} createdAt Manifest's creation date
     * @param {string} createdBy Manifest's author
     * @param {string} agency_path Agency to which the manifest corresponds
     * @param {number} number Manifest's number
     * @param {string} year Year of the manifest
     * @param {boolean} deleted Is the manifest marked as deleted or not
     * @param {string} shipping
     * @param {string} type Is it a package or money manifest
     */
    constructor(path: string,
        id?: string,
        orders_path?: string[],
        createdAt?: any,
        createdBy?: string,
        agency_path?: string,
        number?: number,
        year?: string,
        deleted?: boolean,
        shipping?: string,
        type?: string,
    ) {
        super(Manifest, path);
        if (id) {
            this.id = id;
        }
        if (orders_path) {
            this.orders_path = orders_path;
        }
        if (createdAt) {
            this.createdAt = createdAt;
        }
        if (createdBy) {
            this.createdBy = createdBy;
        }
        if (agency_path) {
            this.agency_path = agency_path;
        }
        if (number) {
            this.number = number;
        }
        if (year) {
            this.year = year;
        }
        if (deleted !== undefined) {
            this.deleted = deleted;
        }
        if (shipping) {
            this.shipping = shipping;
        }
        if (type) {
            this.type = type;
        }
    }
}
