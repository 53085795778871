function generateHeader(model, index, agency) {
    const block = {
        id: 'begin-' + index,
        stack: [
            {
                canvas: [
                    { type: 'line', x1: -5, y1: 0, x2: 792 - 35, y2: 0, lineWidth: 3 }
                ],
                margin: [0, 0, 0, 5]
            },
            {
                columns: [
                    { text: index, style: 'boldRight', width: 15 },
                    { text: model.id, style: 'center', width: 130 },
                    { text: model.amount, style: 'center', width: 100 },
                    { text: agency.name, style: 'center', width: 100 },
                    { text: model.recipient.name, style: 'center', width: 100 },
                    { text: model.recipient.phone.filter((s) => s.length > 0).join(', '), style: 'center', width: 100 },
                    { text: new Date().toLocaleString(), style: 'center', width: '*' },
                ]
            },
            {
                canvas: [
                    { type: 'line', x1: 0, y1: 0, x2: 792 - 40, y2: 0, lineWidth: 1 }
                ]
            },
            {
                columns: [
                    { text: 'To:', style: 'header', width: 30 },
                    {
                        text: model.address.state_name
                            + ', ' + model.address.country_name
                            + ', ' + model.address.address,
                        style: 'center',
                        width: '*'
                    },
                ]
            },
            {
                canvas: [
                    { type: 'line', x1: -5, y1: 0, x2: 792 - 35, y2: 0, lineWidth: 3 }
                ],
                margin: [0, 10, 0, 0]
            },
        ]
    };

    return block;
}

function generateContent(models: any[], agency) {
    const content = [];
    let counter = 0;
    for (const model of models) {
        content.push(generateHeader(model, ++counter, agency));
    }
    return content;
}

export function parseMoneyManifestPDF(model: any) {
    return {
        pageMargins: [20, 39, 20, 59],
        pageSize: 'LETTER',
        pageOrientation: 'landscape',
        pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
            if (
                currentNode.id // If the current node has an ID
                && currentNode.id.startsWith('begin') // And it starts with 'begin'
                && currentNode.id.split('-')[1] !== 1 // And it doesn't end with '-1'
                && (currentNode.pageNumbers.length !== 1) // And it is spanned in more than one page
            ) {
                return true; // Pagebreak before
            }
            return false;
        },
        header: {
            columns: [
                { text: '#', style: 'header', width: 15 },
                { text: 'Tracking ID', style: 'header', width: 130 },
                { text: 'Amount', style: 'header', width: 100 },
                { text: 'Agency', style: 'header', width: 100 },
                { text: 'Recipient', style: 'header', width: 100 },
                { text: 'Phones', style: 'header', width: 100 },
                { text: 'Date', style: 'header', width: '*' }
            ],
            margin: [20, 20, 20, 20]
        },
        footer: function (index, total) {
            return {
                columns: [
                    { text: model.id },
                    { text: model.createdAt ? model.createdAt.toDate().toLocaleString() : null, style: 'center' },
                    { text: index + '/' + total, style: 'right' },
                ],
                margin: [20, 20, 20, 20]
            };
        },
        content: generateContent(model.orders, model.agency).concat({
            columns: [
                { text: 'Orders: ' + model.orders.length },
                { text: 'Total: ' + model.orders.reduce((a, c) => a + +c.amount, 0) + '$' },
            ],
            style: 'total',
            margin: [0, 10, 0, 0]
        }),

        styles: {
            header: {
                bold: true,
                fontSize: 11,
                color: 'black',
                alignment: 'center',
            },
            headerRight: {
                bold: true,
                fontSize: 11,
                color: 'black',
                alignment: 'right',
            },
            right: {
                alignment: 'right',
            },
            bold: {
                bold: true
            },
            boldRight: {
                bold: true,
                fontSize: 13,
                alignment: 'right',
            },
            center: {
                alignment: 'center'
            },
            total: {
                alignment: 'right',
                bold: true,
                fontSize: 15,
                color: 'black'
            }
        }
    };
}
