import { Component, OnInit } from '@angular/core';
import { ManifestService } from '../../../services/manifest.service';
import { Manifest } from '../../../models';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-list',
  templateUrl: './manifest-package-list.component.html',
  styleUrls: ['./manifest-package-list.component.scss']
})
export class ManifestPackageListComponent implements OnInit {

  manifestsList: Observable<Collection<Manifest>>;

  /**
   * @constructor
   * @param {ManifestService} manifestService Service that allows manifests management
   * @param {UserService} userService Service that allows users management
   */
  constructor(
    private manifestService: ManifestService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userService.userProfile.subscribe((p) => {
      if (p) {
        if (p.role === 'admin') {
          this.manifestsList = this.manifestService.getAll(ref => {
            return ref.where('deleted', '==', false).where('shipping', '==', '???').where('type', '==', 'package')
          }).asObservable()
        } else {
          this.manifestsList = this.manifestService.getAll(ref => {
            return ref.where('agency_path', '==', p.agency_path).where('deleted', '==', false).where('shipping', '==', '???').where('type', '==', 'package')
          }).asObservable()
        }
      }
    });
  }

}
