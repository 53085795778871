import { Injectable } from '@angular/core';

import { AetherXlsx, AXInput } from 'aether-xlsx';

@Injectable({
  providedIn: 'root'
})
export class XlsxService {

  private axlsx: AetherXlsx;

  /**
   * @constructor
   */
  constructor() {
    this.axlsx = new AetherXlsx();
  }

  /**
   * Description: Exports the AXInput received as param as an excel document. If the file name does not end with
   * the ".xlsx" extension, adds it to it and downloads it as an excel document. 
   * @author Alejandro Font
   * @param {AXInput} input  
   * @param {string} filename 
   * @returns void 
   */
  public export(input: AXInput, filename: string) {
    if (!filename.endsWith('.xlsx')) {
      filename += '.xlsx';
    }
    this.axlsx.export(input).then(wb => this.axlsx.download(wb, filename));
  }

}
