import { Component, Input, SimpleChanges, EventEmitter } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Order } from '../../models/order';
import * as moment from 'moment';

@Component({
  selector: 'app-date-filter',
  template: ``,
  styleUrls: ['../../app.component.scss']
})
export class DateClrDatagridFilterInterfaceComponent implements ClrDatagridFilterInterface<Order> {

  // Date range for filtering
  @Input() DateFrom: string;
  @Input() DateTo: string;

  /**
   * Description: Return date range set as filter.
   * @author Maximiliano Casale
   * @returns {any} Date range
   */
  dateValues(): any {
    return {
      from: this.DateFrom,
      to: this.DateTo,
    }
  }

  changes: EventEmitter<any> = new EventEmitter<any>(false);

  /**
   * Description: It emits true if there are changes in the filter condition.
   * @author Maximiliano Casale
   * @param {SimpleChanges} changes Changes made to the date range filter
   * @returns void
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.DateFrom) {
      this.changes.emit(true);
    }
    if (changes.DateTo) {
      this.changes.emit(true);
    }
  }

  /**
   * Description: This function returns true if both dates are set, and false if not.
   * @author Maximiliano Casale
   * @returns {boolean} Boolean that indicates if the date filter is active or not
   */
  isActive(): boolean {
    if (this.DateFrom != null && this.DateTo != null) {
      return this.DateFrom.length > 0 && this.DateTo.length > 0;
    } else {
      return false;
    }
  }

  /**
   * Description: It returns true if the order date is between the established parameters.
   * @author Maximiliano Casale
   * @param {Order} order Item of the order list
   * @returns {boolean} 
   */
  accepts(order: Order) {
    return moment(order.createdAt).isBetween(this.DateFrom, this.DateTo, null, '[]');
  }

}
