import { Component, OnInit } from '@angular/core';
import { StoreProduct, iStoreProduct } from 'src/app/models/store-product';
import { ActivatedRoute } from '@angular/router';
import { Location as navLocation } from '@angular/common';
import { StoreProductService } from 'src/app/services/store-product.service';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/models/category';
import { Collection } from 'aether-blaze';

@Component({
  selector: 'app-store-product-edit',
  templateUrl: './store-product-edit.component.html',
  styleUrls: ['./store-product-edit.component.scss']
})
export class StoreProductEditComponent implements OnInit {

  public selectedStoreProduct: StoreProduct;

  public productForm: FormGroup;
  public timestamp: string;
  public isLoading: boolean = false;

  public categories: Observable<Collection<Category>>;

  /**
   * @constructor
   * @param {ActivatedRoute} activatedRoute Current navigation route
   * @param {StoreProductService} storeProductService Service for store products management
   * @param {FormBuilder} fb Creates a form group for the data of the store product selected to edit 
   * @param {navLocation} location Current navigation location
   * @param {AlertService} alertService Service for alerts management
   * @param {CategoryService} categoryService Service for categories management
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private storeProductService: StoreProductService,
    private fb: FormBuilder,
    private location: navLocation,
    private alertService: AlertService,
    private categoryService: CategoryService
  ) { }

  ngOnInit() {
    this.getCategories();
    this.timestamp = new Date().getTime().toString();
    this.createProductForm();
    this.activatedRoute.params.subscribe(params => {
      this.selectedStoreProduct = this.storeProductService.get(params['id'])
      this.selectedStoreProduct.__toPromise().then(product => {
        this.productForm.get('name').setValue(product.name)
        this.productForm.get('sku').setValue(product.sku)
        this.productForm.get('description').setValue(product.description)
        this.productForm.get('price').setValue(product.price)
        this.productForm.get('tax').setValue(product.tax)
        this.productForm.get('width').setValue(product.width)
        this.productForm.get('height').setValue(product.height)
        this.productForm.get('length').setValue(product.length)
        this.productForm.get('weight').setValue(product.weight)
        this.productForm.get('stock').setValue(product.stock)
        this.productForm.get('category_path').setValue(product.category_path)
      });
    })
  }

  /**
   * Description: Gets all the non-deleted categories.
   * @author Maximiliano Casale
   * @returns void
   */
  public getCategories(): void {
    this.categories = this.categoryService.getAll(ref => {
      return ref.where('deleted', '==', false);
    }).asObservable()
  }

  /**
   * Description: Creates a form group for the data of the store product to edit.
   * @author Maximiliano Casale
   * @returns void
   */
  createProductForm() {
    this.productForm = this.fb.group({
      name: ['', [Validators.required]], // Validators.pattern('[a-zA-ZñÑ\\0-9\\s :]*')]],
      sku: ['', [Validators.required]],
      description: ['', [Validators.required]], // Validators.pattern('[a-zA-ZñÑ\\0-9\\s]*')]],
      price: [0, [Validators.required, Validators.pattern('^(0*[1-9][0-9]*(\.[0-9]+)?|0\.[0-9]*[1-9][0-9]*)$')]],
      tax: [0, [Validators.required, Validators.pattern('^(0*[1-9][0-9]*(\.[0-9]+)?|0\.[0-9]*[1-9][0-9]*)$')]],
      width: [0, [Validators.required, Validators.min(0)]],
      height: [0, [Validators.required, Validators.min(0)]],
      length: [0, [Validators.required, Validators.min(0)]],
      weight: [0, [Validators.required, Validators.min(0)]],
      stock: [0, [Validators.required, Validators.min(0)]],
      category_path: ['', [Validators.required]]
    });
  }

  /**
   * Description: Resets the product form with its default values and navigates back to the previous
   * navigation location.
   * @author Maximiliano Casale
   * @returns void
   */
  cancel(): void {
    this.productForm.reset();
    this.location.back();
  }

  /**
   * Description: Updates the data of the product edited in the database. If the product is updated 
   * successfully, shows a success alert and navigates to the previous navigation location. 
   * @author Maximiliano Casale
   * @returns void
   */
  public save() {
    this.isLoading = true;

    this.selectedStoreProduct.name = this.productForm.get('name').value;
    this.selectedStoreProduct.sku = this.productForm.get('sku').value;
    this.selectedStoreProduct.description = this.productForm.get('description').value;
    this.selectedStoreProduct.price = this.productForm.get('price').value;
    this.selectedStoreProduct.tax = this.productForm.get('tax').value;
    this.selectedStoreProduct.width = this.productForm.get('width').value;
    this.selectedStoreProduct.height = this.productForm.get('height').value;
    this.selectedStoreProduct.length = this.productForm.get('length').value;
    this.selectedStoreProduct.weight = this.productForm.get('weight').value;
    this.selectedStoreProduct.stock = this.productForm.get('stock').value;
    this.selectedStoreProduct.category_path = this.productForm.get('category_path').value;

    this.storeProductService.update(this.selectedStoreProduct).then(res => {
      console.log(res);
      this.alertService.showAlert('Product updated successfully', 'alert-success');
      this.location.back();
    }).catch(err => {
      this.isLoading = false;
      console.log(err)
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    });
  }

  /**
   * Description: Sets the image url with the url received as param.
   * @author Maximiliano Casale
   * @param {Observable<string>} url 
   * @param {any} index
   * @returns void 
   */
  onUpload(url: Observable<string>, index): void {
    url.subscribe((s) => {
      this.selectedStoreProduct.image[index] = s;
      this.selectedStoreProduct.timestamp[index] = `${this.timestamp}-${index}`
    });
  }

  /**
   * Description: Sets to null the image url of the store product form.
   * @author Maximiliano Casale
   * @param {number} index
   * @returns void 
   */
  resetUpload(index: number): void {
    this.selectedStoreProduct.image[index] = null;
  }

}
