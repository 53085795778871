import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';
import { Timestamp } from '@google-cloud/firestore';


export enum PromotionType {
    NONE,
    VALUE,
    COUPON
}

// tslint:disable-next-line:class-name
export class iPromotion {
    name: string;
    initial_date: string;
    end_date: Timestamp;
    description: string;
    type: string;
    value: number;
    enabled: boolean;
}

export class Promotion extends Entity implements iPromotion {

    public name: string;
    public initial_date: string;
    public end_date: Timestamp;
    public description: string;
    public type: string;
    public value: number;
    public enabled: boolean;

    /**
     * Description: Returns the promotion as a promise.
     * @author Sarkis
     * @returns {Promise<Promotion>}
     */
    public __toPromise() {
        return this.__promise as Promise<Promotion>;
    }

    /**
     * Description: Returns the promotion as an observable.
     * @author Sarkis
     * @returns {Observable<Promotion>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<Promotion>);
    }

    /**
     * @constructor
     * @param {string} path
     * @param {string} name
     * @param {string} initial_date
     * @param {Timestamp} end_date
     * @param {string} description
     * @param {string} type Percentage/value
     * @param {number} value
     * @param {boolean} enabled
     */
    constructor(path: string,
        name?: string,
        initial_date?: string,
        end_date?: Timestamp,
        description?: string,
        type?: string,
        value?: number,
        enabled?: boolean
    ) {
        super(Promotion, path);
        if (name) {
            this.name = name;
        }
        if (initial_date) {
            this.initial_date = initial_date;
        }
        if (end_date) {
            this.end_date = end_date;
        }
        if (description) {
            this.description = description;
        }
        if (type) {
            this.type = type;
        }
        if (value) {
            this.value = value;
        }
        if (enabled) {
            this.enabled = enabled;
        }
    }
}
