import { Component, OnInit } from '@angular/core';
import { PickUpService } from 'src/app/services/pick-up.service';
import { UserService } from 'src/app/services/user.service';
import { PickUp } from 'src/app/models/pickup';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-pick-up-list',
  templateUrl: './pick-up-list.component.html',
  styleUrls: ['./pick-up-list.component.scss']
})
export class PickUpListComponent implements OnInit {

  public pickUps: Observable<Collection<PickUp>>;
  public userRole: string;

  public showCompleted: boolean;

  public loading = true;

  /**
   * @constructor
   * @param {UserService} userService Service for users management
   * @param {PickUpService} pickUpService Service for pick ups management
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks
   */
  constructor(
    private userService: UserService,
    private pickUpService: PickUpService,
    private router: Router
  ) { }

  ngOnInit() {
    this.showCompleted = false;
    this.queryPickUps(this.showCompleted);
  }

  /**
   * Description: Queries the current user's agency's pick ups. It queries only the completed or all the
   * pickups depending on the parameter received.
   * @author Maximiliano Casale
   * @param {boolean} showCompleted Filter
   * @returns void
   */
  public queryPickUps(showCompleted: boolean) {
    this.loading = true;
    this.userService.userProfile.subscribe(user => {
      this.userRole = user.role;
      if (user) {
        if (user.role === 'admin') {
          this.pickUps = this.pickUpService.getAll(ref => {
            return ref.where('completed', '==', showCompleted).orderBy('createdAt', 'desc');
          }).asObservable();
        } else {
          this.pickUps = this.pickUpService.getAll(ref => {
            return ref.where('completed', '==', showCompleted).where('agency_path', '==', user.agency_path).orderBy('createdAt', 'desc');
          }).asObservable();
        }
        this.pickUps.toPromise().then(_ => {
          this.loading = false;
        });
      }
    });
  }

  TogglePickups() {
    this.showCompleted = !this.showCompleted;
    this.queryPickUps(this.showCompleted);
  }

  /**
   * Description: Navigates to the selected pick-up's details view.
   * @author Maximiliano Casale
   * @param {String} id Id of the pick-up
   * @returns void
   */
  public goToDetails(id: string): void {
    this.router.navigate([`/pick-up/${id}`]);
  }

}
