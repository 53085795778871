import { Component, Input } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs/Subject';

@Component({
  moduleId: 'checkbox-list-filter',
  selector: 'app-checkbox-list-filter',
  template: `
    <div>
      <ul style="list-style:none">
        <li *ngFor="let item of items">
          <clr-checkbox
            (change)="onItemChanged(item)"
            [(clrChecked)]="item.checked"
            [clrInline]="true"
            [clrDisabled]="item.disabled">
            <span>{{ item.value }}</span>
          </clr-checkbox>
        </li>
      </ul>
    </div>
  `
})

export class CheckboxListClrDatagridFilterInterfaceComponent implements ClrDatagridFilterInterface<{ key: string, value: string }> {

  // Used as the key for the param string
  @Input() public filterParamName: string;

  @Input() public items: Array<{ key: string, value: string, checked: boolean }>;

  public selectedItems: Array<{ key: string, value: string }> = [];

  public changes = new Subject<any>();

  /**
   * Description: Returns only the object that has a match with the filter parameter name.
   * @author Mauro Lanza
   * @param {any} obj 
   * @returns {any}  
   */
  public static instanceof(obj: any) {
    return obj.hasOwnProperty('filterParamName') && obj.hasOwnProperty('items') && obj.hasOwnProperty('selectedItems');
  }

  @Input('defaultClrDatagridFilterInterfaceValues')

  /**
   * Description: Looks into the list of items and attempts to match it.
   * @author Mauro Lanza
   * @param {string []} newValues 
   */
  public set defaultClrDatagridFilterInterfaceValues(newValues: string[]) {
    if (newValues) {
      this.selectedItems = [];
      if (this.items && Array.isArray(this.items)) {
        for (let item of this.items) {
          if (newValues && Array.isArray(newValues)) {
            for (let newValue of newValues) {
              if (item.key === newValue) {
                item.checked = true;
                this.selectedItems.push(item);
              }
            }
          }
        }
      }
    }
    this.changes.next(true);
  }

  /**
   * Description: This function checks if the item is checked or not, if so, the function adds it to 
   * the list of selected items, and if not, the function removes it from selected items list.
   * @author Mauro Lanza
   * @param {any} item Selected item of the option list
   * @returns void
   */
  public onItemChanged(item) {
    if (item.checked) {
      this.selectedItems.push(item);
    } else {
      const index = this.selectedItems.indexOf(item);
      if (index >= 0) {
        this.selectedItems.splice(index, 1);
      }
    }

    this.changes.next(true);
  }

  /**
   * Description: This function returns true when the checked current checked intem matches the item 
   * received as a parameter.
   * @author Mauro Lanza
   * @param {any} item 
   * @returns {boolean}
   */
  public accepts(item): boolean {
    for (let currentItem of this.items) {
      if (currentItem.checked && currentItem.key === item[this.filterParamName]) {
        return true;
      }
    }
    return false;
  }

  /**
   * Description: Returns true if there are selected items from the option list, and false if not.
   * @author Mauro Lanza
   * @returns {boolean} 
   */
  public isActive(): boolean {
    return this.selectedItems != null && this.selectedItems.length > 0;
  }

}
