import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class ProductCreateComponent implements OnInit {

  productDetails: FormGroup;

  /**
   * @constructor
   * @param {FormBuilder} fb Builds the form for a product's details 
   * @param {Location} location Current navigation location
   */
  constructor(
    private fb: FormBuilder,
    private location: Location) { }

  ngOnInit() {
    this.createProductForm();
  }

  /**
   * Description: Creates a product form for a new product's details.
   * @author ???
   * @returns void
   */
  createProductForm() {
    this.productDetails = this.fb.group({
      sendName: ['', [Validators.required, Validators.pattern('[a-zA-Z]*')]],
      sendCategory: ['', [Validators.required]],
      sendPrice: ['', [Validators.required, Validators.pattern('[0-9].*')]],
      sendAvailable: ['', [Validators.required]],
      sendDescription: ['', [Validators.required]],
      sendImage: ['', [Validators.required]],
      sendStock: ['', [Validators.required, Validators.pattern('[0-9].*')]],
      sendStockWarning: ['', []],
    });
  }

  /**
   * Description: Creates a new product.
   * @author ???
   * @returns void 
   */
  create(): void {
    // product service edit
  }

  /**
   * Description: Saves the product created in firebase.
   * @author ???
   * @returns void
   */
  save() {
    try {

    } catch {

    }
  }

  /**
   * Description: Resets the product details form and navigates to the previous view when the "go back" 
   * botton is clicked.
   * @author ???
   * @returns void
   */
  cancel(): void {
    this.productDetails.reset();
    this.location.back();
  }

}
