import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/models';
import { ProductService } from '../../services/product.service';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-agency-products',
  templateUrl: './agency-products.component.html',
  styleUrls: ['./agency-products.component.scss']
})
export class AgencyProductsComponent implements OnInit {

  filter = false;
  filterSKU = '';

  products: Observable<Collection<Product>>;

  /**
   * @constructor
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks 
   * @param {ProductService} productService Service for products management
   */
  constructor(
    private router: Router,
    private productService: ProductService) { }

  ngOnInit() {
    this.getProducts();
  }

  /**
   * Description: Gets all the agency's products.
   * @author Mauro Lanza
   * @returns void
   */
  getProducts(): void {
    this.products = this.productService.getAll().asObservable();
    console.log(this.products);
  }

  /**
   * Description: Asks for confirmation once a product is deleted. If the user confirms deletion, 
   * the product is marked as deleted from the agency inventory and the product's data is updated in
   * the database.
   * @author Mauro Lanza
   * @param {any} product Product to delete
   * @returns void
   */
  toggleDeleted(product: any): void {
    const check = confirm(`Are you sure you want to ${product.deleted ? 'restore' : 'delete'} "${product.name}" ?`);
    if (check) {
      product.deleted = !product.deleted;
      this.productService.update(product);
    }
  }

  /**
   * Description: Navigates to the edit view of the product selected to edit.
   * @author Mauro Lanza
   * @param {string} id Id of the product selected to edit
   * @returns void
   */
  editSelected(id: string): void {
    this.router.navigate(['store/agency-products/edit', id]);
  }

  /**
   * Description: Navigates to the details route of the product selected.
   * @author Mauro Lanza
   * @param {string} id Id of the product selected
   * @returns void
   */
  viewDetails(id: string): void {
    this.router.navigate(['store/agency-products/details', id]);
  }

}