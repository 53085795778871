import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ManifestService } from 'src/app/services/manifest.service';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { Manifest } from 'src/app/models';

@Component({
  selector: 'app-manifest-money-list',
  templateUrl: './manifest-money-list.component.html',
  styleUrls: ['./manifest-money-list.component.scss']
})
export class ManifestMoneyListComponent implements OnInit {

  public manifests: Observable<Collection<Manifest>>;

  /**
   * @constructor
   * @param {UserService} userService Service that allows users management
   * @param {ManifestService} manifestService Service that allows manifests management
   */
  constructor(private userService: UserService,
    private manifestService: ManifestService,
  ) { }

  ngOnInit() {
    this.userService.userProfile.subscribe((p) => {
      if (p) {
        if (p.role === 'admin') {
          this.manifests = this.manifestService.getAll(ref => {
            return ref.where('deleted', '==', false).where('shipping', '==', '???').where('type', '==', 'money')
          }).asObservable()
        } else {
          this.manifests = this.manifestService.getAll(ref => {
            return ref.where('agency_path', '==', p.agency_path).where('deleted', '==', false).where('shipping', '==', '???').where('type', '==', 'money')
          }).asObservable()
        }
      }
    });
  }

}
