import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationComponent } from './navigation/navigation/navigation.component';
import { CreateOrderComponent } from './orders/create-order/create-order.component';
import { OrderListComponent } from './orders/order-list/order-list.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { LoginComponent } from './login/login.component';
import { ManifestPackageListComponent } from './admin/manifests/manifest-package-list/manifest-package-list.component';
import { ManifestMoneyListComponent } from './admin/manifests/manifest-money-list/manifest-money-list.component';
import { ManifestDetailsComponent } from './admin/manifests/manifest-details/manifest-details.component';
import { ShipmentPackageListComponent } from './admin/shipments/shipment-package-list/shipment-package-list.component';
import { ShipmentMoneyListComponent } from './admin/shipments/shipment-money-list/shipment-money-list.component';
import { ShipmentDetailsComponent } from './admin/shipments/shipment-details/shipment-details.component';
import { NewMoneyOrdersComponent } from './orders/new-money-orders/new-money-orders.component';
import { QuotationComponent } from './components/quotation/quotation.component';
import { OrdersDetailsComponent } from './orders/orders-details/orders-details.component';
import { OrderManifestComponent } from './orders/order-manifest/order-manifest.component';
import { ProfileViewComponent } from './profile/profile-view/profile-view.component';
import { ProfileEditComponent } from './profile/profile-edit/profile-edit.component';

// test
import { TestComponent } from './test/test.component';


import { RatesListComponent } from './admin/rates/rates-list/rates-list.component';
import { CreateRateComponent } from './admin/rates/create-rate/create-rate.component';

import { AgenciesListComponent } from './admin/agencies/agencies-list/agencies-list.component';
import { AgencyDetailsComponent } from './admin/agencies/agency-details/agency-details.component';
import { AgencyEditComponent } from './admin/agencies/agency-edit/agency-edit.component';
import { AgencyCreateComponent } from './admin/agencies/agency-create/agency-create.component';
import { InviteComponent } from './admin/invite/invite.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';

import { StorePurchaseComponent } from './store/store-purchase/store-purchase.component';
import { StoreManifestComponent } from './store/store-manifest/store-manifest.component';
import { BillListComponent } from './store/bill-list/bill-list.component';
import { BillDetailsComponent } from './store/bill-details/bill-details.component';
import { RestrictUserGuard } from './guards/restrict-user.guard';
import { AgentGuard } from './guards/agent.guard';
import { ManagerGuard } from './guards/manager.guard';
import { AdministrationGuard } from './guards/administration.guard';
import { MoneyOrderListComponent } from './orders/money-order-list/money-order-list.component';
import { StoreCategoriesComponent } from './store/store-categories/store-categories.component';
import { StoreProductsComponent } from './store/store-products/store-products.component';
import { AgencyProductsComponent } from './store/agency-products/agency-products.component';
import { AgProductsEditComponent } from './store/agency-products/ag-products-edit/ag-products-edit.component';
import { AgProductsCreateComponent } from './store/agency-products/ag-products-create/ag-products-create.component';
import { LockerListComponent } from './locker/locker-list/locker-list.component';
import { LockerDetailsComponent } from './locker/locker-details/locker-details.component';
import { AgProductsDetailsComponent } from './store/agency-products/ag-products-details/ag-products-details.component';
import { InventoryComponent } from './store/inventory/inventory.component';
import { InventoryDetailsComponent } from './store/inventory/inventory-details/inventory-details.component';
import { InventoryCreateComponent } from './store/inventory/inventory-create/inventory-create.component';

import { StoreProductCreateComponent } from './store/store-products/store-product-create/store-product-create.component';
import { StoreProductEditComponent } from './store/store-products/store-product-edit/store-product-edit.component';
import { StoreProductDetailsComponent } from './store/store-products/store-product-details/store-product-details.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { PickUpListComponent } from './pick-up/pick-up-list/pick-up-list.component';
import { PromotionsCreateComponent } from './admin/promotions/promotions-create/promotions-create.component';
import { PromotionsListComponent } from './admin/promotions/promotions-list/promotions-list.component';
import { PromotionsDetailsComponent } from './admin/promotions/promotions-details/promotions-details.component';
import { PickUpDetailsComponent } from './pick-up/pick-up-details/pick-up-details.component';
import { StoreOrdersListComponent } from './store/store-orders/store-orders-list/store-orders-list.component';
import { StoreOrderDetailsComponent } from './store/store-orders/store-order-details/store-order-details.component';
import { InventoryRequestsComponent } from './store/inventory-requests/inventory-requests.component';
import { IvRequestsDetailsComponent } from './store/inventory-requests/iv-requests-details/iv-requests-details.component';
import { ManagerCcargaGuard } from './guards/manager-ccarga.guard';
import { PersonsListComponent } from './persons/persons-list/persons-list.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'profile',
    pathMatch: 'full'
  },
  {
    path: '',
    children: [
      // { path: 'dashboard', component: DashboardComponent },
      { path: 'test', component: TestComponent },
      { path: 'new-order', component: CreateOrderComponent, canActivate: [AuthGuard, AgentGuard] },
      {
        path: 'promotions', children: [
          { path: '', component: PromotionsListComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'create', component: PromotionsCreateComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'details/:id', component: PromotionsDetailsComponent, canActivate: [AuthGuard, AdminGuard] }
        ]
      },
      { path: 'order-list', component: OrderListComponent, canActivate: [AuthGuard] },
      { path: 'money-order-list', component: MoneyOrderListComponent, canActivate: [AuthGuard] },
      { path: 'users', component: UserManagementComponent, canActivate: [AuthGuard, AdministrationGuard] },
      { path: 'invite', component: InviteComponent, canActivate: [AuthGuard, AdministrationGuard] },
      { path: 'packages-manifests', component: ManifestPackageListComponent, canActivate: [AuthGuard, AdministrationGuard] },
      { path: 'money-manifests', component: ManifestMoneyListComponent, canActivate: [AuthGuard, AdministrationGuard] },
      { path: 'manifest-details/:id', component: ManifestDetailsComponent, canActivate: [AuthGuard, AdministrationGuard] },
      { path: 'packages-shipments', component: ShipmentPackageListComponent, canActivate: [AuthGuard, AdministrationGuard] },
      { path: 'money-shipments', component: ShipmentMoneyListComponent, canActivate: [AuthGuard, AdminGuard] },
      { path: 'shipment-details/:id', component: ShipmentDetailsComponent, canActivate: [AuthGuard, AdminGuard] },
      { path: 'new-money-order', component: NewMoneyOrdersComponent, canActivate: [AuthGuard, AgentGuard] },
      { path: 'locker-list', component: LockerListComponent, canActivate: [AuthGuard] },
      { path: 'locker-details/:id', component: LockerDetailsComponent, canActivate: [AuthGuard] },
      { path: 'quotation', component: QuotationComponent, canActivate: [AuthGuard, AgentGuard] },
      { path: 'pick-up-list', component: PickUpListComponent, canActivate: [AuthGuard] },
      { path: 'pick-up/:id', component: PickUpDetailsComponent, canActivate: [AuthGuard] },
      { path: 'senders-receivers', component: PersonsListComponent, canActivate: [AuthGuard]},
      // {
      //   path: 'product', children: [
      //     { path: 'details/:id', component: ProductDetailsComponent, canActivate: [AuthGuard] },
      //     { path: 'edit/:id', component: ProductEditComponent, canActivate: [AuthGuard] },
      //     { path: 'create', component: ProductCreateComponent, canActivate: [AuthGuard] }
      //   ]
      // },
      {
        path: 'agencies', children: [
          { path: '', component: AgenciesListComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'details/:id', component: AgencyDetailsComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'edit/:id', component: AgencyEditComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'create', component: AgencyCreateComponent, canActivate: [AuthGuard, AdminGuard] },
        ]
      },
      { path: 'order-details/:id', component: OrdersDetailsComponent, canActivate: [AuthGuard] },
      { path: 'order-manifest', component: OrderManifestComponent, canActivate: [AuthGuard, AdministrationGuard] },
      {
        path: 'rates-management', children: [
          { path: '', component: RatesListComponent, canActivate: [AuthGuard, AdministrationGuard] },
          { path: 'create', component: CreateRateComponent, canActivate: [AuthGuard, AdministrationGuard] },
          { path: 'edit/:id', component: CreateRateComponent, canActivate: [AuthGuard, AdministrationGuard] }
        ]
      },
      { path: 'profile', component: ProfileViewComponent, canActivate: [AuthGuard] },
      { path: 'profile/edit', component: ProfileEditComponent, canActivate: [AuthGuard] },
      { path: 'store/purchase', component: StorePurchaseComponent, canActivate: [AuthGuard, AgentGuard] },
      { path: 'store/manifest/bills/:id', component: StoreManifestComponent, canActivate: [AuthGuard] },
      { path: 'store/categories', component: StoreCategoriesComponent, canActivate: [AuthGuard] },
      {
        path: 'store/agency-products', children: [
          { path: '', component: AgencyProductsComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'details/:id', component: AgProductsDetailsComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'edit/:id', component: AgProductsEditComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'create', component: AgProductsCreateComponent, canActivate: [AuthGuard, AdminGuard] }
        ]
      },
      {
        path: 'store/inventory', children: [
          { path: '', component: InventoryComponent, canActivate: [AuthGuard] },
          { path: 'details/:id', component: InventoryDetailsComponent, canActivate: [AuthGuard] },
          { path: 'create', component: InventoryCreateComponent, canActivate: [AuthGuard] }
        ]
      },
      {
        path: 'store/inventory/requests', children: [
          { path: '', component: InventoryRequestsComponent, canActivate: [AuthGuard] },
          { path: 'details/:id', component: IvRequestsDetailsComponent, canActivate: [AuthGuard] },
        ]
      },
      {
        path: 'store/products', children: [
          { path: '', component: StoreProductsComponent, canActivate: [AuthGuard] },
          { path: 'create', component: StoreProductCreateComponent, canActivate: [AuthGuard] },
          { path: 'details/:id', component: StoreProductDetailsComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: StoreProductEditComponent, canActivate: [AuthGuard] },
        ]
      },
      { path: 'store/bills', component: BillListComponent, canActivate: [AuthGuard] },
      { path: 'store/bill-details/:id', component: BillDetailsComponent, canActivate: [AuthGuard] },
      {
        path: 'storeOrders', children: [
          { path: '', component: StoreOrdersListComponent, canActivate: [AuthGuard] },
          { path: 'details/:id', component: StoreOrderDetailsComponent, canActivate: [AuthGuard] },
        ]
      },
    ],
    component: NavigationComponent, canActivate: [AuthGuard, RestrictUserGuard],
  },
  { path: 'login', component: LoginComponent },
  { path: '404', component: NotFoundComponent },
  { path: 'unauthorized', component: NotAuthorizedComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
