import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { of, Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {

  /**
   * @constructor
   * @param {UserService} userService Service for users mangement
   * @param {Router} router
   */
  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  /**
   * Description: Navigates to https://ccargaxpress.com after a specific amount of time.
   * @author Jorge Del Castillo
   * @param {number} n Delay in seconds
   * @returns {Subscription}
   */
  private navigateAfterSeconds(n: number) {
    return of(' ').pipe(
      delay(n * 1000),
      tap(() => {
        window.location.href = 'https://ccargaxpress.com';
      })
    ).subscribe();
  }

  ngOnInit() {
    this.userService.logOut().then(() => {
      return this.navigateAfterSeconds(5);
    });
  }

}
