import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<firebase.User>;

  /**
   * @constructor
   * @param {AngularFireAuth} firebaseAuth Firebase authentication service
   */
  constructor(private firebaseAuth: AngularFireAuth) {
    this.user = firebaseAuth.authState;
    this.firebaseAuth.auth.languageCode = 'es';
  }

  /**
   * Description: Creates a new account in the firebase authentication service with the email and password 
   * received as params.
   * @author Sarkis Bazdikian
   * @param {string} email Email to add in the new account to create
   * @param {string} password Password to add in the new account to create
   * @returns {Promise<firebase.auth.UserCredential>} The firebase authentication service returns an user credential
   */
  registerByEmail(email: string, password: string) {
    return this.firebaseAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  /**
   * Description: Signs in a user with the received credentials, to access the page.
   * @author Sarkis Bazdikian
   * @param {string} email Email entered by the user who wants login
   * @param {string} password Password entered by the user who wants login
   * @returns {Promise<firebase.auth.UserCredential>} The firebase authentication service returns an user credential
   */
  loginEmail(email: string, password: string) {
    return this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(email, password);
  }

  /**
   * Description: Authenticates an user in Firebase through its Google account 
   * integrating access with Google by using the Firebase SDK to perform the access flow.
   * @author Sarkis Bazdikian
   * @returns {Promise<firebase.auth.UserCredential>} The firebase authentication service returns an user credential
   */
  loginGoogle() {
    firebase.auth().languageCode = 'es';
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');
    return this.firebaseAuth.auth.signInWithPopup(
      provider
    );
  }

  /**
   * Description: Logsout the current logged user.
   * @author Sarkis Bazdikian
   * @returns {Promise<void>}
   */
  logout() {
    return this.firebaseAuth.auth.signOut();
  }

  /**
   * Description: Sends an email to reset an user's password.
   * @author Sarkis Bazdikian
   * @param {any} email The email of the person that requested a new password
   * @returns {Promise<any>} A confirmation of the sent email
   */
  resetPassword(email): Promise<any> {
    return new Promise<any>((resolve) => {
      firebase.auth().sendPasswordResetEmail(email).then(function () {
        resolve('Email sent');
        // Email sent.
      }).catch(function (error) {
        resolve(error);
        // An error happened.
      });
    });
  }
}
