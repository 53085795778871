import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location as navLocation } from '@angular/common';
import { ProductService } from '../../../services/product.service';
import { Product } from 'src/app/models';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertService } from 'src/app/services/alert.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-ag-products-edit',
  templateUrl: './ag-products-edit.component.html',
  styleUrls: ['./ag-products-edit.component.scss']
})
export class AgProductsEditComponent implements OnInit {
  public productForm: FormGroup;
  public product: Product;

  imgUrl: string;
  uploadFolder = 'agencies-products';
  timestamp: string;
  isLoading: boolean;
  safeSrc: SafeResourceUrl;

  /**
   * @constructor
   * @param {FormBuilder} fb Creates a form for the data of the product to edit
   * @param {navLocation} location Current navigation location
   * @param {ActivatedRoute} route Current navigation route
   * @param {AlertService} alertService Service for alerts management
   * @param {DomSanitizer} sanitizer Helps preventing Cross Site Scripting Security bugs
   * @param {ProductService} productService Service for products management
   */
  constructor(
    private fb: FormBuilder,
    private location: navLocation,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private productService: ProductService) { }

  ngOnInit() {
    this.fillProductForm();
  }

  /**
   * Description: Gets the product which id is the one indicated by the route's params and subscribes
   * to it. Gets its image url, creates a form for the product's data and sets its values with the
   * values of the product's data to show the current values of the product to edit.
   * @author Mauro Lanza
   * @returns void
   */
  fillProductForm() {
    this.productService.get(this.route.snapshot.params['id']).__asObservable()._subscribe((p) => {
      this.product = p;
      this.imgUrl = this.product.image;
      this.timestamp = this.product.timestamp || new Date().getTime().toString();
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(p.logo) || null;
      this.createProductForm();

      this.productForm.setValue({
        name: this.product.name ? this.product.name : '',
        description: this.product.description ? this.product.description : '',
        sku: this.product.sku ? this.product.sku : '',
        price: this.product.price ? this.product.price : '',
        tax: this.product.tax ? this.product.tax : '',
      });

    });
  }

  /**
   * Description: Creates a form for the values of the product to edit.
   * @author Mauro Lanza
   * @returns void
   */
  createProductForm() {
    this.productForm = this.fb.group({
      name: ['', [Validators.required]], // Validators.pattern('[a-zA-ZñÑ\\0-9\\s :]*')]],
      description: ['', [Validators.required]], // Validators.pattern('[a-zA-ZñÑ\\0-9\\s]*')]],
      sku: ['', [Validators.required, Validators.pattern('[a-zA-ZñÑ\\0-9\\s]*')]],
      price: ['', [Validators.required, Validators.pattern('^(0*[1-9][0-9]*(\.[0-9]+)?|0\.[0-9]*[1-9][0-9]*)$')]],
      tax: ['', [Validators.required, Validators.pattern('^(0*[1-9][0-9]*(\.[0-9]+)?|0\.[0-9]*[1-9][0-9]*)$')]],
    });
  }

  /**
   * Description: Saves the values in the product form in the data of the product selected to edit
   * and updates it in the database. If the changes are made successfully, shows a success alert.
   * If there is an error, shows a warning.
   * @author Mauro Lanza
   * @returns void
   */
  save(): void {
    try {
      this.isLoading = true;
      const value = this.productForm.value;
      this.product.name = value.name;
      this.product.description = value.description;
      this.product.sku = value.sku;
      this.product.price = value.price;
      this.product.tax = value.tax;
      this.product.image = this.imgUrl;
      if (!this.product.timestamp) {
        this.product.timestamp = new Date().getTime().toString();
      }
      this.productService.update(this.product).then(() => {
        this.alertService.showAlert('Product created successfully', 'alert-success');
        this.location.back();
      });
    } catch (error) {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }

  /**
   * Description: Resets the product form to its default values and navigates to the previous location.
   * @author Mauro Lanza
   * @returns void
   */
  cancel(): void {
    this.productForm.reset();
    this.location.back();
  }

  /**
   * Description: Subscribes to the url received as param and sets it to the image url of the
   * product selected to edit.
   * @author Mauro Lanza
   * @param {Observable<string>} url Url of the image uploaded as the product image
   * @returns void
   */
  onUpload(url: Observable<string>): void {
    url.subscribe((s) => {
      this.imgUrl = s;
      // console.log(s);
    });
  }

  /**
   * Description: Sets to null the image url and the safe resource url of the product to edit.
   * @author Mauro Lanza
   * @returns void
   */
  resetUpload(): void {
    this.imgUrl = null;
    this.safeSrc = null;
  }

}
