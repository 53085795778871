import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { User, Agency, Location } from '../models';
import * as firebase from 'firebase';
import { DocumentReference } from '@angular/fire/firestore';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { LocationsService } from '../services/locations.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {


  public users: Observable<Collection<User>>;
  public user: User;
  public profile: User;
  public user_email: string;
  public data: any;
  public loc: Observable<Collection<Location>>;
  public countries: Collection<Location>;
  public states: Observable<Collection<Location>>;
  public cities: Observable<Collection<Location>>;
  city = '';
  state = '';
  country = '';


  constructor(public userService: UserService, public authService: AuthService, private router: Router,
    public locationsService: LocationsService) {
  }

  ngOnInit() {
    // this.userService.userProfile.subscribe(async p => {
    //   this.user = await p.__toPromise();
    //   console.log(this.user.agency.id);
    // });
    this.users = this.userService.getAll().asObservable();
    //this.userService.delete('jgcwvxciEHWUlwSqynfH');
    //this.userService.delete('Yss8ese0KKOu8z8ofD6S');
    //this.userService.disable('gTpnaXWX9ISQ3FsTqwlW', true);
    //this.user = this.userService.get('wqqeqweqeq');
    //this.userService.create('sagas', 'test');
    //this.user.name = 'ponleunstringcualquieraTEST'
    //this.userService.update(this.user)
    //console.log(this.user)
    //this.userService
    this.data = { email: "j.inver93@gmail.com", password: "password", agency_path: "agencies/oiy45fHXilg7NPgQb4kp" }
    this.user_email = this.data.email;
    this.getCountries();
  }

  // loginGoogle(): void {
  //   this.authService.loginGoogle()
  //     .then(data => {
  //       this.successCallback(data);
  //     })
  //     .catch((err) => {
  //       console.log('Algo Malo Sucedio');
  //     });
  // }

  registerHandler() {

  }

  loginGoogle(){

  }

  // registerHandler(): void {
  //   console.log("CLICK")
  //   let email = 'test21@gmail.com';
  //   let name = 'test21';
  //   let pwd = 'guachuguachu17';
  //   let lastname = 'testian17';

  //   this.authService.registerByEmail(email, String(pwd))
  //     .then(data => {
  //       this.successCallbackEmail(data, name, lastname);
  //     })
  //     .catch((err) => {
  //       console.log('Algo Malo Sucedio, y no pudimos procesar tu petición.');
  //     })
  // }

  // successCallback($event: any) {
  //   this.userService.signInSuccessCallback($event).then(() => {
  //     this.router.navigate(['/test']);
  //   }).catch(() => {
  //     alert('Something went terribly wrong, please try again later...');
  //   });
  // }

  // successCallbackEmail($event: any, name, lastname) {
  //   this.userService.signInSuccessCallbackEmail($event, name, lastname).then(() => {
  //     this.router.navigate(['/test']);
  //   }).catch(() => {
  //     alert('Something went terribly wrong, please try again later...');
  //   });
  // }

  // sendInvitation() {
  //   var data = { "email": "sbazdikian@gmail.com" }
  //   this.userService.invite(data);
  // }

  getCountries(): void {
    this.locationsService.getCountries().asObservable().subscribe((p) => {
      this.countries = p
    });
  }

  getStates() {
    this.cities = undefined;
    this.city = '';
    this.state = '';
    this.states = this.locationsService.getStates(this.country).asObservable();
  }

  getCities() {
    this.city = '';
    this.cities = this.locationsService.getCities(this.state).asObservable();
  }

  sendInvitation() {
    this.userService.invite(this.data);
  }

}
