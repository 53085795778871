import { Injectable } from '@angular/core';
import { Promotion } from '../models';

import { Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  private pathUrl = '/promotions/';
  public promotion: Promotion;

  /**
   * @constructor
   * @param {AngularFirestore} afs Document-oriented database 
   */
  constructor(
    private afs: AngularFirestore
  ) { }

  /**
   * Description: Gets a specific promotion by its id.
   * @author Sarkis Bazdikian 
   * @param {string} id Promotion's id
   * @returns {Promotion}   
   */
  public get(id: string): Promotion {
    return new Promotion(this.pathUrl + id);
  }

  /**
   * Description: Gets all the collection of promotions.
   * @author Sarkis Bazdikian
   * @param query 
   * @returns {Collection<Promotion>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Promotion> {
    if (query) {
      return new Collection<Promotion>(Promotion, this.pathUrl, query);
    } else {
      return new Collection<Promotion>(Promotion, this.pathUrl);
    }
  }

  /**
   * Description: Adds the new promotion received as param to the collection.
   * @author Sarkis Bazdikian
   * @param {any} promotion 
   * @returns {Promise<Promotion>} 
   */
  public create(promotion): Promise<Promotion> {
    return new Promise<Promotion>((resolve) => {
      firebase.firestore().collection(this.pathUrl).add(promotion).then((docRef) => {
        this.get(docRef.id).__toPromise().then((p) => {
          resolve(p);
        });
      });
    });
  }

  /**
   * Description: Updates the data of the promotion received as param.
   * @author Sarkis Bazdikian
   * @param {Promotion} data
   * @returns {Promise<void>} Update confirmation/denial 
   */
  public update(data: Promotion): Promise<void> {
    return new Promise<void>((resolve) => {
      data.__save(true).then(() => resolve());
    });
  }

  /**
   * Description: Disables the promotion received as param.
   * @author Sarkis Bazdikian
   * @param {Promotion} data
   * @returns {Promise<void>} Operation confirmation/denial 
   */
  public delete(data: Promotion): Promise<void> {
    return new Promise<void>((resolve) => {
      data.enabled = false
    });
  }

}
