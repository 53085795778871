import { Injectable } from '@angular/core';
import { Product } from '../models';
import { Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private pathUrl = '/products/';
  public product: Product;

  /**
   * @constructor
   * @param {AngularFirestore} afs Document-oriented database 
   */
  constructor(
    private afs: AngularFirestore
  ) { }

  /**
   * Description: Gets a specific product by its id.
   * @author Mauro Lanza
   * @param {string} id 
   * @returns {Product}  
   */
  public get(id: string): Product {
    return new Product(this.pathUrl + id);
  }

  /**
   * Description: Gets all the collection of products.
   * @author Mauro Lanza
   * @param query
   * @returns {Collection<Product>}  
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Product> {
    if (query) {
      return new Collection<Product>(Product, this.pathUrl, query);
    } else {
      return new Collection<Product>(Product, this.pathUrl);
    }
  }

  /**
   * Description: Adds a recently created product to the collection.
   * @author Mauro Lanza
   * @param {any} product New product's data
   * @returns {Promise<Product>} Recently added product
   */
  public create(product): Promise<Product> {
    return new Promise<Product>((resolve) => {
      firebase.firestore().collection(this.pathUrl).add(product).then((docRef) => {
        this.get(docRef.id).__toPromise().then((p) => {
          resolve(p);
        });
      });
    });
  }

  /**
   * Description: Updates the data of the product received as param.
   * @author Mauro Lanza
   * @param {Product} data
   * @returns {Promise<void>} Update confirmation 
   */
  public update(data: Product): Promise<void> {
    return new Promise<void>((resolve) => {
      data.__save(true).then(() => resolve());
    });
  }

  public getProductByID(id:string, hasPath: boolean): Product{
    if(hasPath){
      return new Product(id);
    }
    return new Product(this.pathUrl + id);
  }

  // getBase64Img(id): Observable<any> {
  //   return this.afs.doc('/images/' + id).valueChanges();
  // }

}
