import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location as navLocation } from '@angular/common';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { StoreProductService } from 'src/app/services/store-product.service';
import { CategoryService } from 'src/app/services/category.service';
import { Collection } from 'aether-blaze';
import { Category } from 'src/app/models/category';

@Component({
  selector: 'app-store-product-create',
  templateUrl: './store-product-create.component.html',
  styleUrls: ['./store-product-create.component.scss']
})
export class StoreProductCreateComponent implements OnInit {
  public productForm: FormGroup;

  imgUrl: string[] = [];
  uploadFolder = 'agencies-products';
  timestamp: string;
  isLoading: boolean;

  public categories: Observable<Collection<Category>>;

  counter = [1];

  /**
   * @constructor
   * @param {FormBuilder} fb Creates a form group for a new store product  
   * @param {navLocation} location Current navigation location
   * @param {StoreProductService} productService Service for products management
   * @param {AlertService} alertService Service for alerts management
   * @param {CategoryService} categoryService Service for categories management
   */
  constructor(
    private fb: FormBuilder,
    private location: navLocation,
    private productService: StoreProductService,
    private alertService: AlertService,
    private categoryService: CategoryService
  ) { }

  ngOnInit() {
    this.getCategories();
    this.timestamp = new Date().getTime().toString();
    this.createProductForm();
  }

  /**
   * Description: Gets all the categories that have not been deleted.
   * @author Maximiliano Casale
   * @returns void
   */
  public getCategories(): void {
    this.categories = this.categoryService.getAll(ref => {
      return ref.where('deleted', '==', false);
    }).asObservable()
  }

  /**
   * Description: Creates a form group for a new store product.
   * @author Maximiliano Casale
   * @returns void
   */
  createProductForm() {
    this.productForm = this.fb.group({
      name: ['', [Validators.required]], // Validators.pattern('[a-zA-ZñÑ\\0-9\\s :]*')]],
      sku: ['', [Validators.required]],
      description: ['', [Validators.required]], // Validators.pattern('[a-zA-ZñÑ\\0-9\\s]*')]],
      price: [0, [Validators.required, Validators.pattern('^(0*[1-9][0-9]*(\.[0-9]+)?|0\.[0-9]*[1-9][0-9]*)$')]],
      tax: [0, [Validators.required, Validators.min(0)]],
      width: [0, [Validators.required, Validators.min(0)]],
      height: [0, [Validators.required, Validators.min(0)]],
      length: [0, [Validators.required, Validators.min(0)]],
      weight: [0, [Validators.required, Validators.min(0)]],
      stock: [0, [Validators.required, Validators.min(0)]],
      category_path: ['', [Validators.required]]
    });
  }

  /**
   * Description: Creates a new store product. If the product is created successfully created, shows
   * a success alert. If there is an error, shows a warning alert.
   * @author Maximiliano Casale
   * @returns void
   */
  save() {
    this.isLoading = true;
    const data = this.productForm.value;
    data.timestamp = [];
    for (let i = 0; i < this.counter.length; i++) {
      data.timestamp[i] = this.timestamp + '-' + i;
    }
    data.image = this.imgUrl;
    data.deleted = false;
    this.productService.create(data).then(res => {
      console.log(res);
      this.alertService.showAlert('Product updated successfully', 'alert-success');
      this.location.back();
    }).catch(err => {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    });
  }

  /**
   * Description: Resets the product form to its defaut values and navigates to the previous navigation
   * location.
   * @author Maximiliano Casale
   * @returns void
   */
  cancel(): void {
    this.productForm.reset();
    this.location.back();
  }

  /**
   * Description: Sets to null the image url of the store product form.
   * @author Maximiliano Casale
   * @param {number} index
   * @returns void 
   */
  resetUpload(index: number): void {
    this.imgUrl[index] = null;
  }

  /**
   * Description: Sets the image url with the url received as param.
   * @author Maximiliano Casale
   * @param {Observable<string>} url 
   * @param {any} index
   * @returns void 
   */
  onUpload(url: Observable<string>, index): void {
    url.subscribe((s) => {
      this.imgUrl[index] = s;
      // console.log(s);
    });
  }

}
