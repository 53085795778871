import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';

export class iCategory {
  name: string;
  deleted: boolean;
}

export class Category extends Entity implements iCategory {
  public name: string;
  public deleted: boolean;

  public __toPromise() {
    return this.__promise as Promise<Category>;
  }

  public __asObservable() {
    return this.__subject.asObservable() as Observable<Category>;
  }

  constructor(path: string, name?: string, deleted?: boolean) {
    super(Category, path);
    if (name) {
      this.name = name;
    }
    if (deleted) {
      this.deleted = deleted;
    }
  }
}
