import { Component, OnInit } from '@angular/core';
import { Locker, iLocker } from 'src/app/models/locker';
import { LockerService } from 'src/app/services/locker.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ClrDatagridStateInterface } from '@clr/angular';
import { DateClrDatagridFilterInterfaceComponent } from 'src/app/components/datagrid-filters/date-filter.component';
import { CompleteFilterComponent } from 'src/app/components/complete-filter/complete-filter.component';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-locker-list',
  templateUrl: './locker-list.component.html',
  styleUrls: ['./locker-list.component.scss']
})
export class LockerListComponent implements OnInit {

  public lockerList: Observable<Collection<Locker>>;

  public filtersForm: FormGroup; // Filters
  public filters: Array<any>;
  public pagination: any;
  public fetching = false;
  private userAgencyPath: string;

  /**
   * @constructor
   * @param {LockerService} lockerService Service for lockers management
   * @param {UserService} userService Service for users management
   * @param {Router} router Navigation router
   * @param {FormBuilder} fb Form builder for the locker list filter form
   */
  constructor(private lockerService: LockerService,
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.userService.userProfile.subscribe(u => {
      this.userAgencyPath = u.agency_path;
      this.getLockers(u);
    });
    this.createFilterForm();
  }

  /**
   * Description: Creates the form for the lockers filter.
   * @author Maximiliano Casale
   * @returns void
   */
  public createFilterForm() {
    this.filtersForm = this.fb.group(
      {
        dateFrom: [''],
        dateTo: [''],
        complete: [false]
      });
  }

  /**
   * Description: Maps the filters to the array if there are any.
   * @author Maximiliano Casale
   * @param state filters
   * @returns void
   */
  public refresh(state: ClrDatagridStateInterface): void {
    // mapping of the filters
    const filters: { [prop: string]: any[] } = {};
    if (state.filters) {  // if there are any filters we map them to the array
      for (const filter of state.filters) {
        if (filter instanceof DateClrDatagridFilterInterfaceComponent) {
          filters.dateFilter = (<DateClrDatagridFilterInterfaceComponent>filter).dateValues();
        } else if (filter instanceof CompleteFilterComponent) {
          filters.complete = (<CompleteFilterComponent>filter).isChecked();
        }
      }
    }
    this.pagination = state.page;
    this.filters = Object.entries(filters);
  }

  /**
   * Description: Gets all the incomplete lockers of the agency ordered by the creation date as an observable.
   * @author Maximiliano Casale
   * @returns void
   */
  public getLockers(user: User): void {
    if (user) {
      if (user.role === 'admin') {
        this.lockerList = this.lockerService.getAll((ref) => {
          return ref.where('complete', '==', false).orderBy('createdAt');
        }).asObservable();
      } else {
        this.lockerList = this.lockerService.getAll((ref) => {
          return ref.where('agency_path', '==', this.userAgencyPath).where('complete', '==', false).orderBy('createdAt');
        }).asObservable();
      }
    }

    // this.lockerList = this.lockerService.getAll((ref) => {
    //   return ref.where('agency_path', '==', this.userAgencyPath).where('complete', '==', false).orderBy('createdAt');
    // }).asObservable()


  }

  /**
   * Description: Navigates to the selected locker's details' view.
   * @author Maximiliano Casale
   * @param {any} id Id of the selected locker
   * @returns void
   */
  public lockerDetails(id): void {
    this.router.navigate([`locker-details/${id}`]);
  }

  /**
   * Description: Filters the lockers list according to the applied filter parameters.
   * @author Maximiliano Casale
   * @returns void
   */
  public filter(): void {
    this.fetching = true;
    this.lockerList = this.lockerService.searchFiltered(this.filters, this.pagination, this.userAgencyPath).asObservable();
    this.lockerList.subscribe(_ => this.fetching = false);
  }

  /**
   * Description: Resets filter's form.
   * @author Maximiliano Casale
   * @returns void
   */
  resetFilter(): void {
    this.filtersForm.reset();
    this.filters = [];
    this.filter();
  }

}
