import { Observable } from 'rxjs';
import { Entity } from 'aether-blaze';

import { Agency } from './agency';
import { User } from './user';
import { Timestamp } from 'rxjs/internal/operators/timestamp';



export interface iLocker {
    id: string;
    user_path: string;
    agency_path: string;
    shipments: {
        shipper: string,
        trackings?: {
            tracking: string,
            complete: boolean,
        }[],
        img?: string,
        totalAmount: number,
        complete: boolean,
    }[];
    deleted: boolean;
    createdAt: firebase.firestore.Timestamp;
    comments: string;
    complete: boolean;

}

export class Locker extends Entity implements iLocker {

    public id: string;
    public user_path: string;
    public agency_path: string;
    public shipments: {
        shipper: string,
        trackings?: {
            tracking: string,
            complete: boolean,
        }[],
        img?: string,
        totalAmount: number,
        complete: boolean,
    }[];
    public deleted: boolean;
    public createdAt: firebase.firestore.Timestamp;
    public comments: string;
    public complete: boolean;



    public get agency() {
        if (this.agency_path) {
            return new Agency(this.agency_path);
        }
    }

    public get user() {
        if (this.user_path) {
            return new User(this.user_path);
        }
    }

    /**
     * Description: Returns the locker as a promise.
     * @author Maximiliano Casale
     * @returns {Promise<Locker>}
     */
    public __toPromise() {
        return this.__promise as Promise<Locker>;
    }

    /**
     * Description: Returns the locker as an observable.
     * @author Maximiliano Casale
     * @returns {Observable<Locker>}
     */
    public __asObservable() {
        return (this.__subject.asObservable() as Observable<Locker>);
    }

    /**
     * @constructor
     * @param {string} path Locker's path
     * @param {string} id Locker's id
     * @param {string} user_path Locker's user
     * @param {string} agency_path Agency to which the locker belongs
     * @param shipments Shipments related to the locker
     * @param {boolean} deleted Is the locker deleted or not
     * @param {Timestamp} createdAt Date when the locker was created
     * @param {string} comments Comments on the locker
     * @param {boolean} complete Is the locker complete or not
     */
    constructor(path: string,
        id?: string,
        user_path?: string,
        agency_path?: string,
        shipments?: {
            shipper: string,
            trackings?: {
                tracking: string,
                complete: boolean,
            }[],
            img?: string,
            totalAmount: number,
            complete: boolean,
        }[],
        deleted?: boolean,
        createdAt?: firebase.firestore.Timestamp,
        comments?: string,
        complete?: boolean
    ) {
        super(Locker, path);
        if (id) {
            this.id = id;
        }
        if (user_path) {
            this.user_path = user_path;
        }
        if (agency_path) {
            this.agency_path = agency_path;
        }
        if (shipments) {
            this.shipments = shipments;
        }
        if (deleted !== undefined) {
            this.deleted = deleted;
        }
        if (createdAt) {
            this.createdAt = createdAt;
        }
        if (comments) {
            this.comments = comments;
        }
        if (complete !== undefined) {
            this.complete = complete;
        }
    }
}
