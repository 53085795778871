import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AgencyService } from '../../services/agency.service';
import { User, Agency } from '../../models';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  public basic = false;
  public profile: User;
  agencies: Observable<Collection<Agency>>;

  public selected: User;
  public newAgency: Agency;


  public roleFilterOptions = [
    { key: 'user', value: 'user' },
    { key: 'agent', value: 'agent' },
    { key: 'manager', value: 'manager' },
    { key: 'manager-ccarga', value: 'manager-ccarga' },
    // { key: 'admin', value: 'admin' }
  ];

  public statusFilterOptions = [
    { key: true, value: 'enabled' },
    { key: false, value: 'disabled' }
  ];

  users: Observable<Collection<User>>;
  usersIterable: User[];

  /**
   * @constructor
   * @param {UserService} userService Service for users management 
   * @param {AgencyService} agencyService Service for agencies management
   */
  constructor(
    public userService: UserService,
    private agencyService: AgencyService) { }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      this.profile = p;
      this.getUsers(this.profile);
    });
  }

  /**
   * Description: Gets all the users of the app.
   * @author Mauro Lanza
   * @param profile Current logged user
   * @returns void
   */
  getUsers(profile: User): void {
    if (profile) {
      if (profile.role === 'admin') {
        this.agencies = this.agencyService.getAll().asObservable();
        this.users = this.userService.getAll().asObservable();
      } else {
        this.users = this.userService.getAll(ref => ref.where('agency_path', '==', profile.agency_path)).asObservable();
      }
    }
  }

  /**
   * Description: Shows certain users depending of the current logged user role.
   * @author Mauro Lanza
   * @returns void
   */
  public export() {
    if (this.profile.role === 'admin') {
      this.userService.exportAll();
    } else {
      this.userService.exportAll(this.profile.agency_path);
    }
  }

  /**
   * Description: This function toggles the status of the selected account between enabled or 
   * disabled.
   * @author Mauro Lanza
   * @param {any} user Current user selected 
   * @returns {Promise<void>}
   */
  async toggleStatus(user): Promise<void> {
    let usr = user.__id;
    usr = this.userService.get(usr);
    usr = await usr.__toPromise();
    usr.status = (user.status ? false : true);
    this.userService.update(usr);
  }

  /**
   * Description: This function toggles the role of the selected account between admin or user.
   * @author Mauro Lanza
   * @param {any} user Current user selected 
   * @returns {Promise<void>}
   */
  async changeRole(user): Promise<void> {
    let usr = user.__id;
    usr = this.userService.get(usr);
    usr = await usr.__toPromise();
    usr.role = user.newRole;
    delete user.toggle_role;
    delete user.newRole;
    this.userService.update(usr);
  }

  /**
   * Description: This function toggles the parameter that shows/hides the options to change the 
   * account role.
   * @author Mauro Lanza
   * @param {any} user Current user selected 
   * @returns void
   */
  toggleRole(user): void {
    if (user.toggle_role) {
      delete user.toggle_role;
      delete user.newRole;
    } else {
      user.toggle_role = true;
      user.newRole = '';
    }
  }

  /**
   * Description: Changes the agency related to the selected user and deletes from the options the
   * selected agency.
   * @author Mauro Lanza
   * @param {any} user User selected to change its agency
   * @returns {Promise<void>}
   */
  async changeAgency(user): Promise<void> {
    // user = this.selected;
    if (this.newAgency) {
      console.log(user.newAgency);
      let usr = user.__id;
      usr = this.userService.get(usr);
      usr = await usr.__toPromise();
      usr.agency_path = this.newAgency.__path;
      delete user.toggle_agency;
      // delete user.newAgency;
      this.userService.update(usr);
    } else {
      alert('No agency selected');
      console.log(user.newAgency);
    }
  }

  /**
   * Description: Shows/hides the agencies available to the selected user.
   * @author Mauro Lanza
   * @param {any} user User selected to change its agency
   * @returns void
   */
  toggleAgency(user): void {
    if (user.toggle_agency) {
      delete user.toggle_agency;
      // delete user.newAgency;
    } else {
      user.toggle_agency = true;
      // user.newAgency = this.profile.agency;
    }
  }

  /**
   * Description: Allows to show the edit view of the selected user.
   * @author Mauro Lanza
   * @param {any} user User selected to edit
   * @returns void 
   */
  editUser(user): void {
    this.selected = user;
    this.basic = true;
  }

}
