import { Injectable } from '@angular/core';
import { StoreProduct } from '../models/store-product';
import { Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from 'tough-cookie';

@Injectable({
  providedIn: 'root'
})
export class StoreProductService {

  private pathUrl = '/store-products/';
  public storeProduct: StoreProduct;

  /**
   * @constructor
   * @param {AngularFirestore} afs Document-oriented database 
   */
  constructor(
    private afs: AngularFirestore
  ) { }

  /**
   * Description: Gets a store product by its id.
   * @author Jorge Del Castillo
   * @param {string} id
   * @returns {StoreProduct} 
   */
  public get(id: string): StoreProduct {
    return new StoreProduct(this.pathUrl + id);
  }

  /**
   * Description: Gets all the collection of store products.
   * @author Jorge Del Castillo
   * @param query 
   * @returns {Collection<StoreProduct>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<StoreProduct> {
    if (query) {
      return new Collection<StoreProduct>(StoreProduct, this.pathUrl, query);
    } else {
      return new Collection<StoreProduct>(StoreProduct, this.pathUrl);
    }
  }

  /**
   * Description: Adds a new store product to the collection.
   * @author Jorge Del Castillo
   * @param {StoreProduct} product 
   * @returns {Promise<StoreProduct>} 
   */
  public create(product: StoreProduct): Promise<StoreProduct> {
    return new Promise<StoreProduct>((resolve) => {
      firebase.firestore().collection(this.pathUrl).add(product).then((docRef) => {
        this.get(docRef.id).__toPromise().then((p) => {
          resolve(p);
        });
      });
    });
  }

  /**
   * Description: Updates the data of the store product received as param.
   * @author Jorge Del Castillo
   * @param {StoreProduct} data
   * @returns {Promise<void>} 
   */
  public update(data: StoreProduct): Promise<void> {
    return data.__save(true);
  }

}
