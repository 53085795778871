import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgentGuard implements CanActivate {

  /**
   * @constructor
   * @param {UserService} userService Service for users management
   * @param {Router} router Navigation router
   */
  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  /**
   * Description: Allows or denies access depending on the user's role.
   * @author Alejandro Font
   * @param {ActivatedRouteSnapshot} next Route that the user wants to access
   * @param {RouterStateSnapshot} state Represents the state of the router at the moment
   * @returns {boolean | Observable<boolean> | Promise<boolean>}
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.userProfile.pipe(
      map(user => {
        if (user && user.role) {

          switch (user.role) {
            case 'agent': return true;
            case 'manager': return true;
            case 'manager-ccarga': return true;
            default: {
              console.log('Acess denied');
              this.router.navigate(['']);
              return false;
            }
          }

        } else {
          console.log('Acess denied');
          this.router.navigate(['']);
          return false;
        }
      }));
  }

}
