import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ShippingService } from 'src/app/services/shipping.service';
import { User, Agency } from 'src/app/models';
import { UserService } from 'src/app/services/user.service';
import { ManifestService } from 'src/app/services/manifest.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ClrDatagridStateInterface } from '@clr/angular';
import { DateClrDatagridFilterInterfaceComponent } from '../datagrid-filters/date-filter.component';
import { AgencyService } from 'src/app/services/agency.service';
import { OrderService } from 'src/app/services/order.service';
import * as firebase from 'firebase';
import { user } from 'firebase-functions/lib/providers/auth';

@Component({
  selector: 'app-manifest-list',
  templateUrl: './manifest-list.component.html',
  styleUrls: ['./manifest-list.component.scss']
})
export class ManifestListComponent implements OnInit {

  @Input() data: any = [];
  @Input() dataType: string;
  @Input() dataClass: string;

  user: User;

  basic = false;
  start_date_: Date;
  end_date_: Date;
  start_date: any;
  public fetching = false;
  end_date: any;
  dateFrom;
  agencyToExport: string;
  dateTo;
  public filters: Array<any>;
  public pagination: any;
  public filtersForm: FormGroup; // Filters

  selected = [];
  agencies: any;

  /**
   * @constructor
   * @param {FormBuilder} fb Creates filter's form
   * @param {Router} router Navigation router
   * @param {DatePipe} datePipe Transforms string into date format
   * @param {ShippingService} shippingService Service for shipments management
   * @param {UserService} userService Service for users management
   * @param {ManifestService} manifestService Service for manifests management
   */
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private shippingService: ShippingService,
    private userService: UserService,
    private manifestService: ManifestService,
    private agencyService: AgencyService) { }

  ngOnInit() {
    this.userService.userProfile.subscribe(p => {
      this.user = p;
      this.agencyToExport = p.agency_path;
    });
    this.createFilterForm();
    this.agencies = this.agencyService.getAll().asObservable();

  }

  /**
   * Description: Creates form for date filter.
   * @author Mauro Lanza
   * @returns void
   */
  public createFilterForm() {
    this.filtersForm = this.fb.group(
      {
        dateFrom: [''],
        dateTo: [''],
      });
  }

  /**
   * Description: Maps the filters to the array if there are any.
   * @author Mauro Lanza
   * @param {ClrDatagridStateInterface} state State of the date filter
   * @returns void
   */
  public refresh(state: ClrDatagridStateInterface): void {


    const filters: { [prop: string]: any[] } = {};
    if (state.filters) {
      for (const filter of state.filters) {
        if (filter instanceof DateClrDatagridFilterInterfaceComponent) {
          filters.dateFilter = (<DateClrDatagridFilterInterfaceComponent>filter).dateValues();
        }
      }
    }
    this.pagination = state.page;
    this.filters = Object.entries(filters);
  }

  /**
   * Description: This function checks the data type and then it redirects to the proper view depending if
   * the data type is manifest or shipment.
   * @author Mauro Lanza
   * @param id Identifier of the selected shipment or manisfest to view its details
   * @returns void
   */
  viewDetails(id) {
    switch (this.dataType) {
      case 'manifest':
        this.router.navigate(['/manifest-details', id]);
        break;
      case 'shipment':
        this.router.navigate(['/shipment-details', id]);
        break;
      default:
        break;
    }
  }

  /**
   * Description: ???
   * @author Mauro Lanza
   * @param {any} bareNum
   * @returns {string}
   */
  public padNum(bareNum): string {
    bareNum = bareNum + '';
    const zeroString = '000000';
    return zeroString.substring(bareNum.length, 6) + bareNum;
  }

  /**
   * Description: It changes to true the condition that shows the modal of shipping creation.
   * @author Mauro Lanza
   * @returns void
   */
  public generateShipping() {
    const manifestsPath: Array<string> = [];
    const createdDate = new Date();
    let numberID: number;
    this.selected.forEach(element => {
      manifestsPath.push(element.__id);
    });
    // console.log(manifestsPath);
    if (this.dataClass === 'package') {

      const year = this.datePipe.transform(createdDate, 'yy');
      this.shippingService.getNextOrderNumber().then((number) => {
        let id: string;
        numberID = number;
        id = `SHIP-PA-${this.datePipe.transform(createdDate, 'MMM')}-${year}-${this.padNum(numberID)}`;
        this.shippingService.create(
          id,
          manifestsPath,
          createdDate.toString(),
          this.user.__path,
          this.user.agency_path,
          numberID,
          year,
          false,
          'package'
        ).__toPromise().then(res => {
          this.assingShippings(res.__path, manifestsPath);
        }).catch(err => { console.error(err); });
      });


    } else {

      const year = this.datePipe.transform(createdDate, 'yy');
      this.shippingService.getNextOrderNumber().then((number) => {
        let id: string;
        numberID = number;
        id = `SHIP-MO-${this.datePipe.transform(createdDate, 'MMM')}-${year}-${this.padNum(numberID)}`;
        this.shippingService.create(
          id,
          manifestsPath,
          createdDate.toString(),
          this.user.__path,
          this.user.agency_path,
          numberID,
          year,
          false,
          'money'
        ).__toPromise().then(res => {
          this.assingShippings(res.__path, manifestsPath);
        }).catch(err => { console.error(err); });
      });


    }
    this.selected = [];
    this.basic = false;
  }

  /**
   * Description: Assigns the manifest path to all orders in the newly created manifest.
   * @author Maxmiliano Casale
   * @param {string} shippingId Id of the shipping that corresponds to a manifest
   * @param {Array<string>} manifests All the manifests
   * @returns void
   */
  public assingShippings(shippingId: string, manifests: Array<string>): void {
    manifests.forEach(manifest_path => {
      this.manifestService.get(manifest_path).__toPromise().then(manifest => {
        manifest.shipping = shippingId;
        this.manifestService.update(manifest);
      }).catch(err => {
        console.log('98', err);
      });
    });
  }

  /**
  * Description: The function receives a parameter and checks if it is true or not.
  * If so, it stores and applies the start and end dates entered by the user, and if not it sets the dates
  * to null.
  * @author Mauro Lanza
  * @returns void
  */
  public filter(): void {
    this.fetching = true;
    if (this.dataType === 'manifest') {
      this.data = this.manifestService.searchFiltered(this.filters, this.pagination, this.dataClass, this.user).asObservable();
      this.data.subscribe(_ => this.fetching = false);
    } else if (this.dataType === 'shipment') {
      this.data = this.shippingService.searchFiltered(this.filters, this.pagination, this.dataClass).asObservable();
      this.data.subscribe(_ => this.fetching = false);
    }
  }

  /**
   * Description: Resets the manifests filter form.
   * @author Mauro Lanza
   * @returns void
   */
  resetFilter(): void {
    this.filtersForm.reset();
    this.filters = [];
    this.filter();
  }

  async exportExcel() {
    this.manifestService.exportAll(this.agencyToExport, this.dateFrom, this.dateTo);
  }

}
