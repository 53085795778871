import { Component, Input, SimpleChanges, EventEmitter } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Order, Agency } from '../../models';

@Component({
  selector: 'app-checkbox-filter',
  template: ``,
  styleUrls: ['../../app.component.scss']
})
export class CheckboxClrDatagridFilterInterfaceComponent implements ClrDatagridFilterInterface<Order> {

  // boolean for filtering
  @Input() checked: boolean;

  changes: EventEmitter<any> = new EventEmitter<any>(false);

  /**
   * Description: Returns the checked items.
   * @author Maximiliano Casale
   * @returns {any[]} Checked items
   */
  isChecked(): any[] {
    return [this.checked];
  }

  /**
   * Description: It emits true if there is a change in the filter condition.
   * @author Maximiliano Casale
   * @param {SimpleChanges} changes Change in the checked items
   * @returns void
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.checked) {
      this.changes.emit(true);
    }
  }

  /**
   * Description: This function returns true if the checkbox is checked, and false if not.
   * @author Maximiliano Casale
   * @returns {boolean} 
   */
  isActive(): boolean {
    return this.checked;
  }

  /**
   * Description: This function returns true if the order is deleted and false if not.
   * @author Maximiliano Casale
   * @param {any} item Item of the order list.
   * @returns {boolean}
   */
  accepts(item) {
    return item.deleted === this.checked;
  }

}
