import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { tap } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { reject } from 'q';
import { resolve } from 'url';
import { encode } from 'punycode';


@Component({
  selector: 'app-file-upload-base64',
  templateUrl: './file-upload-base64.component.html',
  styleUrls: ['./file-upload-base64.component.scss']
})
export class FileUploadBase64Component {

  @Input()
  public folder: string;
  @Input()
  public timestamp: string;

  // Download URL
  downloadURL = new Observable<string>();

  @Output()
  uploadUrl = new EventEmitter<Observable<string>>();

  @Output()
  uploadData = new EventEmitter<{}>();

  @Output()
  uploading = new EventEmitter<boolean>();

  // State for dropzone CSS toggling
  isHovering: boolean;

  /**
   * @constructor
   * @param {AngularFireStorage} storage Firebase storage
   * @param {AngularFirestore} afs
   */
  constructor(private storage: AngularFireStorage, private afs: AngularFirestore) { }

  uploadPercent: Observable<number>;

  /**
   * Description: Manages image upload.
   * @author Jorge Del Castillo
   * @param {any} event
   * @returns {Promise<string>}
   */
  base64(event): Promise<string> {
    if (event.target.files[0].type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      return;
    }

    // tslint:disable-next-line:no-shadowed-variable
    return new Promise<string>((resolve, reject) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const encoded = reader.result as string;
        // encoded.replace('data:image/png;base64,', '');
        // if ((encoded.length % 4) > 0) {
        // encoded += '='.repeat(4 - (encoded.length % 4));
        // }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
    // console.log('str', str);
  }

  /**
   * Description: Manages files upload.
   * @author Jorge Del Castillo
   * @param {any} event
   * @returns void
   */
  uploadFile(event) {
    this.base64(event).then((encoded) => {
      if (encoded) {
        // encoded = encoded.substring(23);
        // console.log('enconded', encoded.substring(23));

        // const file = event.target.files[0].toString();
        const filePath = `${this.folder}/${this.timestamp}`;
        const fileRef = this.storage.ref(filePath);
        const task = fileRef.putString(encoded, 'data_url');
        // const task = fileRef.putString(encoded, 'base64');
        // const task = this.storage.upload(filePath, file);

        // observe percentage changes
        this.uploadPercent = task.percentageChanges();
        // get notified when the download URL is available
        task.snapshotChanges().pipe(
          finalize(() => {
            this.downloadURL = fileRef.getDownloadURL();
            this.storage.ref(filePath);
            this.downloadURL.subscribe((s) => {
              // console.log('timestamp', this.timestamp);

              this.afs.collection('images').doc(this.timestamp).set({ filePath, image: s, base64: encoded });
              this.uploadData.emit({ image: s });
            });
            // Update firestore on completion
            this.uploadUrl.emit(this.downloadURL);
          })
        )
          .subscribe();
      }
    });
  }

  /**
   * Description: Event listener.
   * @author Jorge Del Castillo
   * @param {boolean} event Mouse hovers over a toggle
   * @returns void
   */
  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  /**
   * Description: Determines if the upload task is active.
   * @author Jorge Del Castillo
   * @param {any} snapshot
   * @returns {boolean}
   */
  isActive(snapshot) {
    const condition = snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
    this.uploading.emit(condition);
    return condition;
  }

}
