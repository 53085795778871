import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { User } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ClarityModule, ClrFormsModule } from '@clr/angular';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  logIn = true;
  accessDenied = false;
  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public forgotForm: FormGroup;
  msgLog: any;
  isLoading: boolean;

  /**
   * @constructor
   * @param {UserService} userService Service for users management
   * @param {AuthService} authService Service for users authentication
   * @param {FormBuilder} fb Builder of the forms needed
   * @param {Router} router Navigation router
   */
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.createLoginForm();
    this.createForgotForm();
    // this.createRegisterForm();
  }

  /**
   * Description: Creates the user login form with email and password required to log in.
   * @author Sarkis
   * @returns void
   */
  createLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  /**
   * Description: Form to recover password.
   * @author Sarkis
   * @returns void
   */
  createForgotForm() {
    this.forgotForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  /**
   * Description: Creates a form to register a user.
   * @author Sarkis
   * @returns void
   */
  createRegisterForm() {
    this.registerForm = this.fb.group({
      emailR: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      passwordR: ['', [Validators.required, Validators.minLength(8)]],
      lastname: ['', [Validators.required]],
    });
  }

  /**
   * Description: Allows log in with google.
   * @author Jesus Ponte
   * @returns void
   */
  loginGoogle(): void {
    this.authService.loginGoogle()
      .then(data => {
        // console.log(data);
        this.successCallback(data);

      })
      .catch((err) => {
        console.log('Algo Malo Sucedio en google login');
      });
  }

  /**
   * Description: Resets user's password in case the user forgets it.
   * @author Sarkis
   * @returns void
   */
  forgotPasswordHandler() {
    this.authService.resetPassword(this.forgotForm.value.email).then((str) => {
      this.msgLog = str;
      this.toggleLogIn();
    });

    // this.auth.sendPasswordResetEmail(emailAddress).then(function() {
    // Email sent.
    // }).catch(function(error) {
    // An error happened.
    // });
  }

  /**
   * Description: Handles user registry.
   * @author Sarkis
   * @returns void
   */
  registerHandler(): void {
    // console.log('CLICK');
    const email = this.registerForm.value.emailR;
    const name = this.registerForm.value.name;
    const lastname = this.registerForm.value.lastname;
    const pwd = this.registerForm.value.passwordR;

    this.authService.registerByEmail(email, String(pwd))
      .then(data => {
        this.successCallbackEmail(data, name, lastname);
      })
      .catch((err) => {
        console.log('Algo Malo sucedió, y no pudimos procesar tu petición de registro.');
      });
  }

  /**
   * Description: Handles user's login.
   * @author Sarkis
   * @returns void
   */
  loginHandler() {
    const email = this.loginForm.value.email;
    const pwd = this.loginForm.value.password;
    this.isLoading = true;
    this.authService.loginEmail(email, pwd)
      .then(data => {
        // console.log("Logueado");
        this.userService.userProfile.subscribe(profile => {
          if (profile) {
            this.router.navigate(['']);
          }
        });
      })
      .catch((err) => {
        this.isLoading = false;
        this.accessDenied = true;
        this.loginForm.reset();
        console.log('Algo malo sucedió en login');
      });
  }

  /**
   * Description: Navigates to the user's profile view if the login is successful.
   * @author Sarkis
   * @param $event Successful login
   * @returns void
   */
  successCallback($event: any) {
    this.userService.signInSuccessCallback($event).then(() => {
      this.router.navigate(['/profile/edit']);
    }).catch(() => {
      alert('Something went terribly wrong, please try again later...');
    });
  }

  /**
   * Description: Navigates to the user's profile view if the login is successful.
   * @author Sarkis
   * @param $event login
   * @param name user's name
   * @param lastname user's lastname
   * @returns void
   */
  successCallbackEmail($event: any, name, lastname) {
    this.userService.signInSuccessCallbackEmail($event, name, lastname).then(() => {
      this.router.navigate(['/profile/edit']);
    }).catch(() => {
      alert('Something went terribly wrong, please try again later...');
    });
  }

  /**
   * Description: Shows/hides login button.
   * @author Jesus Ponte
   * @returns void
   */
  toggleLogIn() {
    this.logIn = !this.logIn;
  }

}
