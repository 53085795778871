import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PromotionType } from 'src/app/models/promotion';

@Component({
  selector: 'app-boxes-table',
  templateUrl: './boxes-table.component.html',
  styleUrls: ['./boxes-table.component.scss']
})
export class BoxesTableComponent implements OnInit {

  @Input() boxes: Array<any>;
  @Input() shippingCost: number;
  @Input() privateShippingCost: number;
  @Input() insurance: number;
  @Input() countryTax: number;
  @Input() packingService: number;
  @Input() total: number;
  @Input() delete: boolean;
  @Input() indexStartAt = 0;
  @Input() customsManagement: number;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onDelete = new EventEmitter();

  public get hasPromotion() {
    return this.boxes.some((b) => b.promotionType !== PromotionType.NONE)
  }

  public promotionType = PromotionType;

  /**
   * @constructor
   */
  constructor() { }

  ngOnInit() {

  }

  /**
   * Description: Removes the selected box from the boxes array and modifies the shipping info
   * considering the deleted box's info.
   * @author Maximiliano Casale
   * @param  {any} i Position to remove box
   * @returns void
   */
  public deleteBox(i): void {
    const box = this.boxes.splice(i, 1);
    // console.log('in boxes', box);

    this.privateShippingCost -= box[0].privateShippingCost;
    this.shippingCost -= box[0].priceBox;
    this.insurance -= box[0].insuranceCost;
    this.countryTax -= box[0].tax;
    this.packingService -= box[0].packingValue;

    // this.packingService = this.orderForm.controls['packingValue'].value || 0;
    this.total = Math.ceil(this.shippingCost + this.insurance + this.countryTax + this.packingService);

    this.onDelete.emit(box);
  }

}
