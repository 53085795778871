import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AgencyService } from '../../../services/agency.service';
import { Location } from '@angular/common';
import { Agency } from '../../../models';

@Component({
  selector: 'app-agency-details',
  templateUrl: './agency-details.component.html',
  styleUrls: ['./agency-details.component.scss']
})
export class AgencyDetailsComponent implements OnInit {

  agency: Agency;

  /**
   * @constructor
   * @param {Router} router Route management
   * @param {Location} location Current navigation location
   * @param {ActivatedRoute} route Current route
   * @param {AgencyService} agencyService Service for the agencies management
   */
  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private agencyService: AgencyService,
  ) { }

  ngOnInit() {
    this.agency = this.agencyService.get(this.route.snapshot.params['id']);
  }

  /**
   * Description: Navigates to edit view of the agency selected.
   * @author Jorge Del Castillo
   * @param {string} id Id of the agency selected to edit
   * @returns void
   */
  edit(id: string): void {
    this.router.navigate(['agencies/edit', id]);
  }

  /**
   * Description: Navigates back to the previous view.
   * @author Jorge Del Castillo
   * @returns void
   */
  goBack(): void {
    this.location.back();
  }

}
