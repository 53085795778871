import { concat, of, Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';

export function typeAhead(
    input: Observable<string>,
    searchFn: (text: string) => Observable<any>,
    loadingCb?: (isLoading: boolean) => void,
    minLength: number = 0,
    debounceMilis: number = 500,
) {
    return concat(
        of([]),
        input.pipe(
            map((text) => { // Bodge para arreglar "You provided 'undefined' where a stream was expected"
                if (!text || text.length === 0) {
                    return ' ';
                }
                return text;
            }),
            debounceTime(debounceMilis),
            distinctUntilChanged(),
            tap(() => {
                if (loadingCb) {
                    loadingCb(true);
                }
            }),
            switchMap(text => {
                if (text && text.length > minLength) {
                    return searchFn(text).pipe(
                        tap(() => {
                            if (loadingCb) {
                                loadingCb(false);
                            }
                        }),
                        catchError(() => of([]))
                    );
                } else {
                    of([]);
                }
            }),
        )
    );
}
