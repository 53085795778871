import { Injectable } from '@angular/core';
import { InventoryRequest, Inventory } from '../models';

import { Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { InventoryService } from './inventory.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryRequestService {

  private pathUrl = '/inventory-requests/';
  public inventory: InventoryRequest;

  /**
   * @constructor
   * @param {AngularFirestore} afs Document-oriented database
   */
  constructor(
    private afs: AngularFirestore,
    private inventoryService: InventoryService,
  ) { }

  /**
   * Description: Gets a requested inventory by its id.
   * @author Mauro Lanza
   * @param {string} id Id of the requested inventory
   * @returns {InventoryRequest} 
   */
  public get(id: string): InventoryRequest {
    return new InventoryRequest(this.pathUrl + id);
  }

  /**
   * Description: Gets a specific inventory of a specific agency and product.
   * @author Mauro Lanza
   * @param {string} agency_path Agency of the inventory request 
   * @param {string} product_path Product requested
   * @returns {Collection<InventoryRequest>} Gets all the inventory requests of the specified agency and product
   */
  public getWhere(agency_path: string, product_path: string) {
    return this.getAll(ref => ref.where('agency_path', '==', agency_path).where('product_path', '==', product_path));
  }

  /**
   * Description: Gets all the inventory requests.
   * @author Mauro Lanza 
   * @param query 
   * @returns {Collection<InventoryRequest>}
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<InventoryRequest> {
    if (query) {
      return new Collection<InventoryRequest>(InventoryRequest, this.pathUrl, query);
    } else {
      return new Collection<InventoryRequest>(InventoryRequest, this.pathUrl);
    }
  }

  /**
   * Description: Adds an new inventory request to the collection.
   * @author Mauro Lanza
   * @param {any} inventory New inventory
   * @returns {Promise<InventoryRequest>} Addition confirmation
   */
  public create(inventory): Promise<InventoryRequest> {
    inventory.request_date = firebase.firestore.Timestamp.now();
    inventory.pending = true;
    inventory.status = '';
    inventory.archived = false;
    inventory.deleted = false;
    return new Promise<InventoryRequest>((resolve) => {
      firebase.firestore().collection(this.pathUrl).add(inventory).then((docRef) => {
        this.get(docRef.id).__toPromise().then((p) => {
          resolve(p);
        });
      });
    });
  }

  /**
   * Description: Updates an inventory request's data in the database.
   * @author Mauro Lanza
   * @param {InventoryRequest} data Modified inventory request
   * @returns {Promise<void>} Update confirmation
   */
  public update(data: InventoryRequest): Promise<void> {
    return new Promise<void>((resolve) => {
      data.__save(true).then(() => resolve());
    });
  }

  /**
   * Description: Marks as deleted the inventory request received as param.
   * @author Mauro Lanza
   * @param {InventoryRequest} data Inventory request to be mark as deleted
   * @returns {Promise<void>} Updates data of the inventory request in the collection 
   */
  public delete(data: InventoryRequest): Promise<void> {
    data.deleted = true;
    return this.update(data);
  }

  /**
   * Description: Changes the inventory request's status to accepted.
   * @author Mauro Lanza
   * @param {InventoryRequest} data Inventory request accepted
   * @returns {Promise<void>} Updates the inventory request's data in the database 
   */
  public accept(data: InventoryRequest): Promise<void> {
    data.pending = false;
    data.status = 'Accepted';
    data.response_date = firebase.firestore.Timestamp.now();
    return this.update(data);
  }

  /**
   * Description: Changes the inventory request's status to rejected.
   * @author Mauro Lanza
   * @param {InventoryRequest} data Inventory request rejected
   * @returns {Promise<void>} Updates the inventory request's data in the database 
   */
  public reject(data: InventoryRequest): Promise<void> {
    data.pending = false;
    data.status = 'Rejected';
    data.archived = true;
    data.response_date = firebase.firestore.Timestamp.now();
    return this.update(data);
  }

  public arrived(data: InventoryRequest): Promise<void> {
    const inventory = new Inventory(data.inventory_path);
    inventory.stock += data.stock;
    this.inventoryService.update(inventory);

    data.status = 'Delivered';
    data.archived = true;
    data.arrived_date = firebase.firestore.Timestamp.now();
    return this.update(data);
  }

  public cancelDelivery(data: InventoryRequest): Promise<void> {
    data.status = '';
    data.pending = true;
    delete data.response_date;
    return this.update(data);
  }


}
