import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location as navLocation } from '@angular/common';
import { ProductService } from '../../../services/product.service';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';


@Component({
  selector: 'app-ag-products-create',
  templateUrl: './ag-products-create.component.html',
  styleUrls: ['./ag-products-create.component.scss']
})
export class AgProductsCreateComponent implements OnInit {
  public productForm: FormGroup;

  imgUrl: string;
  uploadFolder = 'agencies-products';
  timestamp: string;
  isLoading: boolean;

  /**
   * @constructor
   * @param {FormBuilder} fb Creates a form group for a new agency product
   * @param {navLocation} location Current navigation location
   * @param {ProductService} productService Service for products management
   * @param {AlertService} alertService Service for alerts management
   */
  constructor(
    private fb: FormBuilder,
    private location: navLocation,
    private productService: ProductService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.timestamp = new Date().getTime().toString();
    this.createProductForm();
  }

  /**
   * Description: Creates a form for a new product.
   * @author Mauro Lanza
   * @returns void
   */
  createProductForm() {
    this.productForm = this.fb.group({
      name: ['', [Validators.required]], // Validators.pattern('[a-zA-ZñÑ\\0-9\\s :]*')]],
      description: ['', [Validators.required]], // Validators.pattern('[a-zA-ZñÑ\\0-9\\s]*')]],
      sku: ['', [Validators.required, Validators.pattern('[a-zA-ZñÑ\\0-9\\s]*')]],
      price: ['', [Validators.required, Validators.pattern('^(0*[1-9][0-9]*(\.[0-9]+)?|0\.[0-9]*[1-9][0-9]*)$')]],
      tax: ['', [Validators.required, Validators.pattern('^(0*[1-9][0-9]*(\.[0-9]+)?|0\.[0-9]*[1-9][0-9]*)$')]],
    });
  }

  /**
   * Description: Declares a data with the value of the product form and sets it the image of the
   * new product, sets its deleted property as false and creates the new product in the database.
   * If the product is successfully created, shows a success alert and navigates to the privious
   * navigation route. If the the product is not successfully created, shows a warning.
   * @author Mauro Lanza
   * @returns void
   */
  save() {
    try {
      this.isLoading = true;

      const data = this.productForm.value;
      data.timestamp = this.timestamp;
      data.image = this.imgUrl;
      data.deleted = false;
      data.agencies = [];
      this.productService.create(data).then(() => {
        this.alertService.showAlert('Product updated successfully', 'alert-success');
        this.location.back();
      });
    } catch (error) {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }

  /**
   * Description: Resets the product form to its default value and navigates to the previous 
   * navigation route.
   * @author Mauro Lanza
   * @returns void
   */
  cancel(): void {
    this.productForm.reset();
    this.location.back();
  }

  /**
   * Description: Sets to null the image url. Resets the image upload.
   * @author Mauro Lanza
   * @returns void
   */
  resetUpload(): void {
    this.imgUrl = null;
  }

  /**
   * Description: Subscribes to the product's image url on its upload.
   * @author Mauro Lanza
   * @param {Observable<string>} url Product's image's url
   * @returns void
   */
  onUpload(url: Observable<string>): void {
    url.subscribe((s) => {
      this.imgUrl = s;
      // console.log(s);
    });
  }

}
