import { Order } from './../../../models/order';
import { Component, OnInit } from '@angular/core';
import { StoreOrderService } from 'src/app/services/store-order.service';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { StoreOrder } from 'src/app/models';
import { ClrDatagridStateInterface } from '@clr/angular';
import { DateClrDatagridFilterInterfaceComponent } from 'src/app/components/datagrid-filters/date-filter.component';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-store-orders-list',
  templateUrl: './store-orders-list.component.html',
  styleUrls: ['./store-orders-list.component.scss']
})
export class StoreOrdersListComponent implements OnInit {

  loading = false;

  public pagination: any;
  public filters: Array<any>;
  storeOrders: Observable<Collection<StoreOrder>>;

  /**
   * @constructor
   * @param {StoreOrderService} storeOrdersService Service for store orders management 
   */
  constructor(
    private storeOrdersService: StoreOrderService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getStoreOrders();
    console.log(this.storeOrders)
  }

  /**
   * Description: Gets all the store orders as an observable.
   * @returns {any} 
   */
  getStoreOrders(): any {
    this.storeOrders = this.storeOrdersService.getAll(ref => {
      return ref.orderBy('date', 'desc')
    }).asObservable()
      // .subscribe(orders => {
      //   this.storeOrders = orders.iterable;
      // });
  }

  /**
   * Description: Filters store orders.
   * @param {ClrDatagridStateInterface<any>} state
   * @returns void 
   */
  public refresh(state: ClrDatagridStateInterface): void {

    // mapping of the filters
    let filters: { [prop: string]: any[] } = {};
    if (state.filters) {  // if there are any filters we map them to the array
      for (let filter of state.filters) {
        if (filter instanceof DateClrDatagridFilterInterfaceComponent) {
          filters.dateFilter = (<DateClrDatagridFilterInterfaceComponent>filter).dateValues();
        }
      }
    }
    this.pagination = state.page;
    this.filters = Object.entries(filters);
  }

  viewDetails(id: string): void {
    this.router.navigate(['storeOrders/details', id]);
  }

}
