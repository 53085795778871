import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BillService } from '../../services/bill.service';
import { ProductService } from '../../services/product.service';
import { InventoryService } from '../../services/inventory.service';
import { UserService } from '../../services/user.service';
import { Observable } from 'rxjs';
import { Collection } from 'aether-blaze';
import { Product } from '../../models';
import { Inventory, User, Bill } from '../../models';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-store-purchase',
  templateUrl: './store-purchase.component.html',
  styleUrls: ['./store-purchase.component.scss']
})
export class StorePurchaseComponent implements OnInit {

  public productsModal = false;

  public productForm: FormGroup;
  public selectedInventory: Inventory;
  public cart = [];
  public cartTotal = 0;
  public toggleConfirm = false;
  public selectedPayment: string;
  public products: Observable<Collection<Product>>;
  public inventories: Observable<Collection<Inventory>>;
  public profile: User;
  public isLoading = false;

  /**
   * @constructor 
   * @param {FormBuilder} fb  
   * @param {Router} router Enables navigation from one view to the next as users perform application tasks
   * @param {ProductService} productService Service for products management
   * @param {InventoryService} inventoryService Service for inventories management
   * @param {UserService} userService Service for users management
   * @param {BillService} billService Service for bills management
   * @param {AlertService} alertService Service for alerts management
   */
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private productService: ProductService,
    private inventoryService: InventoryService,
    private userService: UserService,
    private billService: BillService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.getData();
    this.createProductForm();
  }

  /** 
   * Description: Gets the agency of the person logged in.
   * @author Jesus Ponte
   * @returns void
   */
  getData(): void {
    this.userService.userProfile.subscribe(p => {
      if (p) {
        this.profile = p;
        this.products = this.productService.getAll().asObservable();
        this.inventories = this.inventoryService.getAll(ref => {
          return ref.where('agency_path', '==', p.agency_path).where('available', '==', true);
        }).asObservable();
      }
    });
  }

  /** 
   * Description: Shows modal with the selected inventory to add to cart.
   * @author Jesus Ponte
   * @param {Inventory} inventory
   * @returns void
   */
  selectInventory(inventory?: Inventory) {
    this.productForm.reset();
    if (inventory) {
      this.selectedInventory = inventory;
      this.productForm.controls['quantity'].setValue('1');
    } else {
      this.selectedInventory = undefined;
    }
  }

  /**
   * Description: Controls the quantity of the product to add to the store purchase.
   * @author Jesus Ponte
   * @returns void
   */
  selectInventoryNgSelect() {
    this.productForm.reset();
    if (this.selectedInventory) {
      this.productForm.controls['quantity'].setValue('1');
    }
  }

  /** 
   * Description: Creates the product form when accessing the product info.
   * @author Jesus Ponte
   * @returns void
   */
  createProductForm() {
    this.productForm = this.fb.group({
      quantity: ['', [Validators.required, Validators.pattern('^[1-9][0-9]*$')]],
    });
  }

  /**
   * Description: Increases number in form.
   * @author Jesus Ponte
   * @returns void
   */
  increaseQuantity() {
    const qty = parseInt(this.productForm.value.quantity, 10) + 1;
    this.productForm.controls['quantity'].setValue(qty.toString());
  }

  /**
   * Description: Decreases number in form.
   * @author Jesus Ponte
   * @returns void
   */
  decreaseQuantity() {
    let qty = parseInt(this.productForm.value.quantity, 10);
    if (qty > 0) {
      qty -= 1;
    }
    this.productForm.controls['quantity'].setValue(qty.toString());
  }

  /**
   * Description: Calculates the total cost for the purchase of all the products added to the cart.
   * @author Jesus Ponte
   * @returns void
   */
  calculateCartTotal() {
    let total = 0;
    this.cart.forEach(element => {
      total += element.inventory.product.price * element.quantity;
    });
    this.cartTotal = total;
  }

  /**
   * Description: Calculates and shows the remaining stock of the product to add to the cart.
   * @author Jesus Ponte
   * @param {any} product
   * @returns {number} Ramaining stock  
   */
  remainingStock(product?) {

    if (product) {

    } else {
      const index = this.cart.findIndex(item => item.inventory.__path === this.selectedInventory.__path);

      if (index < 0) {
        return this.selectedInventory.stock;
      } else {
        return this.selectedInventory.stock - this.cart[index].quantity;
      }
    }
  }

  /** 
   * Description: Adds the desired product to the cart array.
   * @author Jesus Ponte
   * @returns void
   */
  addToCart() {

    const cartProduct = {
      inventory: this.selectedInventory,
      quantity: (parseInt(this.productForm.value.quantity, 10))
    };

    if (this.productForm.valid) {

      const index = this.cart.findIndex(item => item.inventory.__path === cartProduct.inventory.__path);

      if (this.remainingStock() >= cartProduct.quantity) {
        if (index < 0) {
          this.cart.push(cartProduct);
        } else {
          this.cart[index].quantity += cartProduct.quantity;
        }
        this.selectInventory(),
          this.calculateCartTotal();
      } else {
        this.alertService.showAlert('There is not enough stock of the selected product, stock left:'
          + this.remainingStock(), 'alert-danger');
      }
    }
  }

  /** 
   * Description: Adds +1 to the quantity of the selected product.
   * @author Jesus Ponte
   * @param {any} cartProduct Product to add to the cart
   * @returns void
   */
  increaseItem(cartProduct) {
    if (cartProduct.inventory.stock >= (cartProduct.quantity + 1)) {
      cartProduct.quantity += 1;
    }
    this.calculateCartTotal();
  }

  /** 
   * Description: Decreases 1 unit to the quantity of the selected product.
   * @author Jesus Ponte
   * @param {any} cartProduct Product to add to the cart
   * @returns void
   */
  reduceItem(cartProduct) {
    if (cartProduct.quantity > 1) {
      cartProduct.quantity -= 1;
      this.calculateCartTotal();
    }
  }

  /**
   * Description: Deletes the selected item from the cart.
   * @author Jesus Ponte
   * @param {any} cartProduct
   * @returns void
   */
  deleteItem(cartProduct) {
    const index = this.cart.findIndex(item => item.inventory.__path === cartProduct.inventory.__path);
    this.cart.splice(index, 1);
    this.calculateCartTotal();
  }

  /** 
   * Description: Sends cart data to the store service and navigates to the purchase's bill.
   * @author Jesus Ponte
   * @returns void
   */
  proceed() {
    try {
      this.isLoading = true;
      this.calculateCartTotal();

      const data = {} as Bill;
      data.products = this.cart;
      data.agency_path = this.profile.agency_path;
      data.paymentMethod = this.selectedPayment;
      data.total = this.cartTotal;

      this.billService.create(data).then((bill) => {
        this.isLoading = false;
        this.alertService.showAlert('Bill created successfully', 'alert-success');
        this.router.navigate([`/store/bill-details/${bill.__id}`]);

        // this.router.navigate([`/store/manifest/bills/${bill.__id}`]);
      });
    } catch (error) {
      this.isLoading = false;
      this.alertService.showAlert('Something went wrong', 'alert-warning');
    }
  }

  /**
   * Description: Opens de confirmation modal, that allows to select the method of payment used.
   * @author Jesus Ponte
   * @returns void
   */
  confirmation() {
    this.toggleConfirm = !this.toggleConfirm;
  }

  /**
   * Description: Sets the selected payment method.
   * @author Jesus Ponte
   * @param {string} selection
   * @returns void 
   */
  paymentSelection(selection: string) {
    this.selectedPayment = selection;
  }

  /**
   * Description: Does not allow to purchase more products than the available quantity.
   * @author Jesus Ponte
   * @returns {boolean} 
   */
  allAvailable(): boolean {
    let proceed = true;
    this.cart.forEach(element => {
      if (element.quantity > element.inventory.stock) {
        proceed = false;
      }
    });
    return proceed;
  }

}
