import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClarityModule, ClrFormsNextModule } from '@clr/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateOrderComponent } from './orders/create-order/create-order.component';
import { LocationsService } from './services/locations.service';

// Firebase
import * as firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/functions';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from '../environments/environment';

// i18n
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// services / providers
import { I18nService } from './services/i18n.service';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { NavigationComponent } from './navigation/navigation/navigation.component';
import { OrderListComponent } from './orders/order-list/order-list.component';
import { CheckboxListClrDatagridFilterInterfaceComponent } from './components/datagrid-filters/checkbox-list-filter.component';
import { ManifestListComponent } from './components/manifest-list/manifest-list.component';
import { ManifestDetailComponent } from './components/manifest-detail/manifest-detail.component';
import { AgmDirectionModule } from 'agm-direction';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';

// test
import { TestComponent } from './test/test.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
// Aether Blaze
// import { EntityFirebaseProvider } from '../aether-blaze/src/';
import { EntityFirebaseProvider } from 'aether-blaze';

// Services
import { UserService } from './services/user.service';
import { AuthService } from '../app/services/auth.service';
import { OrderService } from './services/order.service';
import { RateService } from './services/rate.service';
import { AgencyService } from './services/agency.service';
import { PersonService } from './services/person.service';
import { XlsxService } from './services/xlsx.service';

// Pipes
import { FilterByDates, FilterByDeleted, OrderByName, FilterByString, FilterByAvailable, FilterByEnabled, FilterByPending } from './pipes/filter-pipe';
import { DatePipe } from '@angular/common';

import { NgSelectModule } from '@ng-select/ng-select';

// Components
import { LoginComponent } from './login/login.component';
import { NewMoneyOrdersComponent } from './orders/new-money-orders/new-money-orders.component';
import { QuotationComponent } from './components/quotation/quotation.component';
import { ManifestClrDatagridFilterInterfaceComponent } from './components/datagrid-filters/manifest-filter.component';
import { DateClrDatagridFilterInterfaceComponent } from './components/datagrid-filters/date-filter.component';
import { CheckboxClrDatagridFilterInterfaceComponent } from './components/datagrid-filters/checkbox-filter.component';
import { OrdersDetailsComponent } from './orders/orders-details/orders-details.component';
import { OrderManifestComponent } from './orders/order-manifest/order-manifest.component';
import { ManifestPackageListComponent } from './admin/manifests/manifest-package-list/manifest-package-list.component';
import { ManifestMoneyListComponent } from './admin/manifests/manifest-money-list/manifest-money-list.component';
import { ManifestDetailsComponent } from './admin/manifests/manifest-details/manifest-details.component';
import { ShipmentMoneyListComponent } from './admin/shipments/shipment-money-list/shipment-money-list.component';
import { ShipmentPackageListComponent } from './admin/shipments/shipment-package-list/shipment-package-list.component';
import { ShipmentDetailsComponent } from './admin/shipments/shipment-details/shipment-details.component';
import { RatesListComponent } from './admin/rates/rates-list/rates-list.component';
import { CreateRateComponent } from './admin/rates/create-rate/create-rate.component';
import { ProfileViewComponent } from './profile/profile-view/profile-view.component';
import { ProfileEditComponent } from './profile/profile-edit/profile-edit.component';
import { ProductEditComponent } from './admin/products/product-edit/product-edit.component';
import { ProductDetailsComponent } from './admin/products/product-details/product-details.component';
import { AgenciesListComponent } from './admin/agencies/agencies-list/agencies-list.component';
import { AgencyDetailsComponent } from './admin/agencies/agency-details/agency-details.component';
import { AgencyCreateComponent } from './admin/agencies/agency-create/agency-create.component';
import { AgencyEditComponent } from './admin/agencies/agency-edit/agency-edit.component';
import { ProductCreateComponent } from './admin/products/product-create/product-create.component';
import { InviteComponent } from './admin/invite/invite.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { StorePurchaseComponent } from './store/store-purchase/store-purchase.component';
import { SendersComponent } from './components/senders/senders.component';
import { RecipientsComponent } from './components/recipients/recipients.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { StoreManifestComponent } from './store/store-manifest/store-manifest.component';
import { BoxesTableComponent } from './components/boxes-table/boxes-table.component';
import { AddressesComponent } from './components/addresses/addresses.component';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { OnlyTextDirective } from './directives/only-text.directive';
import { AlertsComponent } from './components/alerts/alerts.component';
import { AlertService } from './services/alert.service';
import { LoadingComponent } from './components/loading/loading.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileUploadBase64Component } from './components/file-upload-base64/file-upload-base64.component';
import { ProductsListComponent } from './admin/products/products-list/products-list.component';
import { BillListComponent } from './store/bill-list/bill-list.component';
import { BillDetailsComponent } from './store/bill-details/bill-details.component';
import { MoneyOrderListComponent } from './orders/money-order-list/money-order-list.component';
import { StoreCategoriesComponent } from './store/store-categories/store-categories.component';
import { StoreProductsComponent } from './store/store-products/store-products.component';
import { AgencyProductsComponent } from './store/agency-products/agency-products.component';
import { AgProductsEditComponent } from './store/agency-products/ag-products-edit/ag-products-edit.component';
import { AgProductsCreateComponent } from './store/agency-products/ag-products-create/ag-products-create.component';
import { LockerListComponent } from './locker/locker-list/locker-list.component';
import { LockerDetailsComponent } from './locker/locker-details/locker-details.component';
import { AgProductsDetailsComponent } from './store/agency-products/ag-products-details/ag-products-details.component';
import { InventoryComponent } from './store/inventory/inventory.component';
import { InventoryDetailsComponent } from './store/inventory/inventory-details/inventory-details.component';
import { InventoryCreateComponent } from './store/inventory/inventory-create/inventory-create.component';
import { StoreProductCreateComponent } from './store/store-products/store-product-create/store-product-create.component';
import { StoreProductEditComponent } from './store/store-products/store-product-edit/store-product-edit.component';
import { StoreProductDetailsComponent } from './store/store-products/store-product-details/store-product-details.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { PromotionsCreateComponent } from './admin/promotions/promotions-create/promotions-create.component';
import { CompleteFilterComponent } from './components/complete-filter/complete-filter.component';
import { PickUpListComponent } from './pick-up/pick-up-list/pick-up-list.component';
import { PickUpDetailsComponent } from './pick-up/pick-up-details/pick-up-details.component';
import { PromotionsListComponent } from './admin/promotions/promotions-list/promotions-list.component';
import { PromotionsDetailsComponent } from './admin/promotions/promotions-details/promotions-details.component';
import { StoreOrdersListComponent } from './store/store-orders/store-orders-list/store-orders-list.component';
import { StoreOrderDetailsComponent } from './store/store-orders/store-order-details/store-order-details.component';
import { InventoryRequestsComponent } from './store/inventory-requests/inventory-requests.component';
import { IvRequestsDetailsComponent } from './store/inventory-requests/iv-requests-details/iv-requests-details.component';
import { PersonsListComponent } from './persons/persons-list/persons-list.component';

// const app = firebase.initializeApp(environment.firebase);
// var functions = firebase.functions();
// tslint:disable-next-line:no-unused-expression
// new EntityFirebaseProvider(app);

@NgModule({
  declarations: [
    AppComponent,
    CreateOrderComponent,
    NavigationComponent,
    OrderListComponent,
    UserManagementComponent,
    NavigationComponent,
    CheckboxListClrDatagridFilterInterfaceComponent,
    LoginComponent,
    ManifestListComponent,
    ManifestDetailComponent,
    ManifestPackageListComponent,
    ManifestMoneyListComponent,
    ManifestDetailsComponent,
    ShipmentMoneyListComponent,
    ShipmentPackageListComponent,
    ShipmentDetailsComponent,
    FilterByDates,
    FilterByDeleted,
    FilterByAvailable,
    FilterByEnabled,
    OrderByName,
    FilterByString,
    FilterByPending,
    TestComponent,
    NewMoneyOrdersComponent,
    QuotationComponent,
    OrdersDetailsComponent,
    OrderManifestComponent,
    ManifestClrDatagridFilterInterfaceComponent,
    RatesListComponent,
    CreateRateComponent,
    DateClrDatagridFilterInterfaceComponent,
    CheckboxClrDatagridFilterInterfaceComponent,
    OrdersDetailsComponent,
    ProfileViewComponent,
    ProfileEditComponent,
    ProductEditComponent,
    ProductDetailsComponent,
    AgenciesListComponent,
    AgencyDetailsComponent,
    AgencyCreateComponent,
    AgencyEditComponent,
    ProductCreateComponent,
    TestComponent,
    InviteComponent,
    LoginButtonComponent,
    DashboardComponent,
    // StorePurchaseComponent
    SendersComponent,
    RecipientsComponent,
    StorePurchaseComponent,
    StoreManifestComponent,
    BoxesTableComponent,
    AddressesComponent,
    OnlyNumbersDirective,
    OnlyTextDirective,
    AlertsComponent,
    LoadingComponent,
    FileUploadComponent,
    FileUploadBase64Component,
    ProductsListComponent,
    BillListComponent,
    BillDetailsComponent,
    MoneyOrderListComponent,
    StoreCategoriesComponent,
    StoreProductsComponent,
    AgencyProductsComponent,
    AgProductsEditComponent,
    AgProductsCreateComponent,
    LockerListComponent,
    LockerDetailsComponent,
    AgProductsDetailsComponent,
    InventoryComponent,
    InventoryDetailsComponent,
    InventoryCreateComponent,
    StoreProductCreateComponent,
    StoreProductEditComponent,
    StoreProductDetailsComponent,
    NotFoundComponent,
    NotAuthorizedComponent,
    PromotionsCreateComponent,
    CompleteFilterComponent,
    PickUpListComponent,
    PickUpDetailsComponent,
    PromotionsListComponent,
    PromotionsDetailsComponent,
    StoreOrdersListComponent,
    StoreOrderDetailsComponent,
    InventoryRequestsComponent,
    IvRequestsDetailsComponent,
    PersonsListComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDsg9Iqj8j06H8U4g84PYyWn8w6rO_MqOc',
      libraries: ['places']
    }),
    AgmDirectionModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    HttpClientModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ClarityModule,
    ClrFormsNextModule,
    BrowserAnimationsModule,
    NgSelectModule
  ],
  providers: [
    I18nService,
    UserService,
    AuthService,
    OrderService,
    RateService,
    AgencyService,
    PersonService,
    XlsxService,
    AlertService,
    DatePipe,
    LocationsService,
    GoogleMapsAPIWrapper,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor() {
    const app = firebase.initializeApp(environment.firebase);
    // tslint:disable-next-line:no-unused-expression
    new EntityFirebaseProvider(app);

    const firestore = firebase.firestore();
    const functions = firebase.functions();
    const settings = { timestampsInSnapshots: true };
    firestore.settings(settings);
  }

}
