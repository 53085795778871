import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location as navLocation } from '@angular/common';
import { BillService } from 'src/app/services/bill.service';
import { Bill, Agency } from 'src/app/models';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { switchMap, tap } from 'rxjs/operators';
import { AgencyService } from 'src/app/services/agency.service';

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.scss']
})
export class BillDetailsComponent implements OnInit {

  constructor(
    private billService: BillService,
    private location: navLocation,
    private route: ActivatedRoute,
    private agencyService: AgencyService,
    private sanitizer: DomSanitizer,
  ) { }
  public cart: any[] = [];
  public cartTotal: number = 0;
  public paymentMethod: string;
  public agency: Agency;
  public bill: Bill;

  public orderAgency: Agency;
  public noLogo: string = '../../assets/no-logo.png';
  srcData: SafeResourceUrl;
  img: any;
  imageToShow: string | ArrayBuffer;

  ngOnInit() {

    this.route.params.first().pipe(
      switchMap(params => {
        this.bill = this.billService.get(params['id']);
        this.cartTotal = this.bill.total;
        this.paymentMethod = this.bill.paymentMethod;
        return this.bill.__asObservable();
      }),
      switchMap((bill: Bill) => {
        this.orderAgency = new Agency(bill.agency_path)
        return this.orderAgency.__asObservable();
      }),
      switchMap((agency: Agency) => {
        return this.agencyService.getBase64Img(agency.timestamp);
      }),
      tap( i => {
        this.img = this.sanitizer.bypassSecurityTrustUrl(i.base64);
      })
    ).subscribe( _ => {
      // console.log(this.bill)
    });

    // this.route.params.subscribe(params=>{
    //   this.bill = this.billService.get(params['id']);
    //   this.cartTotal = this.bill.total;
    //   this.paymentMethod = this.bill.paymentMethod;
    // });
  }

  getPdf() {
    let pdf = new jspdf('p', 'mm', 'a4');
    let element = document.getElementById('pdf');
    html2canvas(element).then(canvas => {
      const margin = 10;
      const imgWidth = 190;
      const imgHeight = canvas.height * 1.30 * imgWidth / canvas.width;
      const pageHeight = 295;

      const contentDataURL = canvas.toDataURL('img/png');
      pdf.addImage(contentDataURL, 'PNG', margin, margin, imgWidth, imgHeight);
    }).then(_ => {
      pdf.save(`Bill ${this.bill.__id}`);
    })
  }

  /**
   * Description: Navigates to the previous navigation location.
   * @author Jesus Ponte
   * @returns void
   */
  goBack() {
    this.location.back();
  }

}
