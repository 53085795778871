import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AgencyService } from '../../../services/agency.service';
import { LocationsService } from '../../../services/locations.service';
import { Agency, Rate, Location } from '../../../models';
import { DatePipe, Location as location } from '@angular/common';
import { RateService } from '../../../services/rate.service';
import { Collection } from 'aether-blaze';
import { Observable } from 'rxjs';
// import { get } from 'https';


@Component({
  selector: 'app-create-rate',
  templateUrl: './create-rate.component.html',
  styleUrls: ['./create-rate.component.scss']
})
export class CreateRateComponent implements OnInit {

  public loading = true;

  public zoneForm: FormGroup;
  public locationForm: FormGroup;

  public type = '';
  public modalOn = false;

  public editingRate: Rate;
  public editing = false;


  public allAgencies: Observable<Collection<Agency>>;
  public rateZones: any; // for testing purposes only
  public invalidSelectedLocation = false;

  public countries: Collection<Location>;
  public states: Observable<Collection<Location>>;
  public cities: Observable<Collection<Location>>;

  public selectedCity = '';
  public selectedState = '';
  public selectedCountry = '';

  public selectedAgency = '';

  public locationsSet = new Set();

  /**
   * @constructor
   * @param {FormBuilder} fb Creates a form for a location
   * @param {ActivatedRoute} activatedRoute Current navigation route
   * @param {AgencyService} agencyService Service for agencies management
   * @param {RateService} rateService Service for rates management
   * @param {DatePipe} datePipe Pipe to format a date
   * @param {location} loc Current navigation location
   * @param {LocationsService} locationsService Service for locations management
   */
  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private agencyService: AgencyService,
    private rateService: RateService,
    private datePipe: DatePipe,
    private loc: location,
    private locationsService: LocationsService) { }

  ngOnInit() {
    this.getCountries();
    this.rateService.getAll();
    // fetching all agencies registered for asignation
    this.allAgencies = this.agencyService.getAll().asObservable();
    // fetching id from routing params
    this.activatedRoute.params.subscribe(async (params: Params) => {
      // fetching all agencies registered for asignation
      this.allAgencies = this.agencyService.getAll((ref) => ref.where('deleted', '==', false)).asObservable();
      // declare rates array empty
      this.rateZones = [];
      // conditioning for edition
      if (params['id']) {
        this.editing = true;
        // getting the rate to edit
        await this.rateService.get(params['id']).__toPromise()
          .then((result) => {
            // arrives and assign it to editingRate
            this.editingRate = result;
            // assign type
            this.type = result.type;
          })
          .catch((err) => {
            console.error('Error fetching the requested Rate');
            console.error(err);
          });
      }
      // create the form
      await this.createLocationsForm();
      await this.createZonesForm();
      if (this.editingRate) {
        for (const loc of this.editingRate.locations) {
          const formLocations = this.zoneForm.get('locations') as FormArray;
          formLocations.push(this.fb.group({
            country: loc.country,
            state: loc.state,
            city: loc.city,
          }));
        }
      }
      this.loading = false;
    });
  }

  /**
  * Description: Adds a new location for the new zone.
  * @author Maximiliano Casale
  * @returns void
  */
  addLocation(): void {
    // pushing to the arrayForm
    const locations = this.zoneForm.get('locations') as FormArray;
    locations.push(this.fb.group({
      country: this.locationForm.get('country').value,
      state: this.locationForm.get('state').value,
      city: this.locationForm.get('city').value
    }));
    // pushing to the validation Set
    const loc = {
      city: this.locationForm.get('city').value,
      country: this.locationForm.get('country').value,
      state: this.locationForm.get('state').value,
    };
    this.locationsSet.add(JSON.stringify(loc));
    this.locationForm.reset();
    this.selectedCountry = '';
  }

  /**
   * Description: Deletes the selected location from the zone.
   * @author Maximiliano Casale
   * @param {number} i Position of the location in the locations array
   * @returns void
   */
  deleteZoneRate(i: number): void {
    const locations = this.zoneForm.get('locations') as FormArray;
    let loc = locations.controls[i].value;
    locations.removeAt(i);
    loc = {
      city: loc.city,
      country: loc.country,
      state: loc.state,
    };
    this.locationsSet.delete(JSON.stringify(loc));
  }

  /**
   * Description: Updates the rates of the selected zone to edit.
   * @author Maximiliano Casale
   * @returns void
   */
  public update(): void {
    this.editingRate.active = true;
    this.editingRate.aerialRate = {
      minPounds: this.zoneForm.get('minAerialPounds').value,
      private: this.zoneForm.get('aerialPrivate').value,
      public: this.zoneForm.get('aerialPublic').value,
      tax: this.zoneForm.get('aerialZoneTax').value,
      shipmentType: 'Aerial',
    };
    this.editingRate.agency_path = this.zoneForm.get('agency').value;
    this.editingRate.deleted = false;
    this.editingRate.insurance = this.zoneForm.get('insurance').value;
    this.editingRate.minimum_insurance = this.zoneForm.get('minimum_insurance').value;
    this.editingRate.locations = this.zoneForm.get('locations').value;
    this.editingRate.maritimeRate = {
      minPounds: this.zoneForm.get('minMaritimePounds').value,
      private: this.zoneForm.get('maritimePrivate').value,
      public: this.zoneForm.get('maritimePublic').value,
      tax: this.zoneForm.get('maritimeZoneTax').value,
      shipmentType: 'Maritime',
    };
    this.editingRate.name = this.zoneForm.get('name').value;
    this.editingRate.type = this.zoneForm.get('zoneType').value;
    if (this.editingRate.type === 'Global') {
      this.editingRate.agency_path = null;
    }
    this.editingRate.customsManagement = this.zoneForm.get('customs_management').value;
    this.editingRate.minCustomsManagement = this.zoneForm.get('min_customs_management').value;
    this.loading = true;
    this.rateService.update(this.editingRate).then(() => {
      this.loading = false;
      this.loc.back();
    });
  }

  /**
   * Description: Method for submitting form values to the service.
   * @author Maximiliano Casale
   * @returns void
   */
  public submit(): void {
    this.loading = true;
    const newRate = {} as Rate;
    newRate.active = true;
    newRate.aerialRate = {
      minPounds: this.zoneForm.get('minAerialPounds').value,
      private: this.zoneForm.get('aerialPrivate').value,
      public: this.zoneForm.get('aerialPublic').value,
      tax: this.zoneForm.get('aerialZoneTax').value,
      shipmentType: 'Aerial',
    };
    newRate.agency_path = this.zoneForm.get('agency').value;
    newRate.deleted = false;
    newRate.insurance = this.zoneForm.get('insurance').value;
    newRate.minimum_insurance = this.zoneForm.get('minimum_insurance').value;
    newRate.locations = this.zoneForm.get('locations').value;
    newRate.maritimeRate = {
      minPounds: this.zoneForm.get('minMaritimePounds').value,
      private: this.zoneForm.get('maritimePrivate').value,
      public: this.zoneForm.get('maritimePublic').value,
      tax: this.zoneForm.get('maritimeZoneTax').value,
      shipmentType: 'Maritime',
    };
    newRate.name = this.zoneForm.get('name').value;
    newRate.type = this.zoneForm.get('zoneType').value;
    if (newRate.type === 'Global') {
      delete newRate.agency_path;
    }
    newRate.customsManagement = this.zoneForm.get('customs_management').value;
    newRate.minCustomsManagement = this.zoneForm.get('min_customs_management').value;
    this.rateService.create(newRate).then(_ => { this.loading = false, this.loc.back(); });
  }

  /**
   * Description: Creates the form to add a new location to the zone details.
   * @author Maximiliano Casale
   * @returns void
   */
  public createLocationsForm(): void {
    this.locationForm = this.fb.group({
      'country': new FormControl('', [Validators.required]),
      'state': new FormControl('', [Validators.required]),
      'city': new FormControl('', [Validators.required])
    });

    this.locationForm.get('country').valueChanges.subscribe(country => {
      this.selectedCountry = country;
      this.selectedState = '';
      this.locationForm.get('state').setValue('');
      this.selectedCity = '';
      this.locationForm.get('city').setValue('');
      this.getStates();
    });

    this.locationForm.get('state').valueChanges.subscribe(state => {
      this.selectedState = state;
      this.selectedCity = '';
      this.locationForm.get('city').setValue('');
      this.getCities();
    });

    this.locationForm.get('city').valueChanges.subscribe(city => {
      this.selectedCity = city;
      this.validateLocation();
    });

  }

  /**
   * Description: Initializes the form for the rate.
   * @author Maximiliano Casale
   * @returns void
   */
  public createZonesForm(): void {

    this.zoneForm = this.fb.group({
      'name': new FormControl('', [Validators.required, Validators.minLength(3)]),
      'zoneType': new FormControl('', [Validators.required]),
      'insurance': new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      'minimum_insurance': new FormControl('', [Validators.required, Validators.min(0)]),
      'aerialZoneTax': new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      'aerialPublic': new FormControl('', [Validators.required, Validators.min(0)]),
      'aerialPrivate': new FormControl('', [Validators.required, Validators.min(0)]),
      'minAerialPounds': new FormControl('', [Validators.required, Validators.min(0)]),
      'maritimeZoneTax': new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      'maritimePublic': new FormControl('', [Validators.required, Validators.min(0)]),
      'maritimePrivate': new FormControl('', [Validators.required, Validators.min(0)]),
      'minMaritimePounds': new FormControl('', [Validators.required, Validators.min(0)]),
      'customs_management': new FormControl('', [Validators.min(0)]),
      'min_customs_management': new FormControl('', [Validators.min(0)]),
      'agency': new FormControl('', [Validators.required]),
      'locations': this.fb.array(this.rateZones, [Validators.required, Validators.minLength(1)])
    });
    this.zoneForm.get('aerialPublic').valueChanges.subscribe((limit: number) => {
      this.zoneForm.get('aerialPrivate').setValidators([Validators.required, Validators.min(0), Validators.max(limit)]);
      this.zoneForm.get('aerialPrivate').markAsTouched();
      this.zoneForm.get('aerialPrivate').updateValueAndValidity();
    });

    this.zoneForm.get('maritimePublic').valueChanges.subscribe((limit: number) => {
      this.zoneForm.get('maritimePrivate').setValidators([Validators.required, Validators.min(0), Validators.max(limit)]);
      this.zoneForm.get('maritimePrivate').markAsTouched();
      this.zoneForm.get('maritimePrivate').updateValueAndValidity();
    });

    this.zoneForm.get('zoneType').valueChanges.subscribe(
      (type: string) => {
        // this.rateZones = [{}];
        // this.zoneForm.get('locations').setValue(this.rateZones);
        if (type === 'Local') {
          this.zoneForm.get('agency').setValidators([Validators.required]);
        } else if (type === 'Global') {
          this.zoneForm.get('agency').setValidators([]);
          this.getGlobalRates();
        }
        this.zoneForm.controls['agency'].reset('');
        this.rateZones = [];
        this.zoneForm.get('locations').reset(this.rateZones);
        this.zoneForm.get('agency').updateValueAndValidity();
      }
    );
    this.zoneForm.get('agency').valueChanges.subscribe(_ => {
      const length = this.zoneForm.get('locations').value.length;
      for (let i = 0; i < length; i++) {
        this.deleteZoneRate(0);
      }
      this.rateZones = [];
      this.getAgencyRates();
    });

    if (this.editingRate) {
      this.zoneForm.controls['name'].setValue(this.editingRate.name);
      this.zoneForm.controls['zoneType'].setValue(this.editingRate.type);
      if (this.editingRate.agency_path) {
        this.zoneForm.controls['agency'].setValue(this.editingRate.agency_path);
        this.selectedAgency = this.editingRate.agency_path;
      }
      this.zoneForm.controls['insurance'].setValue(this.editingRate.insurance);
      this.zoneForm.controls['minimum_insurance'].setValue(this.editingRate.minimum_insurance);
      this.zoneForm.controls['aerialZoneTax'].setValue(this.editingRate.aerialRate.tax);
      this.zoneForm.controls['aerialPublic'].setValue(this.editingRate.aerialRate.public);
      this.zoneForm.controls['aerialPrivate'].setValue(this.editingRate.aerialRate.private);
      this.zoneForm.controls['minAerialPounds'].setValue(this.editingRate.aerialRate.minPounds);
      this.zoneForm.controls['maritimeZoneTax'].setValue(this.editingRate.maritimeRate.tax);
      this.zoneForm.controls['maritimePublic'].setValue(this.editingRate.maritimeRate.public);
      this.zoneForm.controls['maritimePrivate'].setValue(this.editingRate.maritimeRate.private);
      this.zoneForm.controls['minMaritimePounds'].setValue(this.editingRate.maritimeRate.minPounds);
      this.zoneForm.controls['customs_management'].setValue(this.editingRate.customsManagement);
      this.zoneForm.controls['min_customs_management'].setValue(this.editingRate.minCustomsManagement);


    }
  }

  /**
   * Description: Shows a list of available countries to add in the "add location" section.
   * @author Maximiliano Casale
   * @returns void
   */
  getCountries(): void {
    this.selectedCountry = '';
    this.locationsService.getCountries().asObservable().subscribe((p) => {
      this.countries = p;
    });
  }

  /**
   * Description: Gets the states of the selected country.
   * @author Maximiliano Casale
   * @returns void
   */
  getStates() {
    this.selectedState = '';
    this.states = this.selectedCountry && this.selectedCountry !== 'All'
      ? this.locationsService.getStates(this.selectedCountry).asObservable() : null;
  }

  /**
   * Description: Gets the cities of the selected country's state.
   * @author Maximiliano Casale
   * @returns void
   */
  getCities() {
    this.selectedCity = '';
    this.cities = this.selectedState && this.selectedState !== 'All'
      ? this.locationsService.getCities(this.selectedState).asObservable() : null;
  }

  /**
   * Description: Gets the global rates.
   * @author Maximiliano Casale
   * @returns void
   */
  public getGlobalRates(): void {
    let rates;
    this.rateService.getAll(ref => ref.where('type', '==', 'Global')).asObservable().subscribe(async ratesCollection => {
      rates = ratesCollection.iterable;
      // console.log(rates);
      this.locationsSet = new Set();
      for (const rate of rates) {
        for (const loc of rate.locations) {
          this.locationsSet.add(JSON.stringify(loc));
        }
      }
    });
  }

  /**
   * Description: Gets the rates of a specific agency.
   * @author Maximiliano Casale
   * @returns void
   */
  public getAgencyRates(): void {
    if (this.zoneForm.get('agency').value.length > 0) {
      this.rateService.getAll(
        (ref) => ref.where('agency_path', '==', this.zoneForm.get('agency').value)).asObservable().subscribe((rates) => {
          this.locationsSet = new Set();
          for (const rate of rates.iterable) {
            for (const loc of rate.locations) {
              this.locationsSet.add(JSON.stringify(loc));
            }
          }
          // console.log(this.locationsSet);
        });
    }
  }

  /**
   * Description: Validates if the selected location has already been set to another zone.
   * @author Maximiliano Casale
   * @returns void
   */
  public validateLocation(): void {
    const selectedLocation = {
      city: this.locationForm.get('city').value,
      country: this.locationForm.get('country').value,
      state: this.locationForm.get('state').value,
    };
    this.invalidSelectedLocation = this.locationsSet.has(JSON.stringify(selectedLocation));
  }

  /**
   * Description: Getters for form validations.
   * @author Maximiliano Casale
   * @returns {string | number} name, type of zone, insurance %, aerial zone tax %, aerial public rate,
   * aerial private rate, minimun aerial cubic feets, maritime zone tax %, maritime public rate,
   * maritime private rate, minimun maritime cubic feets, locations, user agency, countries, states and cities.
   */
  get name() { return this.zoneForm.get('name'); }
  get zoneType() { return this.zoneForm.get('zoneType'); }
  get insurance() { return this.zoneForm.get('insurance'); }
  get minimum_insurance() { return this.zoneForm.get('minimum_insurance'); }
  get aerialZoneTax() { return this.zoneForm.get('aerialZoneTax'); }
  get aerialPublic() { return this.zoneForm.get('aerialPublic'); }
  get aerialPrivate() { return this.zoneForm.get('aerialPrivate'); }
  get minAerialPounds() { return this.zoneForm.get('minAerialPounds'); }
  get maritimeZoneTax() { return this.zoneForm.get('maritimeZoneTax'); }
  get maritimePublic() { return this.zoneForm.get('maritimePublic'); }
  get maritimePrivate() { return this.zoneForm.get('maritimePrivate'); }
  get minMaritimePounds() { return this.zoneForm.get('minMaritimePounds'); }
  get locations() { return this.zoneForm.get('locations'); }
  get agency() { return this.zoneForm.get('agency'); }
  get country() { return this.locationForm.get('country'); }
  get state() { return this.locationForm.get('state'); }
  get city() { return this.locationForm.get('city'); }

}
