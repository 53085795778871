import { Injectable } from '@angular/core';
import { Bill } from '../models';
import { Collection } from 'aether-blaze';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { InventoryService } from './inventory.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillService {

  private pathUrl = '/bills/';
  public bill: Bill;

  /**
   * @constructor
   * @param {AngularFirestore} afs Document-oriented database
   * @param {InventoryService} inventoryService Service for inventories management
   */
  constructor(
    private afs: AngularFirestore,
    private inventoryService: InventoryService
  ) { }

  /**
   * Description: Gets a specific bill by its id.
   * @author Mauro Lanza
   * @param {string} id Id of the requested bill
   * @returns {Bill} Requested bill
   */
  public get(id: string): Bill {
    return new Bill(this.pathUrl + id);
  }

  /**
   * Description: Allows to get the entire collection of bills.
   * @author Mauro Lanza
   * @param query
   * @returns {Collection<Bill>} Collection of bills
   */
  public getAll(
    query?: (ref: firebase.firestore.CollectionReference) => firebase.firestore.Query): Collection<Bill> {
    if (query) {
      return new Collection<Bill>(Bill, this.pathUrl, query);
    } else {
      return new Collection<Bill>(Bill, this.pathUrl);
    }
  }

  /**
   * Description: Adds the new bill received as param to the bills collection.
   * @author Mauro Lanza
   * @param {any} bill New bill
   * @returns {Promise<Bill>} Addition confirmation
   */
  public create(bill): Promise<Bill> {
    const cart = [];
    bill.products.forEach(product => {
      this.inventoryService.reduceStock(product.inventory.__path, product.quantity);
      cart.push({
        name: product.inventory.product.name,
        description: product.inventory.product.description,
        image: product.inventory.product.image,
        price: product.inventory.product.price,
        tax: product.inventory.product.tax ? product.inventory.product.tax : null,
        sku: product.inventory.product.sku ? product.inventory.product.sku : null,
        quantity: product.quantity,
      });
    });
    bill.products = cart;
    bill.date = firebase.firestore.Timestamp.now();

    return new Promise<Bill>((resolve) => {
      firebase.firestore().collection(this.pathUrl).add(bill).then((docRef) => {
        this.get(docRef.id).__toPromise().then((p) => {
          resolve(p);
        });
      });
    });
  }

  /**
   * Description: Updates a bill's data in the bills collection.
   * @author Mauro Lanza
   * @param {Bill} data Data of the updated bill
   * @returns {Promise<void>} Operation confirmation
   */
  public update(data: Bill): Promise<void> {
    return new Promise<void>((resolve) => {
      data.__save(true).then(() => resolve());
    });
  }

}
